import React, { useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  List,
  CircularProgress,
} from "@mui/material";
import {
  ParameterMastersInputType,
  ParameterMastersModules,
} from "../../config/masterdata";

const CreateField = ({
  formData,
  handleChange,
  handleCreate,
  createMasterDataMutation,
  errors,
  openCreateMasters,
  handleCloseCreateMasters,
  setFormData,
}) => {
  const [inputValue, setInputValue] = useState(null);
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [inputType, setInputType] = useState("number"); // 'number' or 'alphanumeric'

  const handleCloseDialog = () => {
    setList([]);
    setInputValue(null);
    setError("");
    setInputType("number");
    handleCloseCreateMasters();
  };

  const handleAdd = () => {
    // Validation for numbers or alphanumeric
    const isNumber = inputType === "number";
    const isText = inputType === "alphanumeric" && inputValue.trim();

    if (
      (isText && list.includes(inputValue.trim())) ||
      (isNumber && list.includes(inputValue))
    ) {
      setError("Duplicate entry! Please enter a unique value.");
    } else if (
      (isText && inputValue.trim() === "") ||
      (isNumber && inputValue === "")
    ) {
      setError("Input cannot be empty.");
    } else if (inputType === "number" && !isNumber) {
      setError("Please enter a valid number.");
    } else if (inputType === "alphanumeric" && !isText) {
      setError("Please enter a valid alphanumeric value.");
    } else {
      const updatedList = [...list, inputValue];
      setList(isNumber ? [...list, inputValue] : [...list, inputValue.trim()]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        options: updatedList,
      }));
      setInputValue("");
      // console.log("option list: ", list);
      // console.log("inputType: ", inputType);
      setError("");
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    setError(""); // Reset error when typing
  };

  const handleOptionChange = (e) => {
    setInputType(e.target.value);
    setList([]);
    setInputValue(""); // Reset input when switching option type
    setError(""); // Reset error
  };

  return (
    <Dialog
      open={openCreateMasters}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <h3>Create Parameter Masters</h3>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={errors.name} // Display red border if there's an error
          helperText={errors.name ? "Name is required" : ""} // Show error message
        />
        <TextField
          fullWidth
          select
          margin="normal"
          label="Category"
          name="category"
          value={formData.category}
          onChange={handleChange}
          error={errors.category}
          helperText={errors.category ? "Category is required" : ""}
        >
          {ParameterMastersModules?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          margin="normal"
          label="Input Type"
          name="inputType"
          value={formData.inputType}
          onChange={handleChange}
          error={errors.inputType}
          helperText={errors.inputType ? "Input Type is required" : ""}
        >
          {ParameterMastersInputType?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          margin="normal"
          label="Out of norms"
          name="outOfNorms"
          value={formData.outOfNorms}
          onChange={handleChange}
          error={errors.outOfNorms}
          helperText={errors.outOfNorms ? "This field is required" : ""}
        >
          {["Yes", "No"]?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {(formData.inputType === "Single select" ||
          formData.inputType === "Multiple select") && (
          <>
            {/* Radio Button for selecting Input Type */}
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Option Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={inputType}
                name="radio-buttons-group"
                onChange={handleOptionChange}
                row // This will display the radio buttons in a row (horizontal layout)
              >
                <FormControlLabel
                  value="number"
                  control={<Radio />}
                  label="Number"
                />
                <FormControlLabel
                  value="alphanumeric"
                  control={<Radio />}
                  label="Text"
                />
              </RadioGroup>
            </FormControl>

            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                label={`Enter ${inputType === "number" ? "Number" : "Text"}`}
                variant="outlined"
                type={inputType === "number" ? "number" : "text"}
                value={inputValue}
                // onChange={handleInputChange}
                onChange={(e) => {
                  const value =
                    inputType === "number" ? +e.target.value : e.target.value;
                  handleInputChange(value);
                }}
                error={!!error}
                helperText={error}
                fullWidth
                style={{ borderColor: error ? "red" : "inherit" }}
              />

              {/* Add Button */}
              <Button variant="contained" color="primary" onClick={handleAdd}>
                Add
              </Button>
            </Box>

            <p style={{ fontWeight: "bold", marginTop: "2%" }}>
              List of Items: {}
            </p>
            {errors.options && (
              <p style={{ color: "red" }}>
                Please add at least one item to the list.
              </p>
            )}
            <List>
              {list.map((item, index) => (
                // <ListItem key={index}>
                //   <ListItemText primary={item} />
                // </ListItem>
                <span key={index}>{item}, </span>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Button
          onClick={handleCreate}
          disabled={createMasterDataMutation?.isLoading}
          variant="contained"
          color="primary"
        >
          {createMasterDataMutation?.isLoading ? (
            <CircularProgress size={25} />
          ) : (
            "Create"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateField;
