import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getAllSubmittedData, getStandardsMasterData } from "./mutation";
import {
  FormControl,
  Select,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
import dayjs from "dayjs";
import CheckOverflow from "../../../utils/CheckOverflow";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import { handleToastMsg } from "../../../components/Toast";

const AllPreviousEntries = ({ goBack, masterKey }) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");

  // const {
  //   data: allSubmittedRecords,
  //   isLoading,
  //   refetch,
  // } = useQuery("all-submitted-data", () => getAllSubmittedData(masterKey));
  const {
    data: allSubmittedRecords,
    isLoading,
    refetch,
  } = useQuery("all-submitted-data", () => getAllSubmittedData(masterKey));

  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [applied, setApplied] = useState(false);
  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedRecords?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedRecords]);

  //this is for get api for the standard data
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  //this is for showing the variety show the value color in red or green  in the ui
  const params = { partyCode, key: masterKey };
  const [apiStandardsData, setApiStandardsData] = useState({});
  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      setAllDates([]);
      setSelectedDates([]);
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { refetch: standardRefetch } = useQuery(
    "all-standard-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      standardRefetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, standardRefetch]);

  //handle party code
  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
  };

  const handlePartyFilter = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false);
    setSelectedDates([]);
    setApplied(false);
    setApiStandardsData({});
  };

  //this is for when the partyCode is changing we have to call the function with apply
  useEffect(() => {
    //this is for when partyCode is empty
    if (partyCode === "") {
      handleResetPartyCode();
    } //and this is for when partyCode is changing we have to reset all the things
    else {
      setShouldFetch(false);
      setSelectedDates([]);
      setApplied(false);
      setApiStandardsData({});
    }
  }, [partyCode]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const removeDate = (dateToRemove) => {
    setSelectedDates((prevDates) =>
      prevDates?.filter((date) => date !== dateToRemove)
    );
  };

  // const filteredDataFromApi =
  //   allSubmittedRecords?.data?.filter(
  //     (item) =>
  //       item.partyCode === partyCode && selectedDates?.includes(item.date)
  //   ) || [];

  //this is for rerender issue
  const filteredDataFromApi = useMemo(() => {
    return (
      allSubmittedRecords?.data?.filter(
        (item) =>
          item.partyCode === partyCode && selectedDates?.includes(item.date)
      ) || []
    );
  }, [allSubmittedRecords, partyCode, selectedDates]);

  const showApiFields =
    filteredDataFromApi?.length > 0
      ? Object.keys(filteredDataFromApi[0].data || {})?.map((item) => item)
      : [];

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    filteredDataFromApi.length > 0 ? filteredDataFromApi : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    //this method for to clear that data for that particular key
    queryClient.setQueryData("all-standard-data", {});
    goBack();
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid container justifyContent={"center"} xs={12} alignItems="center">
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">
            {capitalizeFirstLetter(masterKey)} Previous Records
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <label for="partyCode">Party Code: </label>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              // style={{ marginRight: "16px" }}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handlePartyFilter}
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {partyCode && applied && (
            <FormControl fullWidth variant="outlined">
              <Select
                labelId="select-date-label"
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                renderValue={(selected) =>
                  selected
                    .map((date) => dayjs(date).format("DD-MM-YYYY"))
                    .join(", ")
                }
                label="Select Dates"
              >
                {allDates?.map((date) => (
                  <MenuItem key={date} value={date}>
                    {dayjs(date).format("DD-MM-YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#f5f5f5" }}>
              <TableCell style={{ fontWeight: "bold" }}>PARTICULAR</TableCell>
              {applied &&
                selectedDates?.map((date, index) => (
                  <TableCell key={index} style={{ fontWeight: "bold" }}>
                    {`PREVIOUS DATA (${dayjs(date).format("DD-MM-YYYY")})`}
                    <IconButton onClick={() => removeDate(date)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {applied &&
              showApiFields?.map((heading, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {heading.charAt(0).toUpperCase() + heading.slice(1)}
                  </TableCell>
                  {selectedDates?.map((date, i) => {
                    const item =
                      filteredDataFromApi?.find((data) => data.date === date) ||
                      [];
                    let value;
                    let isOutOfNorms;

                    if (item?.data) {
                      // Handle different data types for display
                      if (Array.isArray(item?.data[heading].value)) {
                        value = item?.data[heading].value?.join(", ") ?? "N/A";
                        isOutOfNorms = item?.data[heading]?.isOutOfNorms;
                      } else if (
                        typeof item?.data[heading].value === "object" &&
                        item?.data[heading].value !== null
                      ) {
                        // For object values, format the display to show x * y * z (if z exists)
                        value =
                          Object.keys(item?.data[heading].value)
                            ?.map(
                              (subKey) =>
                                `${item?.data[heading].value[subKey] ?? "N/A"}`
                            ) // Handle null/undefined values
                            ?.join(" * ") ?? "N/A"; // Join the values with " * "

                        isOutOfNorms = item?.data[heading]?.isOutOfNorms;
                      } else {
                        // For simple values (string, number, etc.)
                        value = item?.data[heading].value ?? "N/A";
                        isOutOfNorms = item?.data[heading]?.isOutOfNorms;
                      }
                    } else {
                      value = "N/A";
                      isOutOfNorms = false;
                    }

                    // const checkFieldOutOfNorms =
                    //   apiStandardsData?.data?.data?.some(
                    //     (item) => item[heading] && item?.outOfNorms === "Yes"
                    //   );

                    return (
                      <TableCell key={i}>
                        <p
                          style={{
                            color:
                              apiStandardsData?.data?.data?.some(
                                (item) =>
                                  item[heading] && item?.outOfNorms === "Yes"
                              ) && item?.data
                                ? isOutOfNorms
                                  ? "red"
                                  : "green"
                                : "black",
                          }}
                        >
                          {value}
                        </p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AllPreviousEntries;
