import React, { useEffect, useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  createFilterOptions,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import { getVariety } from "../../Blowroom/mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { autoLevellerOnOffMutate } from "./mutation";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO } from "../../../config/masterdata";
import CalculateCV from "../../../utils/CalculateCV";
import "./autocss.css";

function AutoLevellerOnOff() {
  const filter = createFilterOptions();
  const { mutate, isLoading } = useMutation(autoLevellerOnOffMutate);

  const [variety, setVariety] = useState("");
  const [feedback, setFeedback] = useState("");

  const [cardingLineNo, setCardingLineNo] = useState("");
  const [cardingMachineNo, setCardingMachineNo] = useState([]);
  const [hallNo, setHallNo] = React.useState("");
  const [showCardingMachineNo, setShowCardingMachineNo] = useState([]);
  const [showCardingMachineNoOnReadings, setShowCardingMachineNoOnReadings] =
    useState([]);

  const [cardingMachineNoOptions, setCardingMachineNoOptions] = useState([]);
  const [noOfReadings, setNoOfReadings] = useState("");
  const [requiredHank, setRequiredHank] = useState("");

  const [readings, setReadings] = useState({});
  const [readings2, setReadings2] = useState({});

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  useEffect(() => {
    let showNumbers = [];
    switch (cardingLineNo) {
      case 1:
        showNumbers = [10, 11, 12];
        break;
      case 2:
        showNumbers = [7, 8, 9];
        break;
      case 3:
        showNumbers = [13, 14];
        break;
      case 4:
        showNumbers = [1, 2, 3, 4, 5, 6];
        break;
      case 5:
        showNumbers = [15, 16, 17, 18, 19, 20];
        break;
      case 6:
        showNumbers = [21, 22, 23, 24];
        break;

      default:
        showNumbers = [];
    }
    setCardingMachineNoOptions(showNumbers);
    setCardingMachineNo([]); // Reset cardingMachineNo when cardingLineNo changes
    setShowCardingMachineNo([]);
    setShowCardingMachineNoOnReadings([]);
    setRequiredHank("");
    setNoOfReadings("");
  }, [cardingLineNo]);

  useEffect(() => {
    const newReadings = {};
    for (let i = 0; i < noOfReadings; i++) {
      newReadings[i] = {};
      showCardingMachineNo.forEach((machine) => {
        newReadings[i][machine] = "";
        newReadings[i][`sample`] = i + 1;
      });
    }
    setReadings(newReadings);

    const newOnReadings = {};
    for (let i = 0; i < noOfReadings; i++) {
      newOnReadings[i] = {};
      showCardingMachineNo.forEach((machine) => {
        newOnReadings[i][machine] = "";
        newOnReadings[i][`sample`] = i + 1;
      });
    }
    setReadings2(newOnReadings);

    /**set the required hank based on the machine no */
    /**dont change this cardingMachineNo as showCardingMachineNo keep cardingMachineNo only */
    if (cardingMachineNo.length > 0) {
      const isInRange1to6 = cardingMachineNo.every(
        (machine) => machine >= 1 && machine <= 6
      );

      const isInRange7to24 = cardingMachineNo.every(
        (machine) => machine >= 7 && machine <= 24
      );

      if (isInRange1to6) {
        setRequiredHank(0.124);
      } else if (isInRange7to24) {
        setRequiredHank(0.114);
      } else {
        setRequiredHank("");
      }
    } else {
      setRequiredHank("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfReadings, cardingMachineNo]);

  /**handle all the OFF readings data */
  const handleReadingChange = (rowIndex, machine, value, reading) => {
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (reading === "OFF-READING") {
      setReadings((prevReadings) => {
        const newReadings = { ...prevReadings };
        newReadings[rowIndex].sample = rowIndex + 1;
        newReadings[rowIndex][machine] = value === "" ? "" : parseFloat(value);

        // Calculate averages
        const machineAverages = {};
        const measuredHank = {};
        const isInRange = {};
        const cv = {};

        // handle for previous average and measured hank and isInRange data for the machine no
        const previousMachineAverages = { ...prevReadings.averageEntry };
        const previousMeasuredHank = { ...prevReadings.measuredHankEntry };
        const previousIsInRange = { ...prevReadings.isInRangeEntry };
        const previousCV = { ...prevReadings.cvEntry };

        const entriesToExclude = [
          "averageEntry",
          "measuredHankEntry",
          "isInRangeEntry",
          "cvEntry",
        ];

        const allValuesPresent = Object.entries(newReadings)
          .filter(([key]) => !entriesToExclude.includes(key))
          .every(([key, value]) => {
            return value[machine] !== "";
          });

        // const newValuesForCV = Object.entries(newReadings)
        //   .filter(([key]) => !entriesToExclude.includes(key))
        //   .map(([, value]) => {
        //     return value[machine];
        //   });

        // if (allValuesPresent) {
        //   const CV = CalculateCV(newValuesForCV);
        //   cv[machine] = CV;
        // }

        if (allValuesPresent) {
          showCardingMachineNo.forEach((machine) => {
            let total = 0;
            let count = 0;
            let cvArrayValues = [];
            for (let i = 0; i < noOfReadings; i++) {
              const value = parseFloat(newReadings[i][machine]) ?? "";
              if (!isNaN(value) && value !== "") {
                total += value;
                count++;
                //calculate CV for particular samples readings
                cvArrayValues.push(value);
              }
            }

            const average =
              count > 0 ? parseFloat((total / count).toFixed(2)) : "";

            machineAverages[machine] = average;

            const mh = calculateMeasuredHank(average);
            measuredHank[machine] = mh;
            isInRange[machine] = checkMeasuredHankRange(machine, mh);

            /**calculate to get the average(mean) value */
            const CV =
              cvArrayValues.length > 0 ? CalculateCV(cvArrayValues) : "";
            cv[machine] = CV;
          });
        } else {
          /**handle for average and measured hank and isInRange for the machine no */
          cardingMachineNo.forEach((machine) => {
            if (newReadings[rowIndex][machine] === "") {
              machineAverages[machine] = "";
              measuredHank[machine] = "";
              isInRange[machine] = false;
              cv[machine] = "";
            } else {
              machineAverages[machine] = previousMachineAverages[machine] || "";
              measuredHank[machine] = previousMeasuredHank[machine] || "";
              isInRange[machine] = previousIsInRange[machine] || false;
              cv[machine] = previousCV[machine] || "";
            }
          });
        }

        const averageEntry = { sample: "Average", ...machineAverages };
        const measuredHankEntry = { sample: "Measured Hank", ...measuredHank };
        const isInRangeEntry = { sample: "Is In Range", ...isInRange };
        const cvEntry = { sample: "CV", ...cv };

        const updatedReadings = {
          ...newReadings,
          averageEntry,
          measuredHankEntry,
          isInRangeEntry,
          cvEntry,
        };

        return updatedReadings;
      });
    } else if (reading === "ON-READING") {
      setReadings2((prevReadings) => {
        const newReadings = { ...prevReadings };
        newReadings[rowIndex].sample = rowIndex + 1;
        newReadings[rowIndex][machine] = value === "" ? "" : parseFloat(value);

        // Calculate averages
        const machineAverages = {};
        const measuredHank = {};
        const isInRange = {};
        const cv = {};

        // handle for previous average and measured hank and isInRange data for the machine no
        const previousMachineAverages = { ...prevReadings.averageEntry };
        const previousMeasuredHank = { ...prevReadings.measuredHankEntry };
        const previousIsInRange = { ...prevReadings.isInRangeEntry };
        const previousCV = { ...prevReadings.cvEntry };

        const entriesToExclude = [
          "averageEntry",
          "measuredHankEntry",
          "isInRangeEntry",
          "cvEntry",
        ];

        const allValuesPresent = Object.entries(newReadings)
          .filter(([key]) => !entriesToExclude.includes(key))
          .every(([key, value]) => {
            return value[machine] !== "";
          });

        // const newValuesForCV = Object.entries(newReadings)
        //   .filter(([key]) => !entriesToExclude.includes(key))
        //   .map(([, value]) => {
        //     return value[machine];
        //   });

        if (allValuesPresent) {
          showCardingMachineNoOnReadings?.forEach((machine) => {
            let total = 0;
            let count = 0;
            let cvArrayValues = [];

            for (let i = 0; i < noOfReadings; i++) {
              const value = parseFloat(newReadings[i][machine]);
              if (!isNaN(value) && value !== "") {
                total += value;
                count++;

                //calculate CV for particular samples readings
                cvArrayValues.push(value);
              }
            }

            const average =
              count > 0 ? parseFloat((total / count).toFixed(2)) : "";
            // const average = calculateAverage(newReadings, machine, noOfReadings);
            machineAverages[machine] = average;

            const mh = calculateMeasuredHank(average);
            measuredHank[machine] = mh;
            isInRange[machine] = checkMeasuredHankRange(machine, mh);

            /**calculate to get the average(mean) value */
            const CV =
              cvArrayValues.length > 0 ? CalculateCV(cvArrayValues) : "";
            cv[machine] = CV;
          });
        } else {
          /**handle for average and measured hank and isInRange for the machine no */
          cardingMachineNo.forEach((machine) => {
            if (newReadings[rowIndex][machine] === "") {
              machineAverages[machine] = "";
              measuredHank[machine] = "";
              isInRange[machine] = false;
              cv[machine] = "";
            } else {
              machineAverages[machine] = previousMachineAverages[machine] || "";
              measuredHank[machine] = previousMeasuredHank[machine] || "";
              isInRange[machine] = previousIsInRange[machine] || false;
              cv[machine] = previousCV[machine] || "";
            }
          });
        }

        const averageEntry = { sample: "Average", ...machineAverages };
        const measuredHankEntry = { sample: "Measured Hank", ...measuredHank };
        const isInRangeEntry = { sample: "Is In Range", ...isInRange };
        const cvEntry = { sample: "CV", ...cv };

        const updatedReadings = {
          ...newReadings,
          averageEntry,
          measuredHankEntry,
          isInRangeEntry,
          cvEntry,
        };

        return updatedReadings;
      });
    }
  };

  const calculateMeasuredHank = (average) => {
    const avg = parseFloat(average);
    return !isNaN(avg) && avg !== 0 ? parseFloat((3.24 / avg).toFixed(3)) : "";
  };

  const checkMeasuredHankRange = (machine, value) => {
    const measuredHank = parseFloat(value);

    const updatedMachineNumber = parseInt(machine.match(/\d+/)[0]);

    if (updatedMachineNumber >= 1 && updatedMachineNumber <= 6) {
      return measuredHank >= 0.116 && measuredHank <= 0.124 ? true : false;
    } else if (updatedMachineNumber >= 7 && updatedMachineNumber <= 24) {
      return measuredHank >= 0.106 && measuredHank <= 0.114 ? true : false;
    }
  };

  //sorting when add a new Machine No
  const sortAndFormatMachineNumbers = (machineNumbers) => {
    // Extract and sort machine numbers with their suffixes
    const sortedValues = machineNumbers.sort((a, b) => {
      const [baseA, suffixA] = a.match(/\d+/g).map(Number);
      const [baseB, suffixB] = b.match(/\d+/g).map(Number);

      return baseA === baseB ? suffixA - suffixB : baseA - baseB;
    });

    return sortedValues;
  };

  /**this use effect for handle add and delete same machine no data if is not in range or in the range */
  useEffect(() => {
    const entriesToCheck = ["isInRangeEntry"];

    const entriesToExclude = [
      "averageEntry",
      "measuredHankEntry",
      "isInRangeEntry",
      "cvEntry",
    ];

    /**this is for OFF READING iteration */
    const updatedShowCardingMachineNo = [...showCardingMachineNo];

    Object.entries(readings)
      .filter(([key]) => entriesToCheck.includes(key))
      .forEach(([, value]) => {
        Object.entries(value).forEach(([machine, inRange]) => {
          const allValuesPresent = Object.entries(readings)
            .filter(([key]) => !entriesToExclude.includes(key))
            .every(([key, value]) => {
              return value[machine] !== undefined && value[machine] !== "";
            });

          if (allValuesPresent && inRange === false) {
            // check if the machine no already in the array and Extract base machine name and number
            const match = machine.match(/^(.*?)(\d+)$/);
            if (match) {
              const baseName = match[1];
              const currentNumber = parseInt(match[2], 10);
              const newMachineName = `${baseName}${currentNumber + 1}`;

              if (!updatedShowCardingMachineNo.includes(newMachineName)) {
                updatedShowCardingMachineNo.push(newMachineName);

                //here also sort the machine no for ui showing
                const formattedValues = sortAndFormatMachineNumbers(
                  updatedShowCardingMachineNo
                );
                setShowCardingMachineNo(formattedValues);
              }
            }
          } else if (!allValuesPresent || inRange === true) {
            // Remove machine number from showCardingMachineNo if out of range
            const match = machine.match(/^(.*?)(\d+)$/);
            if (match) {
              const baseName = match[1];
              const currentNumber = parseInt(match[2], 10);
              const newMachineName = `${baseName}${currentNumber + 1}`;

              const indexToRemove =
                updatedShowCardingMachineNo.indexOf(newMachineName);
              if (indexToRemove !== -1) {
                updatedShowCardingMachineNo.splice(indexToRemove, 1);

                // Remove the machine data from readings
                Object.entries(readings).forEach(([key, values]) => {
                  if (values.hasOwnProperty(newMachineName)) {
                    delete values[newMachineName];
                  }
                });
              }
            }
          }
        });
      });

    // Set the updated showCardingMachineNo state
    setShowCardingMachineNo(updatedShowCardingMachineNo);

    //<------------------------------------------------------------------------->//

    /**this is for ON READING iteration */
    const updatedShowCardingMachineNoOnReading = [
      ...showCardingMachineNoOnReadings,
    ];

    Object.entries(readings2)
      .filter(([key]) => entriesToCheck.includes(key))
      .forEach(([, value]) => {
        Object.entries(value).forEach(([machine, inRange]) => {
          const allValuesPresent = Object.entries(readings2)
            .filter(([key]) => !entriesToExclude.includes(key))
            .every(([key, value]) => {
              return value[machine] !== undefined && value[machine] !== "";
            });

          if (allValuesPresent && inRange === false) {
            // check if the machine no already in the array
            // Extract base machine name and number
            const match = machine.match(/^(.*?)(\d+)$/);
            if (match) {
              const baseName = match[1];
              const currentNumber = parseInt(match[2], 10);
              const newMachineName = `${baseName}${currentNumber + 1}`;

              if (
                !updatedShowCardingMachineNoOnReading.includes(newMachineName)
              ) {
                updatedShowCardingMachineNoOnReading.push(newMachineName);

                //here also sort the machine no for ui showing
                const formattedValues = sortAndFormatMachineNumbers(
                  updatedShowCardingMachineNoOnReading
                );
                setShowCardingMachineNoOnReadings(formattedValues);
              }
            }
          } else if (!allValuesPresent || inRange === true) {
            // Remove machine number from showCardingMachineNo if out of range
            const match = machine.match(/^(.*?)(\d+)$/);
            if (match) {
              const baseName = match[1];
              const currentNumber = parseInt(match[2], 10);
              const newMachineName = `${baseName}${currentNumber + 1}`;

              const indexToRemove =
                updatedShowCardingMachineNoOnReading.indexOf(newMachineName);
              if (indexToRemove !== -1) {
                updatedShowCardingMachineNoOnReading.splice(indexToRemove, 1);

                // Remove the machine data from readings
                Object.entries(readings2).forEach(([, values]) => {
                  if (values.hasOwnProperty(newMachineName)) {
                    delete values[newMachineName];
                  }
                });
              }
            }
          }
        });
      });

    // Set the updated showCardingMachineNo state
    setShowCardingMachineNoOnReadings(updatedShowCardingMachineNoOnReading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readings, readings2]);

  const handleCardingMachineNoChange = (e) => {
    const selectedValues = e.target.value;
    // Ensure selectedValues is an array of numbers
    const numericValues = selectedValues?.map((value) => {
      // Extract numeric part if value is in format 'MachineX_1'
      if (typeof value === "string" && value.startsWith("Machine")) {
        const numberString = value.match(/Machine(\d+)_1/)[1];
        return parseInt(numberString, 10);
      }
      return parseInt(value, 10);
    });

    // Sort the numeric values in ascending order
    const sortedValues = numericValues.sort((a, b) => a - b);

    // Format the sorted values to the desired string format
    const formattedValues = sortedValues.map((value) => `Machine${value}_1`);

    setCardingMachineNo(sortedValues); //showing the value in the carding machine no select
    setShowCardingMachineNo(formattedValues); // this is for do all operations
    setShowCardingMachineNoOnReadings(formattedValues);
  };

  /**handle feedback */
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  /**handle api payload change for ON and OFF readings data */
  const transformReadings = (readings) => {
    // Initialize an empty array for the transformed structure
    const transformedReadings = [];

    // Iterate through the readings object
    Object.entries(readings).forEach(([key, value]) => {
      if (
        !["averageEntry", "measuredHankEntry", "isInRangeEntry"].includes(key)
      ) {
        // For entries with numeric keys (e.g., "0", "1", etc.)
        transformedReadings.push({
          sample: value.sample,
          ...Object.keys(value).reduce((acc, machineKey) => {
            if (machineKey !== "sample") {
              acc[machineKey.toLowerCase()] = value[machineKey];
            }
            return acc;
          }, {}),
        });
      }
    });

    // Add special entries to the transformed readings array
    ["averageEntry", "measuredHankEntry"].forEach((key) => {
      if (readings[key]) {
        transformedReadings.push({
          sample: readings[key].sample,
          ...Object.keys(readings[key]).reduce((acc, machineKey) => {
            if (machineKey !== "sample") {
              acc[machineKey.toLowerCase()] = readings[key][machineKey];
            }
            return acc;
          }, {}),
        });
      }
    });

    return transformedReadings;
  };

  const offReadingsParticular = transformReadings(readings);
  const onReadingsParticular = transformReadings(readings2);

  const queryClient = useQueryClient();
  /**handle submit details */
  const handleSubmit = () => {
    const payload = {
      variety: variety,
      lineNo: cardingLineNo,
      machineNo: cardingMachineNo,
      requiredHank: requiredHank,
      noOfReadings: noOfReadings,
      offReadingsParticular,
      onReadingsParticular,
      feedback: feedback,
      hall: hallNo,
    };

    mutate(payload, {
      onSuccess: () => {
        handleToastMsg("Data updated successfully", "success");
        resetForm();
        queryClient.invalidateQueries("get-variety");
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const resetForm = () => {
    setVariety("");
    setFeedback("");
    setCardingMachineNo([]);
    setShowCardingMachineNo([]);
    setShowCardingMachineNoOnReadings([]);
    setRequiredHank("");
    setNoOfReadings("");
    setCardingLineNo("");
    setHallNo("");
  };

  //handle for On Reading textfield disable and enable with isInRange
  const isMachineInRange = (machine, readings) => {
    const machineNumbers = machine.match(/\d+/g); // Extract all numbers from the machine string
    if (!machineNumbers) return false;

    const machineNumber = machineNumbers[0]; // Take only the first number

    // Check if any key in isInRangeEntry includes the extracted machine number
    const isInRangeEntry = readings?.isInRangeEntry || {};
    const keyExists = Object.keys(isInRangeEntry).some((key) =>
      key.includes(machineNumber)
    );

    // Return the value if the key exists, otherwise true if key doesn't exist
    return keyExists
      ? Object.keys(isInRangeEntry).some(
          (key) => key.includes(machineNumber) && isInRangeEntry[key]
        )
      : false;
  };

  return (
    <ContainerWrapper container>
      <Grid
        container
        item
        md={12}
        alignContent={"flex-start"}
        sx={{ marginLeft: "20px" }}
        rowGap={3}
      >
        <Grid container item md={12} rowGap={3}>
          <h2 className="heading">Auto Leveller On/Off</h2>
        </Grid>
        <Grid container item md={12} rowGap={3}>
          <Grid container item md={6} alignItems={"center"}>
            <Grid item xs={5} className="content">
              Variety :
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                value={variety}
                fullWidth
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setVariety(newValue || "");
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setVariety(newValue || "");
                  } else {
                    setVariety(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue.toUpperCase());
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : ""
                } // Ensure option is a string
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: 180 }}
                freeSolo
                renderInput={(params) => (
                  <TextField required {...params} label="Variety" fullWidth />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item md={6} alignItems={"center"}>
            <Grid item xs={5} className="content">
              Hall Number :
            </Grid>
            <Grid item xs={7}>
              <TextField
                value={hallNo}
                select
                onChange={(e) => setHallNo(parseInt(e.target.value))}
                type="number"
                sx={{ width: 180 }}
                id="outlined-basic"
                label="Hall Number"
                variant="outlined"
                // onInput={handleCardingLineNumInput}
              >
                {HALL_NO?.map((hall) => (
                  <MenuItem key={hall} value={hall}>
                    {hall}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item md={12} alignItems={"center"} rowGap={3}>
            <Grid container item md={12} rowGap={3}>
              <Grid container item md={6} alignItems={"center"}>
                <Grid item xs={5} className="content">
                  Carding line no :
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    sx={{ width: 180 }}
                    select
                    value={cardingLineNo}
                    onChange={(e) => setCardingLineNo(Number(e.target.value))}
                  >
                    {[1, 2, 3, 4, 5, 6].map((lineNo) => (
                      <MenuItem key={lineNo} value={lineNo}>
                        {lineNo}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container item md={6} alignItems={"center"}>
                <Grid item xs={5} className="content">
                  Carding machine no :
                </Grid>
                <Grid item xs={7}>
                  <FormControl fullWidth>
                    <Select
                      sx={{ width: 180 }}
                      multiple
                      value={cardingMachineNo}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(e) => handleCardingMachineNoChange(e)}
                    >
                      {cardingMachineNoOptions.map((machineNo) => (
                        <MenuItem key={machineNo} value={machineNo}>
                          <Checkbox
                            checked={cardingMachineNo?.indexOf(machineNo) > -1}
                          />
                          <ListItemText primary={machineNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={12} rowGap={3}>
              <Grid container item md={6} alignItems={"center"}>
                <Grid item xs={5} className="content">
                  Required Hank :
                </Grid>
                <Grid item xs={7}>
                  <TextField sx={{ width: 180 }} value={requiredHank} />
                </Grid>
              </Grid>
              <Grid container item md={6} alignItems={"center"}>
                <Grid item xs={5} className="content">
                  No of readings :
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    sx={{ width: 180 }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    value={noOfReadings}
                    onChange={(e) => setNoOfReadings(parseInt(e.target.value))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item md={12} rowGap={2}>
          <Grid container item md={12} className="headingsub">
            OFF READING
          </Grid>
          <Grid container item md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SAMPLE</TableCell>
                    {showCardingMachineNo.map((machine, index) => (
                      <TableCell key={index}>{machine}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: noOfReadings }, (_, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {showCardingMachineNo?.map((machine) => (
                        <TableCell key={machine}>
                          <TextField
                            fullWidth
                            type="number"
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            disabled={
                              readings?.isInRangeEntry?.[machine] &&
                              readings[index]?.[machine] &&
                              readings[index]?.[machine] !== ""
                            }
                            value={readings[index]?.[machine]}
                            onChange={(e) =>
                              handleReadingChange(
                                index,
                                machine,
                                e.target.value,
                                "OFF-READING"
                              )
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell>Average</TableCell>
                    {showCardingMachineNo?.map((machine, index) => (
                      <TableCell key={index}>
                        <p>{readings?.averageEntry?.[machine] ?? ""}</p>
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell>Measured Hank</TableCell>
                    {showCardingMachineNo?.map((machine, index) => (
                      <TableCell key={index}>
                        <p
                          style={{
                            color: readings?.isInRangeEntry?.[machine]
                              ? "green"
                              : "red",
                          }}
                        >
                          {readings?.measuredHankEntry?.[machine] ?? ""}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell>CV</TableCell>
                    {showCardingMachineNo?.map((machine, index) => (
                      <TableCell key={index}>
                        <p>{readings?.cvEntry?.[machine] ?? ""}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid container item md={12} rowGap={2}>
          <Grid container item md={12} className="headingsub">
            ON READING
          </Grid>
          <Grid container item md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SAMPLE</TableCell>
                    {showCardingMachineNoOnReadings?.map((machine, index) => (
                      <TableCell key={index}>{machine}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: noOfReadings }, (_, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {showCardingMachineNoOnReadings?.map((machine) => (
                        <TableCell key={machine}>
                          <TextField
                            fullWidth
                            type="number"
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            // disabled={
                            //   !readings?.isInRangeEntry?.[machine]
                            // }
                            disabled={!isMachineInRange(machine, readings)}
                            value={readings2[index]?.[machine]}
                            onChange={(e) =>
                              handleReadingChange(
                                index,
                                machine,
                                e.target.value,
                                "ON-READING"
                              )
                            }
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell>Average</TableCell>
                    {showCardingMachineNoOnReadings?.map((machine, index) => (
                      <TableCell key={index}>
                        <p>{readings2?.averageEntry?.[machine] ?? ""}</p>
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell>Measured Hank</TableCell>
                    {showCardingMachineNoOnReadings.map((machine, index) => (
                      <TableCell key={index}>
                        <p
                          style={{
                            color: readings2?.isInRangeEntry?.[machine]
                              ? "green"
                              : "red",
                          }}
                        >
                          {readings2?.measuredHankEntry?.[machine] ?? ""}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell>CV</TableCell>
                    {showCardingMachineNoOnReadings?.map((machine, index) => (
                      <TableCell key={index}>
                        <p>{readings2?.cvEntry?.[machine] ?? ""}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container item md={12}>
          <TextField
            onChange={handleFeedbackChange}
            value={feedback}
            fullWidth
            size="lg"
            name="Size"
            label="Feedback"
          />
        </Grid>
        <Grid container item md={12} justifyContent="flex-end">
          {" "}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="submitbutton"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
}

export default AutoLevellerOnOff;
