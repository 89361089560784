import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme, useMediaQuery, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
const dialogStyles = makeStyles(() => ({
    modalStyle: {
        width: "100%",
        margin: 0,
        maxHeight: "100%",
        height: "100%",
        // padding: "50px",
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 15,
                        top: 20,
                        color: (theme) => theme.palette.grey[700],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
    children,
    toggleModal,
    setToggleModal,
    title,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const customClasses = dialogStyles();

    const handleClose = () => {
        setToggleModal(false);
    };
    useEffect(() => {
        document.body.style.height = "100vh";
    }, [toggleModal]);
    return (
        <Dialog
            fullScreen={fullScreen}
            open={toggleModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"lg"}
            fullWidth
            // disableScrollLock={true}
            // disableBackdropClick={disableBackdropClick}
            scroll={"body"}
            className={customClasses.modalStyle}
        >
            <BootstrapDialogTitle onClose={handleClose}>{title}</BootstrapDialogTitle>
            <DialogContent style={{overflow: 'auto'}}>{children}</DialogContent>
        </Dialog>
    );
}
