import { CircularProgress, Paper, TableHead } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { getVariety } from "../../Blowroom/mutation";
import { yardCreate } from "./mutation";
import { handleToastMsg } from "../../../components/Toast";
import CalculateCV from "../../../utils/CalculateCV";
import { ContainerWrapper } from "../../../components/styles";
import "./yards.css";

export default function FinisherDrawing() {
  const [oneYardValues, setOneYardValues] = useState(Array(0).fill(""));
  const [halfYardValues, setHalfYardValues] = useState(Array(0).fill(""));
  const [hallNo, setHallNo] = useState("");
  const [machineNo, setMachineNo] = useState("");
  const [numberOfOneYardSamples, setNumberOfOneYardSamples] = useState("");
  const [machineName, setMachineName] = useState("");
  const [variety, setVariety] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleOneYardChange = (index) => (event) => {
    const newValues = [...oneYardValues];
    newValues[index] =
      event.target.value === "" ? "" : parseFloat(event.target.value);
    setOneYardValues(newValues);
  };

  const handleHalfYardChange = (index) => (event) => {
    const newValues = [...halfYardValues];
    newValues[index] =
      event.target.value === "" ? "" : parseFloat(event.target.value);
    setHalfYardValues(newValues);
  };

  const handleHallNo = (e) => setHallNo(e.target.value);
  const handleMachineNo = (e) => setMachineNo(e.target.value);
  const handleMachineName = (value) => setMachineName(value);
  const handleFeedbackChange = (e) => setFeedback(e.target.value.toUpperCase());

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const filter = createFilterOptions();

  const calculateAverage = (values) => {
    const filteredValues = values.filter((val) => !isNaN(val) && val !== ""); // Filter out non-numeric and empty Values
    if (filteredValues.length === 0) {
      return "";
    }
    const sum = filteredValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
    return parseFloat((sum / filteredValues.length).toFixed(2));
  };
  const calculateHank = (values) => {
    const filteredValues = values.filter((val) => !isNaN(val) && val !== ""); // Filter out non-numeric and empty Values
    if (filteredValues.length === 0) {
      return "";
    }
    return parseFloat((0.54 / calculateAverage(values)).toFixed(2));
  };

  // import and use CalculateCV method
  // const CalculateCV = (values) => {
  //   const filteredValues = values.filter((val) => !isNaN(val) && val !== ""); // Filter out non-numeric and empty Values
  //   if (filteredValues.length === 0) {
  //     return "";
  //   }
  //   const sum = filteredValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
  //   return (sum / filteredValues.length).toFixed(2);
  // };

  const handleOneYardSamples = (e) => {
    const numSamples = parseInt(e.target.value);
    if (isNaN(numSamples) || numSamples <= 0) {
      // If the value is not a number or is less than or equal to 0, set Values to an empty array
      setNumberOfOneYardSamples("");
      setOneYardValues([]);
      setHalfYardValues([]);
    } else {
      // Otherwise, set Values with the appropriate number of elements
      setNumberOfOneYardSamples(numSamples);
      setOneYardValues(Array(numSamples).fill(""));
      setHalfYardValues(Array(numSamples).fill(""));
    }
  };

  const { mutate, isLoading } = useMutation(yardCreate);
  const queryClient = useQueryClient();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      hall: parseInt(hallNo),
      variety: variety,
      machineNo: parseInt(machineNo),
      machineName: machineName,
      noOfReadings: parseInt(numberOfOneYardSamples),
      oneYardParticular: [
        ...oneYardValues.map((val, index) => ({
          sample: index + 1,
          particulars: parseFloat(val || 0),
        })),
        { sample: "average", particulars: calculateAverage(oneYardValues) },
        { sample: "hank", particulars: calculateHank(oneYardValues) },
        { sample: "cv", particulars: CalculateCV(oneYardValues) },
      ],
      halfYardParticular: [
        ...halfYardValues.map((val, index) => ({
          sample: index + 1,
          particulars: parseFloat(val || 0),
        })),
        { sample: "average", particulars: calculateAverage(halfYardValues) },
        { sample: "hank", particulars: calculateHank(halfYardValues) },
        { sample: "cv", particulars: CalculateCV(halfYardValues) },
      ],
      feedback: feedback,
    };

    try {
      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          setHallNo("");
          setVariety("");
          setMachineNo("");
          setMachineName("");
          setNumberOfOneYardSamples("");
          setOneYardValues([]);
          setHalfYardValues([]);
          setFeedback("");
          queryClient.invalidateQueries("get-variety");
          queryClient.invalidateQueries(
            "manager-finisher-drawing-notifications"
          );
        },
        onError: (error) => {
          handleToastMsg("Data Error Occured", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <ContainerWrapper>
        <form>
          <Grid
            container
            item
            md={12}
            xs={12}
            alignContent={"flex-start"}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid container item md={12} rowGap={3}>
              <h2 className="heading">1 & 1/2 Yard</h2>
            </Grid>
            <Grid
              container
              item
              md={12}
              xs={12}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid container item md={6}>
                <Grid container item md={4.5} alignItems={"center"}>
                  <p className="content">Hall No :</p>
                </Grid>
                <Grid container item md={6}>
                  <TextField
                    value={hallNo}
                    onChange={(e) => handleHallNo(e)}
                    select
                    fullWidth
                  >
                    {[...Array(4)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container item md={6}>
                <Grid container item md={4.5} alignItems={"center"}>
                  <p className="content">Variety: </p>
                </Grid>
                <Grid container item md={6}>
                  <Autocomplete
                    value={variety}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setVariety(newValue || "");
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setVariety(newValue || "");
                      } else {
                        setVariety(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push(inputValue.toUpperCase());
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : ""
                    } // Ensure option is a string
                    renderOption={(props, option) => (
                      <li {...props}>{option}</li>
                    )}
                    style={{
                      width: "100%",
                    }}
                    freeSolo
                    renderInput={(params) => <TextField required {...params} />}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid container item md={6}>
                <Grid container item md={4.5} alignItems={"center"}>
                  <p className="content">Machine No :</p>
                </Grid>
                <Grid container item md={6}>
                  <TextField
                    type="number"
                    value={machineNo}
                    onChange={(e) => handleMachineNo(e)}
                    select
                    fullWidth
                  >
                    {[...Array(10)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container item md={6}>
                <Grid container item md={4.5} alignItems={"center"}>
                  <p className="content">Machine Name :</p>
                </Grid>
                <Grid container item md={6}>
                  <TextField
                    type="text"
                    value={machineName}
                    onChange={(e) =>
                      handleMachineName(e.target.value.toUpperCase())
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item md={12} xs={12}>
              <Grid container item md={6}>
                <Grid container item md={4.4} alignItems={"center"}>
                  <p className="content">No. of Samples: </p>
                </Grid>
                <Grid container item md={6}>
                  <TextField
                    value={numberOfOneYardSamples}
                    onChange={(e) => handleOneYardSamples(e)}
                    select
                    fullWidth
                  >
                    {[...Array(100)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            {numberOfOneYardSamples && (
              <Grid
                container
                item
                md={12}
                mt={5}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                justifyContent={"space-between"}
              >
                <Grid container item md={6}>
                  <Grid item container md={12} justifyContent={"center"} mb={2}>
                    <p className="heading">1 Yard</p>
                  </Grid>
                  <TableContainer
                    component={Paper}
                    style={{
                      width: "80%",
                      height: "fit-content",
                      overflow: "auto",
                    }}
                    className="yard1"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "1vw" }}>Sample</TableCell>
                          <TableCell
                            style={{ width: "1vw" }}
                            className="content"
                          >
                            Particulars
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {oneYardValues?.map((value, index) => (
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                value={value}
                                onChange={handleOneYardChange(index)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell className="content">Average</TableCell>
                          <TableCell>
                            {oneYardValues.every((val) => val !== "") && (
                              <>{calculateAverage(oneYardValues)}</>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="content">Hank</TableCell>
                          <TableCell>
                            {oneYardValues.every((val) => val !== "") && (
                              <>{calculateHank(oneYardValues)}</>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="content">CV %</TableCell>
                          <TableCell>
                            {oneYardValues.every((val) => val !== "") && (
                              <>
                                {CalculateCV(oneYardValues) <= 0.5 &&
                                CalculateCV(oneYardValues) >= -0.5 ? (
                                  <b>
                                    <p style={{ color: "green" }}>
                                      {CalculateCV(oneYardValues)} %{" "}
                                    </p>
                                  </b>
                                ) : (
                                  <b>
                                    <p style={{ color: "red" }}>
                                      {CalculateCV(oneYardValues)} %
                                    </p>
                                  </b>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid container item md={6}>
                  <Grid item container md={12} justifyContent={"center"} mb={2}>
                    <p className="heading">1/2 Yard</p>
                  </Grid>
                  <TableContainer
                    component={Paper}
                    style={{
                      width: "80%",
                      height: "fit-content",
                      overflow: "auto",
                    }}
                    className="yard1"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ width: "1vw" }}
                            className="content"
                          >
                            Sample
                          </TableCell>
                          <TableCell
                            style={{ width: "1vw" }}
                            className="content"
                          >
                            Particulars
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {halfYardValues?.map((value, index) => (
                          <TableRow key={index + 1}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                value={value}
                                onChange={handleHalfYardChange(index)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableRow>
                          <TableCell className="content">Average</TableCell>
                          <TableCell>
                            {halfYardValues.every((val) => val !== "") && (
                              <>{calculateAverage(halfYardValues)}</>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="content">Hank</TableCell>
                          <TableCell>
                            {halfYardValues.every((val) => val !== "") && (
                              <>{calculateHank(halfYardValues)}</>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="content">CV %</TableCell>
                          <TableCell>
                            {halfYardValues.every((val) => val !== "") && (
                              <>
                                {CalculateCV(halfYardValues) <= 1.0 &&
                                CalculateCV(halfYardValues) >= -1.0 ? (
                                  <b>
                                    <p style={{ color: "green" }}>
                                      {CalculateCV(halfYardValues)} %{" "}
                                    </p>
                                  </b>
                                ) : (
                                  <b>
                                    <p style={{ color: "red" }}>
                                      {CalculateCV(halfYardValues)} %
                                    </p>
                                  </b>
                                )}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}

            <Grid mt={3} item xs={12}>
              <TextField
                fullWidth
                onChange={(e) => handleFeedbackChange(e)}
                name="Size"
                label="Feedback"
                value={feedback}
              />
            </Grid>

            <Grid mt={3} item xs={12}>
              {/* <Grid item><Button
                // onClick={handleSubmit}
                className="submitBtn"
              // type="submit "
              >
                Save
              </Button></Grid> */}
              <Grid item display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  className="submitbutton"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ContainerWrapper>
    </>
  );
}
