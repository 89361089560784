import React from "react";
import { Card, CardContent } from "@material-ui/core";

function DashboardCard({ menu }) {
  return (
    <Card
      style={{
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 0px 32px 0px #00000026",
        width: "100%",
        textAlign: "center",
        padding: "18% 0%",
      }}
    >
      <CardContent>
        <b>
          <p style={{ margin: 0 }}>{menu}</p>
        </b>
      </CardContent>
    </Card>
  );
}

export default DashboardCard;
