import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogContent,
  TableHead,
  TableBody,
  DialogActions,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material"; // Import FormControlLabel and Checkbox

import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

function RewindingStudyReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    variety: [],
    MachineNoEnabled: false,
    MachineNo: [],
    CountNoEnabled: false,
    CountNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: null,
  });

  const [CountNoOptions] = useState(
    Array.from({ length: 50 }, (_, i) => i + 1)
  );
  const [MachineNoOptions] = useState(
    Array.from({ length: 8 }, (_, i) => i + 1)
  );

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleCountChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]); // State to store the fetched data

  const handlePreviewData = async (event) => {
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    const varieties = formData?.variety?.join(",") || "";
    const MachinesNo = formData?.MachineNo?.join(",") || "";
    const CountNo = formData?.CountNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/autoconer/rewinding-study-report?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  machineNo: MachinesNo,
                  variety: varieties,
                  countNo: CountNo,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      setPreviewData(response.data);
      setOpenPreview(true);
    } catch (error) {
      handleClosePreview();
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewData([]);
  };
  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      let headers = new Set();
      let dataRows = [];

      const flattenObject = (obj, parentKey = "") => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(acc, flattenObject(value, newKey));
          } else {
            acc[newKey] = value;
          }
          return acc;
        }, {});
      };

      previewData?.forEach((item) => {
        let row = {};
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((obj, index) => {
              const flatObj = flattenObject(obj, `${key}_${index + 1}`);
              Object.entries(flatObj).forEach(([subKey, subValue]) => {
                headers.add(subKey);
                row[subKey] = subValue;
              });
            });
          } else if (typeof value === "object" && value !== null) {
            const flatObj = flattenObject(value, key);
            Object.entries(flatObj).forEach(([subKey, subValue]) => {
              headers.add(subKey);
              row[subKey] = subValue;
            });
          } else {
            headers.add(key);
            row[key] =
              key === "date" ? dayjs(value).format("DD-MM-YYYY") : value;
          }
        });
        dataRows.push(row);
      });

      headers = Array.from(headers);

      const excludeHeaders = ["id", "created_at", "updated_at", "employeeId"];
      headers = headers.filter((header) => !excludeHeaders.includes(header));

      const worksheetData = [headers];

      dataRows.forEach((row) => {
        const rowData = headers.map((header) => row[header] || "");
        worksheetData.push(rowData);
      });
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "REWINDING-STUDY-REPORTS.xlsx");
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
      handleClosePreview();
    }
  };

  return (
    <ContainerWrapper container rowGap={2} height={0}>
      <Grid item xs={12}>
        <h2>Rewinding Study Report</h2>
      </Grid>
      <Grid item container justifyContent={"space-between"} xs={12} md={8}>
        <Grid item>
          <RadioGroup
            name="reportType"
            value={formData.reportType}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="individual"
              control={<Radio />}
              label="Individual Report"
            />
            <FormControlLabel
              value="consolidated"
              control={<Radio />}
              label="Consolidated Report"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid item container mb={3}>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date from"
                  value={formData.from_date}
                  onChange={(value) => handleDateChange("from_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date to"
                  value={formData.to_date}
                  onChange={(value) => handleDateChange("to_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} md={12} alignItems={"center"}>
        <TableContainer component={Paper} style={{ width: "100%" }}>
          <Table>
            <TableRow>
              <TableCell>Variety</TableCell>
              <TableCell style={{ width: 500 }}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    multiple
                    value={formData?.variety}
                    disabled={formData.reportType === "consolidated"}
                    renderValue={(selected) => selected.join(", ")}
                    onChange={(e) => handleFormChange(e, "variety")}
                  >
                    {varietyData?.map((variety) => (
                      <MenuItem key={variety} value={variety}>
                        <Checkbox
                          checked={formData?.variety?.indexOf(variety) > -1}
                        />
                        <ListItemText primary={variety} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell> Count No</TableCell>
              <TableCell style={{ width: 500 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.CountNo}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handleCountChange("CountNo", event.target.value)
                      }
                    >
                      {CountNoOptions.map((CountNo) => (
                        <MenuItem key={CountNo} value={CountNo}>
                          <Checkbox
                            checked={formData?.CountNo?.indexOf(CountNo) > -1}
                          />
                          <ListItemText primary={CountNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell> Machine No</TableCell>
              <TableCell style={{ width: 500 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.MachineNo}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handleInputChange("MachineNo", event.target.value)
                      }
                    >
                      {MachineNoOptions.map((machineNo) => (
                        <MenuItem key={machineNo} value={machineNo}>
                          <Checkbox
                            checked={
                              formData?.MachineNo?.indexOf(machineNo) > -1
                            }
                          />
                          <ListItemText primary={machineNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
        <CustomButton
          style={{ width: "150px", height: "50px" }}
          type="submit"
          onClick={handlePreviewData}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size={32} />
          ) : (
            "Preview"
          )}
        </CustomButton>
      </Grid>

      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F5F5F5",
          }}
        >
          Preview
          <IconButton onClick={handleClosePreview} sx={{ color: "#000" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Hall Number</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Variety</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Auto Coner No
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Reading Number Average
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box sx={{ textAlign: "center", padding: 2 }}>
                        no data will be available
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  previewData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {dayjs(item.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{item.hall}</TableCell>
                      <TableCell>{item.variety}</TableCell>
                      <TableCell>{item.autoConerNo}</TableCell>
                      <TableCell>
                        <Grid
                          style={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            overflow: "hidden",
                            width: "100%",
                          }}
                        >
                          {/* Header Row */}
                          <Grid
                            container
                            style={{
                              backgroundColor: "#f0f0f0",
                              fontWeight: "bold",
                            }}
                          >
                            <Grid
                              item
                              xs={6}
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              DrumNo
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              Break length metre
                            </Grid>
                          </Grid>

                          {Array.isArray(item?.particular) &&
                            item.particular.map((particularItem, i) => (
                              <Grid container key={i}>
                                {Object.entries(particularItem).map(
                                  ([subKey, subValue]) => {
                                    // First check for drumNo
                                    if (subKey.startsWith("drumNo")  ) {
                                      return (
                                        <Grid
                                          item
                                          xs={6}
                                          key={subKey}
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          {subValue}
                                        </Grid>
                                      );
                                    }
                                    return null;
                                  }
                                )}

                                {/* Now separately render lengthInMM */}
                                {Object.entries(particularItem).map(
                                  ([subKey, subValue]) => {
                                    if (subKey === "lengthInMM" ) {
                                      return (
                                        <Grid
                                          item
                                          xs={6}
                                          key={subKey}
                                          style={{
                                            border: "1px solid black",
                                            padding: "8px",
                                          }}
                                        >
                                          {subValue}
                                        </Grid>
                                      );
                                    }
                                    return null; // Skip unwanted fields
                                  }
                                )}
                              </Grid>
                            ))}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </ContainerWrapper>
  );
}

export default RewindingStudyReport;
