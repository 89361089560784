import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import Loader from "../../../../../components/Loader";
import CheckOverflow from "../../../../../utils/CheckOverflow";
import { getReportIssueDataForMd } from "./mutation";

const MdTaskSheets = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const {
    data: reportIssueData,
    isLoading: isReportDataLoading,
    refetch,
  } = useQuery(
    "md-task-sheets-report-issue",
    async () => {
      setIsLoadingData(true);
      try {
        const response = await getReportIssueDataForMd();
        return response;
      } catch (error) {
        throw error;
      } finally {
        setIsLoadingData(false);
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  //this is for pretend the rerender issue when we give checkoverflow params

  useEffect(() => {
    let newFilteredData = [];

    //for MD we should show the status 'PENDING' Data only
    newFilteredData =
      reportIssueData?.data?.data?.ReportIssue?.filter(
        (row) => row.status === "PENDING"
      ) || [];

    setFilteredData(newFilteredData);
  }, [reportIssueData]);

  //handle overflow text
  const [isOverflowDraft, addDraftRef] = CheckOverflow(filteredData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflowDraft[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  //handle show image in television only
  const [openNew, setOpenNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpenNew(true);
  };

  const handleClose = () => {
    setOpenNew(false);
  };

  if (isReportDataLoading || isLoadingData) {
    return <Loader />;
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        rowGap={3}
        style={{ padding: "20px" }}
        alignContent={"flex-start"}
      >
        <Grid container item xs={12} justifyContent={"center"}>
          <h2>Task Sheets</h2>
        </Grid>
        <Grid container item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ maxWidth: "70px", fontWeight: "600" }}>
                    Sl No
                  </TableCell>
                  <TableCell style={{ maxWidth: "70px", fontWeight: "600" }}>
                    Date
                  </TableCell>
                  <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                    Description
                  </TableCell>
                  <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                    Department
                  </TableCell>
                  <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                    Reported By
                  </TableCell>

                  <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                    Image
                  </TableCell>

                  <TableCell style={{ maxWidth: "220px", fontWeight: "600" }}>
                    Action / Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.length > 0 ? (
                  filteredData
                    ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                    ?.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell style={{ maxWidth: "70px" }}>
                            <p className="overflowContain"> {row.slNo}</p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "70px" }}>
                            <p className="overflowContain">
                              {dayjs(row.date).format("DD-MM-YYYY")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "150px" }}>
                            <p
                              // className="overflowContain"
                              ref={addDraftRef(`description-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `description-${row.id}`,
                                  row.description
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                color: "#e03131",
                                fontWeight: "bold",
                                textAlign: "justify",
                                wordWrap: "break-word", // Breaks long words to fit within the container
                                whiteSpace: "normal", // Allow wrapping on multiple lines
                              }}
                            >
                              {row.description}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`department-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `department-${row.id}`,
                                  row?.department
                                    ?.map((item) => item)
                                    .join(", ")
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row?.department?.map((item) => item).join(", ")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`reportedBy-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `reportedBy-${row.id}`,
                                  row.reportedBy
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row.reportedBy}
                            </p>
                          </TableCell>

                          <TableCell style={{ maxWidth: "100px" }}>
                            <img
                              src={row?.image}
                              alt="issue"
                              style={{
                                width: "100%",
                                height: "100px",
                                cursor: "pointer",
                                objectFit: "cover",
                              }}
                              onClick={() => handleClickOpen(row.image)}
                            />
                          </TableCell>

                          <TableCell style={{ maxWidth: "220px" }}>
                            <Grid
                              container
                              item
                              xs={12}
                              rowGap={1}
                              gap={2}
                              alignItems="center"
                            >
                              {row.status === "PENDING" && (
                                <p
                                  style={{
                                    width: "100%",
                                    color: "#f08c00",
                                  }}
                                >
                                  {row.status}
                                </p>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography align="center">No data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <Dialog open={openNew} onClose={handleClose} maxWidth="lg">
              <DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  style={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img
                  src={selectedImage}
                  alt="issue"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </DialogContent>
            </Dialog>

            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <p style={{ padding: "15px" }}>{popoverContent}</p>
            </Popover>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default MdTaskSheets;
