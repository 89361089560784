import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {
  getAllSubmittedData,
  getStandardsMasterData,
  managerUpdateData,
  saveDraftsData,
  submitData,
  updateDraftsData,
} from "./mutation";
import DialogForLoadingMachine from "../../../components/DialogForLoadingMachine";
import { CircularProgress, MenuItem, Popover, Select } from "@mui/material";
import { handleToastMsg } from "../../../components/Toast";
import CheckOverflow from "../../../utils/CheckOverflow";
import InputFields from "../InputFields";
import { useLocation } from "react-router";

const AddMachineRecord = ({
  activeComponent,
  selectedDataToEdit,
  goBack,
  viewMode,
  editMode,
  isManager,
  isTelevision,
  setPauseInterval,
  masterKey,
}) => {
  const queryClient = useQueryClient();
  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query
  const [testReportFileName, setTestReportFileName] = useState("");

  const location = useLocation();
  const fileInputRef = useRef(null);

  const today = dayjs().format("DD-MM-YYYY");
  const params = { partyCode, key: masterKey };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { data: allSubmittedData } = useQuery(["all-data", masterKey], () =>
    getAllSubmittedData(masterKey)
  );

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const [formData, setFormData] = useState({
    partyCode: "",
    feedback: "",
  });

  //this is for resetting the inputFields when the partyCode is changing
  useEffect(() => {
    if (activeComponent === "AddRecord" && !editMode && !viewMode) {
      setFormData({
        partyCode: partyCode,
        feedback: "",
      });

      setApiStandardsData({});
    }
  }, [activeComponent, editMode, partyCode, viewMode]);

  const showApiFields =
    apiStandardsData?.data?.data
      ?.map((item) => {
        // Destructure to remove the unwanted fields: inputType, options, and outOfNorms
        const { inputType, options, outOfNorms, category, ...rest } = item;
        // Return only the remaining fields
        return rest;
      })
      // Optional: filter out any empty objects, if needed
      ?.filter((item) => Object.keys(item)?.length > 0) || [];

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const { isLoading, refetch } = useQuery(
    "all-standard-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const submitMutation = useMutation(
    ({ payload, masterKey }) => submitData(payload, masterKey),
    {
      onSuccess: () => {
        goBack();
        queryClient.invalidateQueries("all-data");
        queryClient.invalidateQueries("all-drafts-data");
        handleToastMsg("Data submitted successfully", "success");
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const saveMutation = useMutation(
    ({ payload, masterKey }) => saveDraftsData(payload, masterKey),
    {
      onSuccess: () => {
        goBack();
        queryClient.invalidateQueries("all-drafts-data");
        handleToastMsg("Draft data saved successfully", "success");
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const updateMutation = useMutation(updateDraftsData, {
    onSuccess: () => {
      goBack();
      queryClient.invalidateQueries("all-drafts-data");
      handleToastMsg("Draft data updated successfully", "success");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const managerUpdateMutation = useMutation(
    (params) => managerUpdateData(params),
    {
      onSuccess: () => {
        goBack();
        queryClient.invalidateQueries("all-data");
        queryClient.invalidateQueries("all-notification-data");
        handleToastMsg("Draft data updated successfully", "success");
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const [applied, setApplied] = useState(false);
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState("");

  useEffect(() => {
    if (isManager) {
      //this is for showing the date select
      setApplied(true);
      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        // Iterate over `data` and include the keys and values in formData
        ...Object.entries(selectedDataToEdit?.data || {}).reduce(
          (acc, [key, valueObj]) => ({
            ...acc,
            [key]: valueObj, // Assign the `value` part to the key in formData
          }),
          {}
        ),
      }));
    }

    if ((editMode || viewMode) && selectedDataToEdit) {
      //this is for showing the date select
      setApplied(true);

      //this is for standard data api call
      setPartyCode(selectedDataToEdit?.partyCode);
      setShouldFetch(true);
      //this all other data fetch

      setFormData((prev) => ({
        ...prev,
        partyCode: selectedDataToEdit?.partyCode,
        feedback: selectedDataToEdit?.feedback,
        // Iterate over `data` and include the keys and values in formData
        ...Object.entries(selectedDataToEdit?.data || {}).reduce(
          (acc, [key, valueObj]) => ({
            ...acc,
            [key]: valueObj, // Assign the `value` part to the key in formData
          }),
          {}
        ),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, selectedDataToEdit]);

  const handlePartyChange = (event) => {
    setPartyCode(event.target.value);
    setFormData((prev) => ({ ...prev, partyCode: event.target.value }));
  };

  const handleFeedback = (event) => {
    setFormData((prev) => ({
      ...prev,
      feedback: event.target.value.toUpperCase(),
    }));
  };

  //handle submit
  const handleSubmit = (event) => {
    event.preventDefault();

    const { partyCode, feedback, ...restData } = formData;

    // Filter out keys where nested "isOutOfNorms" is true
    const outOfNormsCheck = Object.entries(restData)?.flatMap(
      ([key, value]) => {
        // Check if value itself is an object and has "isOutOfNorms" as a nested property
        return Object.entries(value)
          ?.filter(
            ([nestedKey, nestedValue]) =>
              nestedKey === "isOutOfNorms" && nestedValue === true
          )
          ?.map(() => key); // Map to just the main key when isOutOfNorms is true
      }
    );

    const updatedFormDataForApi = {
      partyCode: formData?.partyCode,
      variety: apiStandardsData?.data?.variety,
      feedback: formData?.feedback,
      isOutOfNorms: outOfNormsCheck?.length > 0 ? true : false,
      isOutOfNormsKeys: outOfNormsCheck, // send the outOfNorms keys
      data: { ...restData },
    };

    const payload = selectedDataToEdit
      ? {
          ...updatedFormDataForApi,
          //we just need to check if 'others' we should send 'other' because of BE not change
          [masterKey === "others"
            ? "otherMasterDraftId"
            : masterKey + "MasterDraftId"]: selectedDataToEdit?.id,
        }
      : updatedFormDataForApi;

    const params = {
      payload: updatedFormDataForApi,
      id: selectedDataToEdit?.id,
      masterKey: masterKey,
    };

    if (isManager) {
      managerUpdateMutation.mutate(params);
    } else {
      submitMutation.mutate({ payload, masterKey });
    }
  };

  // Convert image file to base64 URL
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  //handle add test report
  const handleTestReport = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check if file type is either image or PDF
    const validFileTypes = ["image/", "application/pdf"];
    if (!validFileTypes.some((type) => file.type.startsWith(type))) {
      alert("Please upload a valid Image or PDF file.");
      return;
    }

    // Check PDF file size limit (1 MB)
    if (file.type === "application/pdf" && file.size > 1024 * 1024) {
      alert("PDF file size should not exceed 1 MB.");
      return;
    }

    let processedFile = file;

    setTestReportFileName(processedFile?.name);

    // Check if file is an image and larger than 1 MB
    if (file.type.startsWith("image") && file.size > 1024 * 1024) {
      processedFile = await compressImage(file);
    }

    // Convert to base64 if it's an image
    const base64Url = await convertToBase64(processedFile);

    // Update formData with the processed file
    setFormData((prev) => ({
      ...prev,
      testReport: base64Url,
    }));
  };

  //handle compress image
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 1920;
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          // Compress to JPEG with 0.7 quality (adjust as needed)
          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: "image/jpeg" }));
            },
            "image/jpeg",
            0.7
          );
        };

        image.onerror = (error) => reject(error);
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  //handle Save and edit
  const handleSave = (event) => {
    event.preventDefault();

    const { partyCode, feedback, ...restData } = formData;

    const updatedFormDataForApi = {
      partyCode: formData?.partyCode,
      feedback: formData?.feedback,
      data: { ...restData }, //we need to have the fields in 'data' thats why we have changed
    };

    if (editMode) {
      const params = {
        payload: {
          ...updatedFormDataForApi,
          variety: apiStandardsData?.data?.variety,
        },
        id: selectedDataToEdit?.id,
        masterKey: masterKey,
      };
      updateMutation.mutate(params);
    } else {
      saveMutation.mutate({
        payload: {
          ...updatedFormDataForApi,
          variety: apiStandardsData?.data?.variety,
        },
        masterKey, // Include masterKey here
      });
    }
  };

  const handleApply = () => {
    setShouldFetch(true); // Trigger the query
    setApplied(true);
  };

  const handleResetPartyCode = () => {
    setPartyCode("");
    setShouldFetch(false); // Disable the query
    setSelectedDates("");
    setApiStandardsData({});
  };

  useEffect(() => {
    const uniqueDates = [
      ...new Set(allSubmittedData?.data?.map((item) => item.date)),
    ];
    setAllDates(uniqueDates);
  }, [allSubmittedData]);

  const handleDateChange = (event) => {
    setSelectedDates(event.target.value);
  };

  const filteredDataFromApi =
    allSubmittedData?.data?.filter(
      (item) => item.partyCode === partyCode && selectedDates === item.date
    ) || [];

  const isEnabledSubmitValid = (checkApiStandardsData, checkFr) => {
    if (
      !checkFr.partyCode ||
      checkFr.partyCode === "" ||
      !checkFr.feedback ||
      checkFr.feedback === ""
    ) {
      return false;
    }
    if (!Array.isArray(checkApiStandardsData)) {
      return false;
    }
    for (let item of checkApiStandardsData) {
      const filteredKeys = Object.keys(item)?.filter(
        (key) =>
          key !== "inputType" &&
          key !== "category" &&
          key !== "outOfNorms" &&
          key !== "options"
      );
      const key = filteredKeys;
      if (checkFr[key]) {
        const valueInSubmit = checkFr[key].value;

        if (typeof valueInSubmit === "object" && valueInSubmit !== null) {
          const allValuesFilled = Object.entries(valueInSubmit).every(
            ([key, value]) =>
              value !== "" && value !== null && value !== undefined
          );
          if (!allValuesFilled) {
            return false;
          }
        }

        if (
          valueInSubmit === undefined ||
          valueInSubmit === null ||
          valueInSubmit === "" ||
          (typeof valueInSubmit === "string" && valueInSubmit.trim() === "") ||
          (Array.isArray(valueInSubmit) && valueInSubmit.length === 0)
        ) {
          return false; // If any value is empty or null, return false
        }
      } else {
        return false; // If the key doesn't exist in checkFr, return false
      }
    }
    return true;
  };
  const checkApiStandardsData = apiStandardsData?.data?.data;
  const checkFormData = formData;
  const enableSubmit = isEnabledSubmitValid(
    checkApiStandardsData,
    checkFormData
  );

  //all outOfNorms should be false
  const isManagerEnableSubmit =
    Object.entries(formData)?.every(([key, value]) => {
      if (
        value &&
        typeof value === "object" &&
        value.hasOwnProperty("isOutOfNorms")
      ) {
        return value.isOutOfNorms === false; // Ensuring isOutOfNorms is false for the submission to be enabled
      }
      return true; // Non-object values do not affect this check
    }) && formData?.feedback !== "";

  //this function for checking the text is being overflow or not
  //why we give the undefined for this because if empty array it will rerender infinity
  const [isOverflow, ref] = CheckOverflow(
    apiStandardsData?.data ? apiStandardsData?.data : undefined
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGoBack = () => {
    //this is for setting the standard data empty object
    queryClient.setQueryData("all-standard-data", {});

    goBack();
    if (isTelevision) {
      setPauseInterval(false);
    }
  };

  return (
    <Grid container item md={12} xs={12} alignContent="flex-start">
      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        alignItems="center"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <Button onClick={handleGoBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>
        <Grid item xs={11} container justifyContent="center">
          <Typography variant="h6">
            Add {capitalizeFirstLetter(masterKey)} Record Test
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={8} md={5} container alignItems="center">
          <p>Party Code: </p>
          <Grid item xs={4} style={{ paddingRight: "2%", paddingLeft: "2%" }}>
            <TextField
              fullWidth
              id="outlined-basic"
              variant="outlined"
              value={partyCode}
              onChange={handlePartyChange}
              disabled={viewMode || editMode || isManager}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleApply}
            color="primary"
            style={{
              color: "white",
              textTransform: "none",
              marginRight: "8px",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            onClick={handleResetPartyCode}
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            disabled={viewMode || editMode || isManager}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={2} md={4}>
          <p
            className="overflowContain"
            ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                `Variety-${apiStandardsData?.data?.variety}`,
                apiStandardsData?.data?.variety
              )
            }
            onMouseLeave={handlePopoverClose}
          >
            Variety : <b>{apiStandardsData?.data?.variety}</b>
          </p>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ padding: "15px" }}>{popoverContent}</p>
          </Popover>
        </Grid>

        <Grid item xs={2} md={3}>
          {applied && partyCode && (
            <Select
              value={selectedDates}
              onChange={handleDateChange}
              label="Select Date"
              fullWidth
            >
              {allDates?.map((date) => (
                <MenuItem key={date} value={date}>
                  {dayjs(date).format("DD-MM-YYYY")}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Particulars</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Previous Data
                </Grid>
                <Grid item xs={12}>
                  {selectedDates && dayjs(selectedDates).format("DD-MM-YYYY")}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  Actual Data
                </Grid>
                <Grid item xs={12}>
                  {isManager
                    ? dayjs(selectedDataToEdit?.date).format("DD-MM-YYYY")
                    : today}
                </Grid>
              </TableCell>
              {isManager && (
                <TableCell>
                  <Grid item xs={12}>
                    Correction Data
                  </Grid>
                  <Grid item xs={12}>
                    {today}
                  </Grid>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {showApiFields?.map((item) => {
              return Object.entries(item)?.map(([key, value]) => {
                //this is for option if there is the value is null we dont show the textbox
                if (value === null || value === undefined) {
                  // eslint-disable-next-line array-callback-return
                  return;
                }

                // Check the value from apiStandardsData
                const matchingApiData = apiStandardsData?.data?.data?.find(
                  (dataItem) => Object.keys(dataItem)?.includes(key)
                );

                return (
                  <TableRow key={key}>
                    {/* Display the key */}
                    <TableCell>{key}</TableCell>

                    {/* Display the value or fallback if it's from the API */}
                    <TableCell>
                      {matchingApiData
                        ? Array.isArray(matchingApiData[key])
                          ? matchingApiData[key]?.map((item) => item).join(", ")
                          : typeof matchingApiData[key] === "object"
                          ? Object.values(matchingApiData[key])
                              ?.map((item) => item)
                              ?.join(" * ")
                          : matchingApiData[key]
                        : "No matching data"}
                    </TableCell>

                    {/* Color based on isOutOfNorms condition for previos data showing */}
                    <TableCell>
                      <p
                        style={{
                          color:
                            matchingApiData?.outOfNorms === "Yes" &&
                            filteredDataFromApi?.length > 0
                              ? filteredDataFromApi[0]?.data[key]?.isOutOfNorms
                                ? "red"
                                : "green"
                              : "black",
                        }}
                      >
                        {filteredDataFromApi &&
                        typeof filteredDataFromApi[0]?.data[key]?.value ===
                          "object"
                          ? Object.values(
                              filteredDataFromApi[0]?.data[key]?.value
                            )
                              ?.map((val) => val)
                              .join(" * ") ?? "N/A"
                          : Array.isArray(
                              filteredDataFromApi[0]?.data[key]?.value
                            )
                          ? filteredDataFromApi[0]?.data[key]?.value
                              ?.map((val) => val)
                              .join(", ") ?? "N/A"
                          : filteredDataFromApi[0]?.data[key]?.value ?? "N/A"}
                      </p>
                    </TableCell>

                    {/* Only for managers, display extra column */}
                    {isManager && (
                      <TableCell>
                        <p
                          style={{
                            color:
                              matchingApiData?.outOfNorms === "Yes"
                                ? selectedDataToEdit?.data[key]?.isOutOfNorms
                                  ? "red"
                                  : "green"
                                : "black",
                          }}
                        >
                          {selectedDataToEdit &&
                          selectedDataToEdit?.data &&
                          typeof selectedDataToEdit?.data[key]?.value ===
                            "object"
                            ? Object.values(
                                selectedDataToEdit?.data[key]?.value
                              )
                                ?.map((val) => val)
                                .join(" * ")
                            : Array.isArray(
                                selectedDataToEdit?.data[key]?.value
                              )
                            ? selectedDataToEdit?.data[key]?.value
                                ?.map((val) => val)
                                .join(", ")
                            : selectedDataToEdit?.data[key]?.value}
                        </p>
                      </TableCell>
                    )}

                    {/* TextField for editing the formData */}
                    <TableCell>
                      <InputFields
                        fieldKey={key}
                        viewMode={viewMode}
                        isManager={isManager}
                        selectedDataToEdit={selectedDataToEdit}
                        setFormData={setFormData}
                        apiStandardsData={apiStandardsData}
                        formData={formData}
                        inputType={matchingApiData?.inputType}
                        // options={
                        //   matchingApiData?.options && matchingApiData?.options
                        // }
                        options={
                          matchingApiData?.[key] && matchingApiData?.[key]
                        }
                        outOfNorms={
                          matchingApiData?.outOfNorms &&
                          matchingApiData?.outOfNorms
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!isTelevision && (
        <>
          <TextField
            fullWidth
            name="feedback"
            value={formData.feedback}
            onChange={handleFeedback}
            label="Feedback"
            id="outlined-basic"
            variant="outlined"
            style={{ marginTop: "3%" }}
          />
          <Grid container item xs={12} style={{ marginTop: "20px" }}>
            {location?.pathname === "/autoconer-machine-settings" && (
              <>
                <Grid container>
                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleTestReport}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      color: "white",
                      textTransform: "none",
                      marginRight: "2%",
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload Test Report
                  </Button>
                </Grid>
                <Grid container>
                  {/* Display the file name if testReport exists */}
                  {formData?.testReport && testReportFileName && (
                    <p style={{ marginTop: "8px" }}>
                      Selected File: {testReportFileName}
                    </p>
                  )}
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            style={{ marginTop: "2%" }}
          >
            {!isManager && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "2%",
                }}
                disabled={
                  !partyCode ||
                  enableSubmit ||
                  updateMutation.isLoading ||
                  saveMutation.isLoading
                }
                onClick={handleSave}
              >
                {updateMutation.isLoading || saveMutation.isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save and Edit Later"
                )}
              </Button>
            )}
            <Button
              variant="contained"
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
              disabled={
                isManager
                  ? !isManagerEnableSubmit
                  : !enableSubmit ||
                    !partyCode ||
                    viewMode ||
                    managerUpdateMutation.isLoading ||
                    submitMutation.isLoading ||
                    (location.pathname === "/autoconer-machine-settings"
                      ? !formData?.testReport
                      : false)
              }
              onClick={handleSubmit}
            >
              {managerUpdateMutation.isLoading || submitMutation.isLoading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Grid>
        </>
      )}

      <DialogForLoadingMachine open={isLocalLoading || isLoading} />
    </Grid>
  );
};

export default AddMachineRecord;
