import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Box,
  Hidden,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { uploadCSV } from "./mutation";
import { useMutation } from "react-query";
import CustomButton from "../../components/Button";
import DialogModal from "../../components/Dialog";
import FileDragger from "../../components/FileDragger";
import { ButtonParent, CancelButton, Container, UploadButton } from "./styles";
import { handleToastMsg } from "../../components/Toast";

const WrappingAutomaticWrapper = styled(Grid)`
  width: 100%;
  padding: 4%;
  .box-wrapper {
    border: 1px solid black;
    padding: 4% 3%;
    border-radius: 20px;
  }
`;

const VerticalDivider = () => (
  <Box
    sx={{
      borderLeft: 1,
      height: "80px",
      borderColor: "grey",
    }}
  />
);

function WrappingAutomatic() {
  const [modelToggle, setModelToggle] = useState(false);
  const [csvFile, setCsvFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const uploadCSVMutation = useMutation(uploadCSV);
  const [type, setType] = useState("");

  const handleCloseModal = (e) => {
    setModelToggle(false);
    setCsvFile([]);
    e.stopPropagation();
  };

  //this is when diaolg box open the csvfile should be empty
  useEffect(() => {
    setCsvFile([]);
  }, [modelToggle]);

  const handleUploadButton = (PdfType) => () => {
    setModelToggle(true);
    setType(PdfType);
  };

  const uploadCSVSubmit = (event) => {
    event.stopPropagation();
    const formData = new FormData();
    setIsLoading(true);
    if (csvFile.length > 0) {
      formData.append("file", csvFile[0]);
      uploadCSVMutation.mutate(
        { payload: formData, type },
        {
          onSuccess: (res) => {
            setIsLoading(false);

            if (res) {
              handleToastMsg("File uploaded successfully", "success");
              setModelToggle(false);
              setCsvFile([]);
            } else {
              handleToastMsg("Error while uploading csv", "error");
            }
          },
          onError: (error) => {
            handleToastMsg("Error while uploading csv", "error");
            setIsLoading(false);
          },
        }
      );
    }
  };

  return (
    <WrappingAutomaticWrapper>
      <Grid item container justifyContent={"space-between"} mb={4}>
        <Grid item>
          <h2>UPLOAD SASTRA REPORTS</h2>
        </Grid>
        {/* <Grid item>
          <CustomButton onClick={handleUploadButton("")}>
            Upload CSV
          </CustomButton>
        </Grid> */}
      </Grid>

      {/* do not remove the comment below */}
      {/* <Grid container item xs={12} alignItems={"center"}>
        <Grid item md={1.5} sm={4} xs={12}>
          <p>No. of Samples: </p>
        </Grid>
        <Grid item md={2} sm={4} xs={12}>
          <TextField
            value={shiftValue}
            onChange={(e) => handleShiftChange(e)}
            select
            fullWidth
          >
            {[...Array(10)].map((_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid> */}

      <Grid
        container
        item
        xs={12}
        rowGap={4}
        columnGap={{ md: 4, sm: 1 }}
        mt={4}
        // padding={4}
      >
        <Grid
          item
          md={3.8}
          sm={5.8}
          xs={12}
          className="box-wrapper"
          container
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <b>Carding</b>
          </Grid>
          <Grid item>
            Wrapping{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("CARDING_WRAPPING")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3.8}
          sm={5.8}
          xs={12}
          className="box-wrapper"
          container
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <b>Simplex</b>
          </Grid>
          <Grid item>
            Wrapping{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("SIMPLEX_WRAPPING")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <VerticalDivider />
            </Grid>
          </Hidden>
          <Grid item>
            Stretch{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("STRETCH")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3.8}
          sm={5.8}
          xs={12}
          className="box-wrapper"
          container
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <b>Comber</b>
          </Grid>
          <Grid item>
            Wrapping{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("COMBER_WRAPPING")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <VerticalDivider />
            </Grid>
          </Hidden>
          <Grid item>
            Noils{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("NOILS")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3.8}
          sm={5.8}
          xs={12}
          className="box-wrapper"
          container
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <b>Breaking Drawing</b>
          </Grid>
          <Grid item>
            Wrapping{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("BREAKING_WRAPPING")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={3.8}
          sm={5.8}
          xs={12}
          className="box-wrapper"
          container
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <b>Finisher Drawing</b>
          </Grid>
          <Grid item>
            Wrapping{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("FINISHER_DRAWING")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <VerticalDivider />
            </Grid>
          </Hidden>
          <Grid item>
            A%{" "}
            <Grid item mt={"12%"}>
              <CustomButton onClick={handleUploadButton("APERCENTAGE")}>
                Upload CSV
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DialogModal
        DialogModal
        toggleModal={modelToggle}
        setToggleModal={setModelToggle}
      >
        <DialogTitle>Upload Investment Insights</DialogTitle>
        <DialogContent
          style={{ padding: "16px", maxHeight: "calc(100vh - 400px)" }}
        >
          <Container container>
            <FileDragger
              setPdfFile={setCsvFile}
              upload={["csv"]}
              uploadedFiles={csvFile}
            />
          </Container>
        </DialogContent>
        <DialogActions style={{ padding: "0px 16px 20px 0px" }}>
          <>
            <ButtonParent>
              <Grid item>
                <CancelButton
                  variant="outlined"
                  onClick={(e) => handleCloseModal(e)}
                >
                  Cancel
                </CancelButton>
              </Grid>
              <Grid item>
                <UploadButton
                  variant="contained"
                  disabled={isLoading || csvFile.length === 0}
                  onClick={uploadCSVSubmit}
                  style={{
                    background: csvFile.length === 0 ? "grey" : "",
                    width: "100px",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      style={{ position: "absolute", margin: "6px" }}
                    />
                  ) : (
                    "Upload"
                  )}
                </UploadButton>
              </Grid>
            </ButtonParent>
          </>
        </DialogActions>
      </DialogModal>
    </WrappingAutomaticWrapper>
  );
}

export default WrappingAutomatic;
