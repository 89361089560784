import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import DynamicInputField from "../DynamicInputField";

const BlowroomMasterForm = ({ blowroom, setBlowroom, loading, error }) => {
  useEffect(() => {
    if (blowroom && blowroom.length > 0) {
      const initializedBlowroom = blowroom.map((field) => {
        const fieldName = Object.keys(field).find(
          (key) => key !== "inputType" && key !== "options"
        );

        // Initialize values for specific measurement types if they are not initialized
        if (
          field.inputType === "1*2 measurements" &&
          (typeof field[fieldName] !== "object" || field[fieldName] === null)
        ) {
          return { ...field, [fieldName]: { x: "", y: "" } };
        }

        if (
          field.inputType === "1*3 measurements" &&
          (typeof field[fieldName] !== "object" || field[fieldName] === null)
        ) {
          return { ...field, [fieldName]: { x: "", y: "", z: "" } };
        }

        return field;
      });
      const isDifferent = initializedBlowroom.some((field, index) => {
        const originalField = blowroom[index];
        return JSON.stringify(field) !== JSON.stringify(originalField);
      });

      if (isDifferent) {
        setBlowroom(initializedBlowroom);
      }
    }
  }, [blowroom, setBlowroom]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const updatedBlowroom = blowroom.map((field) => {
      const fieldName = Object.keys(field).find(
        (key) => key !== "inputType" && key !== "options"
      );

      // For measurement fields, handle nested changes (e.g., name="fieldName.x")
      if (
        field.inputType === "1*2 measurements" ||
        field.inputType === "1*3 measurements"
      ) {
        const [fieldKey, subKey] = name.split(".");
        if (fieldKey === fieldName) {
          return {
            ...field,
            [fieldName]: { ...field[fieldName], [subKey]: value },
          };
        }
      }

      if (fieldName === name) {
        return { ...field, [fieldName]: value };
      }
      return field;
    });

    setBlowroom(updatedBlowroom); // Update the blowroom array
    // console.log(blowroom);
  };

  const handleSelectChange = (name, value) => {
    const updatedBlowroom = blowroom.map((field) => {
      const fieldName = Object.keys(field).find(
        (key) => key !== "inputType" && key !== "options"
      );

      if (fieldName === name) {
        return { ...field, [fieldName]: value };
      }
      return field;
    });

    setBlowroom(updatedBlowroom);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  if (!blowroom || blowroom.length === 0) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <Typography>No fields added</Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      style={{ marginTop: "2%" }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Array.isArray(blowroom) &&
              blowroom.map((field, index) => {
                // Ensure the inputType exists
                if (!field.inputType) {
                  console.error(
                    `Field at index ${index} does not have an inputType.`
                  );
                  return null; // Handle missing inputType appropriately
                }

                // Get the key (e.g., "New Field Name", "feildTest", etc.)
                const fieldName = Object.keys(field).find(
                  (key) => key !== "inputType" && key !== "options"
                );
                const fieldValue = field[fieldName] || "";

                return (
                  <TableRow key={index}>
                    <TableCell style={{ width: "30%" }}>{fieldName}</TableCell>
                    <TableCell style={{ width: "70%" }}>
                      <DynamicInputField
                        name={fieldName}
                        value={fieldValue} // Correctly passed value
                        inputType={field.inputType}
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        options={field.options || []} // Pass options or empty array
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default BlowroomMasterForm;
