import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery, useQueryClient } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, CircularProgress } from "@mui/material";
import CustomButton from "../../../components/Button";
import { handleToastMsg } from "../../../components/Toast";
import {
  CHUTE_FEED_WASTE_COTTON_CARDING_NUMBERS,
  HALL_NO,
} from "../../../config/masterdata";
import { chuteFeed15Create, getVariety } from "../mutation";
import "./chutefeedwastecottoncss.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filter = createFilterOptions();

const table_one_data = {
  variety: "", // Use the selected variety or an empty string if not selected
  cardingWaste: "",
  blowroomDrapping: "",
  microWaste: "",
  condamination: "",
};

export default function ChuteFeedWasteCotton() {
  const [data, setData] = React.useState(
    CHUTE_FEED_WASTE_COTTON_CARDING_NUMBERS
  );

  const [tableErrors, setTableErrors] = useState({
    cardingWaste: false,
    blowroomDrapping: false,
    microWaste: false,
    condamination: false,
  });
  const queryClient = useQueryClient();
  const [hallNo, setHallNo] = React.useState("");

  const [tableErrorMessage, setTableErrorMessage] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const [cardingLineNum, setCardingLineNum] = useState("");
  const handlecardingLineNumChange = (event) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseInt(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setCardingLineNum(changedValue);
    }
  };

  const [table_one, setTableOne] = useState(table_one_data);
  const handleWasteChange = (event, key) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setTableOne((prevTableOne) => ({
        ...prevTableOne,
        [key]: changedValue,
      }));
    }
  };

  const [value, setValue] = React.useState("");
  const [selectedCardingNumbers, setSelectedCardingNumbers] = React.useState(
    []
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newData = value?.map((carding_num) => {
      // Finding for exiting data
      const existingData = data?.find(
        (item) => item.carding_num === carding_num
      );

      return existingData
        ? {
            carding_num,
            ...existingData,
          }
        : { carding_num, fs_wt: "", production: "" };
    });

    // set the new data
    setData(newData);
    setSelectedCardingNumbers(value);
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const calculateTotalWaste = () => {
    // Calculate the total waste based on the values in other rows
    const cardingWaste = parseFloat(table_one.cardingWaste) || 0;
    const blowroomDrapping = parseFloat(table_one.blowroomDrapping) || 0;
    const microWaste = parseFloat(table_one.microWaste) || 0;
    const condamination = parseFloat(table_one.condamination) || 0;

    return cardingWaste + blowroomDrapping + microWaste + condamination;
  };

  const calculateTotalPercentage = () => {
    const totalWasteCalculate = calculateTotalWaste();

    //**dont want condamination value to calculate the total percentage */
    const condamination = parseFloat(table_one.condamination) || 0;

    const totalWaste = totalWasteCalculate - condamination;

    const sumOfProduction = data.reduce(
      (sum, row) => sum + parseFloat(row.production || 0),
      0
    );

    // Calculate the total percentage
    const totalWasteless = totalWaste - condamination;
    const totalPercentage =
      (totalWasteless / (totalWasteless + sumOfProduction)) * 100;
    return isNaN(totalPercentage) ? "" : parseFloat(totalPercentage.toFixed(2));
  };

  const calculateIndividualPercentage = (value) => {
    if (value) {
      const sumOfProduction = data.reduce(
        (sum, row) => sum + parseFloat(row.production || 0),
        0
      );
      const totalPercentage = (value / (value + sumOfProduction)) * 100;
      return isNaN(totalPercentage)
        ? ""
        : parseFloat(totalPercentage.toFixed(2));
    } else {
      return "";
    }
  };

  const resetForm = () => {
    setValue("");
    setHallNo("");
    setCardingLineNum("");
    setFeedback("");
    setTableOne(table_one_data);
    setSelectedCardingNumbers([]);
    setData(CHUTE_FEED_WASTE_COTTON_CARDING_NUMBERS);
  };

  const handleFieldChange = (event, index, fieldName) => {
    const { value } = event.target;

    const changedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const updatedData = [...data];
      updatedData[index][fieldName] = changedValue;
      setData(updatedData);
    }
  };

  const handleReview = (e) => {
    const payload = {
      cardingLineNum: cardingLineNum,
      variety: value, // Use the selected variety or an empty string if not selected
      cardingWaste: table_one.cardingWaste, // Set your desired value for fs_waste here
      blowroomDrapping: table_one.blowroomDrapping, // Set your desired value for cardingLineNum here
      microWaste: table_one.microWaste, // Set your desired value for cardingLineNum here
      condamination: table_one.condamination, // Set your desired value for cardingLineNum here
      totalWaste: calculateTotalWaste(),
      hall: hallNo,
      totalWastePercentage: calculateTotalPercentage(),
      cardingWastePercentage: calculateIndividualPercentage(
        table_one?.cardingWaste
      ),
      blowroomDrappingWastePercentage: calculateIndividualPercentage(
        table_one?.blowroomDrapping
      ),
      microWastePercentage: calculateIndividualPercentage(
        table_one?.microWaste
      ),
      feedback: feedback, // Initialize feedback as an empty string
    };

    // Loop through the data for selected carding numbers
    selectedCardingNumbers.forEach((cardingNum, index) => {
      payload[`production${cardingNum}`] = data[index][`production`] || 0;
      payload[`cylinderSpeed${cardingNum}`] =
        data[index][`cylinder_speed`] || 0;
      payload[`likerInSpeed1_${cardingNum}`] =
        data[index][`likerInSpeed1_`] || 0;
      payload[`likerInSpeed2_${cardingNum}`] =
        data[index][`likerInSpeed2_`] || 0;
      payload[`likerInSpeed3_${cardingNum}`] =
        data[index][`likerInSpeed3_`] || 0;
      payload[`flatsSpeed${cardingNum}`] = data[index][`flats_speed`] || 0;
      payload[`deliverySpeed${cardingNum}`] =
        data[index][`delivery_speed`] || 0;
      payload[`wingSetting1_${cardingNum}`] =
        data[index][`wing_setting_1_`] || 0;
      payload[`wingSetting2_${cardingNum}`] =
        data[index][`wing_setting_2_`] || 0;
    });
    setModalData(payload);
    setOpenModal(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (!table_one.cardingWaste) {
      errors.cardingWaste = true;
    }
    if (!table_one.blowroomDrapping) {
      errors.blowroomDrapping = true;
    }
    if (!table_one.microWaste) {
      errors.microWaste = true;
    }
    if (!table_one.condamination) {
      errors.condamination = true;
    }

    // Check if any errors were found
    if (Object.keys(errors).length > 0) {
      setTableErrors(errors);
      setTableErrorMessage("Please fill in all Table values");
      return;
    }

    // Reset errors
    setTableErrors({
      cardingWaste: false,
      blowroomDrapping: false,
      microWaste: false,
      condamination: false,
    });
    setTableErrorMessage("");

    const payload = {
      cardingLineNum: cardingLineNum,
      variety: value, // Use the selected variety or an empty string if not selected
      cardingWaste: table_one.cardingWaste, // Set your desired value for fs_waste here
      blowroomDrapping: table_one.blowroomDrapping, // Set your desired value for cardingLineNum here
      microWaste: table_one.microWaste, // Set your desired value for cardingLineNum here
      condamination: table_one.condamination, // Set your desired value for cardingLineNum here
      totalWaste: calculateTotalWaste(),
      cardingNo: selectedCardingNumbers?.join(", "),
      totalWastePercentage: calculateTotalPercentage(),
      cardingWastePercentage: calculateIndividualPercentage(
        table_one?.cardingWaste
      ),
      blowroomDrappingWastePercentage: calculateIndividualPercentage(
        table_one?.blowroomDrapping
      ),
      microWastePercentage: calculateIndividualPercentage(
        table_one?.microWaste
      ),
      feedback: feedback, // Initialize feedback as an empty string
      hall: hallNo,
    };

    // Loop through the data for selected carding numbers
    selectedCardingNumbers.forEach((cardingNum, index) => {
      payload[`production${cardingNum}`] = data[index][`production`] || 0;
      payload[`cylinderSpeed${cardingNum}`] =
        data[index][`cylinder_speed`] || 0;
      payload[`likerInSpeed1_${cardingNum}`] =
        data[index][`likerInSpeed1_`] || 0;
      payload[`likerInSpeed2_${cardingNum}`] =
        data[index][`likerInSpeed2_`] || 0;
      payload[`likerInSpeed3_${cardingNum}`] =
        data[index][`likerInSpeed3_`] || 0;
      payload[`flatsSpeed${cardingNum}`] = data[index][`flats_speed`] || 0;
      payload[`deliverySpeed${cardingNum}`] =
        data[index][`delivery_speed`] || 0;
      payload[`wingSetting1_${cardingNum}`] =
        data[index][`wing_setting_1_`] || 0;
      payload[`wingSetting2_${cardingNum}`] =
        data[index][`wing_setting_2_`] || 0;
    });

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        queryClient.invalidateQueries("get-variety");
        queryClient.invalidateQueries("manager-blowroom-notifications");
        setOpenModal(false);
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading: mutateLoading } = useMutation(chuteFeed15Create);

  return (
    <form>
      <Grid m={2} container spacing={3}>
        <Grid item xs={12} sm={12}>
          <h2 className="heading">Chute Feed Waste(Cotton)</h2>
        </Grid>
        <Grid item>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue(newValue || "");
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue(newValue || "");
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options?.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue.toUpperCase());
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
            getOptionLabel={(option) =>
              typeof option === "string" ? option : ""
            } // Ensure option is a string
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ width: 250 }}
            freeSolo
            renderInput={(params) => (
              <TextField required {...params} label="Variety" />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            value={hallNo}
            select
            onChange={(e) => setHallNo(parseInt(e.target.value))}
            type="number"
            sx={{ width: 250 }}
            id="outlined-basic"
            label="Hall Number"
            variant="outlined"
            // onInput={handleCardingLineNumInput}
          >
            {HALL_NO?.map((hall) => (
              <MenuItem key={hall} value={hall}>
                {hall}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Carding number
            </InputLabel>
            <Select
              required
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedCardingNumbers}
              onChange={handleChange}
              input={<OutlinedInput label="Carding number" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {CHUTE_FEED_WASTE_COTTON_CARDING_NUMBERS.map((item) => (
                <MenuItem key={item.carding_num} value={item.carding_num}>
                  <Checkbox
                    checked={
                      selectedCardingNumbers.indexOf(item.carding_num) > -1
                    }
                  />
                  <ListItemText primary={item.carding_num} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            onChange={handlecardingLineNumChange}
            value={cardingLineNum}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            type="number"
            sx={{ width: 250 }}
            id="standard-basic"
            label="Carding line number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {selectedCardingNumbers.length > 0 && (
            <Grid container spacing={2}>
              <Grid item md={8} sm={11}>
                {tableErrorMessage && (
                  <Typography color="error">{tableErrorMessage}</Typography>
                )}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      {/* <TableRow>
                        <TableCell></TableCell>
                      </TableRow> */}
                    </TableHead>
                    <TableBody>
                      {/* Add your table rows here */}
                      {/* Example row with dynamic columns */}
                      <TableRow>
                        <TableCell>Carding Waste</TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            onChange={(e) =>
                              handleWasteChange(e, "cardingWaste")
                            }
                            value={table_one.cardingWaste}
                            name="cardingWaste"
                            error={tableErrors.cardingWaste}
                          />
                        </TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            disabled
                            value={calculateIndividualPercentage(
                              table_one?.cardingWaste
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Blowroom Drapping</TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            onChange={(e) =>
                              handleWasteChange(e, "blowroomDrapping")
                            }
                            value={table_one.blowroomDrapping}
                            name="blowroomDrapping"
                            error={tableErrors.blowroomDrapping}
                          />
                        </TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            disabled
                            value={calculateIndividualPercentage(
                              table_one?.blowroomDrapping
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Micro Waste</TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            onChange={(e) => handleWasteChange(e, "microWaste")}
                            value={table_one.microWaste}
                            name="microWaste"
                            error={tableErrors.microWaste}
                          />
                        </TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            disabled
                            value={calculateIndividualPercentage(
                              table_one?.microWaste
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Condamination</TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            onChange={(e) =>
                              handleWasteChange(e, "condamination")
                            }
                            value={table_one.condamination}
                            name="condamination"
                            error={tableErrors.condamination}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total waste</TableCell>

                        <TableCell>
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            disabled={true}
                            value={calculateTotalWaste()}
                            name="Total waste"
                          />
                        </TableCell>
                      </TableRow>

                      {/* Add more rows as needed */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid sx={{ mt: 5 }} item xs={4} sm={4} md={4} lg={5} xl={3}>
                  <TextField
                    size="small"
                    id="standard-number"
                    type="number"
                    step="0.01"
                    variant="outlined"
                    style={{ width: "110px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={true}
                    value={calculateTotalPercentage()} // Use the calculated total percentage value
                    name="Total Waste %"
                    helperText="Total Waste %"
                  />
                </Grid>
              </Grid>
              <Grid style={{ overflowX: "auto" }} item xs={11}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Carding number</TableCell>
                        {data.length > 0 &&
                          data.map((row, index) => (
                            <TableCell key={index}>{row.carding_num}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Production</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="production"
                              value={row?.production}
                              onChange={(e) =>
                                handleFieldChange(e, index, "production")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      <TableRow>
                        <TableCell>Liker in speed 1</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="likerInSpeed1_"
                              value={row?.likerInSpeed1_}
                              onChange={(e) =>
                                handleFieldChange(e, index, "likerInSpeed1_")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Liker in speed 2</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="likerInSpeed2_"
                              value={row?.likerInSpeed2_}
                              onChange={(e) =>
                                handleFieldChange(e, index, "likerInSpeed2_")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Liker in speed 3</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="likerInSpeed3_"
                              value={row?.likerInSpeed3_}
                              onChange={(e) =>
                                handleFieldChange(e, index, "likerInSpeed3_")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Cylinder speed</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="cylinder_speed"
                              value={row?.cylinder_speed}
                              onChange={(e) =>
                                handleFieldChange(e, index, "cylinder_speed")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Flats speed</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="flats_speed"
                              value={row?.flats_speed}
                              onChange={(e) =>
                                handleFieldChange(e, index, "flats_speed")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Delivery speed</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="delivery_speed"
                              value={row?.delivery_speed}
                              onChange={(e) =>
                                handleFieldChange(e, index, "delivery_speed")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Wing setting 1</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="wing_setting_1"
                              value={row?.wing_setting_1_}
                              onChange={(e) =>
                                handleFieldChange(e, index, "wing_setting_1_")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell>Wing setting 2</TableCell>
                        {data.map((row, index) => (
                          <TableCell key={index}>
                            {/* Example input for each column */}
                            <TextField
                              size="small"
                              id="standard-number"
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(
                              //     /[^0-9]/g,
                              //     ""
                              //   );
                              // }}
                              name="wing_setting_2"
                              value={row?.wing_setting_2_}
                              onChange={(e) =>
                                handleFieldChange(e, index, "wing_setting_2_")
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>

                      {/* Add more rows as needed */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={11} sm={11}>
          {selectedCardingNumbers.length > 0 && (
            <TextField
              fullWidth
              onChange={handleFeedbackChange}
              value={feedback}
              size="lg"
              name="Size"
              label="Feedback"
            />
          )}
        </Grid>
        <Grid item xs={11} sm={11}>
          {selectedCardingNumbers.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleReview}
                className="submitbutton"
              >
                Final Review
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                value={value}
                disabled={true}
                helperText="Variety"
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                value={hallNo}
                disabled={true}
                helperText="Hall Number"
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                value={cardingLineNum}
                disabled={true}
                helperText="Carding line number"
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                value={calculateTotalWaste()}
                disabled={true}
                helperText="Total waste"
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                value={calculateTotalPercentage()}
                disabled={true}
                helperText="Total waste percent"
              ></TextField>
            </Grid>
          </Grid>
          <TableContainer style={{ maxHeight: "500px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Carding waste</TableCell>
                  <TableCell>Carding waste %</TableCell>
                  <TableCell>Blowroom Drapping</TableCell>
                  <TableCell>Blowroom Drapping %</TableCell>
                  <TableCell>Micro waste</TableCell>
                  <TableCell>Micro waste %</TableCell>
                  <TableCell>Condamination</TableCell>
                  {/* Add more table headers for other payload fields */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{modalData.cardingWaste}</TableCell>
                  <TableCell>
                    {calculateIndividualPercentage(table_one?.cardingWaste)}
                  </TableCell>
                  <TableCell>{modalData.blowroomDrapping}</TableCell>
                  <TableCell>
                    {calculateIndividualPercentage(table_one?.blowroomDrapping)}
                  </TableCell>
                  <TableCell>{modalData.microWaste}</TableCell>
                  <TableCell>
                    {calculateIndividualPercentage(table_one?.microWaste)}
                  </TableCell>
                  <TableCell>{modalData.condamination}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer style={{ maxHeight: "500px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Carding number</TableCell>
                  <TableCell>Production</TableCell>
                  <TableCell>Liker in speed 1</TableCell>
                  <TableCell>Liker in speed 2</TableCell>
                  <TableCell>Liker in speed 3</TableCell>
                  <TableCell>Cylinder speed</TableCell>
                  <TableCell>Flats speed</TableCell>
                  <TableCell>Delivery speed</TableCell>
                  <TableCell>Wing setting 1</TableCell>
                  <TableCell>Wing setting 2</TableCell>
                  {/* Add more table headers for other payload fields */}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(modalData).map((key) => {
                  // Check if the key represents a material index
                  const match = key.match(/^production(\d+)$/);
                  if (match) {
                    const index = parseInt(match[1]) - 1; // Material indices are 1-based
                    const production = modalData[`production${index + 1}`];
                    const cylinderSpeed =
                      modalData[`cylinderSpeed${index + 1}`];
                    const liker1 = modalData[`likerInSpeed1_${index + 1}`];
                    const liker2 = modalData[`likerInSpeed2_${index + 1}`];
                    const liker3 = modalData[`likerInSpeed3_${index + 1}`];
                    const flatsSpeed = modalData[`flatsSpeed${index + 1}`];
                    const deliverySpeed =
                      modalData[`deliverySpeed${index + 1}`];
                    const wing1 = modalData[`wingSetting1_${index + 1}`];
                    const wing2 = modalData[`wingSetting2_${index + 1}`];
                    const cardingNum = parseInt(key.match(/\d+/)[0]);

                    return (
                      <TableRow key={key}>
                        <TableCell>{cardingNum}</TableCell>
                        <TableCell>{production}</TableCell>
                        <TableCell>{liker1}</TableCell>
                        <TableCell>{liker2}</TableCell>
                        <TableCell>{liker3}</TableCell>
                        <TableCell>{cylinderSpeed}</TableCell>
                        <TableCell>{flatsSpeed}</TableCell>
                        <TableCell>{deliverySpeed}</TableCell>
                        <TableCell>{wing1}</TableCell>
                        <TableCell>{wing2}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          <CustomButton
            onClick={handleSubmit}
            style={{ width: "100px", height: "40px" }}
            disabled={mutateLoading}
            type="submit"
          >
            {mutateLoading ? <CircularProgress size={24} /> : "Submit"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
