import React from "react";
import { ContainerWrapper } from "../../components/styles";
import { Grid } from "@mui/material";

function CottonWeighted() {
  return (
    <ContainerWrapper container>
      <Grid item xs={12}>
        <h2>Cotton Weighted</h2>
      </Grid>
    </ContainerWrapper>
  );
}

export default CottonWeighted;
