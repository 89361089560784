import apiClient from "../../../utils/clients/axios";

export const TrailGet = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/trail-get/`,
    payload
  );
  return response;
};

export const TrailCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/trail-create/`,
    payload
  );
  return response;
};
