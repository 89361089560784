import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-moment";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { handleToastMsg } from "../../../components/Toast";
import { getPartyCode } from "./mutation";
import dayjs from "dayjs";

function MachineSettingsReports() {
  const [formData, setFormData] = useState({
    from_date: null,
    to_date: null,
    partyCode: [],
  });

  //get all variety data
  const { data: partyCodeData } = useQuery(
    "get-partycode",
    () => getPartyCode(),
    {
      refetchOnMount: true,
    }
  );

  //handle date change
  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //handle form change
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const [loading, setLoading] = useState(false);

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      return endOfDay
        ? `${formattedDate}T23:59:59`
        : `${formattedDate}T00:00:00`;
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";
    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";
    const partyCodes = formData?.partyCode?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/master/report?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: { partyCode: partyCodes },
        }
      );

      if (Object.keys(response?.data).length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      const workbook = XLSX.utils.book_new();
      const worksheetData = [];

      const addDataWithTitleAndHeaders = (
        partyCode,
        variety,
        keys,
        masters,
        standardsData
      ) => {
        console.log({ partyCode, variety, keys, masters, standardsData });

        if (keys !== "standards") {
          worksheetData.push([`PartyCode: ${partyCode}`]);
          worksheetData.push([`Variety: ${variety}`]);
          worksheetData.push([`Date: ${dayjs(keys).format("DD-MM-YYYY")}`]);
          worksheetData.push([]);
          worksheetData.push([
            "Title",
            "Sl.no",
            "Particulars",
            "Standards",
            "Right entry",
            "Error (if any)",
            "Corrected Figure",
            "Feedback",
          ]); // Add headers

          const processRecords = (records, title) => {
            console.error({ records, title });

            worksheetData.push([`${title}`]);

            //this is for checking the key for the standards
            const standardForParticularData = Object.entries(
              standardsData
            )?.map(([key, values]) => {
              const checkKeys = title?.toLowerCase().includes(key);

              if (checkKeys) {
                return key;
              }

              return null;
            });

            console.log("standardForParticularData", standardForParticularData);

            const getKeyForStandards = standardForParticularData?.filter(
              (item) => item !== null
            );

            let keyForStandard = "";

            if (getKeyForStandards?.length > 0) {
              keyForStandard = getKeyForStandards[0];
            }

            console.log("getKeyForStandards", getKeyForStandards);
            console.log("keyForStandard", keyForStandard);
            //this is for data align in excel sheets
            for (
              let recordIndex = 0;
              recordIndex < records?.length;
              recordIndex++
            ) {
              const record = records[recordIndex]?.data;
              const particulars = [];
              const standards = [];
              const rightEntries = [];
              const errors = [];
              const correctedFigures = [];
              const feedback = [records[recordIndex]?.feedback];

              console.log("record=----=======>", record);
              console.log("feedback=----=======>", feedback);

              // Initialize slNo for each record
              let slNo = 1;

              for (const [key, value] of Object.entries(record)) {
                if (
                  typeof value === "object" &&
                  value !== null &&
                  value !== undefined
                ) {
                  if (!key.toLowerCase().includes("error")) {
                    particulars.push(key);
                    let rightEntry;
                    let correctedValue;
                    let newStandardValue;

                    // const getStandards =
                    //   standardsData[keyForStandard]
                    //     ?.flatMap((item) => {
                    //       // Destructure to remove the unwanted fields: inputType, options, and outOfNorms
                    //       const {
                    //         inputType,
                    //         options,
                    //         outOfNorms,
                    //         category,
                    //         ...rest
                    //       } = item;
                    //       // Return only the remaining fields

                    //       const updatedValues = Object.entries(rest)?.filter(
                    //         ([key1, value]) => key1 === key
                    //       );

                    //       return updatedValues || rest;
                    //     })
                    //     // Optional: filter out any empty objects, if needed
                    //     ?.filter((item) => Object.keys(item)?.length > 0) || [];

                    const getStandards =
                      standardsData[keyForStandard]
                        ?.flatMap((item) => {
                          // Destructure to remove the unwanted fields: inputType, options, and outOfNorms
                          const {
                            inputType,
                            options,
                            outOfNorms,
                            category,
                            ...rest
                          } = item;

                          // Keep only the key-value pair(s) where the key matches `key`
                          const updatedValues = Object.entries(rest)
                            .filter(([key1]) => key1 === key)
                            .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {}); // Convert back to object format

                          // Return only the remaining fields if `updatedValues` has the target key, otherwise skip
                          return Object.keys(updatedValues).length > 0
                            ? updatedValues
                            : null;
                        })
                        // Filter out any null or undefined values, if needed
                        ?.filter(Boolean) || [];

                    //this is for normal value handling(without error)
                    if (Array.isArray(value.value)) {
                      rightEntry = value?.value?.map((item) => item).join(", ");
                      correctedValue =
                        value.isOutOfNorms === false ? rightEntry : "";
                      newStandardValue = getStandards[0]?.[key]
                        ?.map((item) => item)
                        .join(", ");
                    } else if (typeof value.value === "object") {
                      rightEntry = Object.entries(value.value)
                        .map(([, value]) => value)
                        .join(" * ");
                      correctedValue =
                        value.isOutOfNorms === false ? rightEntry : "";
                      newStandardValue = Object.values(
                        getStandards[0]?.[key] ?? {}
                      ).join(" * ");
                    } else {
                      rightEntry = value.value ?? "";
                      correctedValue =
                        value.isOutOfNorms === false ? value.value : "";
                      newStandardValue = getStandards[0]?.[key] ?? "";
                    }

                    standards.push(newStandardValue);
                    rightEntries.push(rightEntry);
                    correctedFigures.push(correctedValue);
                  }

                  //this is for error value handling(error)
                  if (key.toLowerCase().includes("error")) {
                    errors.push(
                      value.isOutOfNorms === true
                        ? { key: key, value: value.value }
                        : ""
                    );
                  }
                }
              }

              // Output each particular with its slNo
              particulars.forEach((particular, index) => {
                const updatedError = errors?.filter(
                  (item) => item.key === `error${particular}`
                );

                //this is for checking whether the error in object or not
                let showError =
                  typeof updatedError[0]?.value === "object"
                    ? Object.values(updatedError[0]?.value)
                        ?.map((val) => val)
                        .join(" * ")
                    : updatedError[0]?.value;

                //this is for align excel sheets
                worksheetData.push([
                  "",
                  slNo++,
                  particular,
                  standards[index] ?? "",
                  rightEntries[index] ?? "",
                  showError ?? "",
                  correctedFigures[index] ?? "",
                  feedback[index] ?? "",
                ]);
              });

              worksheetData.push([]); // Add an empty row for spacing after each record
            }

            worksheetData.push([]); // Add an extra row for spacing after all records for the same date
          };

          console.error("...>>>>", masters);

          // Iterate over all keys in the masters object
          Object.keys(masters).forEach((key) => {
            processRecords(masters[key], key);
          });
        }
      };

      console.log(response?.data);

      for (const [partyCode, masters] of Object.entries(response?.data)) {
        for (const [keys, values] of Object.entries(masters)) {
          // Extract variety from the first available record in values
          let variety = "";
          let standardsData = {};
          for (const [, records] of Object.entries(values)) {
            if (records?.length > 0) {
              variety = records[0]?.variety;
              break;
            }
          }

          standardsData = masters?.standards ? masters?.standards : {};

          addDataWithTitleAndHeaders(
            partyCode,
            variety,
            keys,
            values,
            standardsData
          );
        }
      }

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Consolidated Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "MACHINE-SETTINGS-REPORTS.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper container rowGap={2} height={0}>
        <Grid item xs={12}>
          <h2>Machine Settings Report</h2>
        </Grid>
        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} alignItems={"center"}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell>Party Code</TableCell>
                <TableCell style={{ width: 800 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.partyCode}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(e) => handleFormChange(e, "partyCode")}
                    >
                      {partyCodeData?.map((variety) => (
                        <MenuItem key={variety} value={variety}>
                          <Checkbox
                            checked={formData?.partyCode?.indexOf(variety) > -1}
                          />
                          <ListItemText primary={variety} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Generate"
            )}
          </CustomButton>
        </Grid>
      </ContainerWrapper>
    </form>
  );
}

export default MachineSettingsReports;
