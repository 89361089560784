import apiClient from "../../utils/clients/axios";

// Get all standardData from the all parameter master data
export const getStandardsMasterData = async (params) => {
  const { partyCode, key } = params;

  const response = await apiClient.get(`/api/quality_control/master_data/`, {
    params: { partyCode: partyCode, key: key },
  });
  return response;
};
