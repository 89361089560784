// eslint-disable
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { CountChangePost } from "../Blowroom/mutation/manager/blowroomReportMutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../components/Toast";
import { useLocation, useNavigate } from "react-router";
import {
  createCountChangeDraft,
  updateCountChangeDraft,
} from "./mutation/countChangeMutation";
import { HALL_NO } from "../../config/masterdata";
import CalculateCV from "../../utils/CalculateCV";
import { getVariety } from "../AutoConer/AutoConerMutationVariety";
import "./spinningcss.css";
const CountChange = () => {
  const filter = createFilterOptions();
  const [variety, setVariety] = useState("");

  const [formValues, setFormValues] = useState({
    counts: "",
    machineNo: "",
    feedback: "",
    LycraDraft: "",
  });

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "feedback") {
      value = value.toUpperCase();
    } else if (["counts"].includes(field) || ["LycraDraft"].includes(field)) {
      value = !isNaN(value) ? parseFloat(value) : value;
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const [samplesCount, setSamplesCount] = useState(1);
  const [anyRowInRange, setAnyRowInRange] = useState(false);

  const [rowData, setRowData] = useState(
    [...Array(samplesCount)].map((_, index) => ({
      sample_count_number: index + 1,
      sample_1: "",
      sample_2: "",
      sample_3: "",
      sample_4: "",
      sample_5: "",
      sample_6: "",
      average: 0,
      count_value: 0,
      cv: 0,
    }))
  );

  const [hallNo, setHallNo] = React.useState("");
  const [validCount, setValidCount] = useState(false);
  const [validCountValue, setValidCountValue] = useState("");

  const handleCountValue = (countValue) => {
    const range = 0.44;

    const isInRange =
      countValue >= formValues?.counts - range &&
      countValue <= formValues?.counts + range;

    if (isInRange) {
      setValidCount(true);
      setValidCountValue(countValue);
      setAnyRowInRange(true);
    } else {
      setValidCount(false);
      setValidCountValue("");
    }
  };

  //this is for refetch the CSP when change the reading in save and edited data
  useEffect(() => {
    setStrengthData((prev) => {
      const updatedData = { ...prev };

      if (
        updatedData.sample_1 !== "" &&
        updatedData.sample_2 !== "" &&
        updatedData.sample_3 !== "" &&
        updatedData.sample_4 !== "" &&
        updatedData.sample_5 !== "" &&
        updatedData.sample_6 !== ""
      ) {
        //caluculate AVERAGE
        updatedData.average = (
          (updatedData.sample_1 +
            updatedData.sample_2 +
            updatedData.sample_3 +
            updatedData.sample_4 +
            updatedData.sample_5 +
            updatedData.sample_6) /
          6
        ).toFixed(2);

        //caluculate CV
        updatedData.cv = CalculateCV([
          updatedData.sample_1,
          updatedData.sample_2,
          updatedData.sample_3,
          updatedData.sample_4,
          updatedData.sample_5,
          updatedData.sample_6,
        ]);

        //caluculate CSP
        updatedData.csp = (validCountValue * updatedData.average).toFixed(2);
      } else {
        updatedData.csp = "";
        updatedData.cv = "";
        updatedData.average = "";
      }

      return updatedData;
    });
  }, [validCountValue]);

  const handleAdd = (index) => {
    if (
      rowData[index].sample_1 !== "" &&
      rowData[index].sample_2 !== "" &&
      rowData[index].sample_3 !== "" &&
      rowData[index].sample_4 !== "" &&
      rowData[index].sample_5 !== "" &&
      rowData[index].sample_6 !== ""
    ) {
      setSamplesCount((prev) => prev + 1);
      setAnyRowInRange(false);
      setRowData((prevData) => [
        ...prevData,
        {
          sample_count_number: samplesCount + 1,
          sample_1: "",
          sample_2: "",
          sample_3: "",
          sample_4: "",
          sample_5: "",
          sample_6: "",
          average: 0,
          count_value: 0,
          cv: 0,
        },
      ]);
    } else {
      return;
    }
  };

  const handleChange = (field, value, sampleIndex) => {
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setRowData((prevData) => {
      const updatedData = prevData?.map((rowData) => {
        if (rowData.sample_count_number === sampleIndex) {
          rowData[field] = value === "" ? "" : parseFloat(value);

          if (
            rowData.sample_1 !== "" &&
            rowData.sample_2 !== "" &&
            rowData.sample_3 !== "" &&
            rowData.sample_4 !== "" &&
            rowData.sample_5 !== "" &&
            rowData.sample_6 !== ""
          ) {
            //caluculate AVERAGE
            rowData.average = parseFloat(
              (
                (rowData.sample_1 +
                  rowData.sample_2 +
                  rowData.sample_3 +
                  rowData.sample_4 +
                  rowData.sample_5 +
                  rowData.sample_6) /
                6
              ).toFixed(2)
            );

            //caluculate COUNT VALUE
            rowData.count_value =
              rowData.average !== 0
                ? parseFloat((64.8 / rowData.average).toFixed(2))
                : 0;
            handleCountValue(rowData.count_value);

            //caluculate CV
            rowData.cv = CalculateCV([
              rowData.sample_1,
              rowData.sample_2,
              rowData.sample_3,
              rowData.sample_4,
              rowData.sample_5,
              rowData.sample_6,
            ]);
          } else {
            rowData.count_value = "";
            rowData.cv = "";
            rowData.average = "";
          }
        }
        return rowData;
      });

      return updatedData;
    });
  };

  const [strengthData, setStrengthData] = useState({
    sample_count_number: 1,
    sample_1: "",
    sample_2: "",
    sample_3: "",
    sample_4: "",
    sample_5: "",
    sample_6: "",
    average: 0,
    csp: 0,
    cv: 0,
    sample_type: "STRENGTH",
  });

  const handleChangeStrength = (field, value) => {
    const parsedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    setStrengthData((prev) => {
      const updatedData = { ...prev, [field]: parsedValue };

      if (
        updatedData.sample_1 !== "" &&
        updatedData.sample_2 !== "" &&
        updatedData.sample_3 !== "" &&
        updatedData.sample_4 !== "" &&
        updatedData.sample_5 !== "" &&
        updatedData.sample_6 !== ""
      ) {
        //caluculate AVERAGE
        updatedData.average = (
          (updatedData.sample_1 +
            updatedData.sample_2 +
            updatedData.sample_3 +
            updatedData.sample_4 +
            updatedData.sample_5 +
            updatedData.sample_6) /
          6
        ).toFixed(2);

        //caluculate CV
        updatedData.cv = CalculateCV([
          updatedData.sample_1,
          updatedData.sample_2,
          updatedData.sample_3,
          updatedData.sample_4,
          updatedData.sample_5,
          updatedData.sample_6,
        ]);

        //caluculate CSP
        updatedData.csp = (validCountValue * updatedData.average).toFixed(2);
      } else {
        updatedData.csp = "";
        updatedData.cv = "";
        updatedData.average = "";
      }

      return updatedData;
    });
  };

  const { mutate, isLoading: isSubmitLoading } = useMutation(CountChangePost);

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const ResetFrom = () => {
    setHallNo("");
    setVariety("");
    setFormValues({
      counts: "",
      machineNo: "",
      feedback: "",
      LycraDraft: "",
    });
    setSamplesCount(1);
    setAnyRowInRange(false);
    setRowData((prevData) => [
      {
        sample_count_number: samplesCount,
        sample_1: "",
        sample_2: "",
        sample_3: "",
        sample_4: "",
        sample_5: "",
        sample_6: "",
        average: 0,
        count_value: 0,
      },
    ]);
    setStrengthData({
      sample_count_number: 1,
      sample_1: "",
      sample_2: "",
      sample_3: "",
      sample_4: "",
      sample_5: "",
      sample_6: "",
      average: 0,
      csp: 0,
      sample_type: "STRENGTH",
    });
  };

  const queryClient = useQueryClient();

  //this is for create count change drafts
  const { mutate: createDraft, isLoading: isCreateLoading } = useMutation(
    async (payload) => {
      const response = await createCountChangeDraft(payload);
      return response.data;
    }
  );

  //this is for update count change drafts
  const { mutate: updateDraft, isLoading: isUpdateLoading } = useMutation(
    async (params) => {
      const response = await updateCountChangeDraft(params);
      return response.data;
    }
  );

  // Function to remove 'id' field from samples
  const removeIdFromSamples = (samples) => {
    return samples?.map(({ id, ...rest }) => rest);
  };
  //handle final submit
  const handleSubmit = async () => {
    try {
      const { csp, id, ...strengthDataWithoutCSPandId } = strengthData;

      const payload = {
        machine_number: formValues?.machineNo,
        hall: hallNo,
        count: formValues?.counts,
        variety: variety,
        lycra_draft: formValues?.LycraDraft,
        csp: csp,
        weight_samples: removeIdFromSamples(rowData),
        strength_sample: strengthDataWithoutCSPandId,
        ...(state?.data?.id && { countChangeDraftId: state?.data?.id }),
        ...(formValues?.feedback && { feedback: formValues.feedback }),
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          ResetFrom();
          queryClient.invalidateQueries("get-variety");
          queryClient.invalidateQueries({ queryKey: ["count-change"] });
          queryClient.invalidateQueries("manager-spinning-notifications");
          navigate("/count-change-record");
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const { state } = useLocation();

  //use effect for etting existing data
  useEffect(() => {
    if (state?.data) {
      setFormValues((prev) => ({
        ...prev,
        counts: state?.data?.count,
        machineNo: state?.data?.machine_number,
        LycraDraft: state?.data?.lycra_draft,
        feedback: state?.data?.feedback,
      }));
      setVariety(state?.data?.variety);
      setHallNo(state?.data?.hall);
      setRowData(state?.data?.weight_samples);
      setSamplesCount(state?.data?.weight_samples?.length);
      setStrengthData(state?.data?.strength_samples[0]);
    }
  }, [state]);

  const navigate = useNavigate();

  const handleSaveAndEdit = () => {
    //this is for update the count change drafts
    const { csp, ...strengthDataWithoutCSP } = strengthData;
    const draftPayload = {
      machine_number: formValues?.machineNo,
      count: formValues?.counts,
      variety: variety,
      lycra_draft: formValues?.LycraDraft,
      csp: csp,
      hall: hallNo,
      weight_samples: rowData?.filter((row) => row.average > 0),
      strength_sample: strengthDataWithoutCSP,
      ...(formValues?.feedback && { feedback: formValues.feedback }),
    };

    if (state?.data?.id && state?.data?.id !== "") {
      try {
        const id = state?.data?.id || "";
        const params = { draftPayload, id };

        updateDraft(params, {
          onSuccess: (res) => {
            handleToastMsg("Draft Data updated successfully", "success");
            ResetFrom();
            queryClient.invalidateQueries("get-variety");
            queryClient.invalidateQueries({ queryKey: ["count-change"] });
            navigate("/count-change-record");
          },
          onError: (error) => {
            handleToastMsg("Draft Data Error Occurred", "error");
          },
        });
      } catch (error) {
        throw error;
      }
    } //this is for create the count change drafts
    else if (!state?.data?.id) {
      try {
        createDraft(draftPayload, {
          onSuccess: (res) => {
            handleToastMsg("Draft Data created successfully", "success");
            ResetFrom();
            queryClient.invalidateQueries("get-variety");
            queryClient.invalidateQueries({ queryKey: ["count-change"] });
            navigate("/count-change-record");
          },
          onError: (error) => {
            handleToastMsg("Draft Data Error Occurred", "error");
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  // const options = varietyOptions
  //   ? varietyOptions.map((option) => option?.variety)
  //   : [];

  // const option = varietyOptions
  //   ? varietyOptions.map((option) => option?.slub_code)
  //   : [];

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        // paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Count Change</h2>
      </Grid>
      <Grid mt={2} item xs={12} sm={12} md={6} lg={6} xl={3}>
        <TextField
          value={hallNo}
          select
          onChange={(e) => setHallNo(parseInt(e.target.value))}
          type="number"
          sx={{ width: 180 }}
          id="outlined-basic"
          label="Hall Number"
          variant="outlined"
          // onInput={handleCardingLineNumInput}
        >
          {HALL_NO?.map((hall) => (
            <MenuItem key={hall} value={hall}>
              {hall}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Machine No</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "10px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Select
                          labelId="machine-no-label"
                          id="machine-no"
                          value={formValues.machineNo}
                          onChange={(e) => handleFormChange(e, "machineNo")}
                        >
                          {[...Array(24)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                      label="Counts"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "10px",
                      }}
                    >
                      <Autocomplete
                        value={variety}
                        fullWidth
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setVariety(newValue || "");
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setVariety(newValue || "");
                          } else {
                            setVariety(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue.toUpperCase());
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : ""
                        } // Ensure option is a string
                        renderOption={(props, option) => (
                          <li {...props}>{option}</li>
                        )}
                        styles={{ width: "100%" }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Variety"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Lycra Draft</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      placeholder="Lycra Draft"
                      label="Lycra Draft"
                      value={formValues.LycraDraft}
                      onChange={(e) => handleFormChange(e, "LycraDraft")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container item xs={11.2}>
        <Grid mt={2} container spacing={2} sx={{ pr: { md: 0, sm: 2 } }}>
          <TableContainer component={Paper} sx={{ mr: { md: 0, sm: 4 } }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1vw" }}>Samples</TableCell>
                  <TableCell style={{ width: "1vw" }}>1</TableCell>
                  <TableCell style={{ width: "1vw" }}>2</TableCell>
                  <TableCell style={{ width: "1vw" }}>3</TableCell>
                  <TableCell style={{ width: "1vw" }}>4</TableCell>
                  <TableCell style={{ width: "1vw" }}>5</TableCell>
                  <TableCell style={{ width: "1vw" }}>6</TableCell>
                  <TableCell style={{ width: "1vw" }}>Average</TableCell>
                  <TableCell style={{ width: "60px" }}>Count Value</TableCell>
                  <TableCell style={{ width: "60px" }}>CV</TableCell>
                  <TableCell style={{ width: "1vw" }}>Add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((currentRow, index) => {
                  // const currentRow = tableData.find(
                  //   (rowData) => rowData?.sampleIndex === index + 1
                  // );

                  const countValue = 64.8 / currentRow.average;

                  const isInRange =
                    countValue >= formValues?.counts - 0.44 &&
                    countValue <= formValues?.counts + 0.44;

                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_1}
                          onChange={(e) =>
                            handleChange("sample_1", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_2}
                          onChange={(e) =>
                            handleChange("sample_2", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_3}
                          onChange={(e) =>
                            handleChange("sample_3", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_4}
                          onChange={(e) =>
                            handleChange("sample_4", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_5}
                          onChange={(e) =>
                            handleChange("sample_5", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          id="outlined-number"
                          step="0.01"
                          type="number"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentRow.sample_6}
                          onChange={(e) =>
                            handleChange("sample_6", e.target.value, index + 1)
                          }
                          disabled={validCount && anyRowInRange}
                        />
                      </TableCell>

                      <TableCell>
                        <p style={{ width: "50px" }}>
                          {/* {tableData[index]?.average.toFixed(2) || 0} */}
                          {currentRow?.average ?? ""}
                        </p>
                      </TableCell>

                      <TableCell>
                        <p
                          style={{
                            width: "50px",
                            color:
                              currentRow?.count_value === 0
                                ? "black"
                                : isInRange
                                ? "green"
                                : "red",
                          }}
                        >
                          {/* {tableData[index]?.countValue.toFixed(2) || 0} */}
                          {currentRow?.count_value ?? ""}
                        </p>
                      </TableCell>

                      <TableCell>
                        <p
                          style={{
                            width: "50px",
                          }}
                        >
                          {currentRow?.cv ?? ""}
                        </p>
                      </TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAdd(index)}
                          disabled={validCount && anyRowInRange}
                        >
                          +
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container item xs={11.2}>
        <Grid mt={2} container spacing={2} sx={{ pr: { md: 0, sm: 2 } }}>
          <TableContainer component={Paper} sx={{ mr: { md: 0, sm: 4 } }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1vw" }}>Samples</TableCell>
                  <TableCell style={{ width: "1vw" }}>1</TableCell>
                  <TableCell style={{ width: "1vw" }}>2</TableCell>
                  <TableCell style={{ width: "1vw" }}>3</TableCell>
                  <TableCell style={{ width: "1vw" }}>4</TableCell>
                  <TableCell style={{ width: "1vw" }}>5</TableCell>
                  <TableCell style={{ width: "1vw" }}>6</TableCell>
                  <TableCell style={{ width: "1vw" }}>Average</TableCell>
                  <TableCell style={{ width: "1vw" }}>CV</TableCell>
                  <TableCell style={{ width: "1vw" }}>CSP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p style={{ width: "50px" }}>Strength</p>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_1}
                      onChange={(e) =>
                        handleChangeStrength("sample_1", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_2}
                      onChange={(e) =>
                        handleChangeStrength("sample_2", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_3}
                      onChange={(e) =>
                        handleChangeStrength("sample_3", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_4}
                      onChange={(e) =>
                        handleChangeStrength("sample_4", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_5}
                      onChange={(e) =>
                        handleChangeStrength("sample_5", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={strengthData.sample_6}
                      onChange={(e) =>
                        handleChangeStrength("sample_6", e.target.value)
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <p style={{ width: "50px" }}>
                      {strengthData?.average ?? ""}
                    </p>
                  </TableCell>

                  <TableCell>
                    <p style={{ width: "50px" }}>{strengthData?.cv ?? ""}</p>
                  </TableCell>

                  <TableCell>
                    <p style={{ width: "50px" }}>{strengthData?.csp ?? ""}</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid mt={3} item xs={11}>
          <TextField
            // styles={{ width: "100%" }}
            fullWidth
            onChange={(e) => handleFormChange(e, "feedback")}
            name="Size"
            variant="outlined"
            label="Feedback (or) Any Queries"
            value={formValues.feedback}
          />
        </Grid>

        <Grid mt={4} item xs={11} sm={11} mb={2}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              style={{ width: "200px", height: "50px", marginRight: "20px" }}
              disabled={validCount || isCreateLoading || isUpdateLoading}
              onClick={() => handleSaveAndEdit()}
            >
              {isCreateLoading || isUpdateLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Save And Edit"
              )}
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ width: "150px", height: "50px" }}
              disabled={!validCount || isSubmitLoading}
              onClick={handleSubmit}
            >
              {isSubmitLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Final Submit"
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CountChange;
