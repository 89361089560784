import apiClient from "../../../utils/clients/axios";

// POST
export const createCotCheckDraft = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/spinning/cot_checks_draft",
    payload
  );

  return response;
};

// GET
export const getCotCheckDraft = async () => {
  const { data } = await apiClient.get(
    `/api/quality_control/spinning/cot_checks_draft/`
  );

  return data;
};

// PUT /api/spinning/cot_checks_draft/:id
export const updateCotCheckDraft = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/spinning/cot_checks_draft/${id}`,
    payload
  );

  return response;
};
