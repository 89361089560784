import apiClient from "../../utils/clients/axios";

// export const uploadCSV = async (payload) => {
//   const response = await apiClient.post(
//     "/api/quality_control/wrapping/upload",
//     payload
//   );
//   return response.data;
// };

export const uploadCSV = async ({ payload, type }) => {

  const url = `/api/quality_control/sastra/upload?type=${type}`;
  const response = await apiClient.post(url, payload);
  return response.data;
};
