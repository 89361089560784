import apiClient from "../../../utils/clients/axios";

// Get all records from the general master
export const getAllSubmittedData = async (masterKey) => {
  const flow = masterKey + "_master";
  const response = await apiClient.get(`/api/quality_control/${flow}`);
  return response;
};

// Get all readings from the general parameter master data
export const getStandardsMasterData = async (params) => {
  const { partyCode, key } = params;

  const response = await apiClient.get(`/api/quality_control/master_data/`, {
    params: { partyCode: partyCode, key: key },
  });
  return response;
};
