import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { UTrailCreate } from "./mutation";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";

const UPercentTrail = () => {
  const filter = createFilterOptions();
  const [selectedNumber] = useState(0);
  const [variety, setVariety] = useState("");
  const [formValues, setFormValues] = useState({
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    frame_no: "",
    no_of_trails: 1,
    particular: [],
    regular: {},
    feedback: "",
    total: "",
    slub_code: "",
  });

  const handleFormChange = (event, field) => {
    let { value } = event.target;
    // let parsedValue = value;

    if (field === "feedback" || field === "denier" || field === "slub_code") {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "auto_coner_no",
        "hallNo",
        "frame_no",
        "no_of_trails",
      ].includes(field)
    ) {
      const changedValue = (value = !isNaN(value) ? parseFloat(value) : value);
      if (changedValue < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = changedValue;
      }
    }

    setFormValues({ ...formValues, [field]: value });
  };

  const handleRegularChange = (e, field) => {
    const { name, value } = e.target;

    const parsedValue = value === "" ? "" : parseFloat(value);
    if (parsedValue < 0) {
      alert("Please do not enter negative value");
      return;
    }

    if (!isNaN(parsedValue)) {
      setFormValues((prevFormValues) => {
        const updatedURegularData = {
          ...prevFormValues.regular,
          [name]: parsedValue,
        };

        const nepsValue = parseFloat(updatedURegularData["neps+200"]) || 0;
        const thinValue = parseFloat(updatedURegularData["thin-50"]) || 0;
        const thickValue = parseFloat(updatedURegularData["thick+50"]) || 0;

        const totalValue = nepsValue + thinValue + thickValue;

        return {
          ...prevFormValues,
          regular: updatedURegularData,
          total: totalValue,
        };
      });
    }
  };

  const handleTrailChange = (e, fieldName, id) => {
    const { value } = e.target;
    const parsedValue = !isNaN(value) ? parseFloat(value) : "";
    if (parsedValue < 0) {
      alert("Please do not enter negative value");
      return;
    }
    setFormValues((prevFormValues) => {
      const updatedParticular = [...prevFormValues.particular];
      const trailIndex = parseInt(id.split("-").pop(), 10);

      // Remove the fieldName from the dataObject if the parsedValue is not a valid number
      if (isNaN(parsedValue)) {
        updatedParticular.forEach((obj) => {
          if (
            `Trail ${trailIndex}` in obj &&
            obj[`Trail ${trailIndex}`][fieldName] !== undefined
          ) {
            delete obj[`Trail ${trailIndex}`][fieldName];
          }
        });
      } else {
        const dataObject = updatedParticular.find(
          (obj) => `Trail ${trailIndex}` in obj
        );

        if (dataObject) {
          dataObject[`Trail ${trailIndex}`][fieldName] = parsedValue;
          if (dataObject) {
            dataObject[`Trail ${trailIndex}`][fieldName] = parsedValue;

            // Extracting the base field names for calculations
            const nepsFieldName = "neps+200";
            const thickFieldName = "thick+50";
            const thinFieldName = "thin-50";

            // Accessing and parsing individual field values or defaulting to 0 if not a valid number
            const nepsValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][nepsFieldName]) || 0;
            const thickValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][thickFieldName]) ||
              0;
            const thinValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][thinFieldName]) || 0;

            // Calculate the total based on the parsed values
            let total = nepsValue + thickValue + thinValue;

            // Update the 'total' field in your form values
            dataObject[`Trail ${trailIndex}`]["total"] = total;
          }
        } else {
          const newDataObject = {
            [`Trail ${trailIndex}`]: {
              [fieldName]: parsedValue,
            },
          };
          updatedParticular.push(newDataObject);
        }
      }

      return {
        ...prevFormValues,
        particular: updatedParticular,
      };
    });
  };

  const { mutate, isLoading } = useMutation(UTrailCreate);

  const handleNumReadingsChange = (value) => {
    let numReadings = parseInt(value);

    if (isNaN(numReadings) || numReadings <= 0) {
      numReadings = 1;
    }
    const changedValue = (value = !isNaN(value) ? parseFloat(value) : value);
    if (changedValue < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      value = changedValue;
    }
    // setTable2Data({ ...table2Data, numberOfReadings: numReadings });
    setFormValues({ ...formValues, no_of_trails: numReadings });
    setNumTrailColumns(numReadings);

    // const newTrailHeaders = Array.from(
    //   { length: numReadings },
    //   (_, index) => `Trail ${index + 1}`
    // );
    if (numReadings > trailHeaders.length) {
      const additionalHeaders = Array(numReadings - trailHeaders.length)
        .fill()
        .map((_, i) => `Trail ${trailHeaders.length + i + 1}`);
      setTrailHeaders((prevHeaders) => [...prevHeaders, ...additionalHeaders]);
    } else if (numReadings < trailHeaders.length) {
      const slicedHeaders = trailHeaders.slice(0, numReadings);
      setTrailHeaders(slicedHeaders);
    }
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      const payload = {
        variety: variety,
        hall: formValues.hallNo,
        counts: formValues.counts,
        denier: formValues.denier,
        autoConerNo: formValues.auto_coner_no,
        frameNo: formValues.frame_no,
        noOfTrails: formValues.no_of_trails,
        particular: formValues.particular,
        regular: formValues.regular,
        feedback: formValues.feedback,

        slubCode: formValues.slub_code,
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          setVariety("");
          queryClient.invalidateQueries("get-variety");
          setFormValues({
            hallNo: "",
            counts: "",
            denier: "",
            auto_coner_no: "",
            frame_no: "",
            no_of_trails: 1,
            particular: [],
            regular: {},
            feedback: "",
            slub_code: "",
          });
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const [trailHeaders, setTrailHeaders] = useState(["Trail 1"]);
  const [numTrailColumns, setNumTrailColumns] = useState(1);
  const table3Data = [];
  for (let i = 1; i <= selectedNumber; i++) {
    table3Data.push({
      col1: i,
      col2: (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Splice"
          value={"S"}
          disabled
        />
      ),
      col3: <TextField variant="outlined" fullWidth placeholder="Name" />,
      col4: <TextField variant="outlined" fullWidth placeholder="% of fault" />,
      col5: (
        <TextField variant="outlined" fullWidth placeholder="Length in cm" />
      ),
    });
  }

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">U%</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={variety}
                          fullWidth
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setVariety(newValue || "");
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setVariety(newValue || "");
                            } else {
                              setVariety(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          styles={{ width: "100%" }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Variety"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      label="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Slub Code"
                      placeholder="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Auto Coner No"
                      placeholder="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Frame No</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="Frame No"
                      value={formValues.frame_no}
                      onChange={(e) => handleFormChange(e, "frame_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of Trails</TableCell>
                  <TableCell>
                    <FormControl variant="outlined">
                      <TextField
                        type="number"
                        variant="outlined"
                        placeholder="Number Of Trails"
                        label="Number Of Trails"
                        // onInput={(e) => {
                        //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        // }}
                        defaultValue={formValues.no_of_trails}
                        onChange={(e) =>
                          handleNumReadingsChange(
                            e.target.value,
                            "no_of_trails"
                          )
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid mt={2} container spacing={2}>
        <Grid item md={9} sm={11}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell>Regular</TableCell>
                  {trailHeaders.map((header, index) => (
                    <TableCell key={`trailHeader_${index}`}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>U %</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="u%"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["u%"] !== undefined &&
                        formValues.regular["u%"] !== null
                          ? formValues.regular["u%"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "u%")}
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "u%"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "u%"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "u%"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "u%",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CVM </TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cvm"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["cvm"] !== undefined &&
                        formValues.regular["cvm"] !== null
                          ? formValues.regular["cvm"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "cvm")}
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cvm"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cvm"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "cvm"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "cvm",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CV %</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cv%"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["cv%"] !== undefined &&
                        formValues.regular["cv%"] !== null
                          ? formValues.regular["cv%"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "cv%")}
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cv%"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cv%"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "cv%"
                          ]
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "cv%",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CVM 10</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cvm10"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["cvm10"] !== undefined &&
                        formValues.regular["cvm10"] !== null
                          ? formValues.regular["cvm10"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "cvm10")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cvm10"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "cvm10"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "cvm10"
                          ]
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "cvm10",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Thin-30</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      // step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thin-30"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thin-30"] !== undefined &&
                        formValues.regular["thin-30"] !== null
                          ? formValues.regular["thin-30"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thin-30")}
                      //   value={row.delivery_speed}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-30"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-30"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thin-30"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thin-30",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Thin -40</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thin-40"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thin-40"] !== undefined &&
                        formValues.regular["thin-40"] !== null
                          ? formValues.regular["thin-40"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thin-40")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-40"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-40"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thin-40"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thin-40",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Thin -50</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thin-50"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thin-50"] !== undefined &&
                        formValues.regular["thin-50"] !== null
                          ? formValues.regular["thin-50"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thin-50")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-50"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thin-50"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thin-50"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thin-50",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>Thick +35</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thick+35"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thick+35"] !== undefined &&
                        formValues.regular["thick+35"] !== null
                          ? formValues.regular["thick+35"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thick+35")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+35"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+35"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thick+35"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thick+35",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>Thick +50</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thick+50"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thick+50"] !== undefined &&
                        formValues.regular["thick+50"] !== null
                          ? formValues.regular["thick+50"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thick+50")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+50"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+50"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thick+50"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thick+50",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>Thick +70</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thick+70"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thick+70"] !== undefined &&
                        formValues.regular["thick+70"] !== null
                          ? formValues.regular["thick+70"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thick+70")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+70"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+70"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thick+70"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thick+70",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>Thick +100</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thick+100"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["thick+100"] !== undefined &&
                        formValues.regular["thick+100"] !== null
                          ? formValues.regular["thick+100"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "thick+100")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+100"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "thick+100"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "thick+100"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "thick+100",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>NEPS +140</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="neps+140"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["neps+140"] !== undefined &&
                        formValues.regular["neps+140"] !== null
                          ? formValues.regular["neps+140"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "neps+140")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "neps+140"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "neps+140"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "neps+140"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "neps+140",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>NEPS +200</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="neps+200"
                      value={
                        formValues &&
                        formValues.regular &&
                        formValues.regular["neps+200"] !== undefined &&
                        formValues.regular["neps+200"] !== null
                          ? formValues.regular["neps+200"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "neps+200")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "neps+200"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "neps+200"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "neps+200"
                          ]
                        : "";
                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "neps+200",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      disabled
                      name="total"
                      value={
                        formValues &&
                        formValues["total"] !== undefined &&
                        formValues["total"] !== null
                          ? formValues["total"]
                          : ""
                      }
                      onChange={(e) => handleRegularChange(e, "total")}
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1; // Increment index by 1 as it starts from 0

                    // Get the total value from the specific Trail index in U_particular
                    const totalValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${trailIndex}`] &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "total"
                      ] !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`][
                        "total"
                      ] !== null
                        ? formValues.particular[index][`Trail ${index + 1}`][
                            "total"
                          ]
                        : "";

                    return (
                      <TableCell key={`trail_${index + 1}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          disabled
                          value={totalValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailChange(
                              e,
                              "neps+200",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                {/* <TableRow>
    <TableCell>
                <TextField
                    type='number'
                    variant="outlined"
                    fullWidth
                    placeholder="Total"
                    // value={formValues.total}
                    onChange={(e) => handleFormChange(e, 'total')}
                />
    </TableCell>
</TableRow> */}
                {/* Add more rows as needed */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          name="Size"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
          onChange={(e) => handleFormChange(e, "feedback")}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UPercentTrail;
