import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { DropzoneArea } from "react-mui-dropzone";
import { FileDropContainer, Container, StyledBox } from "./styles";

function FileDragger({ setPdfFile, upload, uploadedFiles }) {
  const [, setSelectedFiles] = useState([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  const CustomUploadIcon = ({ upload, fileSizeExceeded }) => {
    return (
      <Container>
        <UploadOutlinedIcon />
        <Typography sx={{ paddingTop: "10px" }}>
          Drag & Drop or Choose File to Upload
        </Typography>

        <StyledBox>
          <Typography sx={{ color: "#627288" }}>
            PDF files upto {process.env.REACT_APP_MAX_FILE_SIZE_MB || "30"}MB
            are supported
          </Typography>
          {fileSizeExceeded && (
            <Typography color="error">
              File size exceeds {process.env.REACT_APP_MAX_FILE_SIZE_MB || "30"}{" "}
              MB
            </Typography>
          )}
        </StyledBox>
      </Container>
    );
  };

  useEffect(() => {
    if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
      setSelectedFiles(uploadedFiles);
    } else {
      setDropzoneKey(dropzoneKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  const getFileType = (file) => {
    if (file) {
      const fileNameParts = file.name.split(".");
      return fileNameParts[fileNameParts.length - 1].toLowerCase();
    }
    return "";
  };

  const handleFileChange = (uploadedFiles) => {
    if (uploadedFiles.length) {
      const uploadedFile = uploadedFiles[0];
      const fileType = getFileType(uploadedFiles[0]);
      if (upload.includes(fileType)) {
        setPdfFile([uploadedFile]);
        setFileSizeExceeded(false);
      }
    }
  };

  const handleDropRejected = (rejectedFiles) => {
    setFileSizeExceeded(true);
  };

  const handleDelete = (file) => {
    setPdfFile((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const maxFileSizeStr = process.env.REACT_APP_MAX_FILE_SIZE_MB ?? "30";
  const parsedSize = isNaN(parseInt(maxFileSizeStr))
    ? 30
    : parseInt(maxFileSizeStr);
  const maxFileSize = parsedSize * 1024 * 1024;

  const acceptedFiles = upload.map((fileType) => `.${fileType}`);

  return (
    <FileDropContainer sx={{ width: "100%" }}>
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        classes={{
          root: "custom-dropzone",
        }}
        showPreviewsInDropzone={false}
        useChipsForPreview
        showFileNames={true}
        filesLimit={1}
        maxFileSize={maxFileSize}
        onChange={handleFileChange}
        onDelete={handleDelete}
        onDropRejected={handleDropRejected}
        Icon={() => (
          <CustomUploadIcon
            upload={upload}
            fileSizeExceeded={fileSizeExceeded}
          />
        )}
        dropzoneText=""
        showAlerts={false}
        showPreviews={true}
        previewText="File Name :"
        key={dropzoneKey}
      />
    </FileDropContainer>
  );
}

export default FileDragger;
