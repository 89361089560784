import apiClient from "../../../utils/clients/axios";

export const RewindingStudy = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/rewinding-study-get/`,
    payload
  );
  return response;
};

export const RewindingStudyCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/rewinding-study-create/`,
    payload
  );
  return response;
};
