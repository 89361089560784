import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import { useLocation } from "react-router";

import { getStandardsMasterData } from "./mutation";
import { handleToastMsg } from "../Toast";
import CheckOverflow from "../../utils/CheckOverflow";
import DialogForLoadingMachine from "../DialogForLoadingMachine";

const MasterStandardDialog = ({ open, onClose, heading }) => {
  const allHeadings = [
    {
      key: "general",
      headingCheck: "General Standard Data",
    },
    {
      key: "blowroom",
      headingCheck: "Blowroom Standard Data",
    },
    {
      key: "carding",
      headingCheck: "Carding Standard Data",
    },
    {
      key: "drawing",
      headingCheck: "Drawing Standard Data",
    },
    {
      key: "simplex",
      headingCheck: "Simplex Standard Data",
    },
    {
      key: "spinning",
      headingCheck: "Spinning Standard Data",
    },
    {
      key: "autoconer",
      headingCheck: "Autoconer Standard Data",
    },
    {
      key: "others",
      headingCheck: "Others Standard Data",
    },
  ];

  const [partyCode, setPartyCode] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state to control query
  const [key, setKey] = useState("");

  useEffect(() => {
    const foundHeading = allHeadings?.find((h) => h?.headingCheck === heading);
    if (foundHeading) {
      setKey(foundHeading?.key);
    } else {
      setKey("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heading]);

  //this is for when we go to another component for check satndar prevent the old data showing in ui
  const { state } = useLocation();
  const [preventOldDataShow, setPreventOldDataShow] = useState(false);
  useEffect(() => {
    setPreventOldDataShow(false);
  }, [state]);

  const params = { partyCode, key: key };
  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const fetchStandardData = async () => {
    setIsLocalLoading(true);
    try {
      const response = await getStandardsMasterData(params);
      return response;
    } catch (error) {
      handleToastMsg(`Error: ${error.message}`, "error");
    } finally {
      setIsLocalLoading(false);
    }
  };

  const [apiStandardsData, setApiStandardsData] = useState({});

  const { isError, refetch } = useQuery(
    "all-standard-data",
    fetchStandardData,
    {
      enabled: false,
      onSuccess: (data) => setApiStandardsData(data), // Set the data on success
      onError: () => setApiStandardsData({}), // Reset data on error
    }
  );

  // Clear all the data when component mounts
  useEffect(() => {
    setApiStandardsData({});
  }, []);

  // const keysForValues = apiStandardsData?.data?.data
  //   ?.map((item) => {
  //     // Destructure to remove the unwanted fields: inputType, options, and outOfNorms
  //     const { inputType, options, outOfNorms, category, ...rest } = item;

  //     // Return only the remaining fields
  //     return rest;
  //   })
  //   // Optional: filter out any empty objects, if needed
  //   ?.filter((item) => Object.keys(item)?.length > 0);

  //this is for get api for the standard data
  useEffect(() => {
    if (shouldFetch && partyCode) {
      refetch().finally(() => setShouldFetch(false)); // Reset shouldFetch after the query is made
    }
  }, [shouldFetch, partyCode, refetch]);

  const handleFilterChange = (event) => {
    setPartyCode(event.target.value);
  };

  const handleApplyFilter = async () => {
    setShouldFetch(true); // Trigger the query
    setPreventOldDataShow(true);
  };

  const handleResetFilter = () => {
    setPartyCode("");
    setShouldFetch(false);
    setPreventOldDataShow(false);
    setApiStandardsData({});
  };

  //this function for checking the text is being overflow or not
  const [isOverflow, ref] = CheckOverflow(apiStandardsData?.data);
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflow[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openVariety = Boolean(anchorEl);

  const dialogBoxClose = () => {
    handleResetFilter();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={dialogBoxClose}
      fullWidth
      maxWidth="md" // Use "md" or "lg" or set a custom width using `sx` prop
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
    >
      <DialogTitle>
        <Grid container justifyContent={"center"} style={{ paddingTop: "3%" }}>
          <Typography variant="h6">{heading}</Typography>
        </Grid>
        <IconButton
          aria-label="close"
          onClick={dialogBoxClose}
          style={{ position: "absolute", right: 18, top: 18 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ paddingLeft: "8%", paddingRight: "8%", paddingBottom: "4%" }}
      >
        <Grid
          container
          item
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            paddingTop: "2%",
            paddingBottom: "2%",
          }}
        >
          <Grid container item md={8} xs={9} alignItems="center">
            <TextField
              label="Filter by Party Code"
              value={partyCode}
              onChange={handleFilterChange}
              style={{ marginRight: "16px" }}
            />
            <Button
              variant="contained"
              onClick={handleApplyFilter}
              color="primary"
              style={{
                // background: "rgb(165, 58, 189)",
                color: "white",
                textTransform: "none",
                marginRight: "8px",
              }}
            >
              Apply
            </Button>
            <Button
              variant="contained"
              onClick={handleResetFilter}
              style={{
                background: "#181E72",
                color: "white",
                textTransform: "none",
              }}
            >
              Reset
            </Button>
          </Grid>

          <Grid container item md={4} xs={3} alignItems="center">
            <p
              className="overflowContain"
              ref={ref(`Variety-${apiStandardsData?.data?.variety}`)}
              onMouseEnter={(e) =>
                handlePopoverOpen(
                  e,
                  `Variety-${apiStandardsData?.data?.variety}`,
                  apiStandardsData?.data?.variety
                )
              }
              onMouseLeave={handlePopoverClose}
            >
              Variety : <b>{apiStandardsData?.data?.variety}</b>
            </p>
            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={openVariety}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <p style={{ padding: "15px" }}>{popoverContent}</p>
            </Popover>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell style={{ fontWeight: "bold" }}>
                    PARTICULAR
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>STANDARD</TableCell>
                </TableRow>
              </TableHead>

              {/* <TableBody>
                {!isLocalLoading &&
                  preventOldDataShow &&
                  keysForValues &&
                  !isError &&
                  keysForValues?.map((key, index) => {
                    const value = apiStandardsData?.data?.data[key];

                    let displayValue;

                    if (Array.isArray(value)) {
                      displayValue = value.join(", ");
                    } else if (typeof value === "object" && value !== null) {
                      displayValue = `${value?.x ?? "N/A"} * ${
                        value?.y ?? "N/A"
                      }${value?.z ? ` * ${value?.z ?? "N/A"}` : ""}`;
                    } else {
                      displayValue = value;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </TableCell>
                        <TableCell>{displayValue}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody> */}

              <TableBody>
                {!isLocalLoading &&
                  preventOldDataShow &&
                  apiStandardsData?.data?.data &&
                  !isError &&
                  apiStandardsData?.data?.data.map((item, index) => {
                    // Handle different field types dynamically
                    return Object.keys(item).map((key, innerIndex) => {
                      if (
                        key === "inputType" ||
                        key === "options" ||
                        key === "outOfNorms" ||
                        key === "category"
                      ) {
                        // Skip unwanted fields
                        return null;
                      }

                      // Access the value from the item
                      const value = item[key];

                      let displayValue;

                      // Handle different data types for display
                      if (Array.isArray(value)) {
                        displayValue = value.join(", ");
                      } else if (typeof value === "object" && value !== null) {
                        // For object values, format the display to show x * y * z (if z exists)
                        // displayValue = `${value?.x ?? "N/A"} * ${
                        //   value?.y ?? "N/A"
                        // }${value?.z ? ` * ${value?.z}` : ""}`;

                        displayValue = Object.keys(value)
                          ?.map((subKey) => `${value[subKey] ?? "N/A"}`) // Handle null/undefined values
                          ?.join(" * "); // Join the values with " * "
                      } else {
                        // For simple values (string, number, etc.)
                        displayValue = value || "N/A";
                      }

                      return (
                        <TableRow key={`${index}-${innerIndex}`}>
                          <TableCell>
                            {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
                            {/* Capitalize the key */}
                          </TableCell>
                          <TableCell>{displayValue}</TableCell>
                        </TableRow>
                      );
                    });
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>

      <DialogForLoadingMachine open={isLocalLoading} />
    </Dialog>
  );
};

export default MasterStandardDialog;
