import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  MenuItem,
  Select,
  Card,
  CardContent,
  Box,
  FormControl,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useMutation } from "react-query";
import { useQuery, useQueryClient } from "react-query";
import CustomButton from "../../../components/Button";
import { dropTest, getVariety } from "../mutation";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO } from "../../../config/masterdata";

const filter = createFilterOptions();

export default function DropTest() {
  const initialFormState = {
    variety: "",
    numMaterials: 1,
    materials: [
      {
        name: "",
        displayWeight: "",
        actualWeight: "",
        difference: "",
        cv: "",
        average: "",
      },
    ],
    blendRatio: "",
  };
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  // const variants = [
  //   { title: 'B PSF' },
  //   { title: 'D PSF' },
  //   { title: 'E PSF' },
  //   { title: 'F PSF' },

  // ];
  const [hallNo, setHallNo] = React.useState("");
  const [value, setValue] = React.useState("");
  const [state, setState] = useState(initialFormState);

  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const resetForm = () => {
    setValue("");
    setState({
      ...initialFormState,
      materials: Array.from({ length: 1 }, () => ({
        name: "",
        displayWeight: "",
        actualWeight: "",
        difference: "",
        cv: "",
        average: "",
      })),
    });
    setFeedback("");
    setHallNo("");
  };
  const handleReview = () => {
    const payload = {
      variety: value,
      feedback: feedback,
      hall: hallNo,
    };

    const blendRatioArray = state.blendRatio
      .split(" : ")
      .map((percentage) => parseFloat(percentage).toFixed(2));

    state.materials.forEach((material, index) => {
      payload[`material${index + 1}Name`] = material.name;

      payload[`material${index + 1}DisplayWeight`] = parseFloat(
        material.displayWeight
      );
      payload[`material${index + 1}ActualWeight`] = parseFloat(
        material.actualWeight
      );
      payload[`material${index + 1}Difference`] = parseFloat(
        material.difference
      );
      payload[`material${index + 1}AvgDisplayWeight`] = parseFloat(
        material.average
      );
      payload[`material${index + 1}Cv40`] = parseFloat(material.cv);

      payload[`material${index + 1}BlendPerc`] = parseFloat(
        blendRatioArray[index]
      );
    });
    setModalData(payload);
    setOpenModal(true);
  };

  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      variety: value,
      feedback: feedback,
      hall: hallNo,
    };

    const blendRatioArray = state.blendRatio
      .split(" : ")
      .map((percentage) => parseFloat(percentage).toFixed(2));

    state.materials.forEach((material, index) => {
      payload[`material${index + 1}Name`] = material.name;

      payload[`material${index + 1}DisplayWeight`] = parseFloat(
        material.displayWeight
      );
      payload[`material${index + 1}ActualWeight`] = parseFloat(
        material.actualWeight
      );
      payload[`material${index + 1}Difference`] = parseFloat(
        material.difference
      );
      payload[`material${index + 1}AvgDisplayWeight`] = parseFloat(
        material.average
      );
      payload[`material${index + 1}Cv40`] = parseFloat(material.cv);

      payload[`material${index + 1}BlendPerc`] = parseFloat(
        blendRatioArray[index]
      );
    });

    mutate(payload, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("get-variety");
        queryClient.invalidateQueries("manager-blowroom-notifications");
        handleToastMsg("Data updated successfully", "success");
        setOpenModal(false);
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateState(name, value);
  };

  const calculateBlendRatio = () => {
    const totalAvg = state.materials.reduce(
      (sum, material) => sum + parseFloat(material.average || 0),
      0
    );

    const blendRatio = state?.materials
      ?.map(
        (material) =>
          `${((parseFloat(material.average || 0) / totalAvg) * 100).toFixed(
            2
          )}%`
      )
      .join(" : ");

    updateState("blendRatio", blendRatio);
  };

  const handleNumMaterialsChange = (event) => {
    const numMaterials = event.target.value;
    const materials = Array.from({ length: numMaterials }, () => ({
      name: "",
      displayWeight: "",
      actualWeight: "",
      difference: "",
      cv: "",
      average: "",
    }));

    updateState("numMaterials", numMaterials);
    updateState("materials", materials);
  };
  const { mutate, isLoading: mutateLoading } = useMutation(dropTest);
  const renderMaterialCards = () => {
    return state?.materials?.map((material, index) => (
      <Grid item key={index} xs={6} md={3}>
        <Card>
          <CardContent>
            <Grid>
              {/* <FormControl>
            <InputLabel htmlFor={`input-${index}`}> Material {index + 1}</InputLabel>
            <Input
              id={`input-${index}`}
              fullWidth
              value={value}
              variant="standard"
            />
          </FormControl> */}
            </Grid>
            <TextField
              fullWidth
              label={`Material ${index + 1}`}
              value={material?.name}
              onChange={handleMaterialChange(index, "name")}
              name={`material${index + 1}Name`}
              variant="standard"
            />
            <TextField
              fullWidth
              label="Display Weight"
              value={material?.displayWeight}
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
              // }}
              onChange={handleMaterialChange(index, "displayWeight")}
              name={`material${index + 1}DisplayWeight`}
              type="number"
              step="0.01"
              variant="standard"
            />
            <TextField
              fullWidth
              label="Actual Weight"
              value={material?.actualWeight}
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
              // }}
              onChange={handleMaterialChange(index, "actualWeight")}
              name={`material${index + 1}ActualWeight`}
              type="number"
              step="0.01"
              variant="standard"
            />
            <TextField
              fullWidth
              label="Difference"
              value={material?.difference}
              onChange={handleMaterialChange(index, "difference")}
              name={`material${index + 1}Difference`}
              type="number"
              step="0.01"
              disabled
              variant="standard"
            />
            <TextField
              fullWidth
              label="CV 40"
              value={material?.cv}
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
              // }}
              onChange={handleMaterialChange(index, "cv")}
              name={`material${index + 1}CV`}
              type="number"
              step="0.01"
              variant="standard"
            />
            <TextField
              fullWidth
              label="Average weight in display"
              value={material?.average}
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
              // }}
              onChange={handleMaterialChange(index, "average")}
              name={`material${index + 1}Average`}
              type="number"
              step="0.01"
              disabled={material?.displayWeight === ""}
              variant="standard"
            />
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const handleMaterialChange = (index, key) => (event) => {
    const value = event.target.value;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const materials = [...state.materials];
      materials[index][key] =
        key === "name"
          ? value.toUpperCase()
          : value === ""
          ? ""
          : parseFloat(value);
      setState((prevState) => ({
        ...prevState,
        materials,
      }));
    }

    /**update for blend ratio */
    const materialsUpdate = [...state.materials];

    materialsUpdate.forEach((material, index) => {
      if (material.displayWeight !== "" && material.actualWeight !== "") {
        const difference = material.actualWeight - material.displayWeight;
        materialsUpdate[index].difference = parseFloat(difference.toFixed(2));
      }

      if (
        material.displayWeight !== "" ||
        material.actualWeight !== "" ||
        material.average !== "" ||
        material.cv !== "" ||
        material.name !== "" ||
        material.difference !== ""
      ) {
        calculateBlendRatio();
      }
    });

    setState((prevState) => ({
      ...prevState,
      materials: materialsUpdate,
    }));
    // calculateBlendRatio();
  };

  return (
    <form>
      <Container maxWidth="100%">
        <Grid mt={2} container spacing={3}>
          <Grid item xs={12}>
            <h2 className="heading ">Drop Test</h2>
          </Grid>
          <Grid item>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setValue(newValue || "");
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue(newValue || "");
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue.toUpperCase());
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
              getOptionLabel={(option) =>
                typeof option === "string" ? option : ""
              } // Ensure option is a string
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{ width: 250 }}
              freeSolo
              renderInput={(params) => (
                <TextField required {...params} label="Variety" />
              )}
            />
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="num-materials-label">
                Number of Materials
              </InputLabel>
              <Select
                labelId="num-materials-label"
                label="Number of Materials"
                sx={{ width: 250 }}
                displayEmpty
                value={state.numMaterials}
                onChange={handleNumMaterialsChange}
                name="numMaterials"
              >
                {[1, 2, 3, 4].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              value={hallNo}
              select
              onChange={(e) => setHallNo(parseInt(e.target.value))}
              type="number"
              sx={{ width: 250 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item container spacing={2}>
            {renderMaterialCards()}
          </Grid>

          <Grid item xs={3} md={5}>
            <TextField
              fullWidth
              label="BLEND RATIO"
              value={state.blendRatio}
              onChange={handleInputChange}
              name="blendRatio"
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              onChange={handleFeedbackChange}
              value={feedback}
              size="lg"
              name="Size"
              label="Feedback"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                className="submitbutton"
                onClick={handleReview}
              >
                Final Review
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Variety</TableCell>
                    <TableCell>Material Name</TableCell>
                    <TableCell>Hall Number</TableCell>
                    <TableCell>Display Weight</TableCell>
                    <TableCell>Actual Weight</TableCell>
                    <TableCell>Difference</TableCell>
                    <TableCell>Average Weight in display</TableCell>
                    <TableCell>Blend Percentage</TableCell>
                    <TableCell>CV 40</TableCell>
                    {/* Add more table headers for other payload fields */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(modalData).map((key) => {
                    // Check if the key represents a material index
                    const match = key.match(/^material(\d+)Name$/);
                    if (match) {
                      const index = parseInt(match[1]) - 1; // Material indices are 1-based
                      const name = modalData[`material${index + 1}Name`] ?? "";
                      const displayWeight =
                        modalData[`material${index + 1}DisplayWeight`] ?? "";
                      const actualWeight =
                        modalData[`material${index + 1}ActualWeight`] ?? "";
                      const difference =
                        modalData[`material${index + 1}Difference`] ?? "";
                      const avgDisplayWeight =
                        modalData[`material${index + 1}AvgDisplayWeight`] ?? "";
                      const blendPerc =
                        modalData[`material${index + 1}BlendPerc`] ?? "";
                      const cv40 = modalData[`material${index + 1}Cv40`] ?? "";
                      // const variety = modalData.variety;

                      return (
                        <TableRow key={key}>
                          <TableCell>{value}</TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{hallNo}</TableCell>
                          <TableCell>{displayWeight}</TableCell>
                          <TableCell>{actualWeight}</TableCell>
                          <TableCell>{difference}</TableCell>
                          <TableCell>{avgDisplayWeight}</TableCell>
                          <TableCell>{blendPerc}</TableCell>
                          <TableCell>{cv40}</TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
            <CustomButton
              onClick={handleSubmit}
              style={{ width: "100px", height: "40px" }}
              disabled={mutateLoading}
              type="submit"
            >
              {mutateLoading ? <CircularProgress size={24} /> : "Submit"}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Container>
    </form>
  );
}
