export const VARIANTS = [
  { title: "B PSF" },
  { title: "D PSF" },
  { title: "E PSF" },
  { title: "F PSF" },
];
export const LAP_FEED_CARDING_NUMBERS = [
  {
    carding_num: 15,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 16,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 17,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 18,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 19,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 20,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 21,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 22,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 23,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
  {
    carding_num: 24,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
  },
];
export const CHUTE_FEED_WASTE_COTTON_CARDING_NUMBERS = [
  {
    carding_num: 1,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    likerInSpeed1_: "",
    likerInSpeed2_: "",
    likerInSpeed3_: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting_1_: "",
    wing_setting_2_: "",
  },
  {
    carding_num: 2,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    likerInSpeed1_: "",
    likerInSpeed2_: "",
    likerInSpeed3_: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting_1_: "",
    wing_setting_2_: "",
  },
  {
    carding_num: 3,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    likerInSpeed1_: "",
    likerInSpeed2_: "",
    likerInSpeed3_: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting_1_: "",
    wing_setting_2_: "",
  },
  {
    carding_num: 4,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    likerInSpeed1_: "",
    likerInSpeed2_: "",
    likerInSpeed3_: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting_1_: "",
    wing_setting_2_: "",
  },
  {
    carding_num: 5,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    likerInSpeed1_: "",
    likerInSpeed2_: "",
    likerInSpeed3_: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting_1_: "",
    wing_setting_2_: "",
  },
];

export const CHUTE_FEED_WASTE_OTHERS_CARDING_NUMBERS = [
  {
    carding_num: 7,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 8,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 9,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 10,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 11,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 12,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 13,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
  {
    carding_num: 14,
    waste: "",
    variety: "",
    fs_wt: "",
    production: "",
    cylinder_speed: "",
    liker_in_speed: "",
    flats_speed: "",
    delivery_speed: "",
    wing_setting: "",
  },
];

export const HALL_NO = [1, 2, 3, 4];

export const DEPARTMENTS_TASKSHEETS = [
  "PRODUCTION",
  "QUALITY",
  "MAINTENANCE",
  "ELECTRICAL",
  "HR",
  "CIVIL",
  "VEHICLE",
  "OTHERS",
];

export const MACHINE_SETTINGS_CARDING_NO = Array.from(
  { length: 24 },
  (_, index) => index + 1
);

export const MACHINE_SETTINGS_SPINNING_FRAMENO = Array.from(
  { length: 24 },
  (_, index) => index + 1
);

export const MACHINE_SETTINGS_AUTOCONER_NO = Array.from(
  { length: 8 },
  (_, index) => index + 1
);

export const MACHINE_SETTINGS_AUTOCONER_PAPERCONE = Array.from(
  { length: 8 },
  (_, index) => index + 1
);

export const MACHINE_SETTINGS_SIMPLEX_NO = Array.from(
  { length: 13 },
  (_, index) => index + 1
);

//this is default seconds for showing the notification in the television
export const defaultSeconds = 2000;

export const AllModules = [
  "blow_room",
  "carding",
  "autoconer",
  "spinning",
  "lap",
  "finisher_drawing",
  "simplex_checking",
];

export const ParameterMastersModules = [
  "General",
  "Blowroom",
  "Carding",
  "Drawing",
  "Simplex",
  "Spinning",
  "Autoconer",
  "Others",
];

export const ParameterMastersInputType = [
  "Number(Whole)",
  "Number(Decimals)",
  "Text",
  // "Numbers and Decimals",
  "All Characters",
  "1*2 measurements",
  "1*3 measurements",
  "Single select",
  "Multiple select",
  "Percentage",
  "Negative values",
];

export const TelevisionModulesForAddUser = [
  { label: "General", value: "general" },
  { label: "Blowroom", value: "blow_room" },
  { label: "Carding", value: "carding" },
  { label: "Autoconer", value: "autoconer" },
  { label: "Spinning", value: "spinning" },
  { label: "Finisher Drawing", value: "finisher_drawing" },
  { label: "Simplex Checking", value: "simplex_checking" },
  { label: "Others", value: "others" },
  { label: "Task Sheets", value: "task_sheets" },
];

export const ModulesForAddUser = [
  { label: "Blowroom", value: "blow_room" },
  { label: "Carding", value: "carding" },
  { label: "Autoconer", value: "autoconer" },
  { label: "Spinning", value: "spinning" },
  { label: "LAP", value: "lap" },
  { label: "Finisher Drawing", value: "finisher_drawing" },
  { label: "Simplex Checking", value: "simplex_checking" },
  { label: "General", value: "general" },
  { label: "Others", value: "others" },
  { label: "Task Sheets", value: "task_sheets" },
  { label: "Sasta Reports", value: "sasta" },
];

export const SubModulesForAddUser = [
  {
    label: "Blowroom",
    modules: [
      {
        label: "Blowroom Machine Settings",
        value: "blow_room_machine_settings",
      },
      { label: "Lap Feed", value: "lap_feed" },
      { label: "Chute Feed (Cotton)", value: "chute_feed_cotton" },
      { label: "Chute Feed (Others)", value: "chute_feed_others" },
      { label: "Drop Test", value: "drop_test" },
    ],
  },
  {
    label: "Carding",
    modules: [
      {
        label: "Carding Machine Settings",
        value: "carding_machine_settings",
      },
      { label: "Individual Carding Waste", value: "individual_carding_waste" },
      { label: "Auto Leveller On/Off", value: "auto_leveller_on/off" },
    ],
  },
  {
    label: "Autoconer",
    modules: [
      {
        label: "Autoconer Machine Settings",
        value: "autoconer_machine_settings",
      },
      { label: "Splice Strength", value: "splice_strength" },
      {
        label: "Paper Cone Identification",
        value: "paper_cone_identification",
      },
      { label: "LN Checking", value: "ln_checking" },
      { label: "Rewinding study", value: "rewinding_study" },
      { label: "Trail", value: "trail" },
      { label: "U%", value: "u_percent_trail" },
    ],
  },
  {
    label: "Spinning",
    modules: [
      {
        label: "Spinning Machine Settings",
        value: "spinning_machine_settings",
      },
      { label: "Count Change", value: "count_change" },
      { label: "Cot Checking", value: "cot_checking" },
      { label: "Hariness", value: "hariness" },
      { label: "Apron, Spacer, Condenser", value: "apron_spacer_condenser" },
    ],
  },
  {
    label: "LAP",
    modules: [
      { label: "Sliver Lap", value: "sliver_lap" },
      { label: "Ribbon Lap", value: "ribbon_lap" },
      { label: "Structure Lap", value: "structure_lap" },
    ],
  },
  {
    label: "Finisher Drawing",
    modules: [
      {
        label: "Finisher Drawing Machine Settings",
        value: "finisher_drawing_machine_settings",
      },
      { label: "1 & 1/2 Yard", value: "finisher_drawing_yard" },
    ],
  },
  {
    label: "Simplex Checking",
    modules: [
      {
        label: "Simplex Checking Machine Settings",
        value: "simplex_checking_machine_settings",
      },
      { label: "Simplex Checking", value: "simplex_checking" },
    ],
  },
  {
    label: "General",
    modules: [
      {
        label: "General Machine Settings",
        value: "general_machine_settings",
      },
    ],
  },
  {
    label: "Others",
    modules: [
      {
        label: "Others Machine Settings",
        value: "others_machine_settings",
      },
    ],
  },
  {
    label: "Sasta Reports",
    modules: [{ label: "Sasta Reports", value: "sasta" }],
  },
];

export const ALL_FLOWS_OPTIONS = [
  { flow: "blow_room", path: "lap-feed-waste" },
  { flow: "carding", path: "individual-carding-waste" },
  { flow: "autoconer", path: "splice-strength-checking" },
  { flow: "spinning", path: "count-change-record" },
  { flow: "lap", path: "sliver-lap" },
  { flow: "finisher_drawing", path: "finisher-drawing-yards" },
  { flow: "simplex_checking", path: "simplex-checking-draft" },
];
