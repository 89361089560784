import apiClient from "../../utils/clients/axios";
import { useQuery } from "react-query";

// get all saved records to populate the table
export const getAllSavedRecords = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/master_data/getAllMasterDataDrafts`,
    payload
  );
  return response;
};

// delete a saved record from the table
export const deleteSavedRecord = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/master_data/deleteMasterDataDraft/${id}`
  );
  return response;
};

// Function to fetch saved record by ID
const getSavedRecordById = async (id) => {
  const response = await apiClient.get(
    `/api/quality_control/master_data/getMasterDataDraftById/${id}`
  );
  return response; // Return response data, not response object
};

// Custom hook for fetching saved record by ID
export const useSavedRecordById = (id) => {
  return useQuery(["savedRecord", id], () => getSavedRecordById(id), {
    enabled: !!id, // Query is enabled only if there's a valid ID
  });
};
