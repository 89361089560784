// eslint-disable
import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import { CotCheckingPost } from "../Blowroom/mutation/manager/blowroomReportMutation";
import { useMutation, useQueryClient } from "react-query";
import { handleToastMsg } from "../../components/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  createCotCheckDraft,
  updateCotCheckDraft,
} from "./mutation/cotCheckDraftMutation";
import "./spinningcss.css";
const CountChange = () => {
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState({
    variety: "",
    counts: "",
    machineNo: "",
    feedback: "",
  });

  const { state } = useLocation();

  const [showData, setShowData] = useState([]);
  useEffect(() => {
    if (state?.cotCheckingPayload) {
      setShowData([state?.cotCheckingPayload]);
    }
  }, [state]);

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "variety" || field === "feedback") {
      value = value.toUpperCase();
    } else if (["counts"].includes(field)) {
      value = !isNaN(value) ? parseFloat(value) : value;
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));

    if (field === "feedback") {
      setShowData((prev) => {
        return prev.map((item) => {
          // Update the cot_buffing property of the specific item
          return {
            ...item,
            cot_checking_buffs: {
              ...item.cot_checking_buffs,
              feedback: value,
            },
          };
        });
      });
    }
  };

  const [cotsBuffing, setCotsBuffing] = useState("");
  const [cotsBuffingError, setCotsBuffingError] = useState(false);
  const [saveAndSubmitDisabled, setSaveAndSubmitDisabled] = useState(true);
  const [finalSubmitDisabled, setFinalSubmitDisabled] = useState(false);

  const finalSubmitEnabled = () => {
    setFinalSubmitDisabled(true);
    setSaveAndSubmitDisabled(false);
  };

  const saveAndSubmitEnabled = () => {
    setSaveAndSubmitDisabled(true);
    setFinalSubmitDisabled(false);
  };

  const handleCotsBuffing = (e) => {
    const value = e.target.value;
    const numericValue = value === "" ? "" : parseInt(value);

    if (value === "") {
      setCotsBuffing("");
      setCotsBuffingError(false);
      saveAndSubmitEnabled();
    } else {
      if (!isNaN(numericValue)) {
        setCotsBuffing(numericValue);
        if (
          state?.cotCheckingPayload?.cot_checking_buffs.number_of_cots <
          numericValue
        ) {
          setCotsBuffingError(true);
          saveAndSubmitEnabled();
        } else if (
          value &&
          state?.cotCheckingPayload?.cot_checking_buffs.number_of_cots ===
            numericValue
        ) {
          setCotsBuffingError(false);
          finalSubmitEnabled();
          setShowData((prev) => {
            return prev.map((item) => {
              // Update the cot_buffing property of the specific item
              return {
                ...item,
                cot_checking_buffs: {
                  ...item.cot_checking_buffs,
                  cot_buffing: numericValue,
                },
              };
            });
          });
        } else if (
          state?.cotCheckingPayload?.cot_checking_buffs.number_of_cots >
          numericValue
        ) {
          setCotsBuffingError(false);
          saveAndSubmitEnabled();
          setShowData((prev) => {
            return prev.map((item) => {
              // Update the cot_buffing property of the specific item
              return {
                ...item,
                cot_checking_buffs: {
                  ...item.cot_checking_buffs,
                  cot_buffing: numericValue,
                },
              };
            });
          });
        }
      }
    }
  };

  const [remarks, setRemarks] = useState("");
  const handleRemarks = (e) => {
    const value = e.target.value;
    setRemarks(value);

    setShowData((prev) => {
      return prev.map((item) => {
        // Update the cot_buffing property of the specific item
        return {
          ...item,
          cot_checking_buffs: {
            ...item.cot_checking_buffs,
            remarks: value,
          },
        };
      });
    });
  };

  const { mutate, isLoading: isSubmitLoading } = useMutation(CotCheckingPost);
  // const {
  //   data: varietyOptions,
  //   isLoading,
  //   isError,
  // } = useQuery(
  //   "splice-strength-checking",
  //   async () => {
  //     try {
  //       const response = await MasterData();
  //       return response.data;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  const { mutate: createDraft, isLoading: isCreateLoading } = useMutation(
    async (payload) => {
      try {
        const response = await createCotCheckDraft(payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  const { mutate: updateDraft, isLoading: isUpdateLoading } = useMutation(
    async (params) => {
      try {
        const response = await updateCotCheckDraft(params);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  // const {
  //   data: countChangeData,
  //   isLoading: isCountLoading,
  //   isError: isCountError,
  // } = useQuery(
  //   ["count-change", draftId],
  //   async () => {
  //     try {
  //       const response = await getCotCheckDraft(draftId);
  //       return response.data;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  // Function to remove 'id' field from samples
  const removeIdFromSamples = (samples) => {
    return samples?.map(({ id, ...rest }) => rest);
  };

  // handle final submit
  const handleSubmit = async () => {
    //this is updated payload for final submit only (it remove the id in the cot_check_records)
    const updatedPayload = {
      ...showData[0],
      cot_check_records: removeIdFromSamples(showData[0]?.cot_check_records),
    };

    if (!cotsBuffingError && cotsBuffing !== "") {
      try {
        mutate(updatedPayload, {
          onSuccess: () => {
            handleToastMsg("Data updated successfully", "success");
            setShowData([]);
            setFormValues({
              variety: "",
              counts: "",
              machineNo: "",
              feedback: "",
            });
            queryClient.invalidateQueries({ queryKey: ["cot-check"] });
            queryClient.invalidateQueries("manager-spinning-notifications");
            navigate("/cot-checking");
          },
          onError: (error) => {
            handleToastMsg("Data Error Occurred", "error");
          },
        });
      } catch (error) {
        throw error;
      }
    }
  };

  const navigate = useNavigate();

  const handleSaveAndEdit = () => {
    if (state?.draftId && state?.cotCheckDrafts) {
      const id = state?.draftId || "";
      const payload = showData[0];
      const params = { payload, id };

      updateDraft(params, {
        onSuccess: (res) => {
          handleToastMsg("Draft Data updated successfully", "success");
          setShowData([]);
          setFormValues({
            variety: "",
            counts: "",
            machineNo: "",
            feedback: "",
          });
          queryClient.invalidateQueries({ queryKey: ["cot-check"] });
          navigate("/cot-checking");
        },
        onError: (error) => {
          handleToastMsg("Draft Data Error Occurred", "error");
        },
      });
    } else {
      createDraft(showData[0], {
        onSuccess: (res) => {
          handleToastMsg("Draft Data updated successfully", "success");
          setShowData([]);
          setFormValues({
            variety: "",
            counts: "",
            machineNo: "",
            feedback: "",
          });
          queryClient.invalidateQueries({ queryKey: ["cot-check"] });
          navigate("/cot-checking");
        },
        onError: (error) => {
          handleToastMsg("Draft Data Error Occurred", "error");
        },
      });
    }
  };

  const currentDate = dayjs().format("DD/MM/YYYY");

  return (
    <Container>
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Cot Checking</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "1vw" }}>Date</TableCell>
                <TableCell style={{ width: "1vw" }}>No Of Cuts</TableCell>
                <TableCell style={{ width: "1vw" }}>Cots Buffing</TableCell>
                <TableCell style={{ width: "1vw" }}>Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showData.length > 0 &&
                showData?.map((item, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ width: "1vw" }}>
                        {currentDate}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.cot_checking_buffs?.number_of_cots}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        <TextField
                          value={cotsBuffing}
                          onChange={(e) => handleCotsBuffing(e)}
                          error={!!cotsBuffingError}
                        />
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        <TextField
                          value={remarks}
                          onChange={(e) => handleRemarks(e)}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid mt={3} item xs={10} sm={10}>
        <Textarea
          onChange={(e) => handleFormChange(e, "feedback")}
          name="Size"
          placeholder="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={10} sm={10}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            onClick={() => handleSaveAndEdit()}
            disabled={
              !saveAndSubmitDisabled || isCreateLoading || isUpdateLoading
            }
          >
            {isCreateLoading || isUpdateLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Save And Edit"
            )}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            onClick={handleSubmit}
            disabled={!finalSubmitDisabled || isSubmitLoading}
          >
            {isSubmitLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};

export default CountChange;
