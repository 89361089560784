import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { SpliceStrengthCreate } from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";

const filter = createFilterOptions();

const SpliceStrengthChecking = () => {
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [variety, setVariety] = useState("");
  const [formValues, setFormValues] = useState({
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    csp_value: "",
    feedback: "",
    no_of_readings: "",
    readings: [],
    particular: [],
    drumFrom: "",
    drumTo: "",
    drumNo: "",
    slub_code: "",
    average: "",
  });

  const handleFormChange = (event, field) => {
    let { value } = event.target;
    if (field === "denier" || field === "feedback" || field === "slub_code") {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "hallNo",
        "auto_coner_no",
        "drumNo",
        "drumFrom",
        "drumTo",
        "csp_value",
        "no_of_readings",
      ].includes(field)
    ) {
      const floatValue = value === "" ? "" : parseFloat(value);
      if (value < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = !isNaN(floatValue) && floatValue >= 0 ? floatValue : "";
      }
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  useEffect(() => {
    if (formValues.csp_value !== undefined && formValues.counts !== undefined) {
      calculateAverage(formValues.csp_value, formValues.counts);
    }
  }, [formValues.csp_value, formValues.counts]);

  const calculateAverage = (csp_value, counts) => {
    const parsedCSPValue = parseFloat(csp_value);
    const parsedCounts = parseFloat(counts);

    if (!isNaN(parsedCSPValue) && !isNaN(parsedCounts) && parsedCounts !== 0) {
      const value1 = parsedCSPValue / parsedCounts;
      const value2 = (value1 / 0.264) * (80 / 100);
      setFormValues((prevState) => ({
        ...prevState,
        average: parseFloat(value2.toFixed(2)),
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        average: "",
      }));
    }
  };

  // Column wise average Caluculation for Readings
  useEffect(() => {
    const readings = [];
    for (let i = 1; i <= selectedNumber; i++) {
      const readingObj = {
        readingNo: i,
      };

      readingObj[`splice`] = "";
      readingObj[`parentYarn`] = "";
      readingObj[`percent`] = "";

      readings.push(readingObj);
    }
    setFormValues((prevState) => ({ ...prevState, readings }));
  }, [selectedNumber]);

  const { mutate, isLoading: mutateLoading } =
    useMutation(SpliceStrengthCreate);

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const handleReadingChange = (event, readingIndex, field) => {
    const { value } = event.target;
    let parsedValue = value === "" ? "" : parseFloat(value);

    const updatedFormValues = { ...formValues };
    const currentReading = updatedFormValues.readings[readingIndex];

    if (
      !isNaN(parsedValue) &&
      parsedValue !== null &&
      parsedValue !== undefined
    ) {
      updatedFormValues.readings[readingIndex][field] = parsedValue;

      if (
        currentReading[`splice`] !== undefined &&
        currentReading[`parentYarn`] !== undefined
      ) {
        const spliceValue = currentReading[`splice`];
        const parentYarnValue = currentReading[`parentYarn`];

        let percent = "";

        spliceValue && parentYarnValue
          ? (percent =
              parseFloat(((spliceValue / parentYarnValue) * 100).toFixed(2)) ||
              0)
          : (percent = "");

        updatedFormValues.readings[readingIndex][`percent`] = percent;

        updatedFormValues.readings[readingIndex][`drumNo`] = formValues.drumNo;
      }
      setFormValues(updatedFormValues);
      updateAverages(updatedFormValues.readings);
    }
  };

  // Column wise average Caluculation for Readings
  const updateAverages = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return;
    }

    let spliceSum1 = 0;
    let parentYarnSum1 = 0;
    let percentSum1 = 0;

    data.forEach((row) => {
      const spliceKeys = Object.keys(row).filter((key) =>
        key.startsWith("splice")
      );
      spliceKeys.forEach((key) => {
        const spliceReading = parseFloat(row[key]);
        if (!isNaN(spliceReading)) {
          spliceSum1 += spliceReading;
        }
      });
    });

    data.forEach((row) => {
      const parentYarnKeys = Object.keys(row).filter((key) =>
        key.startsWith("parentYarn")
      );
      parentYarnKeys.forEach((key) => {
        const parentReading = parseFloat(row[key]);
        if (!isNaN(parentReading)) {
          parentYarnSum1 += parentReading;
        }
      });
    });

    data.forEach((row) => {
      const percentKeys = Object.keys(row).filter((key) =>
        key.startsWith("percent")
      );
      percentKeys.forEach((key) => {
        const percentReading = parseFloat(row[key]);
        if (!isNaN(percentReading)) {
          percentSum1 += percentReading;
        }
      });
    });

    const totalCount = data.length;
    const spliceAvg = totalCount > 0 ? spliceSum1 / totalCount : 0;
    const parentYarnAvg = totalCount > 0 ? parentYarnSum1 / totalCount : 0;
    const percentAvg = totalCount > 0 ? percentSum1 / totalCount : 0;

    //add the data in formValues particular
    const averagesIndex = formValues?.particular?.findIndex(
      (reading) => reading.drumNo === formValues.drumNo
    );
    // Create a new object for the "Averages" entry
    let newUpdatedate = [...(formValues.particular || [])];

    const averagesEntry = {
      drumNo: formValues.drumNo,
      noOfReadings: formValues.no_of_readings || "",
      averageSpliceReading: parseFloat(spliceAvg.toFixed(2)) ?? "",
      averageParentYarnReading: parseFloat(parentYarnAvg.toFixed(2)) ?? "",
      averagePercentYarnReading: parseFloat(percentAvg.toFixed(2)) ?? "",
      readings: table3Data,
    };

    if (averagesIndex !== -1) {
      // Update existing entry
      newUpdatedate[averagesIndex] = averagesEntry;
    } else {
      // Add new entry
      newUpdatedate.push(averagesEntry);
    }

    setFormValues((prevState) => ({
      ...prevState,
      particular: newUpdatedate,
    }));
  };

  //useEffect for calculate the avergaes for all readings
  useEffect(() => {
    updateAverages(formValues.readings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.readings]);

  const [storedReadings, setStoredReadings] = useState([]);
  const [, setSelectedDrumNo] = useState(null);

  const handleStore = () => {
    const { readings, drumNo } = formValues;
    const storedData = [...storedReadings];
    const drumNoIndex = storedData.findIndex((item) => item.drumNo === drumNo);
    if (drumNoIndex !== -1) {
      storedData[drumNoIndex][`drum_No_${drumNoIndex + 2}`] = readings;
    } else {
      storedData.push({ [`drum_No_${storedData.length + 1}`]: readings });
    }

    setStoredReadings(storedData);
    setSelectedNumber(0);
    setSelectedDrumNo(formValues.drumNo);
    setFormValues((prevState) => ({
      ...prevState,
      readings: [],
      no_of_readings: "",
      drumNo: "",
    }));
  };

  const generateDrumOptions = (drumFrom, drumTo) => {
    const options = [];
    for (let i = drumFrom; i <= drumTo; i++) {
      options.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };

  // let spliceSum = 0;
  // let parentYarnSum = 0;
  // let percentSum = 0;
  // let count = 0;

  // if (Array.isArray(formValues.readings)) {
  //   for (const row of formValues.readings) {
  //     const spliceValue = parseFloat(row.splice_reading);
  //     const parentYarnValue = parseFloat(row.parent_yarn_reading);
  //     const percentValue = parseFloat(row.percent_reading);

  //     if (
  //       !isNaN(spliceValue) &&
  //       !isNaN(parentYarnValue) &&
  //       !isNaN(percentValue)
  //     ) {
  //       spliceSum += spliceValue;
  //       parentYarnSum += parentYarnValue;
  //       percentSum += percentValue;
  //       count++;
  //     }
  //   }
  // }

  const table3Data = Array.isArray(formValues.readings)
    ? [...formValues.readings]
    : [];

  const queryClient = useQueryClient();

  const handleSubmit = () => {
    const payload = {
      variety: variety || "",
      hall: formValues.hallNo || "",
      counts: formValues.counts || "",
      denier: formValues.denier || "",
      autoConerNo: formValues.auto_coner_no || "",
      drumNo: `${formValues.drumFrom}-${formValues.drumTo}`,
      cspValue: formValues.csp_value || "",
      particular: formValues.particular || "",
      csvStrengthValue: formValues.average || "",
      average: formValues.average || "",
      slubCode: formValues.slub_code || "",
      machineName:
        `${formValues.counts}'S, ${variety}, ${formValues.denier}, ${formValues.slub_code}` ||
        "",
      feedback: formValues.feedback || "",
    };

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        queryClient.invalidateQueries("get-variety");
        queryClient.invalidateQueries("manager-autoconer-notifications");
        setVariety("");
        setFormValues({
          hallNo: "",
          counts: "",
          denier: "",
          auto_coner_no: "",
          drumNo: "",
          drumFrom: "",
          drumTo: "",
          csp_value: "",
          no_of_readings: "",
          average: "",
          feedback: "",
          slub_code: "",
          average_splice_reading: "",
          average_parent_yarn_reading: "",
          average_percent_reading: "",
        });
      },
      onError: (error) => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Splice Strength</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container item xs={12} spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={variety}
                          fullWidth
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setVariety(newValue || "");
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setVariety(newValue || "");
                            } else {
                              setVariety(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          styles={{ width: "100%" }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Variety"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                      label="Counts"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                      label="Denier"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Slub Code"
                      label="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      placeholder="Auto coner No"
                      fullWidth
                      value={formValues.auto_coner_no}
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                      label="Auto Coner No"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drum No</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum From"
                          fullWidth
                          value={formValues.drumFrom}
                          onChange={(e) => handleFormChange(e, "drumFrom")}
                          label="Drum From"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum To"
                          fullWidth
                          value={formValues.drumTo}
                          onChange={(e) => handleFormChange(e, "drumTo")}
                          label="Drum To"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CSP Value</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={formValues.csp_value}
                      onChange={(e) => handleFormChange(e, "csp_value")}
                      label="CSP Value"
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Average</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      fullWidth
                      variant="outlined"
                      placeholder="Average"
                      value={formValues.average}
                      label="Average"
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={2} container item xs={12} spacing={2}>
        <TableRow>
          <TableCell>Drum No</TableCell>
          <TableCell>
            <Select
              value={formValues.drumNo || ""}
              onChange={(e) => handleFormChange(e, "drumNo")}
              fullWidth
              variant="outlined"
              label="Drum No"
            >
              {generateDrumOptions(formValues.drumFrom, formValues.drumTo)}
            </Select>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>No. of Readings</TableCell>
          <TableCell>
            <TextField
              type="text"
              variant="outlined"
              label="No Of Readings"
              fullWidth
              value={
                isNaN(formValues.no_of_readings)
                  ? ""
                  : formValues.no_of_readings
              }
              onChange={(e) => {
                const input = e.target.value;
                if (!isNaN(input)) {
                  const no_of_readings = parseInt(input, 10);
                  setFormValues({
                    ...formValues,
                    no_of_readings: no_of_readings,
                  });
                  setSelectedNumber(no_of_readings);
                }
              }}
            />
          </TableCell>
        </TableRow>
        <Grid container item xs={12} mt={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1vw" }}>Reading Number</TableCell>
                  <TableCell style={{ width: "1vw" }}>
                    Splice Strength
                  </TableCell>
                  <TableCell style={{ width: "1vw" }}>Parent Yarn</TableCell>
                  <TableCell style={{ width: "1vw" }}>Percent Yarn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table3Data.map((row, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell>{row.readingNo}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          placeholder="Splice"
                          value={row[`splice`] ?? ""}
                          label="Splice Strength"
                          onChange={(e) =>
                            handleReadingChange(e, index, `splice`)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          placeholder="Parent Yarn"
                          label="Parent Yarn"
                          value={row[`parentYarn`] ?? ""}
                          onChange={(e) =>
                            handleReadingChange(e, index, `parentYarn`)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          placeholder="Percent"
                          label="Percent Yarn"
                          value={row[`percent`] ?? ""}
                          disabled
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ))}

                {formValues?.particular?.length > 0 &&
                  formValues?.particular
                    ?.filter((item) => item.drumNo === formValues.drumNo)
                    ?.map((item) => (
                      <TableRow>
                        <TableCell>Average</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            fullWidth
                            placeholder="Splice"
                            value={item.averageSpliceReading}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            fullWidth
                            placeholder="Parent Yarn"
                            value={item.averageParentYarnReading}
                            disabled
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            fullWidth
                            placeholder="Percent"
                            value={item.averagePercentYarnReading}
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    ))}

                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => handleFormChange(e, "feedback")}
          name="Size"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            onClick={handleStore}
          >
            Store
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            disabled={mutateLoading}
            onClick={handleSubmit}
          >
            {mutateLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SpliceStrengthChecking;
