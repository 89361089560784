import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Logo from "../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

function Navbar() {
  const navigate = useNavigate();

  // Get the employee's name from local storage or your authentication state
  const employeeName = localStorage.getItem("name"); // Replace with your logic

  const handleLogout = () => {
    // localStorage.clear();

    localStorage.removeItem("authorization");
    localStorage.removeItem("roles");
    localStorage.removeItem("name");
    localStorage.removeItem("activeMenuItem");
    localStorage.removeItem("dashboardMenu");
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  // Extract the first letter of the employee's name
  const firstLetter = employeeName ? employeeName.charAt(0) : "";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#ec0000" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img
              src={Logo}
              alt="logo"
              height={40}
              width={50}
              style={{ borderRadius: "10px" }}
            />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            display={{ xs: "none", md: "block", sm: "block" }}
          >
            Quality Control
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p>
              <span
                style={{ marginRight: 10, backgroundColor: "", color: "#FFF" }}
              >
                {" "}
                HelpLine NO :
              </span>
              <span
                style={{ marginRight: 13, backgroundColor: "", color: "#FFF" }}
              >
                9843297486
              </span>
            </p>
            {employeeName && (
              <div
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 10,
                  color: "black",
                  border: "2px solid black",
                }}
              >
                {firstLetter}
              </div>
            )}

            {employeeName && (
              <Typography
                variant="body1"
                color="inherit"
                display={{ xs: "none", md: "block", sm: "block" }}
              >
                {employeeName}
              </Typography>
            )}
            <Button
              variant="outlined"
              onClick={() => handleLogout()}
              color="inherit"
              style={{ marginLeft: 10, backgroundColor: "", color: "white" }}
            >
              <Typography
                variant="p"
                // component="div"
                display={{ xs: "none", md: "block", sm: "block" }}
              >
                Logout
              </Typography>
              <LogoutIcon
                sx={{ display: { md: "none", sm: "none", xs: "block" } }}
              />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
