import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  capitalize,
} from "@material-ui/core";
import { useQuery } from "react-query";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog";
import MasterStandardDialog from "../../../components/MasterStandardDialog";
import { getAllNotificationData } from "../Mutation/mutation";
import dayjs from "dayjs";
import NoDataAvailable from "../../../utils/NoDataAvailable";
import { CircularProgress } from "@mui/material";
import AllPreviousEntries from "../../MachineSetting/AllPreviousEntries";
import AddMachineRecord from "../../MachineSetting/AddMachineRecord";

const NestedTable = ({ data }) => {
  const fieldsToDisplay = Object.keys(data?.data)?.map((item) => item) || [];

  return (
    <Table size="small" aria-label="nested table">
      <TableBody>
        {fieldsToDisplay?.map((field, index) => {
          // Check if isOutOfNorms is true for the current field
          if (data?.data[field]?.isOutOfNorms) {
            // Access the value from the item
            const value = data?.data[field];

            let displayValue;

            // Handle different data types for display
            if (Array.isArray(value.value)) {
              displayValue = value.join(", ");
            } else if (
              typeof value.value === "object" &&
              value.value !== null
            ) {
              // For object values, format the display to show x * y * z (if z exists)

              displayValue = Object.keys(value.value)
                ?.map((subKey) => `${value.value[subKey] ?? "N/A"}`) // Handle null/undefined values
                ?.join(" * "); // Join the values with " * "
            } else {
              // For simple values (string, number, etc.)
              displayValue = value.value || "N/A";
            }

            return (
              <TableRow key={index}>
                <TableCell style={{ border: "1px solid #E2E2E2" }}>
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #E2E2E2",
                    color: data?.data[field]?.isOutOfNorms ? "red" : "inherit",
                  }}
                >
                  {displayValue}
                </TableCell>
              </TableRow>
            );
          } else {
            return null; // Return null for fields that are not out of norms
          }
        })}
      </TableBody>
    </Table>
  );
};

function AllMachineSettingsManager({
  isTelevision,
  setPauseInterval,
  televisionKey,
}) {
  const [activeComponent, setActiveComponent] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [openStdDataDialog, setOpenStdDataDialog] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    data: allNotificationData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    "all-notification-data",
    async () => {
      if (!isTelevision) {
        setIsLoadingData(true);
      }
      try {
        const response = await getAllNotificationData();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        if (!isTelevision) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  const [masterKey, setMasterKey] = useState("");
  const [heading, setHeading] = useState("");

  const location = useLocation();

  //this is for setting heading and key for getting data dynamically
  useEffect(() => {
    //this is for set the heading in Televion login
    if (isTelevision) {
      // Remove 'Master' and convert the remaining string to uppercase
      const transformedKey = televisionKey?.replace("Master", "").toUpperCase();
      setHeading(transformedKey);
    }

    //this is for set the key and heading dynamically check with pathname
    if (allNotificationData?.data) {
      if (location?.pathname === "/general-machine-settings-correction") {
        setMasterKey("general");
        setHeading("GENERAL");
      } else if (location?.pathname === "/others-machine-settings-correction") {
        setMasterKey("others");
        setHeading("OTHERS");
      } else if (
        location?.pathname === "/blow-room-machine-settings-correction"
      ) {
        setMasterKey("blowroom");
        setHeading("BLOWROOM");
      } else if (
        location?.pathname === "/carding-machine-settings-correction"
      ) {
        setMasterKey("carding");
        setHeading("CARDING");
      } else if (
        location?.pathname === "/autoconer-machine-settings-correction"
      ) {
        setMasterKey("autoconer");
        setHeading("AUTOCONER");
      } else if (
        location?.pathname === "/spinning-machine-settings-correction"
      ) {
        setMasterKey("spinning");
        setHeading("SPINNING");
      } else if (
        location?.pathname === "/finisher-drawing-machine-settings-correction"
      ) {
        setMasterKey("drawing");
        setHeading("FINISHER DRAWING");
      } else if (
        location?.pathname === "/simplex-checking-machine-settings-correction"
      ) {
        setMasterKey("simplex");
        setHeading("SIMPLEX CHECKING");
      }
    }
  }, [allNotificationData, isTelevision, location?.pathname, televisionKey]);

  const keyForData = isTelevision
    ? televisionKey
    : capitalize(masterKey) + "Master";

  const showData = useMemo(() => {
    return allNotificationData?.data?.[keyForData] || [];
  }, [allNotificationData?.data, keyForData]);

  const [isManager, setIsManager] = useState(false);
  const [selectedDataToEdit, setSelectedDataToEdit] = useState({});

  const handleRespond = (rowData) => {
    setActiveComponent("AddRecord");
    setIsManager(true);
    setSelectedDataToEdit(rowData);
    if (isTelevision) {
      setPauseInterval(true);
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "PreviousData":
        return (
          <AllPreviousEntries
            goBack={() => setActiveComponent(null)}
            masterKey={masterKey}
          />
        );
      case "AddRecord":
        return (
          <AddMachineRecord
            selectedDataToEdit={selectedDataToEdit}
            goBack={() => setActiveComponent(null)}
            isManager={isManager}
            isTelevision={isTelevision}
            setPauseInterval={setPauseInterval}
            masterKey={masterKey}
          />
        );
      default:
        return (
          <Grid container>
            {isLoadingData || isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                position="fixed"
                top={0}
                left={0}
                width="100%"
                zIndex={9999} // Ensure this is above other elements
                background="rgba(255, 255, 255, 0.8)" // Background color with transparency
              >
                <CircularProgress /> {/* Center the loading spinner */}
              </Box>
            ) : isError ? (
              <Grid item xs={12} container justifyContent="center">
                <p style={{ color: "red" }}>Error loading data</p>
              </Grid>
            ) : (
              <Grid container item md={12} xs={12} alignContent="flex-start">
                <Grid
                  container
                  item
                  md={12}
                  xs={12}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Grid
                    container
                    item
                    md={isTelevision ? 12 : 6}
                    xs={isTelevision ? 12 : 6}
                    justifyContent={isTelevision && "center"}
                  >
                    <h3>{heading} - MACHINE SETTINGS</h3>
                  </Grid>
                  {!isTelevision && (
                    <Grid
                      item
                      md={5}
                      xs={6}
                      container
                      justifyContent="space-between"
                    >
                      <Button
                        variant="contained"
                        className="view-btn"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          textTransform: "none",
                        }}
                        onClick={handleOpenStdDataDialog}
                      >
                        View Standards
                      </Button>
                      <Link to="">
                        <Button
                          variant="contained"
                          className="add-btn"
                          color="primary"
                          style={{
                            // backgroundColor: "#87bfed",
                            textTransform: "none",
                          }}
                          onClick={() => setActiveComponent("PreviousData")}
                        >
                          View Previous Entries
                        </Button>
                      </Link>
                    </Grid>
                  )}
                </Grid>

                {showData?.length > 0 ? (
                  <TableContainer component={Paper} style={{ marginTop: "2%" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Variety</TableCell>
                          <TableCell align="center">Error</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showData?.length > 0 &&
                          showData?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {dayjs(row?.date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell>{row?.partyCode}</TableCell>
                              <TableCell>
                                <Box>
                                  <NestedTable data={row} />
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() => handleRespond(row)}
                                  style={{
                                    border: "1px solid red",
                                    backgroundColor: "white",
                                    marginRight: "4%",
                                    textTransform: "none",
                                  }}
                                >
                                  {isTelevision ? "View" : "Respond"}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoDataAvailable />
                )}
              </Grid>
            )}
          </Grid>
        );
    }
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    // mutation.mutate(selectedIdToDelete);
    handleCloseDeleteDialog();
  };

  const handleOpenStdDataDialog = () => {
    setOpenStdDataDialog(true);
  };

  const handleCloseStdDataDialog = () => {
    setOpenStdDataDialog(false);
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "2%",
        paddingBottom: "4%",
      }}
    >
      {renderComponent()}
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmed}
      />
      <MasterStandardDialog
        open={openStdDataDialog}
        onClose={handleCloseStdDataDialog}
        heading="General Standard Dialog"
      />
    </Grid>
  );
}

export default AllMachineSettingsManager;
