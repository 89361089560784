import { Grid } from "@mui/material";
import React from "react";

const NoDataAvailable = () => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      style={{ marginTop: "50px" }}
    >
      <h2>No data available</h2>
    </Grid>
  );
};

export default NoDataAvailable;
