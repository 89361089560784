import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  FormControl,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { TrailCreate } from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";

const filter = createFilterOptions();

const Trail = () => {
  const [selectedNumber] = useState(0);
  const [variety, setVariety] = useState("");
  const [table2Data] = useState({
    autoConerNo: "",
    drumNo: "",
    cspValue: "",
    numberOfReadings: 1,
  });
  const [formValues, setFormValues] = useState({
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    frame_no: "",
    no_of_trails: 1,
    particular: [],
    slub_code: "",
    regular_data: {},
    U_particular: [],
    U_regular_data: {},
    feedback: "",
    total: "",
  });

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "feedback" || field === "denier") {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "hallNo",
        "auto_coner_no",
        "frame_no",
        "no_of_trails",
      ].includes(field)
    ) {
      const changedValue = (value = !isNaN(value) ? parseFloat(value) : value);
      if (changedValue < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = changedValue;
      }
    }
    setFormValues({ ...formValues, [field]: value });
  };

  const { mutate, isLoading } = useMutation(TrailCreate);

  const handleTrailNepCutsChange = (e, field) => {
    const { value } = e.target;
    let parsedValue = value;
    const changedValue = value === "" ? "" : parseInt(value);
    // const changedValue= value = !isNaN(value) ? parseFloat(value) : value;
    if (changedValue < 0) {
      alert("Please do not enter negative value");
      return;
    }
    if (value.trim() === "") {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        regular_data: {
          ...prevFormValues.regular_data,
          [field]: "",
        },
      }));
      return;
    }

    const floatValue = parseFloat(value);
    parsedValue = isNaN(floatValue) ? 0 : floatValue;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      regular_data: {
        ...prevFormValues.regular_data,
        [field]: parsedValue,
      },
    }));
  };

  // const [feedback, setFeedback] = useState("");
  // const handleFeedbackChange = (event) => {
  //   const { value } = event.target;
  //   setFeedback(value);
  // };
  const handleNumReadingsChange = (value) => {
    let numReadings = parseInt(value);

    if (isNaN(numReadings) || numReadings <= 0) {
      numReadings = 1;
    }
    const parsedValue = value === "" ? "" : parseFloat(value);

    if (parsedValue < 0) {
      alert("Please do not enter negative value");

      return;
    } else {
      value = parsedValue;
    }
    setFormValues({ ...formValues, no_of_trails: numReadings });
    setNumTrailColumns(numReadings);

    // const newTrailHeaders = Array.from(
    //   { length: numReadings },
    //   (_, index) => `Trail ${index + 1}`
    // );
    if (numReadings > trailHeaders.length) {
      const additionalHeaders = Array(numReadings - trailHeaders.length)
        .fill()
        .map((_, i) => `Trail ${trailHeaders.length + i + 1}`);
      setTrailHeaders((prevHeaders) => [...prevHeaders, ...additionalHeaders]);
    } else if (numReadings < trailHeaders.length) {
      const slicedHeaders = trailHeaders.slice(0, numReadings);
      setTrailHeaders(slicedHeaders);
    }
  };

  const [showUComponent, setShowUComponent] = useState(false);
  const handleCheckboxChange = () => {
    setShowUComponent(!showUComponent);
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const queryClient = useQueryClient();
  const handleSubmit = async () => {
    try {
      const payload = {
        variety: variety,
        hall: formValues.hallNo,
        counts: formValues.counts,
        denier: formValues.denier,
        autoConerNo: formValues.auto_coner_no,
        frameNo: formValues.frame_no,
        noOfTrails: formValues.no_of_trails,
        particular: formValues.particular,
        regular: formValues.regular_data,
        UParticular: formValues.U_particular,
        URegular: formValues.U_regular_data,
        feedback: formValues.feedback,
        slubCode: formValues.slub_code,
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          setVariety("");
          queryClient.invalidateQueries("get-variety");
          setFormValues({
            hallNo: "",
            counts: "",
            denier: "",
            auto_coner_no: "",
            frame_no: "",
            no_of_trails: 1,
            particular: [],
            regular_data: {},
            feedback: "",
            U_particular: [],
            U_regular_data: {},
            slub_code: "",
          });
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const handleTrailTotalCutsChange = (e, fieldName, id) => {
    const { value } = e.target;
    // const parsedValue = !isNaN(value) ? parseFloat(value) : "";
    const parsedValue = value === "" ? "" : parseFloat(value);
    if (parsedValue < 0) {
      alert("Please do not enter negative value");
      return;
    }
    setFormValues((prevFormValues) => {
      const updatedParticular = [...prevFormValues.particular];
      const trailIndex = parseInt(id.split("-").pop(), 10);

      const dataObject = updatedParticular.find(
        (obj) => `Trail ${trailIndex}` in obj
      );

      if (!isNaN(parsedValue)) {
        if (dataObject) {
          dataObject[`Trail ${trailIndex}`][fieldName] = parsedValue;
        } else {
          const newDataObject = {
            [`Trail ${trailIndex}`]: {
              [fieldName]: parsedValue,
            },
          };
          updatedParticular.push(newDataObject);
        }
      } else {
        if (dataObject && dataObject[`Trail ${trailIndex}`][fieldName]) {
          delete dataObject[`Trail ${trailIndex}`][fieldName];
          if (Object.keys(dataObject[`Trail ${trailIndex}`]).length === 0) {
            updatedParticular.splice(updatedParticular.indexOf(dataObject), 1);
          }
        }
      }

      return {
        ...prevFormValues,
        particular: updatedParticular,
      };
    });
  };

  const [trailHeaders, setTrailHeaders] = useState(["Trail 1"]);
  const [numTrailColumns, setNumTrailColumns] = useState(1);
  const table3Data = [];
  for (let i = 1; i <= selectedNumber; i++) {
    table3Data.push({
      col1: i,
      col2: (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Splice"
          value={"S"}
          disabled
        />
      ),
      col3: <TextField variant="outlined" fullWidth placeholder="Name" />,
      col4: <TextField variant="outlined" fullWidth placeholder="% of fault" />,
      col5: (
        <TextField variant="outlined" fullWidth placeholder="Length in cm" />
      ),
    });
  }

  const handleRegularChange = (e, field) => {
    const { name, value } = e.target;

    // let parsedValue = parseFloat(value);
    const parsedValue = value === "" ? "" : parseFloat(value);
    if (parsedValue < 0) {
      alert("Please do not enter negative value");
      return;
    }
    if (!isNaN(parsedValue)) {
      setFormValues((prevFormValues) => {
        const updatedURegularData = {
          ...prevFormValues.U_regular_data,
          [name]: parsedValue,
        };

        const nepsValue = parseFloat(updatedURegularData["neps+200"]) || 0;
        const thinValue = parseFloat(updatedURegularData["thin-50"]) || 0;
        const thickValue = parseFloat(updatedURegularData["thick+50"]) || 0;

        const totalValue = nepsValue + thinValue + thickValue;

        return {
          ...prevFormValues,
          U_regular_data: updatedURegularData,
          total: totalValue,
        };
      });
    }
  };

  const handleTrailChange = (e, fieldName, id) => {
    const { value } = e.target;
    const parsedValue = !isNaN(value) ? parseFloat(value) : "";
    //const parsedValue = value === "" ? "" : parseFloat(value);

    if (parsedValue < 0) {
      alert("Please do not enter negative value");

      return;
    }

    setFormValues((prevFormValues) => {
      const updatedParticular = [...prevFormValues.U_particular];
      const trailIndex = parseInt(id.split("-").pop(), 10);
      if (isNaN(parsedValue)) {
        updatedParticular.forEach((obj) => {
          if (
            `Trail ${trailIndex}` in obj &&
            obj[`Trail ${trailIndex}`][fieldName] !== undefined
          ) {
            delete obj[`Trail ${trailIndex}`][fieldName];
          }
        });
      } else {
        const dataObject = updatedParticular.find(
          (obj) => `Trail ${trailIndex}` in obj
        );

        if (dataObject) {
          dataObject[`Trail ${trailIndex}`][fieldName] = parsedValue;
          if (dataObject) {
            dataObject[`Trail ${trailIndex}`][fieldName] = parsedValue;
            const nepsFieldName = "neps+200";
            const thickFieldName = "thick+50";
            const thinFieldName = "thin-50";
            const nepsValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][nepsFieldName]) || 0;
            const thickValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][thickFieldName]) ||
              0;
            const thinValue =
              parseFloat(dataObject[`Trail ${trailIndex}`][thinFieldName]) || 0;

            let total = nepsValue + thickValue + thinValue;
            dataObject[`Trail ${trailIndex}`]["total"] = total;
          }
        } else {
          const newDataObject = {
            [`Trail ${trailIndex}`]: {
              [fieldName]: parsedValue,
            },
          };
          updatedParticular.push(newDataObject);
        }
      }

      return {
        ...prevFormValues,
        U_particular: updatedParticular,
      };
    });
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Trail</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={variety}
                          fullWidth
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setVariety(newValue || "");
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setVariety(newValue || "");
                            } else {
                              setVariety(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          styles={{ width: "100%" }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Variety"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="Counts"
                      placeholder="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      label="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Slub Code"
                      placeholder="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                      onInput={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      label="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Frame No</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="Frame No"
                      value={formValues.frame_no}
                      onChange={(e) => handleFormChange(e, "frame_no")}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of Trails</TableCell>
                  <TableCell>
                    <FormControl variant="outlined">
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Number Of Trails"
                        defaultValue={table2Data.numberOfReadings}
                        onChange={(e) =>
                          handleNumReadingsChange(
                            e.target.value,
                            "no_of_trails"
                          )
                        }
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>U%</TableCell>
                  <TableCell>
                    <FormControlLabel
                      variant="outlined"
                      control={
                        <Checkbox
                          checked={showUComponent}
                          onChange={handleCheckboxChange}
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item md={9} sm={11}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell>Regular</TableCell>
                  {trailHeaders.map((header, index) => (
                    <TableCell key={`trailHeader_${index}`}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total cuts</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id="outlined-number"
                      step="0.01"
                      type="number"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="total_cuts"
                      defaultValue={""}
                      value={
                        formValues &&
                        formValues.regular_data.total_cuts !== undefined &&
                        formValues.regular_data.total_cuts !== null
                          ? formValues.regular_data.total_cuts
                          : ""
                      }
                      onChange={(e) =>
                        handleTrailNepCutsChange(e, "total_cuts")
                      }
                    />
                  </TableCell>

                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;

                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .total_cuts !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .total_cuts !== null
                        ? formValues.particular[index][`Trail ${index + 1}`]
                            .total_cuts
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`outlined-number-trail-${trailIndex}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={`trail_${trailIndex + 1}_total_cuts`}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "total_cuts",
                              `standard-number-trail-${trailIndex}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Neps cuts </TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="neps_cuts"
                      value={
                        formValues &&
                        formValues.regular_data.neps_cuts !== undefined &&
                        formValues.regular_data.neps_cuts !== null
                          ? formValues.regular_data.neps_cuts
                          : ""
                      }
                      defaultValue={""}
                      onChange={(e) => handleTrailNepCutsChange(e, "neps_cuts")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .neps_cuts !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .neps_cuts !== null
                        ? formValues.particular[index][`Trail ${index + 1}`]
                            .neps_cuts
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "neps_cuts",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Short cuts</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="short_cuts"
                      value={
                        formValues &&
                        formValues.regular_data.short_cuts !== undefined &&
                        formValues.regular_data.short_cuts !== null
                          ? formValues.regular_data.short_cuts
                          : ""
                      }
                      onChange={(e) =>
                        handleTrailNepCutsChange(e, "short_cuts")
                      }
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .short_cuts !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .short_cuts !== null
                        ? formValues.particular[index][`Trail ${index + 1}`]
                            .short_cuts
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={`trail_${index + 2}_total_cuts`}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "short_cuts",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Long cuts</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="long_cuts"
                      value={
                        formValues &&
                        formValues.regular_data.long_cuts !== undefined &&
                        formValues.regular_data.long_cuts !== null
                          ? formValues.regular_data.long_cuts
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "long_cuts")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .long_cuts !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .long_cuts !== null
                        ? formValues.particular[index][`Trail ${index + 1}`]
                            .long_cuts
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={`trail_${index + 2}_total_cuts`}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "long_cuts",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Thin cuts</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thin_cuts"
                      value={
                        formValues &&
                        formValues.regular_data.thin_cuts !== undefined &&
                        formValues.regular_data.thin_cuts !== null
                          ? formValues.regular_data.thin_cuts
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "thin_cuts")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .thin_cuts !== undefined &&
                      formValues.particular[index][`Trail ${index + 1}`]
                        .thin_cuts !== null
                        ? formValues.particular[index][`Trail ${index + 1}`]
                            .thin_cuts
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "thin_cuts",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cp"
                      value={
                        formValues &&
                        formValues.regular_data.cp !== undefined &&
                        formValues.regular_data.cp !== null
                          ? formValues.regular_data.cp
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "cp")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].cp !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].cp !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].cp
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={`trail_${index + 2}_total_cuts`}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "cp",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CM</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cm"
                      value={
                        formValues &&
                        formValues.regular_data.cm !== undefined &&
                        formValues.regular_data.cm !== null
                          ? formValues.regular_data.cm
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "cm")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].cm !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].cm !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].cm
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "cm",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>CCP</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ccp"
                      value={
                        formValues &&
                        formValues.regular_data.ccp !== undefined &&
                        formValues.regular_data.ccp !== null
                          ? formValues.regular_data.ccp
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "ccp")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].ccp !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].ccp !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].ccp
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name={`trail_${index + 2}_total_cuts`}
                          value={trailValue}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "ccp",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>CCM</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ccm"
                      value={
                        formValues &&
                        formValues.regular_data.ccm !== undefined &&
                        formValues.regular_data.ccm !== null
                          ? formValues.regular_data.ccm
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "ccm")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].ccm !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].ccm !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].ccm
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "ccm",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>JP</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="jp"
                      value={
                        formValues &&
                        formValues.regular_data.jp !== undefined &&
                        formValues.regular_data.jp !== null
                          ? formValues.regular_data.jp
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "jp")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].jp !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].jp !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].jp
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "jp",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>A1</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a1"
                      value={
                        formValues &&
                        formValues.regular_data.a1 !== undefined &&
                        formValues.regular_data.a1 !== null
                          ? formValues.regular_data.a1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "a1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].a1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].a1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].a1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "a1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>A2</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      value={
                        formValues &&
                        formValues.regular_data.a2 !== undefined &&
                        formValues.regular_data.a2 !== null
                          ? formValues.regular_data.a2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "a2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].a2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].a2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].a2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "a2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>A3</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number-a3"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a3"
                      value={
                        formValues &&
                        formValues.regular_data.a3 !== undefined &&
                        formValues.regular_data.a3 !== null
                          ? formValues.regular_data.a3
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "a3")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].a3 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].a3 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].a3
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}_a3`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts_a3`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "a3",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>A4</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number-a4"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a4"
                      value={
                        formValues &&
                        formValues.regular_data.a4 !== undefined &&
                        formValues.regular_data.a4 !== null
                          ? formValues.regular_data.a4
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "a4")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].a4 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].a4 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].a4
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "a4",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>B1</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="b1"
                      value={
                        formValues &&
                        formValues.regular_data.b1 !== undefined &&
                        formValues.regular_data.b1 !== null
                          ? formValues.regular_data.b1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "b1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].b1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].b1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].b1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "b1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>B2</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="b2"
                      value={
                        formValues &&
                        formValues.regular_data.b2 !== undefined &&
                        formValues.regular_data.b2 !== null
                          ? formValues.regular_data.b2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "b2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].b2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].b2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].b2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "b2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>B3</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="b3"
                      value={
                        formValues &&
                        formValues.regular_data.b3 !== undefined &&
                        formValues.regular_data.b3 !== null
                          ? formValues.regular_data.b3
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "b3")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].b3 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].b3 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].b3
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "b3",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>B4</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="b4"
                      value={
                        formValues &&
                        formValues.regular_data.b4 !== undefined &&
                        formValues.regular_data.b4 !== null
                          ? formValues.regular_data.b4
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "b4")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].b4 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].b4 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].b4
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "b4",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>C1</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="c1"
                      // value={(formValues && formValues.regular_data.c1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.c1 !== undefined &&
                        formValues.regular_data.c1 !== null
                          ? formValues.regular_data.c1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "c1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].c1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].c1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].c1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "c1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>C2</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="c2"
                      // value={(formValues && formValues.regular_data.c2) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.c2 !== undefined &&
                        formValues.regular_data.c2 !== null
                          ? formValues.regular_data.c2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "c2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].c2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].c2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].c2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "c2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>C3</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="c3"
                      value={
                        formValues &&
                        formValues.regular_data.c3 !== undefined &&
                        formValues.regular_data.c3 !== null
                          ? formValues.regular_data.c3
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "c3")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].c3 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].c3 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].c3
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "c3",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>C4</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="c4"
                      // value={(formValues && formValues.regular_data.c4) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.c4 !== undefined &&
                        formValues.regular_data.c4 !== null
                          ? formValues.regular_data.c4
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "c4")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].c4 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].c4 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].c4
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "c4",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>D1</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="d1"
                      // value={(formValues && formValues.regular_data.d1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.d1 !== undefined &&
                        formValues.regular_data.d1 !== null
                          ? formValues.regular_data.d1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "d1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].d1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].d1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].d1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`}
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "d1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>D2</TableCell>

                  <TableCell>
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="d2"
                      //  value={(formValues && formValues.regular_data.d2) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.d2 !== undefined &&
                        formValues.regular_data.d2 !== null
                          ? formValues.regular_data.d2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "d2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].d2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].d2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].d2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "d2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>D3</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="d3"
                      //  value={(formValues && formValues.regular_data.d3) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.d3 !== undefined &&
                        formValues.regular_data.d3 !== null
                          ? formValues.regular_data.d3
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "d3")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].d3 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].d3 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].d3
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "d3",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>D4</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="d4"
                      // value={(formValues && formValues.regular_data.d4) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.d4 !== undefined &&
                        formValues.regular_data.d4 !== null
                          ? formValues.regular_data.d4
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "d4")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].d4 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].d4 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].d4
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "d4",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>E1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="e1"
                      //   value={(formValues && formValues.regular_data.e1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.e1 !== undefined &&
                        formValues.regular_data.e1 !== null
                          ? formValues.regular_data.e1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "e1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].e1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].e1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].e1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "e1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>F1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="f1"
                      //  value={(formValues && formValues.regular_data.f1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.f1 !== undefined &&
                        formValues.regular_data.f1 !== null
                          ? formValues.regular_data.f1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "f1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].f1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].f1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].f1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "f1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>G1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="g1"
                      // value={(formValues && formValues.regular_data.g1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.g1 !== undefined &&
                        formValues.regular_data.g1 !== null
                          ? formValues.regular_data.g1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "g1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].g1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].g1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].g1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "g1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>H1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="h1"
                      // value={(formValues && formValues.regular_data.h1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.h1 !== undefined &&
                        formValues.regular_data.h1 !== null
                          ? formValues.regular_data.h1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "h1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].h1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].h1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].h1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "h1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>{" "}
                <TableRow>
                  <TableCell>H2</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="h2"
                      // value={(formValues && formValues.regular_data.h2) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.h2 !== undefined &&
                        formValues.regular_data.h2 !== null
                          ? formValues.regular_data.h2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "h2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].h2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].h2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].h2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "h2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>I1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="i1"
                      // value={(formValues && formValues.regular_data.i1) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.i1 !== undefined &&
                        formValues.regular_data.i1 !== null
                          ? formValues.regular_data.i1
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "i1")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].i1 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].i1 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].i1
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "i1",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>I2</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="i2"
                      // value={(formValues && formValues.regular_data.i2) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.i2 !== undefined &&
                        formValues.regular_data.i2 !== null
                          ? formValues.regular_data.i2
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "i2")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].i2 !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].i2 !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].i2
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "i2",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>CVP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cvp"
                      // value={(formValues && formValues.regular_data.cvp) || ""}
                      value={
                        formValues &&
                        formValues.regular_data.cvp !== undefined &&
                        formValues.regular_data.cvp !== null
                          ? formValues.regular_data.cvp
                          : ""
                      }
                      onChange={(e) => handleTrailNepCutsChange(e, "cvp")}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, index) => {
                    const trailIndex = index + 1;
                    const trailValue =
                      formValues &&
                      formValues.particular &&
                      formValues.particular[index] &&
                      formValues.particular[index][`Trail ${index + 1}`] &&
                      formValues.particular[index][`Trail ${index + 1}`].cvp !==
                        undefined &&
                      formValues.particular[index][`Trail ${index + 1}`].cvp !==
                        null
                        ? formValues.particular[index][`Trail ${index + 1}`].cvp
                        : "";

                    return (
                      <TableCell key={`trail_${trailIndex}`}>
                        <TextField
                          size="small"
                          id={`standard-number-trail-${index + 1}`}
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={trailValue}
                          name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                          onChange={(e) =>
                            handleTrailTotalCutsChange(
                              e,
                              "cvp",
                              `standard-number-trail-${index + 1}`
                            )
                          }
                          // value={row.trail_values[index]}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
                {/* Add more rows as needed */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={3}>
          <Grid mt={3} item xs={10} sm={10}></Grid>
        </Grid>
      </Grid>

      <div>
        {showUComponent && (
          <Grid mt={2} container spacing={2}>
            <Grid item xs={9}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Particulars</TableCell>
                      <TableCell>Regular</TableCell>
                      {trailHeaders.map((header, index) => (
                        <TableCell key={`trailHeader_${index}`}>
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>U %</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="u%"
                          value={
                            formValues &&
                            formValues.U_regular_data["u%"] !== undefined &&
                            formValues.U_regular_data["u%"] !== null
                              ? formValues.U_regular_data["u%"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "u%")}
                          //   value={row.liker_in_speed}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "u%"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "u%"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["u%"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "u%",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>CVM </TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="cvm"
                          value={
                            formValues &&
                            formValues.U_regular_data.cvm !== undefined &&
                            formValues.U_regular_data.cvm !== null
                              ? formValues.U_regular_data.cvm
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "cvm")}
                          //   value={row.liker_in_speed}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`]
                            .cvm !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`]
                            .cvm !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ].cvm
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "cvm",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>CV %</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="cv%"
                          value={
                            formValues &&
                            formValues.U_regular_data["cv%"] !== undefined &&
                            formValues.U_regular_data["cv%"] !== null
                              ? formValues.U_regular_data["cv%"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "cv%")}
                          //   value={row.liker_in_speed}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "cv%"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "cv%"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["cv%"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "cv%",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>CVM 10</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="cvm10"
                          value={
                            formValues &&
                            formValues.U_regular_data.cvm10 !== undefined &&
                            formValues.U_regular_data.cvm10 !== null
                              ? formValues.U_regular_data.cvm10
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "cvm10")}
                          //   value={row.flats_speed}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`]
                            .cvm10 !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`]
                            .cvm10 !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ].cvm10
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "cvm10",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Thin-30</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thin-30"
                          value={
                            formValues &&
                            formValues.U_regular_data["thin-30"] !==
                              undefined &&
                            formValues.U_regular_data["thin-30"] !== null
                              ? formValues.U_regular_data["thin-30"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thin-30")}
                          //   value={row.delivery_speed}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-30"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-30"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thin-30"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thin-30",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Thin -40</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thin-40"
                          value={
                            formValues &&
                            formValues.U_regular_data["thin-40"] !==
                              undefined &&
                            formValues.U_regular_data["thin-40"] !== null
                              ? formValues.U_regular_data["thin-40"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thin-40")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-40"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-40"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thin-40"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thin-40",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Thin -50</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thin-50"
                          value={
                            formValues &&
                            formValues.U_regular_data["thin-50"] !==
                              undefined &&
                            formValues.U_regular_data["thin-50"] !== null
                              ? formValues.U_regular_data["thin-50"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thin-50")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-50"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thin-50"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thin-50"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thin-50",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>{" "}
                    <TableRow>
                      <TableCell>Thick +35</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thick+35"
                          value={
                            formValues &&
                            formValues.U_regular_data["thick+35"] !==
                              undefined &&
                            formValues.U_regular_data["thick+35"] !== null
                              ? formValues.U_regular_data["thick+35"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thick+35")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+35"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+35"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thick+35"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thick+35",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>{" "}
                    <TableRow>
                      <TableCell>Thick +50</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thick+50"
                          value={
                            formValues &&
                            formValues.U_regular_data["thick+50"] !==
                              undefined &&
                            formValues.U_regular_data["thick+50"] !== null
                              ? formValues.U_regular_data["thick+50"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thick+50")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+50"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+50"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thick+50"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thick+50",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>{" "}
                    <TableRow>
                      <TableCell>Thick +70</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thick+70"
                          value={
                            formValues &&
                            formValues.U_regular_data["thick+70"] !==
                              undefined &&
                            formValues.U_regular_data["thick+70"] !== null
                              ? formValues.U_regular_data["thick+70"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thick+70")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+70"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+70"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thick+70"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thick+70",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>{" "}
                    <TableRow>
                      <TableCell>Thick +100</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="thick+100"
                          value={
                            formValues &&
                            formValues.U_regular_data["thick+100"] !==
                              undefined &&
                            formValues.U_regular_data["thick+100"] !== null
                              ? formValues.U_regular_data["thick+100"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "thick+100")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+100"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "thick+100"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["thick+100"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "thick+100",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>NEPS +140</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="neps+140"
                          value={
                            formValues &&
                            formValues.U_regular_data["neps+140"] !==
                              undefined &&
                            formValues.U_regular_data["neps+140"] !== null
                              ? formValues.U_regular_data["neps+140"]
                              : ""
                          }
                          onChange={(e) => handleRegularChange(e, "neps+140")}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "neps+140"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "neps+140"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["neps+140"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "neps+140",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>NEPS +200</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="neps+200"
                          value={
                            formValues &&
                            formValues.U_regular_data["neps+200"] !==
                              undefined &&
                            formValues.U_regular_data["neps+200"] !== null
                              ? formValues.U_regular_data["neps+200"]
                              : ""
                          }
                          onChange={handleRegularChange}
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1;

                        const trailValue =
                          formValues &&
                          formValues.U_particular &&
                          formValues.U_particular[index] &&
                          formValues.U_particular[index][
                            `Trail ${index + 1}`
                          ] &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "neps+200"
                          ] !== undefined &&
                          formValues.U_particular[index][`Trail ${index + 1}`][
                            "neps+200"
                          ] !== null
                            ? formValues.U_particular[index][
                                `Trail ${index + 1}`
                              ]["neps+200"]
                            : "";
                        return (
                          <TableCell key={`trail_${trailIndex}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={trailValue}
                              name={`trail_${index + 2}_total_cuts`} // Start from 2 for "Trail 2"
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "neps+200",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                              // value={row.trail_values[index]}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>Total</TableCell>

                      <TableCell>
                        {/* Example input for each column */}
                        <TextField
                          size="small"
                          id="standard-number"
                          type="number"
                          step="0.01"
                          variant="standard"
                          style={{ width: "50px" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={formValues.total || ""}
                          name="total"
                          onChange={handleFormChange}
                          disabled
                        />
                      </TableCell>
                      {[...Array(numTrailColumns)].map((_, index) => {
                        const trailIndex = index + 1; // Increment index by 1 as it starts from 0

                        // Get the total value from the specific Trail index in U_particular
                        const totalValue =
                          (formValues &&
                            formValues.U_particular &&
                            formValues.U_particular[index] &&
                            formValues.U_particular[index][
                              `Trail ${trailIndex}`
                            ] &&
                            formValues.U_particular[index][
                              `Trail ${trailIndex}`
                            ].total) ||
                          "";

                        return (
                          <TableCell key={`trail_${index + 1}`}>
                            <TextField
                              size="small"
                              id={`standard-number-trail-${index + 1}`}
                              type="number"
                              step="0.01"
                              variant="standard"
                              style={{ width: "50px" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                              value={totalValue}
                              name={`trail_${index + 2}_total_cuts`}
                              onChange={(e) =>
                                handleTrailChange(
                                  e,
                                  "neps+200",
                                  `standard-number-trail-${index + 1}`
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {/* <Grid mb={5} mt={3} item xs={10} sm={10} > */}
                        <TextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          placeholder="Total"
                          value={formValues.total || ""}
                          onChange={(e) => handleFormChange(e, "total")}
                          disabled
                        />
                      </TableCell>
                      {/* </Grid> */}
                    </TableRow>
                    {/* Add more rows as needed */}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableRow>
                <TableCell>Counts</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    placeholder="Counts"
                    label="Counts"
                    onChange={(e) => handleFormChange(e, "total")}
                  />
                </TableCell>

                <TableCell>Strength</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    placeholder="Strength"
                    label="Strength"
                    onChange={(e) => handleFormChange(e, "total")}
                  />
                </TableCell>

                <TableCell>CSP Value</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    variant="outlined"
                    placeholder="CSP Value"
                    label="CSP Value"
                    onChange={(e) => handleFormChange(e, "total")}
                  />
                </TableCell>
              </TableRow>
            </Grid>
          </Grid>
        )}
      </div>

      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          name="feedback"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
          onChange={(e) => handleFormChange(e, "feedback")}
        />
      </Grid>
      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Trail;
