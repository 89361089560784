import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { LNCheckingsCreate } from "./mutation";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";
import "./incheckingcss.css";

const LNChecking = () => {
  const filter = createFilterOptions();
  const [variety, setVariety] = useState("");
  const [formValues, setFormValues] = useState({
    counts: "",
    denier: "",
    hallNo: "",
    auto_coner_no: "",
    drumNo: "",
    drumFrom: "",
    drumTo: "",
    ln_value: "",
    feedback: "",
    slub_code: "",
    is_out_of_norms: false,
  });

  const { mutate, isLoading } = useMutation(LNCheckingsCreate);

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "denier" || field === "feedback" || field === "slub_code") {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "auto_coner_no",
        "hallNo",
        "drumNo",
        "ln_value",
        "drumNo",
      ].includes(field)
    ) {
      const changedValue = (value = !isNaN(value) ? parseFloat(value) : value);
      if (changedValue < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = changedValue;
      }
    }
    setFormValues({ ...formValues, [field]: value });
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      const payload = {
        variety: variety,
        counts: formValues.counts,
        denier: formValues.denier,
        hall: formValues.hallNo,
        autoConerNo: formValues.auto_coner_no,
        drumNo: `${formValues.drumFrom} - ${formValues.drumTo}`,
        lnValue: formValues.ln_value,
        // isOutOfNorms: formValues.is_out_of_norms,
        feedback: formValues.feedback,
        slubCode: formValues.slub_code,
        machineName: `${formValues.counts}'S, ${variety}, ${formValues.denier}, ${formValues.slub_code}`,
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          setVariety("");
          queryClient.invalidateQueries("get-variety");
          setFormValues({
            counts: "",
            denier: "",
            auto_coner_no: "",
            drumNo: "",
            hallNo: "",
            ln_value: "",
            is_out_of_norms: false,
            feedback: "",
            slub_code: "",
          });
        },
        onError: (error) => {
          handleToastMsg("Data Error Occured", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">LN Checking</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={variety}
                          fullWidth
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setVariety(newValue || "");
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setVariety(newValue || "");
                            } else {
                              setVariety(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          styles={{ width: "93%" }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Variety"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      label="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Slub Code"
                      label="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      label="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drum No</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum From"
                          fullWidth
                          label="Drum From"
                          value={formValues.drumFrom || ""}
                          onChange={(e) => handleFormChange(e, "drumFrom")}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum To"
                          fullWidth
                          label="Drum To"
                          value={formValues.drumTo || ""}
                          onChange={(e) => handleFormChange(e, "drumTo")}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>LN value</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="LN Value"
                      label="LN Value"
                      value={formValues.ln_value}
                      onChange={(e) => handleFormChange(e, "ln_value")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          name="Size"
          label="Feedback (or) Any Queries"
          onChange={(e) => handleFormChange(e, "feedback")}
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LNChecking;
