import apiClient from "../../../utils/clients/axios";

export const LNCheckings = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/ln-checking-get/`,
    payload
  );
  return response;
};

export const LNCheckingsCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/ln-checking-create/`,
    payload
  );
  return response;
};
