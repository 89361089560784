import apiClient from "../../../utils/clients/axios";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

// // Get all records from the general master
// export const getAllSubmittedData = async () => {
//   const response = await apiClient.get(`/api/quality_control/general_master`);
//   return response;
// };

// Get all records from the general master
export const getAllSubmittedData = async (masterKey) => {
  const flow = masterKey + "_master";
  const response = await apiClient.get(`/api/quality_control/${flow}`);
  return response;
};

// export const getAllDraftsData = async () => {
//   const response = await apiClient.get(
//     `/api/quality_control/general_master/getAllGeneralMastersDraft`
//   );
//   return response;
// };

// Get Drafts records from the general master
export const getAllDraftsData = async (masterKey) => {
  const flow = masterKey + "_master";
  const endPoint = "getAll" + capitalizeFirstLetter(masterKey) + "MastersDraft";
  const response = await apiClient.get(
    `/api/quality_control/${flow}/${endPoint}`
  );
  return response;
};

// // Delete a specific record from the general master by ID
// export const deleteMasterRecord = async (id) => {
//   const response = await apiClient.delete(
//     `/api/quality_control/general_master/${id}`
//   );
//   return response;
// };

// Delete a specific record from a dynamic master by ID
export const deleteMasterRecord = async (masterKey, id) => {
  const flow = masterKey + "_master";
  const response = await apiClient.delete(`/api/quality_control/${flow}/${id}`);
  return response;
};

// // Delete a specific record from the general master by ID
// export const deleteDraftMasterRecord = async (id) => {
//   const response = await apiClient.delete(
//     `/api/quality_control/general_master/deleteGeneralMasterDraft/${id}`
//   );
//   return response;
// };

// Delete a specific record from the general master by ID
export const deleteDraftMasterRecord = async (masterKey, id) => {
  const flow = masterKey + "_master";
  const endPoint = "delete" + capitalizeFirstLetter(masterKey) + "MasterDraft";

  const response = await apiClient.delete(
    `/api/quality_control/${flow}/${endPoint}/${id}`
  );
  return response;
};
