import apiClient from "../../../utils/clients/axios";

// export const getLabFeedWaste = async (date) => {
//   const { data } = await apiClient.get(
//     `/blow_room/lap_feed_15_24_get/?date=${date}`
//   );

//   return data;
// };

export const getVariety = async (date) => {
  const { data } = await apiClient.get(
    `/api/quality_control/blow_room/variety_master_data/`
  );

  return data;
};

export const getChuteFeedWaste = async (date) => {
  const { data } = await apiClient.get(
    `/blow_room/chute_feed_7_14_get/?date=${date}`
  );

  return data;
};

export const getBlowRoom = async (date) => {
  const { data } = await apiClient.get(
    `/blow_room/blow_room/drop_test_get/?date=${date}`
  );

  return data;
};

// export const lapFeed1524Create = async (payload) => {
//   const repsponse = await apiClient.post(
//     "api/quality_control/blow_room/lap_feed_15_24_create/",
//     payload
//   );

//   return repsponse;
// };

export const chuteFeed714Create = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/blow_room/chute_feed_7_14_create/",
    payload
  );

  return response;
};

export const dropTest = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/blow_room/drop_test_create/",
    payload
  );

  return response;
};

export const chuteFeed15Create = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/blow_room/chute_feed_1_5_create/",
    payload
  );

  return response;
};
