import { Button, styled } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)`
  :disabled {
    color: #fff;
    background: #000;
    cursor: none;
  }
  background: #1a1998;
`;

function CustomButton({
  onClick,
  children,
  type = "",
  disabled,
  size = "large",
  style = {},
}) {
  return (
    <StyledButton
      variant="contained"
      size={size}
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
}

export default CustomButton;
