import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Checkbox,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TableBody,
  TableHead,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../Blowroom/mutation";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";

function StructureLapReports() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    variety: [],
    machineNo: "",
    cardingMachineNoEnabled: false,
    cardingMachineNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: null,
  });

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]); // State to store the fetched data

  const handlePreviewData = async (event) => {
    event.preventDefault();
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    const varieties = formData?.variety?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/lap/reports?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true, type: "STRUCTURE" }
              : {
                  type: "STRUCTURE",
                  machineNo: formData?.machineNo,
                  variety: varieties,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }
      setPreviewData(response.data);
      setOpenPreview(true);
    } catch (error) {
      handleClosePreview();
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewData([]);
  };

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      let headers = new Set();
      let dataRows = [];

      const flattenObject = (obj, parentKey = "") => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(acc, flattenObject(value, newKey));
          } else {
            acc[newKey] = value;
          }
          return acc;
        }, {});
      };

      previewData?.forEach((item) => {
        let row = {};
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((obj, index) => {
              const flatObj = flattenObject(obj, `${key}_${index + 1}`);
              Object.entries(flatObj).forEach(([subKey, subValue]) => {
                headers.add(subKey);
                row[subKey] = subValue;
              });
            });
          } else if (typeof value === "object" && value !== null) {
            const flatObj = flattenObject(value, key);
            Object.entries(flatObj).forEach(([subKey, subValue]) => {
              headers.add(subKey);
              row[subKey] = subValue;
            });
          } else {
            headers.add(key);
            row[key] =
              key === "created_at" ? dayjs(value).format("DD-MM-YYYY") : value;
          }
        });
        dataRows.push(row);
      });

      headers = Array.from(headers);

      const excludeHeaders = ["id", "updated_at", "employeeId"];
      headers = headers.filter((header) => !excludeHeaders.includes(header));

      //this is for show the 'date' key in ui instaed of created_at
      headers = headers.map((header) => {
        const newHeaders = header === "created_at" ? "date" : header;
        return newHeaders;
      });

      // Move "date" to the 0th position if it exists in the array
      const dateIndex = headers.indexOf("date");

      if (dateIndex > -1) {
        // Remove "date" from its current position
        const [dateHeader] = headers.splice(dateIndex, 1);
        // Insert "date" at the 0th position
        headers.unshift(dateHeader);
      }

      const worksheetData = [headers];

      dataRows.forEach((row) => {
        const rowData = headers.map(
          //this change also for show the 'date' key in ui instaed of created_at
          (header) => row[header === "date" ? "created_at" : header] || ""
        );
        worksheetData.push(rowData);
      });
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "STRUCTURE-LAP-REPORTS.xlsx");
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occured", "error");
    } finally {
      handleClosePreview();
      setLoading(false);
    }
  };

  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [field]: value === "" ? "" : value,
    }));
  };

  return (
    <ContainerWrapper container rowGap={2} height={0}>
      <Grid item xs={12}>
        <h2>Structure Lap Report</h2>
      </Grid>
      <Grid item container justifyContent={"space-between"} xs={12} md={8}>
        <Grid item>
          <RadioGroup
            name="reportType"
            value={formData.reportType}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="individual"
              control={<Radio />}
              label="Individual Report"
            />
            <FormControlLabel
              value="consolidated"
              control={<Radio />}
              label="Consolidated Report"
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid item container mb={3}>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date from"
                  value={formData.from_date}
                  onChange={(value) => handleDateChange("from_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date to"
                  value={formData.to_date}
                  onChange={(value) => handleDateChange("to_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} alignItems={"center"}>
        <TableContainer component={Paper} style={{ width: "100%" }}>
          <Table>
            <TableRow>
              <TableCell>Variety</TableCell>
              <TableCell style={{ width: 500 }}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    multiple
                    value={formData?.variety}
                    disabled={formData.reportType === "consolidated"}
                    renderValue={(selected) => selected.join(", ")}
                    onChange={(e) => handleFormChange(e, "variety")}
                  >
                    {varietyData?.map((variety) => (
                      <MenuItem key={variety} value={variety}>
                        <Checkbox
                          checked={formData?.variety?.indexOf(variety) > -1}
                        />
                        <ListItemText primary={variety} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>MachineNo</TableCell>
              <TableCell style={{ width: 500 }}>
                <TextField
                  fullWidth
                  value={formData?.machineNo}
                  label="MachineNo"
                  type="number"
                  disabled={formData.reportType === "consolidated"}
                  onChange={(e) => handleFormChange(e, "machineNo")}
                />
              </TableCell>
            </TableRow>
          </Table>{" "}
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
        <CustomButton
          style={{ width: "150px", height: "50px" }}
          type="submit"
          onClick={handlePreviewData}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size={32} />
          ) : (
            "Preview"
          )}
        </CustomButton>
      </Grid>
      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          style={{
            backgroundColor: "#f2f0f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mb={2}
        >
          <b>Preview</b>
          {/* Close Icon */}
          <IconButton onClick={handleClosePreview} sx={{ color: "#000" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                    <TableCell>
                      <b>DATE</b>
                    </TableCell>
                    <TableCell>
                      <b>HALL</b>
                    </TableCell>
                    <TableCell>
                      <b>READINGS</b>
                    </TableCell>
                    <TableCell>
                      <b>CV</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {dayjs(item.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell>{item.hall}</TableCell>
                      <TableCell>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="materialDifference">
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                {/* Render the TableCell only if item.material1Difference has data */}
                                <TableCell>
                                  <b>SAMPLE NO.</b>
                                </TableCell>

                                <TableCell>
                                  <b>GRAM METER</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.readingsValues.map((data) => (
                                <TableRow>
                                  <TableCell>{data.sampleNo}</TableCell>

                                  <TableCell>{data.gram_meter}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TableCell>

                      <TableCell>{item.cv}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No data available to preview</p>
          )}
          <Grid item xs={12} container justifyContent={"flex-end"} mt={3}>
            <CustomButton
              style={{ width: "150px", height: "40px" }}
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={32} />
              ) : (
                "Generate"
              )}
            </CustomButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </ContainerWrapper>
  );
}

export default StructureLapReports;
