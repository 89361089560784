import React, { useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  FormGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  TableHead,
  TableBody,
  DialogActions,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-moment";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

function HarinessReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    variety: [],
    cardingMachineNoEnabled: false,
    cardingMachineNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: null,
    lhs: false,
    rhs: false,
  });

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const [cardingMachineNoOptions] = useState(
    Array.from({ length: 24 }, (_, i) => i + 1)
  );

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
    // setForm({ ...form, [field]: value });
  };
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //handle for chckbox
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  //handle submit
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formatDate = (date, endOfDay = false) => {
  //     const adjustedDate = new Date(
  //       date.getTime() - date.getTimezoneOffset() * 60000
  //     );
  //     const formattedDate = adjustedDate.toISOString().split("T")[0];
  //     if (endOfDay) {
  //       return formattedDate + "T23:59:59";
  //     } else {
  //       return formattedDate + "T00:00:00";
  //     }
  //   };

  //   const formattedFromDate = formData.from_date
  //     ? formatDate(new Date(formData.from_date))
  //     : "";

  //   const formattedToDate = formData.to_date
  //     ? formatDate(new Date(formData.to_date), true)
  //     : "";

  //   //this is for send the data as a single element from the array
  //   const varieties = formData?.variety?.join(",") || "";
  //   const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

  //   try {
  //     const response = await apiClient.get(
  //       `api/quality_control/spinning/hairnesses_report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
  //       {
  //         params: {
  //           ...(formData?.reportType === "consolidated"
  //             ? { consolidated: true }
  //             : {
  //                 machineNo: cardingMacinesNo,
  //                 variety: varieties,
  //                 lhs: formData?.lhs,
  //                 rhs: formData?.rhs,
  //               }),
  //         },
  //       }
  //     );

  //     let headers = new Set();
  //     let dataRows = [];

  //     const flattenObject = (obj, parentKey = "") => {
  //       return Object.entries(obj).reduce((acc, [key, value]) => {
  //         const newKey = parentKey ? `${parentKey}_${key}` : key;
  //         if (
  //           typeof value === "object" &&
  //           value !== null &&
  //           !Array.isArray(value)
  //         ) {
  //           Object.assign(acc, flattenObject(value, newKey));
  //         } else {
  //           acc[newKey] = value;
  //         }
  //         return acc;
  //       }, {});
  //     };

  //     response.data.forEach((item) => {
  //       let row = {};
  //       Object.entries(item).forEach(([key, value]) => {
  //         if (Array.isArray(value)) {
  //           value.forEach((obj, index) => {
  //             const flatObj = flattenObject(obj, `${key}_${index + 1}`);
  //             Object.entries(flatObj).forEach(([subKey, subValue]) => {
  //               headers.add(subKey);
  //               row[subKey] = subValue;
  //             });
  //           });
  //         } else if (typeof value === "object" && value !== null) {
  //           const flatObj = flattenObject(value, key);
  //           Object.entries(flatObj).forEach(([subKey, subValue]) => {
  //             headers.add(subKey);
  //             row[subKey] = subValue;
  //           });
  //         } else {
  //           headers.add(key);
  //           row[key] = value;
  //         }
  //       });
  //       dataRows.push(row);
  //     });

  //     headers = Array.from(headers);

  //     const priorityHeaders = ["feedback", "created_at", "updated_at"];
  //     headers = headers
  //       .filter((header) => !priorityHeaders.includes(header))
  //       .concat(priorityHeaders);

  //     const worksheetData = [headers];

  //     dataRows.forEach((row) => {
  //       const rowData = headers.map((header) => row[header] || "");
  //       worksheetData.push(rowData);
  //     });

  //     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //     XLSX.writeFile(workbook, "hariness_report.xlsx");
  //     handleToastMsg("Reports downloaded successfully", "success");
  //   } catch (error) {
  //     handleToastMsg("Data Error Occured", "error");
  //   }
  // };

  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]); // State to store the fetched data

  const handlePreviewData = async (event) => {
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    //this is for send the data as a single element from the array
    const varieties = formData?.variety?.join(",") || "";
    const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/spinning/hairnesses_report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  machineNo: cardingMacinesNo,
                  variety: varieties,
                  lhs: formData?.lhs,
                  rhs: formData?.rhs,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      setPreviewData(response.data);
      setOpenPreview(true);
    } catch (error) {
      handleClosePreview();
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewData([]);
  };

  //handle submit

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const workbook = XLSX.utils.book_new();
      const worksheetData = [];

      if (formData.reportType === "individual") {
        previewData?.forEach((item) => {
          if (item.createdAt)
            worksheetData.push([
              "Date",
              dayjs(item.createdAt).format("DD-MM-YYYY"),
            ]);
          if (item.machine_number)
            worksheetData.push(["Machine No", item.machine_number]);
          if (item.variety) worksheetData.push(["Variety", item.variety]);
          if (item.lhs) worksheetData.push(["LHS", item.lhs]);
          if (item.rhs) worksheetData.push(["RHS", item.rhs]);
          if (item.remarks) worksheetData.push(["Remarks", item.remarks]);
          worksheetData.push([]);
        });
      } else if (formData.reportType === "consolidated") {
        previewData?.forEach((item) => {
          worksheetData.push([
            "Date",
            dayjs(item.createdAt).format("DD-MM-YYYY"),
          ]);
          worksheetData.push(["Total LHS", item.total_lhs]);
          worksheetData.push(["Total RHS", item.total_rhs]);
          worksheetData.push(["Hall", item.hall]);
          worksheetData.push(["Feedback", item.feedback]);
          worksheetData.push([]);

          worksheetData.push(["Hariness error records"]);
          worksheetData.push([
            "No of errors",
            "Corrected",
            "Remarks",
            "Feedback",
          ]);
          item?.HairnessErrors?.forEach((err) => {
            worksheetData.push([
              err.number_of_errors,
              err.corrected,
              err.remarks,
              err.feedback,
            ]);
          });
          worksheetData.push([]);

          worksheetData.push(["Hariness records"]);
          worksheetData.push([
            "Machine Number",
            "Variety",
            "LHS",
            "RHS",
            "Remarks",
          ]);
          item?.HairnessRecords?.forEach((sample) => {
            worksheetData.push([
              sample.machine_number,
              sample.variety,
              sample.lhs,
              sample.rhs,
              sample.remarks,
            ]);
          });
          worksheetData.push([]);
          worksheetData.push([]);
        });
      }

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "HARINESS-REPORTS.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occurred", "error");
    } finally {
      setLoading(false);
      handleClosePreview();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper container rowGap={2} height={0}>
        <Grid item xs={12}>
          <h2>Hariness Report</h2>
        </Grid>
        <Grid item container justifyContent={"space-between"} xs={12} md={8}>
          <Grid item>
            <RadioGroup
              name="reportType"
              value={formData.reportType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label="Individual Report"
              />
              <FormControlLabel
                value="consolidated"
                control={<Radio />}
                label="Consolidated Report"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} alignItems={"center"}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell>Variety</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.variety}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(e) => handleFormChange(e, "variety")}
                    >
                      {varietyData?.map((variety) => (
                        <MenuItem key={variety} value={variety}>
                          <Checkbox
                            checked={formData?.variety?.indexOf(variety) > -1}
                          />
                          <ListItemText primary={variety} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carding Machine No</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.cardingMachineNo}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handleInputChange(
                          "cardingMachineNo",
                          event.target.value
                        )
                      }
                    >
                      {cardingMachineNoOptions.map((machineNo) => (
                        <MenuItem key={machineNo} value={machineNo}>
                          <Checkbox
                            checked={
                              formData?.cardingMachineNo?.indexOf(machineNo) >
                              -1
                            }
                          />
                          <ListItemText primary={machineNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Select Side</TableCell>
                <TableCell style={{ width: 500 }}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.lhs}
                          onChange={handleCheckboxChange}
                          name="lhs"
                          disabled={formData.reportType === "consolidated"}
                        />
                      }
                      label="LHS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.rhs}
                          onChange={handleCheckboxChange}
                          name="rhs"
                          disabled={formData.reportType === "consolidated"}
                        />
                      }
                      label="RHS"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>

          {/* <Grid item xs={3}>
                        <Input
                            value={formData.variety}
                            onChange={(e) => handleFormChange(e, 'variety')}
                            disabled={!formData.varietyEnabled}
                        />
                    </Grid> */}
        </Grid>
        <Grid item container alignItems={"center"}>
          {/* <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cardingMachineNoEnabled"}
                                    checked={formData.cardingMachineNoEnabled}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Carding Machine No"
                        />
                    </Grid> */}
        </Grid>
        {/* <Grid item container alignItems={"center"}>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={"cardingLineNoEnabled"}
                                    checked={formData.cardingLineNoEnabled}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Carding Line No"
                        />
                    </Grid>
                  
                </Grid> */}
        <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handlePreviewData}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Preview"
            )}
          </CustomButton>
        </Grid>

        <Dialog
          open={openPreview}
          onClose={handleClosePreview}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
            }}
          >
            Preview
            <IconButton onClick={handleClosePreview} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              {previewData?.length === 0 ? (
                <Table>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box sx={{ textAlign: "center", padding: 2 }}>
                        no data will be available
                      </Box>
                    </TableCell>
                  </TableRow>
                </Table>
              ) : (
                <Table>
                  <TableHead>
                    {formData.reportType === "individual" && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Machine Number
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Variety
                        </TableCell>
                        {formData.lhs === true && (
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Total LHS
                          </TableCell>
                        )}
                        {formData.rhs === true && (
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Total RHS
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                    {formData.reportType === "consolidated" && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Hall Number
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Hairness Records
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          No Of Cuts
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Cots Buffing
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {formData.reportType === "individual" &&
                      previewData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {" "}
                            {dayjs(item.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{item.machine_number}</TableCell>
                          <TableCell>{item.variety}</TableCell>

                          {formData.lhs === true && (
                            <TableCell>{item.lhs}</TableCell>
                          )}
                          {formData.rhs === true && (
                            <TableCell>{item.rhs}</TableCell>
                          )}
                        </TableRow>
                      ))}

                    {formData.reportType === "consolidated" &&
                      previewData?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {dayjs(item.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{item.hall}</TableCell>
                          <TableCell>
                            <Grid
                              style={{
                                border: "1px solid black",
                                borderRadius: "5px",
                                overflow: "hidden",
                                width: "100%",
                              }}
                            >
                              {/* Header Row */}
                              <Grid
                                container
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  fontWeight: "bold",
                                }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                  }}
                                >
                                  Machine Number
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                  }}
                                >
                                  Variety
                                </Grid>
                              </Grid>

                              {/* Data Rows */}
                              {Array.isArray(item?.HairnessRecords) &&
                                item.HairnessRecords.map(
                                  (HairnessRecordsItem, i) => (
                                    <Grid container key={i}>
                                      {Object.entries(HairnessRecordsItem).map(
                                        ([subKey, subValue]) => {
                                          // First check for drumNo
                                          if (subKey === "machine_number") {
                                            return (
                                              <Grid
                                                item
                                                xs={6}
                                                key={subKey}
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                {subValue}
                                              </Grid>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                      {Object.entries(HairnessRecordsItem).map(
                                        ([subKey, subValue]) => {
                                          if (subKey === "variety") {
                                            return (
                                              <Grid
                                                item
                                                xs={6}
                                                key={subKey}
                                                style={{
                                                  border: "1px solid black",
                                                  padding: "8px",
                                                }}
                                              >
                                                {subValue}
                                              </Grid>
                                            );
                                          }
                                          return null; // Skip unwanted fields
                                        }
                                      )}
                                    </Grid>
                                  )
                                )}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            {Array.isArray(item?.HairnessErrors) &&
                              item.HairnessErrors.map(
                                (HairnessErrorsItem, i) => (
                                  <Grid container key={i}>
                                    {Object.entries(HairnessErrorsItem).map(
                                      ([subKey, subValue]) => {
                                        // First check for drumNo
                                        if (
                                          subKey.startsWith(
                                            "number_of_errors"
                                          ) &&
                                          subValue
                                        ) {
                                          return (
                                            <Grid item xs={6} key={subKey}>
                                              {subValue}
                                            </Grid>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </Grid>
                                )
                              )}
                          </TableCell>
                          <TableCell>
                            {Array.isArray(item?.HairnessErrors) &&
                              item.HairnessErrors.map(
                                (HairnessErrorsItem, i) => (
                                  <Grid container key={i}>
                                    {Object.entries(HairnessErrorsItem).map(
                                      ([subKey, subValue]) => {
                                        // First check for drumNo
                                        if (
                                          subKey === "corrected" &&
                                          subValue
                                        ) {
                                          return (
                                            <Grid item xs={6} key={subKey}>
                                              {subValue}
                                            </Grid>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </Grid>
                                )
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <CustomButton
              style={{ width: "150px", height: "50px" }}
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={32} />
              ) : (
                "Generate"
              )}
            </CustomButton>
          </DialogActions>
        </Dialog>
      </ContainerWrapper>
    </form>
  );
}

export default HarinessReport;
