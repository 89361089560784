import apiClient from "../../../../utils/clients/axios";


export const lapFeedReport = async (payload) => {
    const response = await apiClient.get(
      `api/quality_control/blow_room/lap_feed_15_24_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
      payload
    );
  
    return response;
  };
  export const chuteFeedCottonReport = async (payload) => {
    const response = await apiClient.get(
      `/api/quality_control/blow_room/chute_feed_1_5_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
      payload
    );
  
    return response;
  };
  export const chuteFeedOthersReport = async (payload) => {
    const response = await apiClient.get(
      `/api/quality_control/blow_room/chute_feed_7_14_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
      payload
    );
  
    return response;
  };
  export const dropTestReport = async (payload) => {
    const response = await apiClient.get(
      `/api/quality_control/blow_room/drop_test_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
      payload
    );
  
    return response;
  };

  export const adminNotification = async (payload) => {
    const response = await apiClient.get(
      `/api/quality_control/blow_room/notifications/?admin=1`,
      payload
    );
  
    return response;
  };