import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useMutation } from "react-query";
import { handleToastMsg } from "../../components/Toast";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { CircularProgress, Paper, TableHead } from "@mui/material";
import { createLap } from "./mutation";
import { useEffect } from "react";
import { HALL_NO } from "../../config/masterdata";
import CalculateCV from "../../utils/CalculateCV";
import { ContainerWrapper } from "../../components/styles";
import CustomButton from "../../components/Button";
import "./lapcss.css";
const boldStyle = { fontWeight: "800", fontSize: "15px" }; // Increase the fontWeight value for bolder text

export default function RibbonLap() {
  const [values, setValues] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [machineNo, setMachineNo] = useState("");
  const [numberOfReadings, setNumberOfReadings] = useState("");
  const [cv, setCv] = useState("");
  const [hallNo, setHallNo] = React.useState("");

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleFormChange = (e) => {
    setFeedback(e.target.value.toUpperCase());
  };

  const handleChange = (index) => (event) => {
    let parsedValue =
      event.target.value === "" ? "" : parseFloat(event.target.value);
    const newValues = [...values];
    newValues[index] = parsedValue;
    setValues(newValues);
  };

  const calculateAverage = () => {
    const filteredValues = values.filter((val) => !isNaN(val) && val !== "");
    if (filteredValues.length === 0) {
      return "";
    }
    const sum = filteredValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
    return (sum / filteredValues.length).toFixed(2);
  };

  const handleMachineNo = (e) => {
    setMachineNo(e.target.value);
  };

  const handleNumberOfReadings = (e) => {
    const numReadings = parseInt(e.target.value);
    if (isNaN(numReadings) || numReadings <= 0) {
      setNumberOfReadings("");
      setValues([]);
    } else {
      setNumberOfReadings(numReadings);
      setValues(Array(numReadings).fill(""));
    }
  };

  //calcualte min and max and range value for the readings
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const rangeValue = maxValue - minValue;

  //mutation
  const { mutate, isLoading } = useMutation(createLap);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      type: "RIBBON",
      machineNo: parseInt(machineNo),
      noOfReadings: parseInt(numberOfReadings),
      minValue: minValue,
      maxValue: maxValue,
      rangeValue: rangeValue,
      readingsValues: values.map((value, index) => ({
        sampleNo: index + 1,
        gram_meter: parseFloat(value),
      })),
      cv: cv,
      feedback: feedback,
      hall: hallNo,
    };

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        setOpenModal(false);
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const resetForm = () => {
    setValues([]);
    setFeedback("");
    setMachineNo("");
    setNumberOfReadings("");
    setCv("");
    setHallNo("");
  };

  //calcualte CV value and Standard Deviation for the readings
  const allFieldsFilled = values && values?.every((value) => value !== "");

  useEffect(() => {
    if (allFieldsFilled) {
      /**calculate to get the average(mean) value */
      const CV = CalculateCV(values);

      if (CV !== "" && !isNaN(CV)) {
        setCv(CV);
      }
    } else {
      setCv("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <ContainerWrapper>
        <form>
          <Grid
            container
            item
            md={12}
            xs={12}
            alignContent={"flex-start"}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid container item xs={12}>
              <h2 className="heading">Ribbon Lap</h2>
            </Grid>
            <Grid container item md={12} xs={12}>
              <Grid container item xs={12} md={6}>
                <Grid container item xs={12} md={4} alignItems={"center"}>
                  <p className="content">Machine No :</p>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <TextField
                    value={machineNo}
                    onChange={handleMachineNo}
                    type="number"
                    fullWidth
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={6}>
                <Grid container item xs={12} md={4} alignItems={"center"}>
                  <p className="content">No of Readings :</p>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <TextField
                    type="number"
                    value={numberOfReadings}
                    onChange={handleNumberOfReadings}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
              <Grid container item xs={12} md={4} alignItems={"center"}>
                <p className="content">Hall Number :</p>
              </Grid>
              <Grid container item xs={12} md={6}>
                <TextField
                  value={hallNo}
                  select
                  onChange={(e) => setHallNo(parseInt(e.target.value))}
                  type="number"
                  fullWidth
                  id="outlined-basic"
                  label="Hall Number"
                  variant="outlined"
                  // onInput={handleCardingLineNumInput}
                >
                  {HALL_NO?.map((hall) => (
                    <MenuItem key={hall} value={hall}>
                      {hall}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container item md={12} mt={5}>
              <Grid container item md={8}>
                <TableContainer
                  component={Paper}
                  style={{
                    width: "500px",
                    height: "fit-content",
                    overflow: "auto",
                  }}
                  className="sampleNoStyles"
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="content" style={{ width: "1vw" }}>
                          Sample No
                        </TableCell>
                        <TableCell className="content" style={{ width: "1vw" }}>
                          Grams/metre
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.map((value, index) => (
                        <TableRow key={index + 1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={value}
                              onChange={handleChange(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableBody>
                      <TableRow>
                        <TableCell className="content">Average</TableCell>
                        <TableCell>{calculateAverage()}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid container item md={4} className="cvstylesoutside">
                <TableContainer
                  component={Paper}
                  style={{
                    width: "200px",
                    height: "fit-content",
                    overflow: "auto",
                  }}
                  className="cvstylesinside"
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="content">CV</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <TextField type="number" value={cv} disabled />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid mt={3} item xs={12}>
              <TextField
                fullWidth
                onChange={handleFormChange}
                name="Size"
                label="Feedback (or) Any Queries"
                value={feedback}
              />
            </Grid>

            <Grid container item md={12} mt={3} justifyContent={"flex-end"}>
              <Button
                onClick={() => setOpenModal(true)}
                style={{ width: "100px", height: "40px" }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </ContainerWrapper>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "500px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Machine No
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    No of Readings
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Sample No
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Grams/metre
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    CV
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Feedback
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{machineNo}</TableCell>
                  <TableCell>{numberOfReadings}</TableCell>
                  <TableCell>
                    {values.map((value, index) => (
                      <div key={index}>{index + 1}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {values.map((value, index) => (
                      <div key={index}>{value}</div>
                    ))}
                  </TableCell>
                  <TableCell>{cv}</TableCell>
                  <TableCell>{feedback}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          <CustomButton
            onClick={handleSubmit}
            style={{ width: "150px", height: "40px" }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Final submit"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
