import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import {
  chuteFeed_1_5_update,
  lapFeed_15_24_update,
  chuteFeed_7_14_update,
  droptest_update,
} from "../../../../Blowroom/mutation/manager/notificationsUpdateMutation";
import { useMutation, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../../../components/Toast";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../../../components/Button";

const MdResponse = ({ selectedItemData, selectedType, setModelToggle }) => {
  const [feedback, setFeedback] = React.useState("");

  const { mutate: chute15Mutate, isLoading: isChute15MutateLoading } =
    useMutation(chuteFeed_1_5_update);

  const { mutate: lapFeedMutate, isLoading: isLapFeedMutateLoading } =
    useMutation(lapFeed_15_24_update);

  const { mutate: chute7_14Mutate, isLoading: isChute7_14MutateLoading } =
    useMutation(chuteFeed_7_14_update);

  const { mutate: droptest_Mutate, isLoading: droptestLoading } =
    useMutation(droptest_update);

  const queryClient = useQueryClient();

  /**filter the data */
  const filterData = (data) => {
    const keysToSkip = [
      "id",
      "createdAt",
      "created_at",
      "updatedAt",
      "updated_at",
      "timestamp",
      "isOutOfNorms",
      "is_out_of_norms",
      "time",
      "materials_out_of_norms",
    ];
    const filteredEntries = Object.entries(data)?.filter(([key, value]) => {
      if (
        keysToSkip?.includes(key) ||
        value === null ||
        // value === 0 ||
        value === "NaN"
      )
        return false;
      return true;
    });

    // Format the date field if it exists
    let formattedEntries = filteredEntries?.map(([key, value]) => {
      if (key === "date") {
        return [key, dayjs(value)?.format("DD-MM-YYYY")];
      }
      return [key, value];
    });

    // If the selectedType is BRDropTest, include the materials_out_of_norms data
    if (selectedType === "BRDropTest" && data?.materials_out_of_norms) {
      const materialsEntries = Object.entries(
        data?.materials_out_of_norms
      )?.flatMap(([materialKey, materialValue]) =>
        Object.entries(materialValue).map(([subKey, subValue]) => [
          `${materialKey}_${subKey}`,
          subValue,
        ])
      );

      formattedEntries = [...formattedEntries, ...materialsEntries];
    }

    return Object.fromEntries(formattedEntries);
  };

  const filteredDataToShow = filterData(selectedItemData);

  /**handle submit the data */
  const handleSubmit = () => {
    const payload = {
      id: selectedItemData.id,
      feedback,
    };

    if (selectedType === "ChuteFeed1_5") {
      chute15Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("manager-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    } else if (selectedType === "ChuteFeed7_14") {
      chute7_14Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("manager-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    } else if (selectedType === "LAPFeed15_24") {
      lapFeedMutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("manager-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    } else if (selectedType === "BRDropTest") {
      droptest_Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("manager-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    }
  };

  return (
    <Grid container rowGap={3}>
      {(selectedType === "ChuteFeed7_14" ||
        selectedType === "ChuteFeed1_5" ||
        selectedType === "LAPFeed15_24") && (
        <Grid item sx={{ mt: 5 }}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "1150px" }}
            className="custom-scrollbar"
          >
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(filteredDataToShow)?.map((key) => (
                    <TableCell key={key} sx={{ minWidth: "100px" }}>
                      {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(filteredDataToShow)?.map((value, index) => (
                    <TableCell key={index} sx={{ minWidth: "100px" }}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {selectedType === "BRDropTest" && (
        <Grid item sx={{ mt: 5 }}>
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "1150px" }}
            className="custom-scrollbar"
          >
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(filteredDataToShow)?.map((key) => (
                    <TableCell key={key} sx={{ minWidth: "100px" }}>
                      {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(filteredDataToShow)?.map((value, index) => (
                    <TableCell key={index} sx={{ minWidth: "100px" }}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      <Grid item xs={12} sm={12}>
        <Textarea
          size="lg"
          name="Size"
          placeholder="Feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value.toUpperCase())}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" justifyContent="center">
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
            disabled={
              !feedback ||
              isChute15MutateLoading ||
              isLapFeedMutateLoading ||
              isChute7_14MutateLoading ||
              droptestLoading
            }
          >
            {isChute15MutateLoading ||
            isLapFeedMutateLoading ||
            isChute7_14MutateLoading ||
            droptestLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Final Submit"
            )}
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MdResponse;
