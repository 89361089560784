import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { HALL_NO } from "../../config/masterdata";
import { getVariety } from "../Blowroom/mutation";
import {
  createSimplexCheckingData,
  createSimplexCheckingDraft,
  getSimplexCheckingData,
  getSimplexCheckingDraft,
  updateSimplexCheckingDraft,
} from "./mutation";
import { useMutation } from "react-query";
import { handleToastMsg } from "../../components/Toast";
import "./simplex.css";
// import { getAscesDraft } from "../mutation/ascesMutation";
const filter = createFilterOptions();

const SimplexCheckings = () => {
  const [hallNo, setHallNo] = React.useState("");
  const [variety, setVariety] = useState("");
  const [ParticularData, setParticularData] = useState({});
  const [MachineNo, setMachineNo] = useState("");
  const [feedback, setFeedback] = useState("");
  const [validCount, setValidCount] = useState(false);
  const [showMachineNumbers, setShowMachineNumbers] = useState(
    [...Array(13).keys()].map((i) => i + 1)
  );

  const { mutate, isLoading: isSubmitLoading } = useMutation(
    createSimplexCheckingData
  );
  const queryClient = useQueryClient();

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const { state } = useLocation();

  const navigate = useNavigate();
  const isSimplexChecking = state?.isSimplexChecking;

  const handleMachineNo = (e) => {
    setMachineNo(e.target.value);
  };

  const particularDatatype = [
    "Bottom Apron",
    "Cots Damage",
    "Spacer",
    "Condensor",
    "Creadle",
    "Can Identification",
    "Others",
  ];

  /**calculate the total waste */
  const calculateTotal = (data) => {
    const wasteTypes = particularDatatype?.filter(
      (type) => type !== "Total" && type !== "Can Identification"
    ); //for skip the Total waste field
    return wasteTypes?.reduce((total, key) => {
      return total + (data[key] || 0);
    }, 0);
  };

  /**handle add all the data */
  const handleAddParticularData = (value, type) => {
    const updatedValue =
      type === "Can Identification"
        ? value
        : value === ""
        ? ""
        : parseFloat(value);

    if (type !== "Can Identification" && value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setParticularData((prev) => {
        const newData = { ...prev, [type]: updatedValue };

        /**handle for total waste and total weight */
        const total = calculateTotal(newData);
        // const totalWeight =
        //   type === "production" && type !== "Can Identification"
        //     ? (updatedValue || 0) + total
        //     : (ParticularData["production"] || 0) + total;

        newData["Total Weight"] = parseFloat(total.toFixed(2));

        return newData;
      });
    }
  };

  //this is for create draft data
  const { mutate: createDraft, isLoading: isCreateLoading } = useMutation(
    async (payload) => {
      const response = await createSimplexCheckingDraft(payload);
      return response.data;
    }
  );

  //this is for update draft data
  const { mutate: updateDraft, isLoading: isUpdateLoading } = useMutation(
    async (params) => {
      const response = await updateSimplexCheckingDraft(params);
      return response.data;
    }
  );

  const { data: simplexCheckingDraftsData } = useQuery(
    "simplex-checking-drafts-data",
    async () => {
      try {
        const response = await getSimplexCheckingDraft();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //simplex all data
  const { data: simplexCheckingData } = useQuery(
    "simplex-checking-all-data",
    async () => {
      try {
        const response = await getSimplexCheckingData();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (simplexCheckingDraftsData && simplexCheckingData) {
      const allCheckData = [
        ...simplexCheckingDraftsData,
        ...simplexCheckingData,
      ];
      const existMachineNo = allCheckData?.map((item) => item.machineNo);

      if (isSimplexChecking && state?.data?.machineNo) {
        setMachineNo(state?.data?.machineNo);
        setShowMachineNumbers([state?.data?.machineNo]);
      } else if (existMachineNo) {
        const updatedNumbers = showMachineNumbers?.filter(
          (number) => !existMachineNo.includes(number)
        );
        setShowMachineNumbers(updatedNumbers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    simplexCheckingDraftsData,
    simplexCheckingData,
    isSimplexChecking,
    state?.data?.machineNo,
  ]);

  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        variety: variety,
        hall: hallNo,
        machineNo: MachineNo,
        bottomApron: ParticularData?.["Bottom Apron"] || null,
        cotsDamage: ParticularData?.["Cots Damage"] || null,
        spacer: ParticularData?.["Spacer"] || null,
        condensor: ParticularData?.["Condensor"] || null,
        creadle: ParticularData?.["Creadle"] || null,
        canIdentification: ParticularData?.["Can Identification"] || null,
        others: ParticularData?.["Others"] || null,
        total: ParticularData?.["Total Weight"] || null,
        feedback: feedback,
        simplexDraftId: state?.data?.id,
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data submitted successfully", "success");
          queryClient.invalidateQueries("get-variety");
          navigate("/simplex-checking-draft");
          resetForm();

          queryClient.invalidateQueries({
            queryKey: ["simplex-checking-drafts-data"],
          });
          queryClient.invalidateQueries({
            queryKey: ["simplex-checking-all-data"],
          });
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const resetForm = () => {
    setVariety("");
    setMachineNo("");
    setFeedback("");
    setParticularData({});
    setHallNo("");
  };

  const handleSaveAndUpdate = async () => {
    try {
      const payload = {
        variety: variety,
        hall: hallNo,
        machineNo: MachineNo,
        bottomApron: ParticularData?.["Bottom Apron"] || null,
        cotsDamage: ParticularData?.["Cots Damage"] || null,
        spacer: ParticularData?.["Spacer"] || null,
        condensor: ParticularData?.["Condensor"] || null,
        creadle: ParticularData?.["Creadle"] || null,
        canIdentification: ParticularData?.["Can Identification"] || null,
        others: ParticularData?.["Others"] || null,
        total: ParticularData?.["Total Weight"] || null,
        feedback: feedback,
      };

      //this is only for update the data
      const params = { payload, id: state?.data?.id };

      state.isDraft
        ? updateDraft(params, {
            onSuccess: (res) => {
              queryClient.invalidateQueries("get-variety");
              handleToastMsg("Draft Data updated successfully", "success");
              navigate("/simplex-checking-draft");

              queryClient.invalidateQueries({
                queryKey: ["simplex-checking-drafts-data"],
              });
              queryClient.invalidateQueries({
                queryKey: ["simplex-checking-all-data"],
              });
              resetForm();
            },
            onError: (error) => {
              handleToastMsg("Data Error Occurred", "error");
            },
          })
        : createDraft(payload, {
            onSuccess: (res) => {
              queryClient.invalidateQueries("get-variety");
              handleToastMsg("Draft Data created successfully", "success");
              navigate("/simplex-checking-draft");

              queryClient.invalidateQueries({
                queryKey: ["simplex-checking-drafts-data"],
              });
              queryClient.invalidateQueries({
                queryKey: ["simplex-checking-all-data"],
              });
              resetForm();
            },
            onError: (error) => {
              handleToastMsg("Data Error Occurred", "error");
            },
          });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (state?.data && state?.isDraft) {
      const particular = {
        "Bottom Apron": state?.data?.bottomApron,
        "Cots Damage": state?.data?.cotsDamage,
        Spacer: state?.data?.spacer,
        Condensor: state?.data?.condensor,
        Creadle: state?.data?.creadle,
        "Can Identification": state?.data?.canIdentification,
        Others: state?.data?.others,
        "Total Weight": state?.data?.total,
      };

      setMachineNo(state?.data?.machineNo);
      setVariety(state?.data?.variety);
      setHallNo(state?.data?.hall);
      setParticularData(particular);
      setFeedback(state?.data?.feedback);
    }
  }, [state]);

  useEffect(() => {
    const datacorrect =
      ParticularData?.["Bottom Apron"] &&
      ParticularData?.["Cots Damage"] &&
      ParticularData?.["Spacer"] &&
      ParticularData?.["Condensor"] &&
      ParticularData?.["Creadle"] &&
      ParticularData?.["Can Identification"] &&
      ParticularData?.["Others"];
    if (
      hallNo &&
      variety &&
      MachineNo &&
      Object.keys(ParticularData).length > 7 &&
      datacorrect
    ) {
      setValidCount(true);
    } else {
      setValidCount(false);
    }
  }, [hallNo, variety, MachineNo, ParticularData]);

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "2%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid container item xs={12}>
        <h2 className="heading">Simplex Checkings</h2>
      </Grid>
      <Grid
        container
        item
        md={12}
        xs={12}
        alignContent={"flex-start"}
        style={{ padding: "30px 5px" }}
        rowGap={3}
        spacing={2}
      >
        <Grid container item md={5}>
          <Grid container item md={5} alignItems={"center"}>
            <p className="content">Hall Number :</p>
          </Grid>
          <Grid container item md={6}>
            {/* //Hall Number */}
            <TextField
              value={hallNo}
              select
              onChange={(e) => setHallNo(parseInt(e.target.value))}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container item md={5} sx={{ marginRight: "30px" }}>
          <Grid container item md={5} alignItems={"center"}>
            <p className="content">Variety :</p>
          </Grid>
          <Grid container item md={6}>
            {/* //variety */}
            <Autocomplete
              value={variety}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setVariety(newValue || "");
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setVariety(newValue || "");
                } else {
                  setVariety(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue.toUpperCase());
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
              getOptionLabel={(option) =>
                typeof option === "string" ? option : ""
              } // Ensure option is a string
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{ width: 180 }}
              freeSolo
              renderInput={(params) => (
                <TextField required {...params} label="Variety" />
              )}
            />
          </Grid>
        </Grid>

        <Grid container item md={12} xs={12}>
          <Grid container item md={5}>
            <Grid container item md={5} alignItems={"center"}>
              <p className="content">Machine No :</p>
            </Grid>
            <Grid container item md={6}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "10px",
                }}
              >
                <FormControl sx={{ width: 180 }} fullWidth>
                  <Select
                    labelId="machine-no-label"
                    id="machine-no"
                    value={MachineNo}
                    onChange={(e) => handleMachineNo(e, "MachineNo")}
                  >
                    {showMachineNumbers?.map((machineNo) => (
                      <MenuItem key={machineNo} value={machineNo}>
                        {machineNo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {MachineNo && (
        <Grid
          container
          item
          md={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="content">Particular</TableCell>
                    <TableCell className="content">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {particularDatatype?.map((type, index) => (
                    <TableRow key={index}>
                      <TableCell>{type}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type={
                            type === "Can Identification" ? "string" : "number"
                          }
                          disabled={type === "Total Waste"}
                          value={ParticularData[type] ?? ""}
                          onChange={(e) =>
                            handleAddParticularData(e.target.value, type)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    {" "}
                    <TableCell className="content">Total</TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={ParticularData["Total Weight"] ?? ""}
                        disabled={true}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}

      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => handleFeedbackChange(e, "feedback")}
          name="Size"
          label="Feedback (or) Any Queries"
          value={feedback}
        />
      </Grid>

      <Grid mt={3} item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="addbutton"
            style={{ marginRight: "20px" }}
            disabled={validCount || isCreateLoading || isUpdateLoading}
            onClick={() => handleSaveAndUpdate()}
          >
            {isCreateLoading || isUpdateLoading ? (
              <CircularProgress size={24} />
            ) : state.isDraft ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleSubmit}
            disabled={!validCount || isSubmitLoading}
          >
            {isSubmitLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SimplexCheckings;
