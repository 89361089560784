import React from "react";
import { ContainerWrapper } from "../../components/styles";
import { Grid } from "@mui/material";
import Input from "../../components/Input";
import CustomButton from "../../components/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function OnnOFF() {
  const initialFormState = {
    variety: "",
    cardingNo: "",
    fsWaste: "",
    production: "",
    waste: "",
    cylinderSpeed: "",
    likerSpeed: "",
    flatSpeed: "",
    deliverySpeed: "",
  };

  const [state, setState] = React.useState(initialFormState);

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateState(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const payload = {
    //   carding_num: state.cardingNo,
    //   variety: state.variety,
    //   production: state.production,
    //   fs_wt: state.fsWaste,
    //   cylinder_speed: state.cylinderSpeed,
    //   liker_in_speed: state.likerSpeed,
    //   flats_speed: state.flatSpeed,
    //   delivery_speed: state.deliverySpeed,
    // };

    // mutate(payload, {
    //   onSuccess: (res) => {
    //     initialFormState();
    //     handleToastMsg("Data updated successfully", "success");
    //   },
    // });
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerWrapper
        container
        justifyContent={"center"}
        //   direction={"row"}
        rowGap={2}
      >
        <Grid item xs={12}>
          <h2>On and Off</h2>
        </Grid>
        <Grid item container xs={12} alignItems={"center"}>
          <Grid item xs={3} container>
            <p>Variety: </p>
          </Grid>
          <Grid item xs={6}>
            <Input
              value={state.variety}
              name="variety"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems={"center"}>
          <Grid item xs={3} container>
            <p>Carding Machine No: </p>
          </Grid>
          <Grid item xs={3}>
            {/* <Input /> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.cardingNo}
              // label="Age"
              name="cardingNo"
              onChange={handleInputChange}
              style={{ width: "100%", border: "1px solid #000" }}
            >
              <MenuItem value={10}>1</MenuItem>
              <MenuItem value={20}>2</MenuItem>
              <MenuItem value={30}>3</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid item container py={3}>
          <Grid
            item
            container
            xs={12}
            md={6}
            rowGap={3}
            //   style={{ borderRight: "1px solid #000" }}
            //   pl={15}
          >
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 1: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.offSample1}
                  onChange={handleInputChange}
                  name="offSample1"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 2: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.offSample2}
                  onChange={handleInputChange}
                  name="offSample2"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 3: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.offSample3}
                  onChange={handleInputChange}
                  name="offSample3"
                  type={"number"}
                  disabled
                  // className={isWeightGreaterThan1 ? "red-text" : "green-text"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 4: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.offSample4}
                  onChange={handleInputChange}
                  name="offSample4"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 5: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.offSample5}
                  onChange={handleInputChange}
                  name="offSample5"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <CustomButton>Add Attempt</CustomButton>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Average: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.average}
                  onChange={handleInputChange}
                  name="average"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>CV%: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.CV}
                  onChange={handleInputChange}
                  name="CV"
                  type={"number"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} rowGap={3}>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 1: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.onSample1}
                  onChange={handleInputChange}
                  name="onSample1"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 2: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.onSample2}
                  onChange={handleInputChange}
                  name="onSample2"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 3: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.onSample3}
                  onChange={handleInputChange}
                  name="onSample3"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 4: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.onSample4}
                  onChange={handleInputChange}
                  name="onSample4"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Sample 5: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.onSample5}
                  onChange={handleInputChange}
                  name="onSample5"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <CustomButton>Add Attempt</CustomButton>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>Average: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.average}
                  onChange={handleInputChange}
                  name="average"
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} container>
                <p>CV%: </p>
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={state.CV}
                  onChange={handleInputChange}
                  name="CV"
                  type={"number"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={"flex-end"} xs={10}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
          >
            Submit
          </CustomButton>
        </Grid>
      </ContainerWrapper>
    </form>
  );
}

export default OnnOFF;
