import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

const ColumnTable = ({ data, handleSpeedChange }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "98%",
        overflowX: "auto",
      }}
      className="custom-scrollbar"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "200px" }}>Carding number</TableCell>{" "}
            {/* Fixed width */}
            {data.length > 0 &&
              data.map((row, index) => (
                <TableCell key={index}>{row.carding_num}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Liker in speed</TableCell>
            {data.map((row, index) => (
              <TableCell key={index}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  step="0.01"
                  variant="standard"
                  style={{ width: "50px" }}
                  name="liker_in_speed"
                  value={row.liker_in_speed}
                  onChange={(e) =>
                    handleSpeedChange(e, index, "liker_in_speed")
                  }
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Cylinder speed</TableCell>
            {data.map((row, index) => (
              <TableCell key={index}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  step="0.01"
                  variant="standard"
                  style={{ width: "50px" }}
                  name="cylinder_speed"
                  value={row.cylinder_speed}
                  onChange={(e) =>
                    handleSpeedChange(e, index, "cylinder_speed")
                  }
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Flats speed</TableCell>
            {data.map((row, index) => (
              <TableCell key={index}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  step="0.01"
                  variant="standard"
                  style={{ width: "50px" }}
                  name="flats_speed"
                  value={row.flats_speed}
                  onChange={(e) => handleSpeedChange(e, index, "flats_speed")}
                />
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Delivery speed</TableCell>
            {data.map((row, index) => (
              <TableCell key={index}>
                <TextField
                  size="small"
                  id="outlined-number"
                  type="number"
                  step="0.01"
                  variant="standard"
                  style={{ width: "50px" }}
                  name="delivery_speed"
                  value={row.delivery_speed}
                  onChange={(e) =>
                    handleSpeedChange(e, index, "delivery_speed")
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ColumnTable;
