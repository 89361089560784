import apiClient from "../../../utils/clients/axios";

export const autoLevellerOnOffMutate = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/carding/on_off_reading_create/",
    payload
  );

  return response;
};
