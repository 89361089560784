// eslint-disable
import React from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getCountChangeDraft } from "./mutation/countChangeMutation";
import "./spinningcss.css";
const CountChangeRecord = () => {
  // const [variety, setVariety] = useState("");
  // const [formValues, setFormValues] = useState({
  //   counts: "",
  //   machineNo: "",
  //   feedback: "",
  // });

  const navigate = useNavigate();

  const handleOpenForm = () => {
    navigate("/count-change");
  };

  const handleCorrection = (item) => {
    navigate("/count-change", { state: { data: item } });
  };

  const { data: countChangeData } = useQuery(
    ["count-change"],
    async () => {
      try {
        const response = await getCountChangeDraft();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Count Change</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "1vw" }}>Date</TableCell>
                <TableCell style={{ width: "1vw" }}>Machine No</TableCell>
                <TableCell style={{ width: "1vw" }}>Counts</TableCell>
                <TableCell style={{ width: "1vw" }}>Count Value</TableCell>
                <TableCell style={{ width: "1vw" }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countChangeData?.length > 0 &&
                countChangeData?.map((item, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ width: "1vw" }}>
                        {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.machine_number}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.count}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.weight_samples
                          ?.map((item) => {
                            const value = item?.count_value ?? 0;
                            return value % 1 === 0
                              ? value.toFixed(0)
                              : value.toFixed(2);
                          })
                          .join(",")}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            width: "100px",
                            height: "40px",
                          }}
                          onClick={() => handleCorrection(item)}
                        >
                          Correction
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "200px", height: "50px" }}
            onClick={() => handleOpenForm()}
          >
            Add New Record
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CountChangeRecord;
