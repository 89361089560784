import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import { Typography, CircularProgress, Box, Dialog } from "@mui/material";
import dayjs from "dayjs";
import ManagerResponse from "../ManagerResponse";
import { managerSpinningNotification } from "../../../mutation/manager/blowroomReportMutation";
import { defaultSeconds } from "../../../../../config/masterdata";
import { ContainerWrapper } from "../../../../../components/styles";

const ManagerSpinningNotification = ({
  setSecondsForFirstInterval,
  isMachineSettingsData,
  isTelevision,
  setPauseInterval,
  pauseInterval,
}) => {
  const [modelToggle, setModelToggle] = React.useState(false);
  const [selectedItemData, setSelectedItemData] = React.useState({});
  const [selectedType, setSelectedType] = React.useState("");

  const [isLoadingData, setIsLoadingData] = useState(false);

  /**handle count spinning notification */
  const {
    data: managerSpinningNotifications,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    "manager-spinning-notifications",
    async () => {
      if (!isTelevision) {
        setIsLoadingData(true);
      }
      try {
        const response = await managerSpinningNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        if (!isTelevision) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Set the interval based on the length of the data
  useEffect(() => {
    if (managerSpinningNotifications && isTelevision) {
      const isMachineData = isMachineSettingsData?.some(
        (item) => item.key === "SpinningMaster" && item.isData === true
      );

      setSecondsForFirstInterval(() => {
        const dataLength = Object.keys(
          managerSpinningNotifications?.data
        ).length;
        return isMachineData
          ? (dataLength + 1) * defaultSeconds
          : dataLength * defaultSeconds;
      });
    }
  }, [
    managerSpinningNotifications,
    isTelevision,
    setSecondsForFirstInterval,
    isMachineSettingsData,
  ]);

  const [currentDataKey, setCurrentDataKey] = useState("");
  useEffect(() => {
    if (isTelevision) {
      const keys = Object.keys(managerSpinningNotifications?.data || {});
      if (keys.length > 0) {
        // Set initial key if it's empty
        if (!currentDataKey) {
          setCurrentDataKey(keys[0]);
        }

        const intervalId = setInterval(() => {
          if (!pauseInterval) {
            setCurrentDataKey((prevKey) => {
              const currentIndex = keys.indexOf(prevKey);
              const nextIndex = (currentIndex + 1) % keys.length;
              return keys[nextIndex];
            });
          }
        }, defaultSeconds); // Switch data every 30 seconds

        return () => clearInterval(intervalId);
      }
    }
  }, [
    managerSpinningNotifications,
    isTelevision,
    currentDataKey,
    pauseInterval,
  ]);

  //this will return the machine no for asc, hariness and cotcheck
  const getMachineNo = (title, row) => {
    let machineNo;
    const data =
      title === "ASCDraft"
        ? row?.ASCRecordDrafts
        : title === "HairnessDraft"
        ? row?.hairness_records
        : title === "CotCheckDraft" && row?.CotCheckRecordDrafts;

    machineNo = data?.map((item) => item.machine_number).join(", ") || "";

    return machineNo;
  };

  //this will return the no of cuts for asc, hariness and cotcheck
  const getNoOfCutsValues = (title, row) => {
    let noOfCuts;
    let data = [];
    let key;

    if (title === "ASCDraft") {
      data = row?.ASCErrorDrafts;
      key = "number_of_errors";
    }
    if (title === "HairnessDraft") {
      data = row?.hairness_errors;
      key = "number_of_errors";
    }
    if (title === "CotCheckDraft") {
      data = row?.CotBuffDrafts;
      key = "number_of_cots";
    }

    noOfCuts = data?.map((item) => item[key]) || "";

    return noOfCuts;
  };

  //this will return the no of corrected for asc, hariness and cotcheck
  const getCorrectedValues = (title, row) => {
    let corrected;
    let data = [];
    let key;

    if (title === "ASCDraft") {
      data = row?.ASCErrorDrafts;
      key = "corrected";
    }
    if (title === "HairnessDraft") {
      data = row?.hairness_errors;
      key = "corrected";
    }
    if (title === "CotCheckDraft") {
      data = row?.CotBuffDrafts;
      key = "cot_buffing";
    }

    corrected = data?.map((item) => item[key]) || "";

    return corrected;
  };

  //render main component
  const renderTable = (title, data) => {
    let headers = [];
    let titleKey;

    if (title === "CountChangeDraft") {
      titleKey = "COUNT CHANGE";
      headers = ["Sl no", "Date", "Machine number", "Value", "View & Respond"];
    } else if (title === "CotCheckDraft") {
      titleKey = "COT CHECK";
      headers = [
        "Sl no",
        "Date",
        "Machine number",
        "No of cuts",
        "Cots buffing",
        "View & Respond",
      ];
    } else if (title === "HairnessDraft") {
      titleKey = "HARINESS";
      headers = [
        "Sl no",
        "Date",
        "Machine number",
        "No of cuts",
        "Cots buffing",
        "View & Respond",
      ];
    } else if (title === "ASCDraft") {
      titleKey = "APRON SPACER CONDENSER";
      headers = [
        "Sl no",
        "Date",
        "Machine number",
        "No of cuts",
        "Cots buffing",
        "View & Respond",
      ];
    }

    return (
      <>
        <Grid container item xs={12}>
          {" "}
          <Grid container item xs={12} rowGap={2}>
            <Grid container item xs={12} justifyContent={"center"}>
              <h3>{titleKey}</h3>
            </Grid>
            <Grid container item xs={12}>
              {Object.keys(data).length > 0 ? ( // Check if data exists and is not empty
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headers?.map((header) => (
                          <TableCell key={header}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {title === "CountChangeDraft"
                              ? row.machine_number
                              : getMachineNo(title, row)}
                          </TableCell>

                          {title === "CountChangeDraft" && (
                            <TableCell>
                              <p style={{ color: "red" }}>
                                {row?.weight_samples
                                  ?.map((item) => item.count_value)
                                  .join(", ")}
                              </p>
                            </TableCell>
                          )}

                          {(title === "CotCheckDraft" ||
                            title === "HairnessDraft" ||
                            title === "ASCDraft") && (
                            <>
                              {" "}
                              <TableCell>
                                <p>{getNoOfCutsValues(title, row)}</p>
                              </TableCell>{" "}
                              <TableCell>
                                <p style={{ color: "red" }}>
                                  {getCorrectedValues(title, row)}
                                </p>
                              </TableCell>
                            </>
                          )}

                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setModelToggle(true);
                                setSelectedItemData(row);
                                setSelectedType(title);
                                if (isTelevision) {
                                  setPauseInterval(true);
                                }
                              }}
                            >
                              {isTelevision ? "View" : "Respond"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // height="100vh"
                >
                  <Typography>No data available</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <ContainerWrapper
      container
      item
      xs={12}
      alignContent={"flex-start"}
      rowGap={4}
    >
      {isLoadingData || isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
          width="100%"
          zIndex={9999} // Ensure this is above other elements
          backdropFilter="blur(10px)" // Apply the blur effect
          background="rgba(255, 255, 255, 0.8)" // Background color with transparency
        >
          <CircularProgress /> {/* Center the loading spinner */}
        </Box>
      ) : isError ? (
        <Typography>Error loading data</Typography>
      ) : (
        <>
          {isTelevision ? (
            renderTable(
              currentDataKey,
              managerSpinningNotifications?.data[currentDataKey] || []
            )
          ) : managerSpinningNotifications &&
            Object.keys(managerSpinningNotifications?.data)?.length > 0 ? (
            Object.keys(managerSpinningNotifications?.data)?.map((key) => (
              <Grid container item md={12} key={key}>
                {renderTable(key, managerSpinningNotifications?.data[key])}
              </Grid>
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Typography>No data available</Typography>
            </Box>
          )}
        </>
      )}

      <Dialog
        open={modelToggle}
        onClose={() => {
          setModelToggle(false);
          if (isTelevision) {
            setPauseInterval(false);
          }
        }}
        maxWidth="lg"
        fullWidth
      >
        <ManagerResponse
          selectedItemData={selectedItemData}
          selectedType={selectedType}
          setModelToggle={setModelToggle}
          isTelevision={isTelevision}
          setPauseInterval={setPauseInterval}
        />
      </Dialog>
    </ContainerWrapper>
  );
};

export default ManagerSpinningNotification;
