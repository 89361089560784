import apiClient from "../../utils/clients/axios";

// report issue data final submit
export const createTaskSheetsIssueData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/report_issue`,
    payload
  );
  return response;
};

// update report issue data both for employee and manager
export const updateTaskSheetsIssueData = async (params) => {
  const { payload, id } = params;

  const response = await apiClient.put(
    `/api/quality_control/report_issue/${id}`,
    payload
  );
  return response;
};
