// import React from "react";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
// import { useQuery } from "react-query";
// import { Typography, CircularProgress, Box } from "@mui/material";
// import DialogModal from "../../../../../components/Dialog";
// import dayjs from "dayjs";
// import {
//   managerAutoconerNotification,
//   managerBlowroomNotification,
//   managerSpinningNotification,
// } from "../../../mutation/manager/blowroomReportMutation";
// import ManagerResponse from "../ManagerResponse";
// import { managerFinisherDrawingNotification } from "../ManagerFinisherDrawingNotification/mutation";
// import ManagerFinisherDrawingNotification from "../ManagerFinisherDrawingNotification";
// import ManagerBlowroomNotification from "../ManagerNotification";
// import ManagerAutoconerNotification from "../../../Autoconer/Manager/Notifications/ManageAutoNotification";
// import ManagerSpinningNotification from "../ManagerSpinningNotification";

// const MdNotification = () => {
//   const [modelToggle, setModelToggle] = React.useState(false);
//   const [selectedItemData, setSelectedItemData] = React.useState({});
//   const [selectedType, setSelectedType] = React.useState("");

//   //Blowroom notification
//   const {
//     data: managerBlowroomNotifications,
//     isLoading: isBlowroomNotificationLoading,
//     isError: isBlowroomNotificationError,
//   } = useQuery(
//     "manager-blowroom-notifications",
//     async () => {
//       try {
//         const response = await managerBlowroomNotification();
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
//     {
//       refetchOnMount: true,
//     }
//   );

//   //Autoconor notification
//   const {
//     data: managerAutoconorNotifications,
//     isLoading: isAutoconorNotificationLoading,
//     isError: isAutoconorNotificationError,
//   } = useQuery(
//     "manager-autoconer-notifications",
//     async () => {
//       try {
//         const response = await managerAutoconerNotification();
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
//     {
//       refetchOnMount: true,
//     }
//   );

//   //Spinng notification
//   const {
//     data: managerSpinningNotifications,
//     isLoading: isSpinningNotificationLoading,
//     isError: isSpinningNotificationError,
//   } = useQuery(
//     "manager-spinning-notifications",
//     async () => {
//       try {
//         const response = await managerSpinningNotification();
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
//     {
//       refetchOnMount: true,
//     }
//   );

//   //Finisher Drawing notification
//   const {
//     data: finisherDrawingNotifications,
//     isLoading: isFinisherDrawingNotificationLoading,
//     isError: isFinisherDrawingNotificationError,
//   } = useQuery(
//     "manager-finisher-drawing-notifications",
//     async () => {
//       try {
//         const response = await managerFinisherDrawingNotification();
//         return response.data;
//       } catch (error) {
//         throw error;
//       }
//     },
//     {
//       refetchOnMount: true,
//     }
//   );

//   const renderTable = (title, data) => {
//     const headers = [
//       "Sl no",
//       "Date",
//       "Carding number",
//       "Value",
//       "View & Respond",
//     ];

//     return (
//       <>
//         <Grid container item md={12} alignContent={"flex-start"}>
//           <Grid sx={{ m: 5 }}>
//             <Typography>{title}</Typography>
//           </Grid>
//           {Object.keys(data).length > 0 ? ( // Check if data exists and is not empty
//             <TableContainer sx={{ m: 5 }} component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     {headers.map((header) => (
//                       <TableCell key={header}>{header}</TableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {data.map((row, index) => (
//                     <TableRow key={index}>
//                       <TableCell>{index + 1}</TableCell>
//                       <TableCell>
//                         {dayjs(row.date).format("DD-MM-YYYY")}
//                       </TableCell>
//                       <TableCell>{row.cardingLineNum}</TableCell>
//                       <TableCell>{row.totalWastePercentage}%</TableCell>
//                       <TableCell>
//                         <Button
//                           variant="contained"
//                           color="primary"
//                           onClick={() => {
//                             setModelToggle(true);
//                             setSelectedItemData(row);
//                             setSelectedType(title);
//                           }}
//                         >
//                           Respond
//                         </Button>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           ) : (
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               // height="100vh"
//             >
//               <Typography>No data available</Typography>
//             </Box>
//           )}
//         </Grid>
//       </>
//     );
//   };

//   return (
//     <Grid container item md={12} alignContent={"flex-start"} sx={{ m: 5 }}>
//       {isBlowroomNotificationLoading ||
//       isAutoconorNotificationLoading ||
//       isFinisherDrawingNotificationLoading ||
//       isSpinningNotificationLoading ? (
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           height="100vh"
//           position="fixed"
//           top={0}
//           left={0}
//           width="100%"
//           zIndex={9999} // Ensure this is above other elements
//           backdropFilter="blur(10px)" // Apply the blur effect
//           background="rgba(255, 255, 255, 0.8)" // Background color with transparency
//         >
//           <CircularProgress /> {/* Center the loading spinner */}
//         </Box>
//       ) : isBlowroomNotificationError ||
//         isAutoconorNotificationError ||
//         isFinisherDrawingNotificationError ||
//         isSpinningNotificationError ? (
//         <Typography>Error loading data</Typography>
//       ) : (
//         <>
//           <Grid container item md={12} alignContent={"flex-start"}>
//             {managerBlowroomNotifications &&
//               Object.keys(managerBlowroomNotifications?.data).length > 0 && (
//                 <>
//                   <Grid container item md={12}>
//                     <h2>Blowroom Notification</h2>
//                   </Grid>
//                   {Object.keys(managerBlowroomNotifications?.data).map(
//                     (key) => (
//                       <Grid container item md={12} key={key}>
//                         {renderTable(
//                           key,
//                           managerBlowroomNotifications?.data[key]
//                         )}
//                       </Grid>
//                     )
//                   )}
//                 </>
//               )}

//             {managerAutoconorNotifications &&
//               Object.keys(managerAutoconorNotifications?.data).length > 0 && (
//                 <>
//                   <Grid container item md={12}>
//                     <h2>Autoconor Notification</h2>
//                   </Grid>
//                   {Object.keys(managerAutoconorNotifications?.data).map(
//                     (key) => (
//                       <Grid container item md={12} key={key}>
//                         {renderTable(
//                           key,
//                           managerAutoconorNotifications?.data[key]
//                         )}
//                       </Grid>
//                     )
//                   )}
//                 </>
//               )}

//             {managerSpinningNotifications &&
//               Object.keys(managerSpinningNotifications?.data).length > 0 && (
//                 <>
//                   <Grid container item md={12}>
//                     <h2>Spinning Notification</h2>
//                   </Grid>
//                   {Object.keys(managerSpinningNotifications?.data).map(
//                     (key) => (
//                       <Grid container item md={12} key={key}>
//                         {renderTable(
//                           key,
//                           managerSpinningNotifications?.data[key]
//                         )}
//                       </Grid>
//                     )
//                   )}
//                 </>
//               )}

//             {finisherDrawingNotifications &&
//               Object.keys(finisherDrawingNotifications?.data).length > 0 && (
//                 <>
//                   <Grid container item md={12}>
//                     <h2>Finisher Drawing Notification</h2>
//                   </Grid>
//                   {Object.keys(finisherDrawingNotifications?.data).map(
//                     (key) => (
//                       <Grid container item md={12} key={key}>
//                         {renderTable(
//                           key,
//                           finisherDrawingNotifications?.data[key]
//                         )}
//                       </Grid>
//                     )
//                   )}
//                 </>
//               )}
//           </Grid>
//         </>
//       )}

//       <DialogModal
//         DialogModal
//         toggleModal={modelToggle}
//         setToggleModal={setModelToggle}
//       >
//         <ManagerResponse
//           selectedItemData={selectedItemData}
//           selectedType={selectedType}
//           setModelToggle={setModelToggle}
//         />
//       </DialogModal>
//     </Grid>
//   );
// };

// export default MdNotification;

import React from "react";
import Grid from "@mui/material/Grid";
import ManagerFinisherDrawingNotification from "../ManagerFinisherDrawingNotification";
import ManagerBlowroomNotification from "../ManagerNotification";
import ManagerAutoconerNotification from "../../../Autoconer/Manager/Notifications/ManageAutoNotification";
import ManagerSpinningNotification from "../ManagerSpinningNotification";

const MdNotification = () => {
  return (
    <>
      <Grid container item md={12} alignContent={"flex-start"} rowGap={5}>
        <Grid container item md={12}>
          {" "}
          <ManagerBlowroomNotification />
        </Grid>
        <Grid container item md={12}>
          {" "}
          <ManagerAutoconerNotification />
        </Grid>
        <Grid container item md={12}>
          {" "}
          <ManagerSpinningNotification />
        </Grid>
        <Grid container item md={12}>
          <ManagerFinisherDrawingNotification />
        </Grid>
      </Grid>
    </>
  );
};

export default MdNotification;
