import React, { useState } from "react";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  FormControl,
  MenuItem,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import {
  PaperConeIdentifications,
  PaperConeIdentificationsCreate,
} from "./mutation";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";
import "./papercss.css";
const filter = createFilterOptions();

const PaperConeIdentification = () => {
  const [variety, setVariety] = useState("");
  const [formValues, setFormValues] = useState({
    variety: "",
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    feedback: "",
    slub_code: "",
    no_of_readings: 0,
    paper_cone_name: "",
    empties_color_name: "",
    wax: "",
    drumFrom: "",
    drumTo: "",
  });

  const { data: paperConeData } = useQuery("get-paper-cone", async () => {
    try {
      const response = await PaperConeIdentifications();
      return response?.data;
    } catch (error) {
      throw error;
    }
  });

  const { mutate, isLoading: isSubmitLoading } = useMutation(
    PaperConeIdentificationsCreate
  );

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "wax") {
      value = value === "true";
    } else if (
      field === "feedback" ||
      field === "empties_color_name" ||
      field === "paper_cone_name" ||
      field === "denier" ||
      field === "slub_code"
    ) {
      if (value) {
        value = value.toUpperCase();
      }
    } else if (
      [
        "counts",
        "hallNo",
        "auto_coner_no",
        "drumFrom",
        "drumTo",
        "no_of_readings",
      ].includes(field)
    ) {
      const changedValue = value === "" ? "" : parseFloat(value);
      if (changedValue < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = changedValue;
      }
    }
    setFormValues({ ...formValues, [field]: value });
    // setUserEnteredValue(value);
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      const payload = {
        hall: formValues.hallNo,
        variety: variety,
        counts: formValues.counts,
        denier: formValues.denier,
        autoConerNo: formValues.auto_coner_no,
        drumNo: `${formValues.drumFrom}-${formValues.drumTo}`,
        paperConeName: formValues.paper_cone_name,
        emptiesColorName: formValues.empties_color_name,
        wax: formValues.wax,
        slubCode: formValues.slub_code,
        machineName: `${formValues.counts}'S, ${formValues.variety}, ${formValues.denier}, ${formValues.slub_code}`,
        feedback: formValues.feedback,
      };

      mutate(payload, {
        onSuccess: (res) => {
          handleToastMsg("Data updated successfully", "success");
          queryClient.invalidateQueries("get-variety");
          queryClient.invalidateQueries("get-paper-cone");
          setVariety("");
          setFormValues({
            counts: "",
            denier: "",
            auto_coner_no: "",
            frame_no: "",
            drumNo: "",
            feedback: "",
            slub_code: "",
            paper_cone_name: "",
            empties_color_name: "",
            wax: "",
            hallNo: "",
          });
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      handleToastMsg("Data Error", "error");
    }
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Paper Cone Identification</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell className="content">Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="content">Variety</TableCell>
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "10px",
                      }}
                    >
                      <Autocomplete
                        value={variety}
                        fullWidth
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setVariety(newValue || "");
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setVariety(newValue || "");
                          } else {
                            setVariety(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue.toUpperCase());
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : ""
                        } // Ensure option is a string
                        renderOption={(props, option) => (
                          <li {...props}>{option}</li>
                        )}
                        styles={{ width: "93%" }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Variety"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>
                </TableRow>
                <TableRow>
                  <TableCell className="content">Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="content">Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      label="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="content">Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Slub Code"
                      label="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="content">Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      label="Auto Coner No"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="content">Drum No</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          variant="outlined"
                          placeholder="Drum From"
                          fullWidth
                          label="Drum From"
                          value={formValues.drumFrom || ""}
                          onChange={(e) => handleFormChange(e, "drumFrom")}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="number"
                          variant="outlined"
                          placeholder="Drum To"
                          fullWidth
                          label="Drum To"
                          value={formValues.drumTo || ""}
                          onChange={(e) => handleFormChange(e, "drumTo")}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="content">Paper Cone Name</TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      gap: "10px",
                    }}
                  >
                    <Autocomplete
                      value={formValues?.paper_cone_name}
                      fullWidth
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          handleFormChange(
                            { target: { value: newValue } },
                            "paper_cone_name"
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options?.some(
                          (option) => inputValue === option
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push(inputValue.toUpperCase());
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={
                        paperConeData?.map((item) => item.paperConeName) || []
                      }
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : ""
                      } // Ensure option is a string
                      renderOption={(props, option) => (
                        <li {...props}>{option}</li>
                      )}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Paper_cone_name"
                          variant="outlined"
                          value={formValues?.paper_cone_name}
                          onChange={(e) =>
                            handleFormChange(e, "paper_cone_name")
                          }
                        />
                      )}
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="content">Empties Color Name</TableCell>
                <TableCell>
                  <Autocomplete
                    value={formValues?.empties_color_name}
                    fullWidth
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        handleFormChange(
                          { target: { value: newValue } },
                          "empties_color_name"
                        );
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options?.some(
                        (option) => inputValue === option
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push(inputValue.toUpperCase());
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={
                      paperConeData?.map((item) => item.emptiesColorName) || []
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : ""
                    } // Ensure option is a string
                    renderOption={(props, option) => (
                      <li {...props}>{option}</li>
                    )}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="empties_color_name"
                        fullWidth
                        value={formValues?.empties_color_name}
                        onChange={(e) =>
                          handleFormChange(e, "empties_color_name")
                        }
                      />
                    )}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="content">Wax</TableCell>
                <TableCell>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="wax"
                      name="wax"
                      value={String(formValues.wax)} // Ensure the value is a string
                      onChange={(e) => handleFormChange(e, "wax")}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => handleFormChange(e, "feedback")}
          name="Size"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleSubmit}
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaperConeIdentification;
