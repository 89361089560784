import apiClient from "../../../utils/clients/axios";

export const getLabFeedWaste = async (date) => {
    const { data } = await apiClient.get(
      `/blow_room/lap_feed_15_24_get/?date=${date}`
    );
  
    return data;
  };

  export const lapFeed1524Create = async (payload) => {
    const repsponse = await apiClient.post(
      "/api/quality_control/blow_room/lap_feed_15_24_create/",
      payload
    );
  
    return repsponse;
  };