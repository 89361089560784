import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import { Typography, CircularProgress, Box, Dialog } from "@mui/material";
import { managerAutoconerNotification } from "../../../mutation/manager/blowroomReportMutation";
import dayjs from "dayjs";
import ManagerResponse from "../../../Manager/Notifications/ManagerResponse";
import { defaultSeconds } from "../../../../../config/masterdata";
import { ContainerWrapper } from "../../../../../components/styles";

const ManagerAutoconerNotification = ({
  setSecondsForFirstInterval,
  isMachineSettingsData,
  isTelevision,
  setPauseInterval,
  pauseInterval,
}) => {
  const [modelToggle, setModelToggle] = React.useState(false);
  const [selectedItemData, setSelectedItemData] = React.useState({});
  const [selectedType, setSelectedType] = React.useState("");

  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    data: managerAutoconerNotifications,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    "manager-autoconer-notifications",
    async () => {
      if (!isTelevision) {
        setIsLoadingData(true);
      }
      try {
        const response = await managerAutoconerNotification();
        return response.data;
      } catch (error) {
        throw error;
      } finally {
        if (!isTelevision) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Set the interval based on the length of the data
  useEffect(() => {
    if (managerAutoconerNotifications && isTelevision) {
      const isMachineData = isMachineSettingsData?.some(
        (item) => item.key === "AutoconerMaster" && item.isData === true
      );

      setSecondsForFirstInterval(() => {
        const dataLength = Object.keys(
          managerAutoconerNotifications?.data
        ).length;
        return isMachineData
          ? (dataLength + 1) * defaultSeconds
          : dataLength * defaultSeconds;
      });
    }
  }, [
    managerAutoconerNotifications,
    isTelevision,
    setSecondsForFirstInterval,
    isMachineSettingsData,
  ]);

  const [currentDataKey, setCurrentDataKey] = useState("");
  useEffect(() => {
    if (isTelevision) {
      const keys = Object.keys(managerAutoconerNotifications?.data || {});
      if (keys.length > 0) {
        // Set initial key if it's empty
        if (!currentDataKey) {
          setCurrentDataKey(keys[0]);
        }

        const intervalId = setInterval(() => {
          if (!pauseInterval) {
            setCurrentDataKey((prevKey) => {
              const currentIndex = keys.indexOf(prevKey);
              const nextIndex = (currentIndex + 1) % keys.length;
              return keys[nextIndex];
            });
          }
        }, defaultSeconds); // Switch data every 30 seconds

        return () => clearInterval(intervalId);
      }
    }
  }, [
    managerAutoconerNotifications,
    isTelevision,
    currentDataKey,
    pauseInterval,
  ]);

  const renderTable = (title, data) => {
    const headers = ["Sl no", "Date", "Counts", "Value", "View & Respond"];

    let titleKey;

    if (title === "RewindingStudy") {
      titleKey = "REWINDING STUDY";
    } else if (title === "SpliceStrength") {
      titleKey = "SPLICE STRENGTH";
    }

    return (
      <>
        <Grid container item xs={12}>
          {" "}
          <Grid container item xs={12} rowGap={2}>
            <Grid container item xs={12} justifyContent={"center"}>
              <h3>{titleKey}</h3>
            </Grid>
            <Grid container item xs={12}>
              {Object.keys(data).length > 0 ? ( // Check if data exists and is not empty
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headers?.map((header) => (
                          <TableCell key={header}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {dayjs(row.date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{row.counts}</TableCell>
                          <TableCell>
                            {title === "RewindingStudy" ? (
                              <p style={{ color: "red" }}>
                                {row?.particular
                                  ?.map((item) => item.breakLengthMeter)
                                  .join(", ")}
                              </p>
                            ) : (
                              <p style={{ color: "red" }}>
                                {row?.particular
                                  ?.map(
                                    (item) => item.averagePercentYarnReading
                                  )
                                  .join(", ")}
                              </p>
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setModelToggle(true);
                                setSelectedItemData(row);
                                setSelectedType(title);
                                if (isTelevision) {
                                  setPauseInterval(true);
                                }
                              }}
                            >
                              {isTelevision ? "View" : "Respond"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  // height="100vh"
                >
                  <Typography>No data available</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <ContainerWrapper
        container
        item
        xs={12}
        alignContent={"flex-start"}
        rowGap={4}
      >
        {isLoadingData || isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            position="fixed"
            top={0}
            left={0}
            width="100%"
            zIndex={9999} // Ensure this is above other elements
            backdropFilter="blur(10px)" // Apply the blur effect
            background="rgba(255, 255, 255, 0.8)" // Background color with transparency
          >
            <CircularProgress /> {/* Center the loading spinner */}
          </Box>
        ) : isError ? (
          <Typography>Error loading data</Typography>
        ) : (
          <>
            {isTelevision ? (
              renderTable(
                currentDataKey,
                managerAutoconerNotifications?.data[currentDataKey] || []
              )
            ) : managerAutoconerNotifications?.data &&
              Object.keys(managerAutoconerNotifications?.data)?.length > 0 ? (
              Object.keys(managerAutoconerNotifications?.data)?.map((key) => (
                <Grid container item xs={12} key={key}>
                  {renderTable(key, managerAutoconerNotifications?.data[key])}
                </Grid>
              ))
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <Typography>No data available</Typography>
              </Box>
            )}
          </>
        )}

        <Dialog
          open={modelToggle}
          onClose={() => {
            setModelToggle(false);
            if (isTelevision) {
              setPauseInterval(false);
            }
          }}
          maxWidth="lg"
          fullWidth
        >
          <ManagerResponse
            selectedItemData={selectedItemData}
            selectedType={selectedType}
            setModelToggle={setModelToggle}
            isTelevision={isTelevision}
            setPauseInterval={setPauseInterval}
          />
        </Dialog>
      </ContainerWrapper>
    </>
  );
};

export default ManagerAutoconerNotification;
