/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { handleToastMsg } from "../../components/Toast";
import { login } from "../Blowroom/mutation/auth/auth";
import useAuth from "../../hooks/useAuth";
import { CircularProgress, Grid } from "@mui/material";
import LoginImage from "../../assets/loginImage.svg";
import Logo from "../../assets/logo.jpg";
import "./styles.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userCode: "",
    password: "",
  });
  const [errors, setErrors] = useState({ userCode: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { mutate, isLoading } = useMutation(login);
  const { setAuth } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dashboardMenuSetting = (res) => {
    let dashboardMenuItems = [];

    const role = res?.data?.user?.role;
    const userModules = res?.data?.user?.modules || [];

    if (role === "ADMIN") {
      dashboardMenuItems = [
        { menu: "User", link: "admin-user" },
        { menu: "Wrapping", link: "wrapping-auto" },
        { menu: "Parameter Master", link: "parameter-master" },
        { menu: "Reports", link: "lap-feed-report" },
      ];
    } else if (role === "MANAGER") {
      dashboardMenuItems = [
        { menu: "Machine Setting", link: "general-machine-setting-correction" },
        { menu: "Notifications", link: "manager-blowroom-notifications" },
        { menu: "Norms", link: "blowroom-norms" },
        { menu: "Sasta", link: "sasta-reports" },
        { menu: "Reports", link: "lap-feed-report" },
      ];

      if (userModules.includes("task_sheets")) {
        dashboardMenuItems.push({
          menu: "Task Sheets Respond",
          link: "task-sheets-resolve",
        });
      }
    } else if (role === "EMPLOYEE" || role === "SUB_MANAGER") {
      //this is for all machine settings options show in dashboard
      dashboardMenuItems.push({
        menu: "Machine Settings",
        //this is dummy link
        link: "machine-setting",
      });

      //this is for all modules check and then update the link
      if (userModules.includes("general")) {
        dashboardMenuItems.push({
          menu: "General",
          link: "general-machine-settings",
        });
      }
      if (userModules.includes("others")) {
        dashboardMenuItems.push({
          menu: "Others",
          link: "others-machine-setting",
        });
      }
      if (userModules.includes("blow_room")) {
        dashboardMenuItems.push({ menu: "Blowroom", link: "lap-feed-waste" });
      }
      if (userModules.includes("carding")) {
        dashboardMenuItems.push({
          menu: "Carding",
          link: "individual-carding-waste",
        });
      }
      if (userModules.includes("autoconer")) {
        dashboardMenuItems.push({
          menu: "Autoconer",
          link: "splice-strength-checking",
        });
      }
      if (userModules.includes("spinning")) {
        dashboardMenuItems.push({
          menu: "Spinning",
          link: "count-change-record",
        });
      }
      if (userModules.includes("lap")) {
        dashboardMenuItems.push({ menu: "Lap", link: "sliver-lap" });
      }
      if (userModules.includes("finisher_drawing")) {
        dashboardMenuItems.push({
          menu: "Finisher Drawing",
          link: "finisher-drawing-yards",
        });
      }
      if (userModules.includes("simplex_checking")) {
        dashboardMenuItems.push({
          menu: "Simplex",
          link: "simplex-checking-draft",
        });
      }

      if (userModules.includes("task_sheets")) {
        dashboardMenuItems.push({
          menu: "Task Sheets",
          link: "task-sheets-complaints",
        });
      }
      // if (userModules.includes("sastra")) {
      //   dashboardMenuItems.push({ menu: "Sastra", link: "/blowroom-norms" });
      // }

      if (role === "SUB_MANAGER") {
        //thi is for conditionally get the report link based on the userModules
        let reportsLink = "/"; // Default link

        if (userModules.includes("blow_room")) {
          reportsLink = "lap-feed-report";
        } else if (userModules.includes("carding")) {
          reportsLink = "individual-carding-waste-report";
        } else if (userModules.includes("autoconer")) {
          reportsLink = "splice-strength-report";
        } else if (userModules.includes("spinning")) {
          reportsLink = "count-change-report";
        } else if (userModules.includes("lap")) {
          reportsLink = "sliver-lap-report";
        } else if (userModules.includes("finisher_drawing")) {
          reportsLink = "finisher-drawing-report";
        } else if (userModules.includes("simplex_checking")) {
          reportsLink = "simplex-checking-report";
        }

        dashboardMenuItems.push(
          { menu: "Norms", link: "blowroom-norms" },
          { menu: "Sasta", link: "sasta-reports" },
          { menu: "Reports", link: reportsLink }
        );
      }
    } else if (role === "MANAGING_DIRECTOR") {
      dashboardMenuItems = [
        { menu: "Notifications", link: "md-notifications" },
        { menu: "Task Sheets", link: "md-task-sheets" },
        // {menu:"Machine Settings Correction",link:"general-machine-setting-correction"},
      ];
    }

    localStorage.setItem("dashboardMenu", JSON.stringify(dashboardMenuItems));
    localStorage.setItem("activeMenuItem", "dashboard");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let userCodeError = "";
    let passwordError = "";

    if (!formData.userCode) {
      userCodeError = "User Code is required";
    }

    if (!formData.password) {
      passwordError = "Password is required";
    }

    if (userCodeError || passwordError) {
      setErrors({ userCode: userCodeError, password: passwordError });
    } else {
      setErrors({ userCode: "", password: "" });
      const payload = {
        employeeCode: formData.userCode,
        password: formData.password,
      };

      mutate(payload, {
        onSuccess: (res) => {
          if (res.data.auth_token) {
            const accessToken = res?.data?.auth_token;
            const role = res?.data?.user?.role;
            const name = res?.data?.user?.name;
            setAuth({ role, accessToken });
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("authorization", accessToken);
            localStorage.setItem("roles", role);
            localStorage.setItem("name", name);
            localStorage.setItem("id", res?.data?.user?.id);
            localStorage.setItem(
              "userModules",
              JSON.stringify(res?.data?.user?.modules)
            );
            localStorage.setItem(
              "userSubModules",
              JSON.stringify(res?.data?.user?.subModules)
            );

            // setCookie("user", user, { path: "/" });

            // Determine the navigation path based on userModules
            // const findModulePath = () => {
            //   for (let option of ALL_FLOWS_OPTIONS) {
            //     if (res?.data?.user?.userModules?.includes(option.flow)) {
            //       return option.path;
            //     }
            //   }
            //   return null;
            // };

            // const navigateToPath = () => {
            //   const modulePath = findModulePath();
            //   const path = modulePath || "login";
            //   navigate(`/${path}`, { replace: true });
            //   localStorage.setItem("activeMenuItem", path);
            // };

            // if (role === "EMPLOYEE" || role === "SUB_MANAGER") {
            //   // Redirect to the specified route upon successful login
            //   navigateToPath();
            // } else if (role === "MANAGER") {
            //   navigate("/manager-blowroom-notifications", { replace: true });
            //   localStorage.setItem(
            //     "activeMenuItem",
            //     "manager-blowroom-notifications"
            //   );
            // } else if (role === "ADMIN") {
            //   navigate("/admin-user", { replace: true });
            //   localStorage.setItem("activeMenuItem", "admin-user");
            // } else {
            //   navigate("/admin-notifications", { replace: true });
            //   localStorage.setItem("activeMenuItem", "admin-notifications");
            // }

            if (
              role === "EMPLOYEE" ||
              role === "SUB_MANAGER" ||
              role === "MANAGER" ||
              role === "ADMIN" ||
              role === "MANAGING_DIRECTOR"
            ) {
              navigate("/dashboard", { replace: true });
              localStorage.setItem("activeMenuItem", "dashboard");
              dashboardMenuSetting(res);
            } else if (role === "TELEVISION") {
              navigate("/television", { replace: true });
            }

            // Show a success message in the toast
            handleToastMsg("Login successful", "success");
          } else {
            // Handle unsuccessful login with an error message
            handleToastMsg("Invalid credentials", "error");
          }
        },
        onError: (error) => {
          // Handle any other errors that may occur during the login process
          handleToastMsg("An error occurred during login", "error");
        },
      });
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={6} style={{ height: "100vh" }}>
        <img
          src={LoginImage}
          alt="Login Image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xs={6} container py="10%">
        <Grid item xs={12} container justifyContent={"center"}>
          <img src={Logo} alt="Logo" height={80} width={80} />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <p className="welcome-text">Welcome Guest</p>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <p className="login-text">Login</p>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={6} xs={10} container alignItems="center">
            <TextField
              label="Enter a user code"
              required
              type="number"
              name="userCode"
              value={formData.userCode}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "16px" }}
              error={!!errors.userCode}
              helperText={errors.userCode}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              label="Password"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "16px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <Grid container justifyContent="center">
              <Button
                disabled={isLoading}
                variant="contained"
                className="login-btn"
                style={{
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "6px",
                  padding: "10px 24px",
                  backgroundColor: "#f01013",
                  color: "#ffffff",
                  fontSize: "15px",
                  fontWeight: "700",
                }}
                onClick={handleSubmit}
              >
                {!isLoading ? (
                  "Sign in"
                ) : (
                  <CircularProgress size={25} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item container style={{ position: "absolute", bottom: 0 }}>
        <Footer />
      </Grid> */}
    </Grid>
  );
}

export default Login;
