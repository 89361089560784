import React, { useEffect, useState } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  createFilterOptions,
  Button,
  CircularProgress,
} from "@mui/material";
import { getVariety } from "../../Blowroom/mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { individualCardingMutate } from "./mutation";
import { HALL_NO } from "../../../config/masterdata";

function IndividualCardingWaste() {
  const filter = createFilterOptions();
  const { mutate, isLoading } = useMutation(individualCardingMutate);

  const [tableData, setTabledata] = useState({});
  const [cardingLineNo, setCardingLineNo] = useState("");
  const [cardingMachineNoOptions, setCardingMachineNoOptions] = useState([]);
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [cardingMachineNo, setCardingMachineNo] = useState("");
  const [variety, setVariety] = useState("");
  const [settingParticular, setSettingParticular] = useState({});
  const [feedback, setFeedback] = useState("");
  const [hallNo, setHallNo] = React.useState("");
  useEffect(() => {
    let showNumbers = [];
    let options = [];
    switch (cardingLineNo) {
      case 1:
        showNumbers = [10, 11, 12];
        options = [
          "Cylinder Speed",
          "Flats Speed",
          "Delivery Speed",
          "Wing Setting 1",
          "Liker - In Speed 1",
          "Liker - In Speed 2",
          "Liker - In Speed 3",
        ];
        break;
      case 2:
        showNumbers = [7, 8, 9];
        options = [
          "Cylinder Speed",
          "Flats Speed",
          "Delivery Speed",
          "Wing Setting 1",
          "Liker - In Speed 1",
        ];
        break;
      case 3:
        showNumbers = [13, 14];
        options = [
          "Cylinder Speed",
          "Flats Speed",
          "Delivery Speed",
          "Wing Setting 1",
          "Liker - In Speed 1",
        ];
        break;
      case 4:
        showNumbers = [1, 2, 3, 4, 5, 6];
        options = [
          "Cylinder Speed",
          "Flats Speed",
          "Delivery Speed",
          "Wing Setting 1",
          "Wing Setting 2",
          "Liker - In Speed 1",
          "Liker - In Speed 2",
          "Liker - In Speed 3",
        ];
        break;
      default:
        showNumbers = [];
        options = [];
    }
    setCardingMachineNoOptions(showNumbers);
    setOptionsToShow(options);
    setCardingMachineNo(""); // Reset cardingMachineNo when cardingLineNo changes
    setSettingParticular({}); // remove the old data
    setTabledata({}); // remove the old data
  }, [cardingLineNo]);

  const wasteData = [
    "Flat Waste",
    "Liker - In Waste",
    "Back Suction Hood",
    "Front Suction Hood",
    "Doffer Bruch Waste",
    "Undercage Waste",
    "Total Waste",
  ];

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  /**calculate the total waste */
  const calculateTotalWaste = (data) => {
    const wasteTypes = wasteData?.filter((type) => type !== "Total Waste"); //for skip the Total waste field
    return wasteTypes.reduce((total, key) => {
      return total + (data[key] || 0);
    }, 0);
  };

  /**handle add all the data */
  const handleAddTableData = (value, type) => {
    const updatedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setTabledata((prev) => {
        const newData = { ...prev, [type]: updatedValue };

        /**handle for total waste and total weight */
        const totalWaste = calculateTotalWaste(newData);
        const totalWeight =
          type === "production"
            ? (updatedValue || 0) + totalWaste
            : (tableData["production"] || 0) + totalWaste;

        newData["Total Waste"] = parseFloat(totalWaste.toFixed(2));
        newData["Total Weight"] = parseFloat(totalWeight.toFixed(2));

        /**handle percentage calculate */
        wasteData.forEach((wasteType) => {
          newData[`${wasteType}Percentage`] =
            totalWeight && newData[wasteType]
              ? parseFloat(
                  ((newData[wasteType] / totalWeight) * 100).toFixed(2)
                )
              : "";
        });

        return newData;
      });
    }
  };

  const handleSettingParticularData = (value, type) => {
    const updatedValue = value === "" ? "" : parseFloat(value);
    const formattedType = type
      .replace(/[\s-]+/g, "")
      .replace(/^./, (match) => match.toLowerCase());

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      /**this update is for api payload */
      setSettingParticular((prev) => {
        const newData = { ...prev, [formattedType]: updatedValue };
        return newData;
      });

      /**this is for showing values in ui */
      setTabledata((prev) => ({ ...prev, [type]: updatedValue }));
    }
  };

  /**handle feedback */
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const wasteParticular = wasteData
    .map((wasteType) => {
      return {
        WasteType: wasteType,
        WasteWeight: tableData[wasteType] ?? "",
        WasteInPercentage: tableData[`${wasteType}Percentage`] ?? "",
      };
    })
    .filter((item) => item.WasteWeight !== "" || item.WasteInPercentage !== "");

  const queryClient = useQueryClient();
  /**handle submit details */
  const handleSubmit = () => {
    const payload = {
      variety: variety,
      lineNo: cardingLineNo,
      machineNo: cardingMachineNo,
      production: tableData?.production || "",
      totalWeight: tableData?.["Total Weight"] || "",
      settingParticular,
      wasteParticular,
      feedback: feedback,
      hall: hallNo,
    };

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        resetForm();
        queryClient.invalidateQueries("get-variety");
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const resetForm = () => {
    setVariety("");
    setCardingMachineNo("");
    setSettingParticular({});
    setTabledata({});
    setHallNo("");
    setFeedback("");
    setCardingLineNo("");
  };

  return (
    <ContainerWrapper container>
      <Grid
        container
        item
        md={12}
        sx={{ marginRight: "20px" }}
        alignContent={"flex-start"}
        rowGap={3}
      >
        <Grid container item md={12} rowGap={3}>
          <h2 className="heading">Individual Carding Waste</h2>
        </Grid>
        <Grid container item md={12} rowGap={3}>
          <Grid container item md={6} alignItems={"center"}>
            <Grid item xs={5} className="content">
              Variety :
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                value={variety}
                fullWidth
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setVariety(newValue || "");
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setVariety(newValue || "");
                  } else {
                    setVariety(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue.toUpperCase());
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : ""
                } // Ensure option is a string
                renderOption={(props, option) => <li {...props}>{option}</li>}
                sx={{ width: 180 }}
                freeSolo
                renderInput={(params) => (
                  <TextField required {...params} label="Variety" fullWidth />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item md={6} alignItems={"center"}>
            <Grid item xs={5} className="content">
              Hall Number :
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={hallNo}
                select
                onChange={(e) => setHallNo(parseInt(e.target.value))}
                type="number"
                sx={{ width: 180 }}
                id="outlined-basic"
                label="Hall Number"
                variant="outlined"
                // onInput={handleCardingLineNumInput}
              >
                {HALL_NO?.map((hall) => (
                  <MenuItem key={hall} value={hall}>
                    {hall}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container item md={12} alignItems={"center"} rowGap={3}>
            <Grid container item md={6} alignItems={"center"}>
              <Grid item xs={5} className="content">
                Carding line no :
              </Grid>
              <Grid item xs={7}>
                <TextField
                  sx={{ width: 180 }}
                  select
                  value={cardingLineNo}
                  onChange={(e) => setCardingLineNo(Number(e.target.value))}
                >
                  {[1, 2, 3, 4].map((lineNo) => (
                    <MenuItem key={lineNo} value={lineNo}>
                      {lineNo}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container item md={6} alignItems={"center"}>
              <Grid item xs={5} className="content">
                Carding machine no :
              </Grid>
              <Grid item xs={7}>
                <TextField
                  sx={{ width: 180 }}
                  select
                  value={cardingMachineNo}
                  onChange={(e) => setCardingMachineNo(Number(e.target.value))}
                >
                  {cardingMachineNoOptions.map((machineNo) => (
                    <MenuItem key={machineNo} value={machineNo}>
                      {machineNo}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {cardingLineNo && cardingMachineNo && (
          <Grid container item md={8} rowGap={3} className="wastestyles">
            <Grid container item md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Waste type</TableCell>
                      <TableCell>Waste weight in Kg</TableCell>
                      <TableCell>Waste in percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wasteData?.map((type, index) => (
                      <TableRow key={index}>
                        <TableCell>{type}</TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            disabled={type === "Total Waste"}
                            value={tableData[type] ?? ""}
                            // onInput={(e) => {
                            //   let value = e.target.value;
                            //   value = value.replace(/[^0-9.]/g, "");

                            //   const parts = value.split('.');
                            //   if (parts.length > 2) {
                            //     // Remove extra dots
                            //     value = parts[0] + '.' + parts.slice(1).join('');
                            //   }

                            //   e.target.value = value;
                            // }}
                            onChange={(e) =>
                              handleAddTableData(e.target.value, type)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={tableData[`${type}Percentage`] ?? ""}
                            disabled={true}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container item md={12}>
              <TableContainer component={Paper} className="wastestyles">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Production</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="number"
                          value={tableData["production"] ?? ""}
                          onChange={(e) =>
                            handleAddTableData(e.target.value, "production")
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      {" "}
                      <TableCell>Total weight</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={tableData["Total Weight"] ?? ""}
                          disabled={true}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container item md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {optionsToShow?.map((option, index) => (
                      <TableRow key={index}>
                        <TableCell>{option}</TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={tableData[option] ?? ""}
                            onChange={(e) =>
                              handleSettingParticularData(
                                e.target.value,
                                option
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        <Grid container item md={12}>
          <TextField
            onChange={handleFeedbackChange}
            value={feedback}
            fullWidth
            size="lg"
            name="Size"
            label="Feedback"
          />
        </Grid>
        <Grid container item md={12} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="submitbutton"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
}

export default IndividualCardingWaste;
