import React, { useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  createFinalSimplexCheckingData,
  getSimplexCheckingData,
  getSimplexCheckingDraft,
} from "./mutation";
import { handleToastMsg } from "../../components/Toast";
import "./simplex.css";

const SimplexCheckingDrafts = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading: isSubmitLoading } = useMutation(
    createFinalSimplexCheckingData
  );
  const navigate = useNavigate();

  //simplex drafts data
  const { data: simplexCheckingDraftsData } = useQuery(
    "simplex-checking-drafts-data",
    async () => {
      try {
        const response = await getSimplexCheckingDraft();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  //simplex all data
  const { data: simplexCheckingData } = useQuery(
    "simplex-checking-all-data",
    async () => {
      try {
        const response = await getSimplexCheckingData();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  const currentDate = dayjs().format("DD/MM/YYYY");

  // const filteredSimplexData = simplexCheckingData?.filter(
  //   (item) => dayjs(item.date).format("DD/MM/YYYY") === currentDate
  // );

  //this is for checking all the machine numbers data is there or not
  const [allMachineNumbers] = useState([...Array(13).keys()].map((i) => i + 1));
  const apiMachineNumbers = simplexCheckingData?.map((item) => item.machineNo);
  const isFinalSubmit = allMachineNumbers?.every((number) =>
    apiMachineNumbers?.includes(number)
  );

  //handle open form
  const handleOpenForm = () => {
    queryClient.invalidateQueries("get-variety");
    navigate("/simplex-checking", {
      state: { isSimplexChecking: true, isDraft: false },
    });
  };

  //handle correction
  const handleCorrection = (item) => {
    queryClient.invalidateQueries("get-variety");
    navigate("/simplex-checking", {
      state: { data: item, isSimplexChecking: true, isDraft: true },
    });
  };

  //handle submit
  const handleSubmit = async () => {
    try {
      const payload = { finalSubmit: true, date: currentDate };

      mutate(payload, {
        onSuccess: (res) => {
          queryClient.invalidateQueries("get-variety");
          handleToastMsg("Data updated successfully", "success");
        },
        onError: (error) => {
          handleToastMsg("Data Error Occurred", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "2%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">SimplexChecking</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid mt={2} item xs={12}>
          <h4 className="headingsub">Simplex Checking Drafts Data</h4>
        </Grid>
        <Grid mt={1} item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1vw" }}>Date</TableCell>
                  <TableCell style={{ width: "1vw" }}>MachineNo</TableCell>
                  <TableCell style={{ width: "1vw" }}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {simplexCheckingDraftsData?.length > 0 &&
                  simplexCheckingDraftsData?.map((item, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell style={{ width: "1vw" }}>
                          {dayjs(item?.date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell style={{ width: "1vw" }}>
                          {item?.machineNo}
                        </TableCell>

                        <TableCell style={{ width: "1vw" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: "100px",
                              height: "40px",
                            }}
                            onClick={() => handleCorrection(item)}
                          >
                            Correction
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid mt={2} container spacing={2}>
        <Grid mt={2} item xs={12}>
          <h4 className="headingsub">Simplex Checking All Data</h4>
        </Grid>
        <Grid mt={1} item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1vw" }}>Date</TableCell>
                  <TableCell style={{ width: "1vw" }}>MachineNo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {simplexCheckingData?.length > 0 &&
                  simplexCheckingData?.map((item, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell style={{ width: "1vw" }}>
                          {dayjs(item?.date).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell style={{ width: "1vw" }}>
                          {item?.machineNo}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid mt={3} item xs={12} sm={12} container justifyContent={"flex-end"}>
        <Button
          variant="contained"
          color="primary"
          // className="addbutton"
          // style={{ width: "200px", height: "50px" }}
          onClick={() => handleOpenForm()}
          sx={{ mr: 2 }}
        >
          Add Record
        </Button>
        <Button
          variant="contained"
          color="primary"
          // className="submitbutton"
          style={{ width: "150px", height: "40px" }}
          onClick={handleSubmit}
          disabled={!isFinalSubmit || isSubmitLoading}
        >
          {isSubmitLoading ? <CircularProgress size={24} /> : "Final Submit"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SimplexCheckingDrafts;
