import apiClient from "../../../utils/clients/axios";

// ASCE draft
export const createAscesDraft = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/spinning/asces_draft",
    payload
  );
  return response.data;
};

// ASCE draft by ID
export const getAscesDraft = async () => {
  const response = await apiClient.get(
    `/api/quality_control/spinning/asces_draft/`
  );
  return response.data;
};

export const updateAscesDraft = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/spinning/asces_draft/${id}`,
    payload
  );
  return response.data;
};
