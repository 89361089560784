import React, { useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { MasterData } from "../Blowroom/mutation/manager/blowroomReportMutation";
import { useQuery } from "react-query";
import { HALL_NO } from "../../config/masterdata";

const AlarmCops = () => {
  const [selectedNumber] = useState(0);
  const [numTrailColumns, setNumTrailColumns] = useState(1);

  const [formValues, setFormValues] = useState({
    variety: "",
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    feedback: "",
    slub_code: "",
  });
  // const handleAutoConerChange = (event) => {
  //     const { value } = event.target;
  //     setSelectedNumber(value);
  //     setNumTrailColumns(1); // Reset the number of trail columns
  // };

  // const [feedback, setFeedback] = useState('');
  // const handleFeedbackChange = (event) => {
  //     const { value } = event.target;
  //     setFeedback(value);
  // };

  const { data: varietyOptions } = useQuery(
    "alarm-cops",
    async () => {
      try {
        const response = await MasterData();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  const handleAddShift = () => {
    setNumTrailColumns(numTrailColumns + 1);
  };

  // const handleFormChange = (event, field) => {
  //     let { value } = event.target;

  //     if (field === 'wax') {
  //         value = value === 'true';
  //     } else if (field === 'variety' || field === 'feedback' ||field === 'denier' || field==='slub_code') {
  //         if (value) {
  //             value = value.toUpperCase();
  //         }
  //     } else if (['counts', 'auto_coner_no', 'drum_number', 'no_of_readings'].includes(field)) {
  //         value = !isNaN(value) ? parseFloat(value) : value;
  //     }
  //     setFormValues({ ...formValues, [field]: value });
  //     // setUserEnteredValue(value)
  // };
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    if (
      field === "variety" ||
      field === "denier" ||
      field === "feedback" ||
      field === "slub_code"
    ) {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "auto_coner_no",
        "hallNo",
        "drumNo",
        "csp_value",
        "no_of_readings",
      ].includes(field)
    ) {
      const floatValue = value === "" ? "" : parseFloat(value);
      if (value < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = !isNaN(floatValue) && floatValue >= 0 ? floatValue : "";
      }
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };
  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2>Alarm cops</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item sm={6} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div style={{ padding: "10px" }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          options={varietyOptions || []}
                          fullWidth
                          freeSolo
                          value={formValues.variety}
                          onChange={(e, newValue) => {
                            if (newValue !== null) {
                              handleFormChange(
                                { target: { value: newValue } },
                                "variety"
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Variety"
                              placeholder="Variety"
                              // InputLabelProps={{ shrink: true }}
                              value={formValues.variety}
                              onChange={(e) => handleFormChange(e, "variety")}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* <div style={{ padding: '10px' }}>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px' }}>
                                                <Autocomplete
                                                    options={varietyOptions && varietyOptions.map((option) => option.variety)}
                                                    getOptionLabel={(option) => option} 
                                                    fullWidth
                                                    freeSolo
                                                    value={formValues.variety}
                                                    onChange={(e, newValue) => {
                                                        if (newValue !== null) {
                                                            handleFormChange({ target: { value: newValue } }, 'variety');
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Variety"
                                                            placeholder='Variety'
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formValues.variety}
                                                            onChange={(e) => handleFormChange(e, 'variety')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div> */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      value={formValues.denier}
                      label="Denier"
                      onChange={(e) => handleFormChange(e, "denier")}
                      onInput={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Slub Code"
                      value={formValues.slub_code}
                      label="Slub Code"
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={6} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto coner number</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      label="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item md={9} xs={11} my={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Auto Coner Number</TableCell>
                <TableCell>Drum number</TableCell>
                {[...Array(numTrailColumns)].map((_, index) => (
                  <TableCell key={`trailHeader_${index}`}>{`Shift ${
                    index + 1
                  }`}</TableCell>
                ))}
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: selectedNumber }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id={`drum-number-${index + 1}`}
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={`drum_number_${index + 1}`}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      id={`drum-number-${index + 1}`}
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={`drum_number_${index + 1}`}
                    />
                  </TableCell>
                  {[...Array(numTrailColumns)].map((_, columnIndex) => (
                    <TableCell key={`trail_${index}_${columnIndex}`}>
                      <TextField
                        size="small"
                        id={`standard-number-trail-${index}_${columnIndex}`}
                        type="number"
                        step="0.01"
                        variant="standard"
                        style={{ width: "50px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name={`trail_${index + 1}_shift_${
                          columnIndex + 1
                        }_total_cuts`}
                      />
                    </TableCell>
                  ))}
                  {/* <TableCell>
                    <TextField
                      size="small"
                      id={`total-${index + 1}`}
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: '50px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={`total_${index + 1}`}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container>
        <Grid mt={3} item xs={8} sm={8}>
          <TextField
            fullWidth
            onChange={(e) => handleFormChange(e, "feedback")}
            size="lg"
            name="Size"
            label="Feedback (or) Any Queries"
            value={formValues.feedback}
          />
        </Grid>
      </Grid>
      <Grid item my={3} xs={12} container justifyContent={"flex-end"} pr={3}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "150px", height: "50px" }}
          onClick={handleAddShift}
          sx={{ mr: 2 }}
        >
          Add Shift
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "150px", height: "50px" }}
        >
          Final Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default AlarmCops;
