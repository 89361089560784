import { CircularProgress, Dialog, DialogContentText } from "@mui/material";

const DialogForLoadingMachine = ({ open }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          height: "150px",
          width: "350px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        },
      }}
    >
      <DialogContentText>Loading please wait...</DialogContentText>
      <CircularProgress />
    </Dialog>
  );
};

export default DialogForLoadingMachine;