import { styled } from "@mui/material";
import React from "react";

const InputStyled = styled("input")`
  padding: 8px 16px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0.9rem 1rem;
  border-radius: 4px;
  border: 1px solid #000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

function Input({
  type,
  value,
  onChange,
  placeholder,
  className,
  name,
  disabled,
}) {
  return (
    <InputStyled
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      name={name}
      disabled={disabled}
    />
  );
}

export default Input;
