import apiClient from "../../../../utils/clients/axios";

export const lapFeedReport = async (payload) => {
  const response = await apiClient.get(
    `api/quality_control/blow_room/lap_feed_15_24_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
    payload
  );

  return response;
};
export const chuteFeedCottonReport = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/chute_feed_1_5_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
    payload
  );

  return response;
};
export const chuteFeedOthersReport = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/chute_feed_7_14_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
    payload
  );

  return response;
};
export const dropTestReport = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/drop_test_report/?from_date=${payload.from_date}&to_date=${payload.to_date}`,
    payload
  );

  return response;
};

export const managerBlowroomNotification = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/notifications/`,
    payload
  );
  return response;
};

export const managerSpinningNotification = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/spinning/notifications/`,
    payload
  );
  return response;
};

export const managerCountChangeNotification = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/count_change/?red_count_value=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const managerCotCheckingNotification = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/cot_checks/?missing_cot_check=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const managerHarinessNotification = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/hairnesses/?missing_hairness=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const managerASCNotification = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/asces/?missing_asc=1`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const managerAutoconerNotification = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/notifications/`,
    payload
  );
  return response;
};

// sastra

export const managerSastraNotification = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/sastra/notifications/`,
    payload
  );
  return response;
};
// export const MasterData = async (payload) => {
//   const response = await apiClient.get(
//     `/api/quality_control/autoconer/autoconer_variety_master_data/`,
//     payload
//   );
//   return response;
// };

export const MasterData = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/varieties/?count_change=1`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SpinningHarinessVariety = async () => {
  try {
    const response = await apiClient.get(
      `/api/quality_control/spinning/varieties/?hariness=1`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CotCheckingPost = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning/cot_checks/`,
    payload
  );
  return response;
};

export const HarinessPost = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning/hairnesses/`,
    payload
  );
  return response;
};

export const ASCPost = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning/asces/`,
    payload
  );
  return response;
};

export const CountChangePost = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/spinning/count_change/`,
    payload
  );
  return response;
};

export const UpdateManagerSpinningNotification = async (params) => {
  const { id, payload } = params;

  const response = await apiClient.post(
    `/api/quality_control/spinning/count_change/${id}/create_manager_feedback/`,
    payload
  );
  return response;
};

export const UpdateManagerSpinningCotCheckNotification = async (params) => {
  const { id, payload } = params;

  const response = await apiClient.post(
    `/api/quality_control/spinning/cot_checks/${id}/create_manager_feedback/`,
    payload
  );
  return response;
};

export const UpdateManagerSpinningHarinessNotification = async (params) => {
  const { id, payload } = params;

  const response = await apiClient.post(
    `/api/quality_control/spinning/hairnesses/${id}/create_manager_feedback/`,
    payload
  );
  return response;
};

export const UpdateManagerSpinningASCNotification = async (params) => {
  const { id, payload } = params;

  const response = await apiClient.post(
    `/api/quality_control/spinning/asces/${id}/create_manager_feedback/`,
    payload
  );
  return response;
};

export const BlowRoom_Lap_feed = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/lap_feed_15_24_get/`,
    payload
  );
  return response;
};

export const BlowRoom_chud_feed = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/chute_feed_15_24_get/`,
    payload
  );
  return response;
};

export const BlowRoom_chud = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/chute_feed_1_5_get/`,
    payload
  );
  return response;
};

export const BlowRoom_chute_feet = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/chute_feed_7_14_get/`,
    payload
  );
  return response;
};

export const BlowRoom_Drop_Test = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/blow_room/drop_test_get/`,
    payload
  );
  return response;
};
