import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

instance.interceptors.request.use(async function (config) {
  const loggedInToken = localStorage.getItem("authorization");
  config.headers.Authorization = `Token ${loggedInToken}`;

  return config;
});

export default instance;
