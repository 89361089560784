import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import CustomButton from "../../../../components/Button";
import {
  chuteFeed_1_5_update,
  lapFeed_15_24_update,
  chuteFeed_7_14_update,
} from "../../../Blowroom/mutation/manager/notificationsUpdateMutation";
import { useMutation, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../../components/Toast";

const AdminResponse = ({ selectedItemData, selectedType, setModelToggle }) => {
  const [feedback, setFeedback] = React.useState("");
  const { mutate: chute15Mutate } = useMutation(chuteFeed_1_5_update);
  const { mutate: chute15_24Mutate } = useMutation(lapFeed_15_24_update);
  const { mutate: chute7_14Mutate } = useMutation(chuteFeed_7_14_update);
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    const payload = {
      id: selectedItemData.id,
      feedback,
    };
    if (selectedType === "ChuteFeed1_5") {
      chute15Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("admin-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    } else if (selectedType === "ChuteFeed7_14") {
      chute7_14Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("admin-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    } else {
      chute15_24Mutate(payload, {
        onSuccess: (res) => {
          setModelToggle(false);
          setFeedback("");
          queryClient.invalidateQueries("admin-notifications");
          handleToastMsg("Updated Successfully", "success");
        },
      });
    }
  };

  return (
    <Grid container rowGap={3}>
      <Grid item>
        <TableContainer component={Paper} style={{ margin: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sl.No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Carding Number</TableCell>
                <TableCell>Variety</TableCell>
                <TableCell>Production</TableCell>
                <TableCell>FS Weight</TableCell>
                <TableCell>Waste Percentage</TableCell>
                <TableCell>Previous Percentage</TableCell>
                <TableCell>Cylinder Speed</TableCell>
                <TableCell>Liker In Speed</TableCell>
                <TableCell>Flats Speed</TableCell>
                <TableCell>Delivery Speed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItemData && Object.keys(selectedItemData).length > 0 && (
                <TableRow key={1}>
                  <TableCell>
                    {selectedItemData.timestamp.slice(0, 10)}
                  </TableCell>
                  <TableCell>{selectedItemData.carding_num}</TableCell>
                  <TableCell>{selectedItemData.variety}</TableCell>
                  <TableCell>{selectedItemData.production}</TableCell>
                  <TableCell>{selectedItemData.fs_wt}</TableCell>
                  <TableCell>{selectedItemData.waste_perc}</TableCell>
                  <TableCell>{selectedItemData.previous_perc}</TableCell>
                  <TableCell>{selectedItemData.cylinder_speed}</TableCell>
                  <TableCell>{selectedItemData.liker_in_speed}</TableCell>
                  <TableCell>{selectedItemData.flats_speed}</TableCell>
                  <TableCell>{selectedItemData.delivery_speed}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Textarea size="lg" name="Size" placeholder="Feedback" />
      </Grid>
      <Grid item xs={10} sm={12}>
        <Box display="flex" justifyContent="center">
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminResponse;
