import apiClient from "../../../utils/clients/axios";

export const UTrailGet = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/u-trail-get/`,
    payload
  );
  return response;
};
export const UTrailCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/u-trail-create/`,
    payload
  );
  return response;
};
