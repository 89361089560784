import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const TableComponent = ({
  data,
  setData,
  handleFsWasteChange,
  handleProductionChange,
  handleWasteChange,
  handleVarietyChange,
  apiData,
}) => {
  const filter = createFilterOptions();

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "98%",
        overflowX: "auto",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%" }}>Carding number</TableCell>
            <TableCell>Variety</TableCell>
            <TableCell>FS Waste</TableCell>
            <TableCell>Production</TableCell>
            <TableCell>Waste %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.carding_num}</TableCell>
              <TableCell style={{ width: "130px" }}>
                <Autocomplete
                  style={{ width: "100%" }}
                  selectOnFocus
                  handleHomeEndKeys
                  clearOnBlur
                  freeSolo
                  value={row?.variety || ""}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  onChange={(event, newValue) =>
                    handleVarietyChange(event, newValue, index)
                  }
         
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(inputValue.toUpperCase());
                    }

                    return filtered;
                  }}
                  options={apiData || []} // Use the API data for Autocomplete options
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : ""
                  } // Ensure option is a string
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  id={`fs_wt_${index}`} 
                  type="number"
                  step="0.01"
                  variant="standard"
                  // onInput={(e) => {
                  //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  // }}
                  style={{ width: "50px" }}
                  value={row.fs_wt}
                  onChange={(e) => handleFsWasteChange(e, row, index)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  id={`production_${index}`}
                  type="number"
                  step="0.01"
                  variant="standard"
                  // onInput={(e) => {
                  //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  // }}
                  style={{ width: "50px" }}
                  value={row.production}
                  onChange={(e) => handleProductionChange(e, row, index)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  id={`waste_percentage_${index}`}
                  type="number"
                  step="0.01"
                  variant="standard"
                  disabled={true}
                  style={{ width: "50px" }}
                  value={row.totalWastePercentage}
                  onChange={(e) => handleWasteChange(e, index)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
