import { Grid, Select, MenuItem, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import apiClient from "../../utils/clients/axios";
import { Box, Button } from "@mui/material";
import "./sastacss.css";

function SastaReports() {
  const [formData, setFormData] = useState({
    ShiftEnabled: false,
    Shift: null,
    FlowEnabled: false,
    Flow: null,
    flowItemsEnabled: false,
    flowItems: null,
    from_date: null,
    to_date: null,
  });

  const [ShiftShow] = useState([1, 2, 3]);
  const [FlowShow] = useState([
    { label: "CARDING", value: "CARDING" },
    { label: "SIMPLEX", value: "SIMPLEX" },
    { label: "COMBER", value: "COMBER" },
    { label: "BREAKING DRAWING", value: "BREAKING_DRAWING" },
    { label: "FINISHER DRAWING", value: "FINISHER_DRAWING" },
  ]);
  // const [FlowShow] = useState(["CARDING", "SIMPLEX", "COMBER", "BREAKING_DRAWING", "FINISHER_DRAWING"]);
  const [flowItemsOptions, setflowItemsOptions] = useState([]);

  useEffect(() => {
    let showNumbers = [];
    switch (formData.Flow) {
      case "CARDING":
        showNumbers = ["WRAPPING"];
        break;
      case "SIMPLEX":
        showNumbers = ["WRAPPING", "STRETCH"];
        break;
      case "COMBER":
        showNumbers = ["WRAPPING", "NOILS"];
        break;
      case "BREAKING_DRAWING":
        showNumbers = ["WRAPPING"];
        break;
      case "FINISHER_DRAWING":
        showNumbers = ["WRAPPING", "A"];
        break;
      default:
        showNumbers = [];
    }
    setflowItemsOptions(showNumbers);
    setFormData((prev) => ({ ...prev, flowItems: [] }));
  }, [formData.Flow]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleshiftChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleRest = (field, value) => {
    setFormData({
      ShiftEnabled: false,
      Shift: null,
      FlowEnabled: false,
      Flow: null,
      flowItemsEnabled: false,
      flowItems: null,
      from_date: null,
      to_date: null,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formatDate = (date) => {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";
    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date))
      : "";

    // const flow =formData.Flow
    // const flowtpye=flow.toUpperCase()

    const type = formData.flowItems;
    const shift = Number(formData.Shift);
    try {
      const response = await apiClient.get(
        `api/quality_control/sastra/reports?type=${type}&shift=${shift}&from_date=${formattedFromDate}&to_date=${formattedToDate}`
      );
      //  `api/quality_control/sastra/reports?type=${type}&shift=${shift}&from_date=${formattedFromDate}&to_date=${formattedToDate}`)
      //   {
      //     params: {
      //            // flow:formData.Flow,
      //

      //           },

      //   }
      // );
      const headers = Object.keys(response.data[0]);

      const worksheetData = [
        headers.map((header) => header.replace(/_/g, " ")),
      ];

      response.data.forEach((item) => {
        const row = headers.map((header) => item[header]);
        worksheetData.push(row);
      });
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Sastra_report.xlsx");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Container sx={{ mb: 2 }}>
      <Grid
        container
        item
        md={12}
        xs={12}
        alignItems={"center"}
        style={{ marginTop: "10px" }}
      >
        <h1 className="heading">Sasta Reports</h1>
      </Grid>

      <Grid container item md={12} xs={12}>
        <Grid container style={{ marginTop: "40px" }}>
          <Grid item xs={4}>
            <p className="content"> Shift</p>
          </Grid>
          <Grid item xs={8}>
            <Select
              labelId="Shift-label"
              id="Shift"
              name="Shift"
              type="number"
              value={formData.Shift || ""}
              disabled={formData.reportType === "consolidated"}
              onChange={(event) =>
                handleshiftChange("Shift", event.target.value)
              }
              style={{
                width: "50%",
                border: "1px solid #000",
                padding: "0px 10px ",
                marginLeft: "10px",
              }}
            >
              {ShiftShow.map((lineNo) => (
                <MenuItem key={lineNo} value={lineNo}>
                  {lineNo}
                </MenuItem>
              ))}
            </Select>{" "}
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "40px" }}>
          <Grid item xs={4}>
            <p className="content">Flow</p>
          </Grid>
          <Grid item xs={8}>
            <Select
              labelId="Flow-label"
              id="Flow"
              name="Flow"
              value={formData.Flow || ""}
              disabled={formData.reportType === "consolidated"}
              onChange={(event) =>
                handleInputChange("Flow", event.target.value)
              }
              style={{
                width: "50%",
                border: "1px  solid #000",
                padding: "0px 10px",
                marginLeft: "10px",
              }}
            >
              {FlowShow.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        {formData.Flow && (
          <Grid container spacing={4} style={{ marginTop: "40px" }}>
            <Grid item xs={4}>
              <p className="content">Flow Item</p>
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                labelId="Flow-label"
                id="flowItems"
                name="flowItems"
                value={formData?.flowItems || ""}
                onChange={(event) =>
                  handleInputChange("flowItems", event.target.value)
                }
                style={{
                  width: "50%",
                  border: "1px  solid #000",
                  padding: "0px 10px",
                }}
              >
                {flowItemsOptions.map((flowtype) => (
                  <MenuItem key={flowtype} value={flowtype}>
                    {flowtype}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid></Grid>

            <Grid item xs={4}>
              <p className="content">Data From</p>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                    sx={{
                      overflow: "hidden",
                      "& .MuiInputBase-root": { height: "60px" }, // Adjust the height here
                      "& .MuiInputBase-input": { padding: "20px 14px" }, // Adjust padding for better alignment
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={4}>
              <p className="content">Data To</p>
            </Grid>

            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                    sx={{
                      overflow: "hidden",
                      "& .MuiInputBase-root": { height: "60px" }, // Adjust the height here
                      "& .MuiInputBase-input": { padding: "20px 14px" }, // Adjust padding for better alignment
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid mt={3} item xs={10} sm={10} style={{ marginTop: "90px" }}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
            className="restbutton"
            onClick={handleRest}
          >
            Reset
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleSubmit}
          >
            Final Submit
          </Button>
        </Box>
      </Grid>
    </Container>
  );
}

export default SastaReports;
