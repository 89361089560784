import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import Box from "@mui/material/Box";
import { useQuery, useQueryClient } from "react-query";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CustomButton from "../../../components/Button";
import { lapFeed1524Create } from "../mutation/LapFeedMutation";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO, LAP_FEED_CARDING_NUMBERS } from "../../../config/masterdata";
import { getVariety } from "../mutation";
import TableComponent from "../../../components/Table";
import { CircularProgress } from "@mui/material";
import ColumnTable from "../../../components/ColumnTable";
import "./lapfeedwastecss.css";
const boldStyle = { fontWeight: "800", fontSize: "15px" }; // Increase the fontWeight value for bolder text

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function LapFeedWaste() {
  const [data, setData] = React.useState(LAP_FEED_CARDING_NUMBERS);
  const [selectedCardingNumbers, setSelectedCardingNumbers] = React.useState(
    []
  );
  const [hallNo, setHallNo] = React.useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newData = value?.map((carding_num) => {
      // Finding for exiting data
      const existingData = data?.find(
        (item) => item.carding_num === carding_num
      );

      return existingData
        ? {
            carding_num,
            ...existingData,
          }
        : { carding_num, fs_wt: "", production: "" };
    });

    // set the new data
    setData(newData);
    setSelectedCardingNumbers(value);
  };

  const setWastePercentage = (row) => {
    const fsWt = parseFloat(row.fs_wt);
    const production = parseFloat(row.production);

    if (isNaN(fsWt) || isNaN(production) || production === 0) {
      return 0;
    }

    const wastePercentage = (fsWt / (fsWt + production)) * 100;

    const dataIndexToUpdate = data?.findIndex(
      (item) => item.carding_num === row.carding_num
    );

    if (dataIndexToUpdate !== -1) {
      const updatedData = [...data]; // Copy the original array
      updatedData[dataIndexToUpdate] = {
        ...updatedData[dataIndexToUpdate],
        waste: parseFloat(wastePercentage.toFixed(2)),
      };
      setData(updatedData);
    }
  };

  /**handle FS waste change */
  const handleFsWasteChange = (event, row, index) => {
    const { value } = event.target;

    const parsedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const updatedData = [...data];
      updatedData[index].fs_wt = parsedValue;
      setData(updatedData);

      /**set wastage percentage */
      setWastePercentage(row);

      calculateWastePercentage(row, index);
    }
  };

  /**handle production change */
  const handleProductionChange = (event, row, index) => {
    const { value } = event.target;

    const parsedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const updatedData = [...data];
      updatedData[index].production = parsedValue;
      setData(updatedData);

      /**set wastage percentage */
      setWastePercentage(row);

      calculateWastePercentage(row, index);
    }
  };

  /**calculate the total percentage of row */
  const calculateWastePercentage = (row, index) => {
    const fsWt = parseFloat(row.fs_wt);
    const production = parseFloat(row.production);

    // if (isNaN(fsWt) || isNaN(production) || production === 0) {
    //   const updatedData = [...data];
    //   updatedData[index].totalWastePercentage = "";
    //   setData(updatedData);
    // }

    const wastePercentage = (fsWt / (fsWt + production)) * 100;
    const updatedData = [...data];

    updatedData[index].totalWastePercentage =
      fsWt && production ? parseFloat(wastePercentage.toFixed(2)) : "";

    setData(updatedData);
  };

  /**handle feedback */
  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const [cardingLineNum, setCardingLineNum] = useState("");
  const handlecardingLineNumChange = (event) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setCardingLineNum(changedValue);
    }
  };

  // const handleCardingLineNumInput = (event) => {
  //   // Prevent input of negative numbers
  //   if (event.target.value.startsWith('-')) {
  //     event.target.value = ''; // Reset the input value to empty string
  //   }
  // };

  const handleSpeedChange = (event, index) => {
    const { name, value } = event.target;

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const updatedData = [...data];
      updatedData[index][name] = parseFloat(value);
      setData(updatedData);
    }
  };

  const handleVarietyChange = (event, newValue, index) => {
    const value = newValue ? newValue : newValue?.inputValue;
    const updatedData = [...data];
    updatedData[index].variety = value;
    setData(updatedData);
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const resetForm = () => {
    setCardingLineNum("");
    setData(LAP_FEED_CARDING_NUMBERS);
    setSelectedCardingNumbers([]);
    setFeedback("");
    setHallNo("");
  };
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleReview = () => {
    // e.preventDefault();

    const payload = data?.map((item) => ({
      cardingNum: item.carding_num,
      cardingLineNum: cardingLineNum,
      variety: item.variety,
      production: item.production,
      fsWt: item.fs_wt,
      wastePerc: item.waste,
      cylinderSpeed: item.cylinder_speed,
      likerInSpeed: item.liker_in_speed,
      totalWastePercentage: item.totalWastePercentage,
      flatsSpeed: item.flats_speed,
      deliverySpeed: item.delivery_speed,
      feedback: feedback,
      hall: hallNo,
    }));

    setModalData(payload);
    setOpenModal(true);

    // mutate(payload, {
    //   onSuccess: (res) => {
    //     handleToastMsg("Data updated successfully", "success");
    //     resetForm();
    //   },
    // });
  };

  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    // e.preventDefault();

    const payload = data.map((item) => ({
      cardingNum: parseFloat(item.carding_num),
      cardingLineNum: cardingLineNum,
      variety: item.variety,
      production: parseFloat(item.production),
      fsWt: parseFloat(item.fs_wt),
      totalWastePercentage: item.totalWastePercentage,
      cylinderSpeed: parseFloat(item.cylinder_speed),
      likerInSpeed: parseFloat(item.liker_in_speed),
      flatsSpeed: parseFloat(item.flats_speed),
      deliverySpeed: parseFloat(item.delivery_speed),
      feedback: feedback,
      hall: hallNo,
    }));

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        queryClient.invalidateQueries("get-variety");
        queryClient.invalidateQueries("manager-blowroom-notifications");
        setOpenModal(false);
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading: mutateLoading } = useMutation(lapFeed1524Create);

  return (
    <form>
      <Grid container spacing={2} sx={{ my: 1, ml: 5 }} item md={12} xs={8}>
        <Grid item xs={12} sx={{ m: 1 }}>
          <h2 className="heading">Lap feed waste</h2>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 220 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Carding number
            </InputLabel>
            <Select
              required
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedCardingNumbers}
              onChange={handleChange}
              input={<OutlinedInput label="Carding number" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {LAP_FEED_CARDING_NUMBERS.map((item) => (
                <MenuItem key={item.carding_num} value={item.carding_num}>
                  <Checkbox
                    checked={
                      selectedCardingNumbers.indexOf(item.carding_num) > -1
                    }
                  />
                  <ListItemText primary={item.carding_num} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            value={cardingLineNum}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            onChange={handlecardingLineNumChange}
            type="number"
            sx={{ width: 220 }}
            id="outlined-basic"
            label="Carding line number"
            variant="outlined"
            // onInput={handleCardingLineNumInput}
          />
        </Grid>

        <Grid item>
          <TextField
            value={hallNo}
            select
            onChange={(e) => setHallNo(parseInt(e.target.value))}
            type="number"
            sx={{ width: 220 }}
            id="outlined-basic"
            label="Hall Number"
            variant="outlined"
            // onInput={handleCardingLineNumInput}
          >
            {HALL_NO?.map((hall) => (
              <MenuItem key={hall} value={hall}>
                {hall}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid mt={2} xs={12} container>
          <Grid item xs={12} pr={{ md: 0, sm: 4 }}>
            {selectedCardingNumbers.length > 0 && (
              <Grid container spacing={2}>
                <Grid container item xs={11}>
                  <TableComponent
                    data={data}
                    setData={setData}
                    handleFsWasteChange={handleFsWasteChange}
                    handleProductionChange={handleProductionChange}
                    handleVarietyChange={handleVarietyChange}
                    apiData={varietyData} // Pass the API data as a prop
                  />
                </Grid>
                <Grid mt={2} container item xs={11}>
                  <ColumnTable
                    data={data}
                    selectedCardingNumbers={selectedCardingNumbers}
                    handleSpeedChange={handleSpeedChange}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid mt={2} item xs={11}>
          {selectedCardingNumbers.length > 0 && (
            <TextField
              fullWidth
              onChange={handleFeedbackChange}
              value={feedback}
              name="Size"
              label="Feedback"
            />
          )}
        </Grid>
      </Grid>
      <Grid sx={{ m: 1 }} md={11} xs={7} container>
        <Grid mt={2} item xs={12}>
          {selectedCardingNumbers.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleReview}
                className="submitbutton"
              >
                Final Review
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: "500px" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Carding Number:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Carding Line Number:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Hall Number:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Variety:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Production:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    FS Weight:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Total Waste:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Liker In Speed:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Cylinder Speed:
                  </TableCell>

                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Flats Speed:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Delivery Speed:
                  </TableCell>
                  <TableCell style={{ minWidth: "75px", ...boldStyle }}>
                    Feedback:
                  </TableCell>
                </TableRow>
                {modalData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.cardingNum}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.cardingLineNum}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.hall}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.variety}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.production}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.fsWt}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.totalWastePercentage
                        ? `${item.totalWastePercentage}%`
                        : ""}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.likerInSpeed}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.cylinderSpeed}
                    </TableCell>

                    <TableCell style={{ minWidth: "75px" }}>
                      {item.flatsSpeed}
                    </TableCell>
                    <TableCell style={{ minWidth: "75px" }}>
                      {item.deliverySpeed}
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: "75px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.feedback}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          <CustomButton
            onClick={handleSubmit}
            style={{ width: "100px", height: "40px" }}
            disabled={mutateLoading}
            type="submit"
          >
            {mutateLoading ? <CircularProgress size={24} /> : "Submit"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
