import React, { useState, useEffect } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Checkbox,
  Table,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  FormControl,
  ListItemText,
  CircularProgress,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../../components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../Blowroom/mutation";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";

function AutoLevellerOnOffReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    variety: [],
    cardingMachineNoEnabled: false,
    cardingMachineNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: "",
  });

  const [cardingLineNoShow] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [cardingMachineNoOptions, setCardingMachineNoOptions] = useState([]);

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  useEffect(() => {
    let showNumbers = [];
    switch (formData.cardingLineNo) {
      case 1:
        showNumbers = [10, 11, 12];
        break;
      case 2:
        showNumbers = [7, 8, 9];
        break;
      case 3:
        showNumbers = [13, 14];
        break;
      case 4:
        showNumbers = [1, 2, 3, 6];
        break;
      case 5:
        showNumbers = [15, 16, 17, 18, 19, 20];
        break;
      case 6:
        showNumbers = [21, 22, 23, 24, 25, 26];
        break;
      case 7:
        showNumbers = [4, 5];
        break;

      default:
        showNumbers = [];
    }
    setCardingMachineNoOptions(showNumbers);
    setFormData((prev) => ({ ...prev, cardingMachineNo: [] }));
  }, [formData.cardingLineNo]);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    //this is for send the data as a single element from the array
    const varieties = formData?.variety?.join(",") || "";
    const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/carding/on_off_reading_report/?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  variety: varieties,
                  machineNo: cardingMacinesNo,
                  lineNo: formData.cardingLineNo,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      let headers = new Set();
      let dataRows = [];

      const flattenObject = (obj, parentKey = "") => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(acc, flattenObject(value, newKey));
          } else {
            acc[newKey] = value;
          }
          return acc;
        }, {});
      };

      response?.data?.forEach((item) => {
        let row = {};
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((obj, index) => {
              //in this the obj is can be object or normal array thats why we have if else
              if (typeof obj === "object") {
                const flatObj = flattenObject(obj, `${key}_${index + 1}`);
                Object.entries(flatObj).forEach(([subKey, subValue]) => {
                  headers.add(subKey);
                  row[subKey] = subValue;
                });
              } else {
                headers.add(key);
                row[key] = value?.map((item) => item).join(", ");
              }
            });
          } else if (typeof value === "object" && value !== null) {
            const flatObj = flattenObject(value, key);
            Object.entries(flatObj).forEach(([subKey, subValue]) => {
              headers.add(subKey);
              row[subKey] = subValue;
            });
          } else {
            headers.add(key);
            row[key] =
              key === "date" ? dayjs(value).format("DD-MM-YYYY") : value;
          }
        });
        dataRows.push(row);
      });

      headers = Array.from(headers);

      const excludeHeaders = ["id", "created_at", "updated_at", "employeeId"];
      headers = headers.filter((header) => !excludeHeaders.includes(header));

      dataRows.sort((a, b) => {
        const machineNumberA = parseInt(a.machineNo || "0", 10);
        const machineNumberB = parseInt(b.machineNo || "0", 10);
        return machineNumberA - machineNumberB;
      });

      const worksheetData = [headers];

      dataRows.forEach((row) => {
        const rowData = headers.map((header) => row[header] || "");
        worksheetData.push(rowData);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "AUTO-LEVELLER-ON-OFF-REPORTS.xlsx");
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <ContainerWrapper container rowGap={2} height={0}>
      <Grid item xs={12}>
        <h2>Auto Leveller ON/OFF Report</h2>
      </Grid>
      <Grid item container justifyContent={"space-between"} xs={12} md={8}>
        <Grid item>
          <RadioGroup
            name="reportType"
            value={formData.reportType}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="individual"
              control={<Radio />}
              label="Individual Report"
            />
            <FormControlLabel
              value="consolidated"
              control={<Radio />}
              label="Consolidated Report"
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid item container mb={3}>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date from"
                  value={formData.from_date}
                  onChange={(value) => handleDateChange("from_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Date to"
                  value={formData.to_date}
                  onChange={(value) => handleDateChange("to_date", value)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} alignItems={"center"}>
        <TableContainer component={Paper} style={{ width: "100%" }}>
          <Table>
            <TableRow>
              <TableCell>Variety</TableCell>
              <TableCell style={{ width: 500 }}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    multiple
                    value={formData?.variety}
                    disabled={formData.reportType === "consolidated"}
                    renderValue={(selected) => selected.join(", ")}
                    onChange={(e) => handleFormChange(e, "variety")}
                  >
                    {varietyData?.map((variety) => (
                      <MenuItem key={variety} value={variety}>
                        <Checkbox
                          checked={formData?.variety?.indexOf(variety) > -1}
                        />
                        <ListItemText primary={variety} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Carding Line No</TableCell>
              <TableCell style={{ width: 500 }}>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    labelId="cardingLineNo-label"
                    id="cardingLineNo"
                    name="cardingLineNo"
                    value={formData.cardingLineNo || ""}
                    disabled={formData.reportType === "consolidated"}
                    onChange={(event) =>
                      handleInputChange("cardingLineNo", event.target.value)
                    }
                  >
                    {cardingLineNoShow.map((lineNo) => (
                      <MenuItem key={lineNo} value={lineNo}>
                        {lineNo}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Carding Machine No</TableCell>
              <TableCell style={{ width: 500 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      disabled={formData.reportType === "consolidated"}
                      value={formData?.cardingMachineNo}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(event) =>
                        handleInputChange(
                          "cardingMachineNo",
                          event.target.value
                        )
                      }
                    >
                      {cardingMachineNoOptions.map((machineNo) => (
                        <MenuItem key={machineNo} value={machineNo}>
                          <Checkbox
                            checked={
                              formData?.cardingMachineNo?.indexOf(machineNo) >
                              -1
                            }
                          />
                          <ListItemText primary={machineNo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} xs={12} md={12}>
        <CustomButton
          style={{ width: "150px", height: "50px" }}
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress sx={{ color: "white" }} size={32} />
          ) : (
            "Generate"
          )}
        </CustomButton>
      </Grid>
    </ContainerWrapper>
  );
}

export default AutoLevellerOnOffReport;
