import apiClient from "../../../utils/clients/axios";

export const PaperConeIdentifications = async (payload) => {
  const response = await apiClient.get(
    `/api/quality_control/autoconer/autoconer-name-master-data/`,
    payload
  );
  return response;
};

export const PaperConeIdentificationsCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/paper-cone-identification-create/`,
    payload
  );
  return response;
};
