import React from "react";
import { Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import NotFoundSvg from "../assets/notFoundSvg.svg";

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    const isLoggin = localStorage.getItem("isLoggedIn");

    if (isLoggin) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item xs={6} style={{ paddingLeft: "2%" }}>
        <Grid item xs={12}>
          <p style={{ fontSize: "40px", fontWeight: 700, marginBottom: "1%" }}>
            Oops....
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ fontSize: "32px", fontWeight: 400, marginBottom: "1%" }}>
            Page not found{" "}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontSize: "16px", fontWeight: 400, marginBottom: "8%" }}>
            This Page doesn`t exist or was removed! We suggest you back to home.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            style={{
              background: "#E81919",
              color: "white",
              textTransform: "none",
              paddingRight: "6%",
              paddingLeft: "6%",
            }}
            onClick={handleBack}
          >
            Back to home
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <img src={NotFoundSvg} alt="" />
      </Grid>
    </Grid>
  );
};

export default Unauthorized;
