import React, { useEffect, useRef, useState } from "react";

const CheckOverflow = (dependencies) => {
  const [isOverflow, setIsOverflow] = useState({});
  const refs = useRef({});
  
  const addRef = (id) => {
    refs.current[id] = refs.current[id] || React.createRef();
    return refs.current[id];
  };

  useEffect(() => {
    const checkOverflow = () => {
      const overflowStates = {};
      Object.keys(refs.current).forEach((id) => {
        const element = refs.current[id].current;
        if (element) {
          overflowStates[id] = element.scrollWidth > element.clientWidth;
        }
      });
      setIsOverflow(overflowStates);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [dependencies]);

  return [isOverflow, addRef];
};

export default CheckOverflow;
