import React from "react";
import { ContainerWrapper } from "../../components/styles";
import { Grid } from "@mui/material";
import CheckboxComponent from "../../components/Checkbox";
import Input from "../../components/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomButton from "../../components/Button";

function Wrapping() {
  return (
    <ContainerWrapper
      container
      // justifyContent={"center"}
      //   direction={"row"}
      rowGap={2}
      height={0}
    >
      <Grid item xs={12}>
        <h2>Wrapping Report</h2>
      </Grid>
      <Grid item container justifyContent={"space-between"} xs={8}>
        <Grid item>
          <CheckboxComponent name={"INDIVIDUAL REPORT"} />
        </Grid>
        <Grid item>
          <CheckboxComponent name={"CONSOLIDATED REPORT"} />
        </Grid>
      </Grid>
      <Grid item container mt={5}>
        <Grid item>
          <CheckboxComponent defaultChecked={true} />
        </Grid>
        <Grid item container xs={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={3}>
            <h2>Date From: </h2>
          </Grid>
          <Grid item xs={5}>
            <Input />
          </Grid>
        </Grid>
        <Grid item container xs={5} alignItems={"center"} columnGap={2}>
          <Grid item xs={3}>
            <h2>Date To: </h2>
          </Grid>
          <Grid item xs={5}>
            <Input />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={5} alignItems={"center"}>
        <Grid item>
          <CheckboxComponent />
        </Grid>
        <Grid item xs={3}>
          <h2>Variety: </h2>
        </Grid>
        <Grid item xs={5}>
          <Input />
        </Grid>
      </Grid>
      <Grid item container alignItems={"center"}>
        <Grid item>
          <CheckboxComponent />
        </Grid>
        <Grid item xs={3}>
          <h2>Carding Machine No: </h2>
        </Grid>
        <Grid item xs={3}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={state.cardingNo}
            // label="Age"
            name="cardingNo"
            // onChange={handleInputChange}
            style={{ width: "100%", border: "1px solid #000" }}
          >
            <MenuItem value={10}>1</MenuItem>
            <MenuItem value={20}>2</MenuItem>
            <MenuItem value={30}>3</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item container alignItems={"center"}>
        <Grid item>
          <CheckboxComponent />
        </Grid>
        <Grid item xs={3}>
          <h2>Carding Line No: </h2>
        </Grid>
        <Grid item xs={3}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={state.cardingNo}
            // label="Age"
            name="cardingNo"
            // onChange={handleInputChange}
            style={{ width: "100%", border: "1px solid #000" }}
          >
            <MenuItem value={10}>1</MenuItem>
            <MenuItem value={20}>2</MenuItem>
            <MenuItem value={30}>3</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid item container justifyContent={"flex-end"} xs={10}>
        <CustomButton style={{ width: "150px", height: "50px" }} type="submit">
          Generate
        </CustomButton>
      </Grid>
    </ContainerWrapper>
  );
}

export default Wrapping;
