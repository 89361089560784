import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";

function CheckboxComponent({ checked, handleChange, name , defaultChecked}) {
  return (
    <Grid item container alignItems={'center'}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
        defaultChecked={defaultChecked}
      />
      <h3>{name}</h3>
    </Grid>
  );
}

export default CheckboxComponent;
