import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  ListItem,
  TextField,
} from "@mui/material";

const InputFields = ({
  fieldKey,
  viewMode,
  isManager,
  selectedDataToEdit,
  setFormData,
  apiStandardsData,
  formData,
  inputType,
  options,
  outOfNorms,
}) => {
  const getFieldData =
    apiStandardsData?.data?.data
      ?.filter((item) => {
        // Destructure to remove the unwanted fields: inputType, options, and outOfNorms
        const { inputType, options, outOfNorms, category, ...rest } = item;

        return rest.hasOwnProperty(fieldKey);
      })
      // Optional: filter out any empty objects, if needed
      ?.map((item) => {
        // Return the actual data (rest object), not a boolean
        const { inputType, options, outOfNorms, category, ...rest } = item;

        return rest;
      }) || [];
  //console.log("getFieldData", getFieldData);

  // const getFieldData = apiStandardsData?.data?.data
  //   ?.filter((item) => item[fieldKey])
  //   ?.map((item) => {
  //     Object.entries(item)?.map(([key, value]) => {
  //       console.log({ key, value });

  //       if (typeof value === "object") {
  //         // alert(key);
  //       }
  //     });

  //     console.log("item========>", item);
  //   });

  const checkXandYvalues =
    getFieldData[0]?.[fieldKey]?.x === formData[fieldKey]?.value?.x &&
    getFieldData[0]?.[fieldKey]?.y === formData[fieldKey]?.value?.y;

  const checkXandYandZvalues =
    getFieldData[0]?.[fieldKey]?.x === formData[fieldKey]?.value?.x &&
    getFieldData[0]?.[fieldKey]?.y === formData[fieldKey]?.value?.y &&
    getFieldData[0]?.[fieldKey]?.z === formData[fieldKey]?.value?.z;

  const handleChange = (e, inputType, newValue) => {
    const { name, value } = e.target;
    let isOutOfNorms;
    // if (value < 0) {
    //   alert("Please do not enter negative value");
    //   return;
    // }

    if (inputType === "1*2 measurements") {
      if (name === "x") {
        isOutOfNorms =
          outOfNorms === "Yes"
            ? getFieldData[0][fieldKey]?.x === value &&
              getFieldData[0][fieldKey]?.y === formData?.[fieldKey]?.value?.y
              ? false
              : true
            : false;
      } else if (name === "y") {
        isOutOfNorms =
          outOfNorms === "Yes"
            ? getFieldData[0][fieldKey]?.y === value &&
              getFieldData[0][fieldKey]?.x === formData?.[fieldKey]?.value?.x
              ? false
              : true
            : false;
      }

      setFormData((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          value: { ...prevState[fieldKey]?.value, [name]: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (inputType === "1*3 measurements") {
      if (name === "x") {
        isOutOfNorms =
          outOfNorms === "Yes"
            ? getFieldData[0]?.[fieldKey]?.x === value &&
              getFieldData[0]?.[fieldKey]?.y ===
                formData?.[fieldKey]?.value?.y &&
              getFieldData[0]?.[fieldKey]?.z === formData?.[fieldKey]?.value?.z
              ? false
              : true
            : false;
      } else if (name === "y") {
        isOutOfNorms =
          outOfNorms === "Yes"
            ? getFieldData[0]?.[fieldKey]?.y === value &&
              getFieldData[0]?.[fieldKey]?.x ===
                formData?.[fieldKey]?.value?.x &&
              getFieldData[0]?.[fieldKey]?.z === formData?.[fieldKey]?.value?.z
              ? false
              : true
            : false;
      } else if (name === "z") {
        isOutOfNorms =
          outOfNorms === "Yes"
            ? getFieldData[0]?.[fieldKey]?.z === value &&
              getFieldData[0]?.[fieldKey]?.x ===
                formData?.[fieldKey]?.value?.x &&
              getFieldData[0]?.[fieldKey]?.y === formData?.[fieldKey]?.value?.y
              ? false
              : true
            : false;
      }

      setFormData((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          value: { ...prevState[fieldKey]?.value, [name]: value },
          isOutOfNorms: isOutOfNorms,
        },
      }));
    } else if (inputType === "Single select") {
      setFormData((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          value: newValue,
          isOutOfNorms: false, //we dont need check outOfNorms for single or multiselect
        },
      }));
    } else if (inputType === "Multiple select") {
      setFormData((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          value: newValue || [],
          isOutOfNorms: false, //we dont need check outOfNorms for single or multiselect
        },
      }));
    } else {
      // const [field, subField] = name;
      isOutOfNorms =
        outOfNorms === "Yes"
          ? getFieldData[0]?.[fieldKey] === value
            ? false
            : true
          : false;

      setFormData((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          value: value,
          isOutOfNorms: isOutOfNorms,
        },
      }));
    }
  };

  //this is for checking the outOfNorms for manager correction
  const managerOutOfNormsCheck =
    Object.entries(selectedDataToEdit?.data || {})?.some(([key, value]) => {
      // Check if the key matches fieldKey and isOutOfNorms is true
      return key === fieldKey && value?.isOutOfNorms === true;
    }) || false; // Ensure a fallback to false if no entries match


  switch (inputType) {
    case "Number(Whole)":
      return (
        <TextField
          name={fieldKey}
          variant="outlined"
          type="number"
          label="Whole Number"
          value={formData?.[fieldKey]?.value ?? ""}
          disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
          onChange={(e) => {
            const inputValue = e.target.value;

            // Prevent negative or decimal numbers
            if (/^\d*$/.test(inputValue)) {
              handleChange(e, inputType); // Only update if the input is valid (positive integer)
            }
          }}
          inputProps={{ min: 0 }}
          InputProps={{
            style: {
              color:
                outOfNorms === "Yes"
                  ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                    ? "green"
                    : "red"
                  : "black",
              width: "200px",
            },
          }}
        />
      );

    case "Number(Decimals)":
      return (
        <TextField
          name={fieldKey}
          variant="outlined"
          type="number"
          label="Decimal Number"
          inputProps={{ step: "0.01" }}
          value={formData?.[fieldKey]?.value ?? ""}
          disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
          onChange={(e) => handleChange(e, inputType)}
          InputProps={{
            style: {
              color:
                outOfNorms === "Yes"
                  ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                    ? "green"
                    : "red"
                  : "black",
              width: "200px",
            },
          }}
        />
      );

    case "Text":
      return (
        <TextField
          name={fieldKey}
          variant="outlined"
          label="Text"
          value={formData?.[fieldKey]?.value ?? ""}
          disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[a-zA-Z]*$/.test(inputValue)) {
              handleChange(e, inputType); 
            }
          }}
          InputProps={{
            style: {
              color:
                outOfNorms === "Yes"
                  ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                    ? "green"
                    : "red"
                  : "black",
              width: "200px",
            },
          }}
        />
      );

    case "All Characters":
      return (
        <TextField
          name={fieldKey}
          variant="outlined"
          label="All characters"
          value={formData?.[fieldKey]?.value ?? ""}
          disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
          onChange={(e) => handleChange(e, inputType)}
          InputProps={{
            style: {
              color:
                outOfNorms === "Yes"
                  ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                    ? "green"
                    : "red"
                  : "black",
              width: "200px",
            },
          }}
        />
      );

    case "1*2 measurements":
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TextField
            variant="outlined"
            label="X"
            name={"x"}
            value={formData?.[fieldKey]?.value?.x ?? ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e, inputType); // Only update if the input is valid (positive integer)
              }
            }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? checkXandYvalues
                      ? "green"
                      : "red"
                    : "black",
                width: "200px",
              },
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Y"
            name={"y"}
            value={formData?.[fieldKey]?.value?.y ?? ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e, inputType); // Only update if the input is valid (positive integer)
              }
            }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? checkXandYvalues
                      ? "green"
                      : "red"
                    : "black",
                width: "200px",
              },
            }}
          />
        </div>
      );

    case "1*3 measurements":
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TextField
            variant="outlined"
            label="X"
            name={"x"}
            value={formData?.[fieldKey]?.value?.x ?? ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e, inputType); // Only update if the input is valid (positive integer)
              }
            }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? checkXandYandZvalues
                      ? "green"
                      : "red"
                    : "black",
                width: "100px",
              },
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Y"
            name={"y"}
            value={formData?.[fieldKey]?.value?.y ?? ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e, inputType); // Only update if the input is valid (positive integer)
              }
            }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? checkXandYandZvalues
                      ? "green"
                      : "red"
                    : "black",
                width: "100px",
              },
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Z"
            name={"z"}
            value={formData?.[fieldKey]?.value?.z ?? ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e, inputType); // Only update if the input is valid (positive integer)
              }
            }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? checkXandYandZvalues
                      ? "green"
                      : "red"
                    : "black",
                width: "100px",
              },
            }}
          />
        </div>
      );

    case "Single select":
      return (
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <Autocomplete
            disablePortal
            id={`${fieldKey}-autocomplete`}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            //this is for double check that single select won't be in array tahts why
            options={
              Array.isArray(options) ? options : options ? [options] : []
            }
            value={formData?.[fieldKey]?.value || null} // Ensure value is valid for Autocomplete
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.toString()
            } // Convert option to string
            onChange={(e, newValue) => {
              if (newValue === null) {
                handleChange(e, inputType, "");
              } else {
                handleChange(e, inputType, newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={fieldKey}
                label="Single Select"
                variant="outlined"
                disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
              />
            )}
            sx={{ minWidth: 200 }}
          />
        </FormControl>
      );

    case "Multiple select":
      return (
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <Autocomplete
            multiple
            id={`${fieldKey}-multiple-select-label`}
            options={Array.isArray(options) ? options : []} // Ensure options is always an array
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            value={
              Array.isArray(formData?.[fieldKey]?.value)
                ? formData?.[fieldKey]?.value
                : []
            } // Ensure value is always an array for multiple select
            getOptionLabel={(option) => option.toString()}
            onChange={(e, newValue) => handleChange(e, inputType, newValue)}
            disableCloseOnSelect // Prevents dropdown from closing on option select
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} button>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected} // Checkbox without icons
                />
                {option}
              </ListItem>
            )}
            // Custom renderTags to remove the cross icon from selected items
            renderTags={(selected) =>
              selected?.map((option) => (
                <span key={option} style={{ margin: "4px" }}>
                  {option}
                </span>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={fieldKey}
                variant="outlined"
                label="Multiple Select"
                disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
              />
            )}
            style={{ minWidth: 200 }}
          />
        </FormControl>
      );

    case "Percentage":
      return (
        <Grid container alignItems={"center"}>
          <TextField
            name={fieldKey}
            variant="outlined"
            label="Percentage"
            type="number"
            value={formData?.[fieldKey]?.value ?? ""}
            inputProps={{ step: "0.01", min: 0, max: 100 }}
            disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Only update if the input is between 0 and 100
              if (
                inputValue === "" ||
                (Number(inputValue) >= 0 && Number(inputValue) <= 100)
              ) {
                handleChange(e, inputType); // Call the change handler only if the value is valid
              }
            }}
            InputProps={{
              style: {
                color:
                  outOfNorms === "Yes"
                    ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                      ? "green"
                      : "red"
                    : "black",
                width: "200px",
              },
            }}
          />

          <span style={{ marginLeft: "5px", fontSize: "20px" }}>%</span>
        </Grid>
      );

    case "Negative values":
      return (
        <TextField
          name={fieldKey}
          variant="outlined"
          label="Negative Number"
          type="number"
          value={formData?.[fieldKey]?.value ?? ""}
          inputProps={{ step: "0.01", min: 0, max: 100 }}
          disabled={viewMode || (isManager && !managerOutOfNormsCheck)}
          onChange={(e) => handleChange(e, inputType)}
          InputProps={{
            style: {
              color:
                outOfNorms === "Yes"
                  ? getFieldData[0][fieldKey] === formData?.[fieldKey]?.value
                    ? "green"
                    : "red"
                  : "black",
              width: "200px",
            },
          }}
        />
      );

    default:
      return null;
  }
};

export default InputFields;
