import React from "react";
import {
  TextField,
  Checkbox,
  FormControl,
  Autocomplete,
  ListItem,
  Grid,
} from "@mui/material";

const DynamicInputField = ({
  name, // Dynamic field name
  inputType, // Field type (e.g., text, measurements, etc.)
  value = "", // The value of the specific field with default empty value
  handleChange, // Function to handle changes for text inputs
  handleSelectChange, // Function to handle changes for select fields
  options = [], // Options for select fields (if any)
}) => {
  switch (inputType) {
    case "Number(Whole)":
      return (
        <TextField
          variant="outlined"
          label="Whole Number"
          name={name}
          type="number"
          value={value}
          onChange={(e) => {
            const inputValue = e.target.value;

            // Prevent negative or decimal numbers
            if (/^\d*$/.test(inputValue)) {
              handleChange(e); // Only update if the input is valid (positive integer)
            }
          }}
          inputProps={{ min: 0 }}
        />
      );

    case "Number(Decimals)":
      return (
        <TextField
          variant="outlined"
          label="Decimal Number"
          name={name}
          type="number"
          inputProps={{ step: "0.01" }}
          value={value}
          onChange={handleChange}
        />
      );

    // case "Text":
    //   return (
    //     <TextField
    //       variant="outlined"
    //       label="Text"
    //       name={name}
    //       value={value}
    //       // onChange={handleChange}
    //       onChange={(e) => {
    //         const inputValue = e.target.value;

    //         // Regular expression to allow only alphabetic characters (both lowercase and uppercase)
    //         if (/^[a-zA-Z]*$/.test(inputValue)) {
    //           handleChange(e); // Update only if the input is valid (alphabets only)
    //         }
    //       }}
    //     />
    //   );

    case "Text":
      return (
        <TextField
          variant="outlined"
          label="Text"
          name={name}
          value={value}
          // onChange={handleChange}
          onChange={(e) => {
            const inputValue = e.target.value;
            // Regular expression to allow only alphabetic characters (both lowercase and uppercase)
            if (/^[a-zA-Z\s]*$/.test(inputValue)) {
              handleChange(e); // Update only if the input is valid (alphabets only)
            }
          }}
        />
      );

    case "All Characters":
      return (
        <TextField
          variant="outlined"
          label="All characters"
          name={name}
          value={value}
          onChange={handleChange}
        />
      );

    // case "Numbers and Decimals":
    //   return (
    //     <TextField
    //       variant="outlined"
    //       label="Number or Decimal"
    //       name={name}
    //       type="number"
    //       value={value}
    //       onChange={handleChange}
    //     />
    //   );

    case "1*2 measurements":
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TextField
            variant="outlined"
            type="number"
            label="X"
            name={`${name}.x`}
            value={value.x || ""}
            // onChange={handleChange}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e); // Only update if the input is valid (positive integer)
              }
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Y"
            type="number"
            name={`${name}.y`}
            value={value.y || ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e); // Only update if the input is valid (positive integer)
              }
            }}
          />
        </div>
      );

    case "1*3 measurements":
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TextField
            variant="outlined"
            label="X"
            type="number"
            name={`${name}.x`}
            value={value.x || ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e); // Only update if the input is valid (positive integer)
              }
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Y"
            type="number"
            name={`${name}.y`}
            value={value.y || ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e); // Only update if the input is valid (positive integer)
              }
            }}
          />
          <span>*</span>
          <TextField
            variant="outlined"
            label="Z"
            type="number"
            name={`${name}.z`}
            value={value.z || ""}
            onChange={(e) => {
              const inputValue = e.target.value;

              // Prevent negative or decimal numbers
              if (/^\d*\.?\d*$/.test(inputValue)) {
                handleChange(e); // Only update if the input is valid (positive integer)
              }
            }}
          />
        </div>
      );

    case "Single select":
      return (
        <FormControl variant="outlined" style={{ minWidth: 210 }}>
          <Autocomplete
            disablePortal
            id={`${name}-autocomplete`}
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.toString()
            } // Convert option to string
            value={value || null} // Ensure value is valid for Autocomplete
            onChange={(event, newValue) => handleSelectChange(name, newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Single Select" variant="outlined" />
            )}
            sx={{ minWidth: 210 }}
          />
        </FormControl>
      );

    case "Multiple select":
      return (
        <FormControl variant="outlined" style={{ minWidth: 210 }}>
          <Autocomplete
            multiple
            id={`${name}-multiple-select-label`}
            options={Array.isArray(options) ? options : []} // Ensure options is always an array
            getOptionLabel={(option) => option.toString()}
            value={Array.isArray(value) ? value : []} // Ensure value is always an array for multiple select
            onChange={(event, newValue) => handleSelectChange(name, newValue)}
            disableCloseOnSelect // Prevents dropdown from closing on option select
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} button>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={selected} // Checkbox without icons
                />
                {option}
              </ListItem>
            )}
            // Custom renderTags to remove the cross icon from selected items
            renderTags={(selected) =>
              selected.map((option) => (
                <span key={option} style={{ margin: "4px" }}>
                  {option}
                </span>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Multiple Select"
                // placeholder="Select options"
              />
            )}
            style={{ minWidth: 210 }}
          />
        </FormControl>
      );

    case "Percentage":
      return (
        <Grid container alignItems={"center"}>
          <TextField
            variant="outlined"
            label="Percentage"
            name={name}
            type="number"
            onChange={(e) => {
              const inputValue = e.target.value;
              // Only update if the input is between 0 and 100
              if (
                inputValue === "" ||
                (Number(inputValue) >= 0 && Number(inputValue) <= 100)
              ) {
                handleChange(e); // Call the change handler only if the value is valid
              }
            }}
            value={value}
            // onChange={handleChange}
            style={{ minWidth: 210 }}
          />

          <span style={{ marginLeft: "5px", fontSize: "20px" }}>%</span>
        </Grid>
      );

    case "Negative values":
      return (
        <TextField
          variant="outlined"
          label="Negative Number"
          name={name}
          type="number"
          value={value}
          onChange={handleChange}
        />
      );

    default:
      return null;
  }
};

export default DynamicInputField;
