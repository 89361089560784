import apiClient from "../../utils/clients/axios";

// get all issue data
export const getReportIssueData = async () => {
  const response = await apiClient.get(`/api/quality_control/report_issue`);
  return response;
};

// get pending issue data
export const getReportPendingIssueData = async () => {
  const response = await apiClient.get(
    `/api/quality_control/report_issue/getPendingIssue`
  );
  return response;
};

// update for 'accept' or 'decline'
export const updateERP = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/report_issue/${id}`,
    payload
  );
  return response;
};
