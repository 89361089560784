import React from "react";
import { CircularProgress, Grid } from "@mui/material";

function Loader() {
  return (
    <Grid container justifyContent={"center"} minHeight={250} direction={'column'} alignItems={'center'}>
      <CircularProgress size={50} />
    </Grid>
  );
}

export default Loader;
