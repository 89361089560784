import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { MasterData } from "../Blowroom/mutation/manager/blowroomReportMutation";
import { useQuery } from "react-query";
import { HALL_NO } from "../../config/masterdata";

const Cuts = () => {
  const [selectedNumber] = useState(0);
  // const [table2Data, setTable2Data] = useState({
  //   autoConerNo: "",
  //   drumNo: "",
  //   cspValue: "",
  //   numberOfReadings: 0,
  // });

  // const [feedback, setFeedback] = useState("");
  // const handleFeedbackChange = (event) => {
  //   const { value } = event.target;
  //   setFeedback(value);
  // };

  // const table1Data = [
  //   { col1: "Variety", col2: "Counts", col3: "Denier" },
  //   {
  //     col1: "",
  //     col2: <TextField variant="outlined" fullWidth placeholder="Counts" />,
  //     col3: <TextField variant="outlined" fullWidth placeholder="Denier" />,
  //   },
  // ];

  const { data: varietyOptions } = useQuery(
    "cuts",
    async () => {
      try {
        const response = await MasterData();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  const [formValues, setFormValues] = useState({
    variety: "",
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    drumNo: "",
    ln_value: "",
    feedback: "",
    slub_code: "",
  });
  // const [trailHeaders, setTrailHeaders] = useState(["Trail 1"]);
  // const [numTrailColumns, setNumTrailColumns] = useState(1);
  const table3Data = [];
  for (let i = 1; i <= selectedNumber; i++) {
    table3Data.push({
      col1: i,
      col2: (
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Splice"
          value={"S"}
          disabled
        />
      ),
      col3: <TextField variant="outlined" fullWidth placeholder="Name" />,
      col4: <TextField variant="outlined" fullWidth placeholder="% of fault" />,
      col5: (
        <TextField variant="outlined" fullWidth placeholder="Length in cm" />
      ),
    });
  }

  // const handleFormChange = (event, field) => {
  //   let { value } = event.target;

  //   if (field === "wax") {
  //     value = value === "true";
  //   } else if (
  //     field === "variety" ||
  //     field === "feedback" ||
  //     field === "denier" ||
  //     field === "slub_code"
  //   ) {
  //     if (value) {
  //       value = value.toUpperCase();
  //     }
  //   } else if (
  //     ["counts", "auto_coner_no", "drum_number", "no_of_readings"].includes(
  //       field
  //     )
  //   ) {
  //     value = !isNaN(value) ? parseFloat(value) : value;
  //   }
  //   setFormValues({ ...formValues, [field]: value });
  //   // setUserEnteredValue(value)
  // };
  // const handleFormChange = (event, field) => {
  //     let { value } = event.target;

  //     if (field === 'wax') {
  //         value = value === 'true';
  //     } else if (field === 'variety' || field === 'feedback' ||field === 'denier' || field==='slub_code') {
  //         if (value) {
  //             value = value.toUpperCase();
  //         }
  //     } else if (['counts', 'auto_coner_no', 'drum_number', 'no_of_readings'].includes(field)) {
  //         value = !isNaN(value) ? parseFloat(value) : value;
  //     }
  //     setFormValues({ ...formValues, [field]: value });
  //     // setUserEnteredValue(value)
  // };
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    if (
      field === "variety" ||
      field === "denier" ||
      field === "feedback" ||
      field === "slub_code"
    ) {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "hallNo",
        "auto_coner_no",
        "drumNo",
        "csp_value",
        "no_of_readings",
      ].includes(field)
    ) {
      const floatValue = value === "" ? "" : parseFloat(value);
      if (value < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = !isNaN(floatValue) && floatValue >= 0 ? floatValue : "";
      }
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        // paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2>Cuts</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2} mr={2}>
        <Grid item sm={6} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    {/* <div style={{ padding: '10px' }}>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px' }}>
                                                <Autocomplete
                                                    options={varietyOptions && varietyOptions.map((option) => option.variety)}
                                                    getOptionLabel={(option) => option} 
                                                    fullWidth
                                                    freeSolo
                                                    value={formValues.variety}
                                                    onChange={(e, newValue) => {
                                                        if (newValue !== null) {
                                                            handleFormChange({ target: { value: newValue } }, 'variety');
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Variety"
                                                            placeholder='Variety'
                                                            InputLabelProps={{ shrink: true }}
                                                            value={formValues.variety}
                                                            onChange={(e) => handleFormChange(e, 'variety')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div> */}
                    <div style={{ padding: "10px" }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          options={varietyOptions || []}
                          fullWidth
                          freeSolo
                          value={formValues.variety}
                          onChange={(e, newValue) => {
                            if (newValue !== null) {
                              handleFormChange(
                                { target: { value: newValue } },
                                "variety"
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Variety"
                              placeholder="Variety"
                              // InputLabelProps={{ shrink: true }}
                              value={formValues.variety}
                              onChange={(e) => handleFormChange(e, "variety")}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Denier"
                      placeholder="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Slub Code"
                      placeholder="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={6} md={5} lg={5} xl={5}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      label="Auto Coner No"
                      value={formValues.auto_coner_no}
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell>Drum No</TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            placeholder='Drum No'
                                            label="Drum No"
                                            value={formValues.drumNo}
                                            onChange={(e) => handleFormChange(e, 'auto_coner_no')}
                                        />
                                    </TableCell> */}
                  <TableCell>Drum No</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum From"
                          fullWidth
                          value={formValues.drumFrom}
                          onChange={(e) => handleFormChange(e, "drumFrom")}
                          label="Drum From"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /^0+|[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum To"
                          fullWidth
                          value={formValues.drumTo}
                          onChange={(e) => handleFormChange(e, "drumTo")}
                          label="Drum To"
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /^0+|[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell>Values</TableCell>
                  {/* {data.length > 0 &&
                          data.map((row, index) => (
                            <TableCell >{row.carding_num}</TableCell>
                          ))} */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total cuts</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="total_cuts"
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NEPS cuts </TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="neps_cuts"
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Short cuts</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="short_cuts"
                      //   value={row.liker_in_speed}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Long cuts</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="long_cuts"
                      //   value={row.flats_speed}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Thin cuts</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="thin_cuts"
                      //   value={row.delivery_speed}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CVP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cp"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>JP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="cm"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>{" "}
                <TableRow>
                  <TableCell>JM</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ccp"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>{" "}
                <TableRow>
                  <TableCell>CP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="ccm"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>{" "}
                <TableRow>
                  <TableCell>CM</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="jp"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>{" "}
                <TableRow>
                  <TableCell>CCP</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a1"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CCM</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>A1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>B1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>C1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>D1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>E1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>F1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>G1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>H1</TableCell>

                  <TableCell>
                    {/* Example input for each column */}
                    <TextField
                      size="small"
                      id="standard-number"
                      type="number"
                      step="0.01"
                      variant="standard"
                      style={{ width: "50px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="a2"
                      //   value={row.wing_setting}
                    />
                  </TableCell>
                </TableRow>
                {/* Add more rows as needed */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid item xs={3}>
                    <Grid mt={3} item xs={10} sm={10} >

                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="primary" style={{ width: "150px", height: "50px" }}
                                onClick={() => {
                                    setNumTrailColumns(numTrailColumns + 1);
                                    setTrailHeaders([...trailHeaders, `Trail ${numTrailColumns + 1}`]);
                                }}
                            >
                                Add trail
                            </Button>
                        </Box>
                    </Grid>
                    <Grid mt={3} item xs={10} sm={10} >

                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="primary" style={{ width: "150px", height: "50px" }}
                            //    onClick={handleReview}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid> */}
        {/* <Grid sx={{ mt: 3 }} item xs={6} sm={6} md={6} lg={6} xl={4}>
                    <TextField
                        size='small'
                        id="standard-number"
                        type="number"
                        step="0.01"
                        variant='outlined'
                        style={{ width: '150px' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                        name='Breaks/Length metre'
                        helperText='Breaks/Length metre'
                    />
                </Grid> */}
      </Grid>
      <Grid mt={3} item xs={10} sm={10}>
        <TextField
          fullWidth
          // onChange={handleFeedbackChange}
          size="lg"
          name="Size"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={11} sm={11}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            //    onClick={handleReview}
          >
            Final Submit
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Cuts;
