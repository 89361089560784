import apiClient from "../../../utils/clients/axios";

export const createCountChangeDraft = async (payload) => {
  try {
    const response = await apiClient.post(
      "/api/quality_control/spinning/count-change-drafts",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCountChangeDraft = async (params) => {
  const { draftPayload, id } = params;
  try {
    const response = await apiClient.put(
      `/api/quality_control/spinning/count-change-drafts/${id}`,
      draftPayload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCountChangeDraft = async () => {
  const response = await apiClient.get(
    `/api/quality_control/spinning/count-change-drafts/`
  );
  return response.data;
};
