import { Button, Grid, styled } from "@mui/material";

export const Container = styled(Grid)`
  width: 100%;
  display: flex;
  border-radius: 8px;
  gap: 16px;
  height: 320px;
`;

export const UploadButton = styled(Button)`
  border-radius: 4px;
  background: #182e4b;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  height: 2rem;
`;

export const CancelButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid #182e4b;
  color: #182e4b;
  height: 2rem;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
`;

export const ButtonParent = styled(Grid)`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
  }
  gap: 8px;
`;