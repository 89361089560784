import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Textarea from "@mui/joy/Textarea";
import {
  chuteFeed_1_5_update,
  lapFeed_15_24_update,
  chuteFeed_7_14_update,
  droptest_update,
  finisher_update,
  splice_strength_update,
  rewinding_study_update,
  count_change_update,
  cot_checking_update,
  hariness_update,
  asc_update,
} from "../../../../Blowroom/mutation/manager/notificationsUpdateMutation";
import { useMutation, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../../../components/Toast";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import CustomButton from "../../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";

const ManagerResponse = ({
  selectedItemData,
  selectedType,
  setModelToggle,
  isTelevision,
  setPauseInterval,
}) => {
  const [feedback, setFeedback] = React.useState("");

  //Blowroom Notifications mutate
  const { mutate: chute15Mutate, isLoading: isChute15MutateLoading } =
    useMutation(chuteFeed_1_5_update);

  const { mutate: lapFeedMutate, isLoading: isLapFeedMutateLoading } =
    useMutation(lapFeed_15_24_update);

  const { mutate: chute7_14Mutate, isLoading: isChute7_14MutateLoading } =
    useMutation(chuteFeed_7_14_update);

  const { mutate: droptest_Mutate, isLoading: droptestLoading } =
    useMutation(droptest_update);

  //Autoconor Notifications mutate
  const { mutate: rewinding_study_mutate, isLoading: rewindingLoading } =
    useMutation(rewinding_study_update);

  const { mutate: splice_strength_mutate, isLoading: spliceStrengthLoading } =
    useMutation(splice_strength_update);

  //Spinning Notifications mutate
  const { mutate: count_change_Mutate, isLoading: countChangeLoading } =
    useMutation(count_change_update);

  const { mutate: cot_checking_Mutate, isLoading: cotCheckingLoading } =
    useMutation(cot_checking_update);

  const { mutate: hariness_Mutate, isLoading: harinessLoading } =
    useMutation(hariness_update);

  const { mutate: asc_Mutate, isLoading: ascLoading } = useMutation(asc_update);

  //Finisher Notifications mutate
  const { mutate: finisher_Mutate, isLoading: finisherLoading } =
    useMutation(finisher_update);

  const isLoadingMutate =
    isChute15MutateLoading ||
    isLapFeedMutateLoading ||
    isChute7_14MutateLoading ||
    droptestLoading ||
    rewindingLoading ||
    spliceStrengthLoading ||
    finisherLoading ||
    countChangeLoading ||
    cotCheckingLoading ||
    harinessLoading ||
    ascLoading;

  const queryClient = useQueryClient();

  /**filter the data */
  const filterData = (data) => {
    const keysToSkip = [
      "id",
      "createdAt",
      "created_at",
      "updatedAt",
      "updated_at",
      "timestamp",
      "isOutOfNorms",
      "is_out_of_norms",
      "time",
      "materials_out_of_norms",
      "oneYardParticular",
      "halfYardParticular",
      "particular",
      "ASCRecordDrafts",
      "ASCErrorDrafts",
      "CotBuffDrafts",
      "CotCheckRecordDrafts",
      "strength_samples",
      "weight_samples",
      "hairness_errors",
      "hairness_records",
    ];
    const filteredEntries =
      data &&
      Object.entries(data)?.filter(([key, value]) => {
        if (
          keysToSkip?.includes(key) ||
          value === null ||
          // value === 0 ||
          value === "NaN"
        )
          return false;
        return true;
      });

    // Format the date field if it exists
    let formattedEntries = filteredEntries?.map(([key, value]) => {
      if (key === "date") {
        return [key, dayjs(value)?.format("DD-MM-YYYY")];
      }
      return [key, value];
    });

    // If the selectedType is BRDropTest, include the materials_out_of_norms data
    if (selectedType === "BRDropTest" && data?.materials_out_of_norms) {
      const materialsEntries = Object.entries(
        data?.materials_out_of_norms
      )?.flatMap(([materialKey, materialValue]) =>
        Object.entries(materialValue).map(([subKey, subValue]) => [
          `${materialKey}_${subKey}`,
          subValue,
        ])
      );

      formattedEntries = [...formattedEntries, ...materialsEntries];
    }

    // If the selectedType is Finisher, include the oneYardParticular and halfYardParticular data
    if (selectedType === "Finisher") {
      const oneYardEntries = data?.oneYardParticular
        ?.flatMap(({ sample, particulars }) => [
          [`oneYard_readings_${sample}`, sample],
          [`oneYard_${sample}`, particulars],
        ])
        .filter(
          ([key, value]) =>
            value !== undefined &&
            value !== "average" &&
            value !== "hank" &&
            value !== "cv"
        );

      const halfYardEntries = data?.halfYardParticular
        ?.flatMap(({ sample, particulars }) => [
          [`halfYard_readings_${sample}`, sample],
          [`halfYard_${sample}`, particulars],
        ])
        .filter(
          ([key, value]) =>
            value !== undefined &&
            value !== "average" &&
            value !== "hank" &&
            value !== "cv"
        );

      formattedEntries = [
        ...formattedEntries,
        ...oneYardEntries,
        ...halfYardEntries,
      ];
    }

    // If the selectedType is RewindingStudy, include the particular data
    if (selectedType === "RewindingStudy") {
      const particularsEntry = data?.particular
        ?.flatMap(({ drumNo, readingNo, ...rest }) =>
          Object.entries(rest).map(([key, value]) => [
            `DrumNo_${drumNo}_Reading_${readingNo}_${key}`,
            value,
          ])
        )
        .filter(
          ([key, value]) => value !== undefined && !key.includes("isOutOfNorms")
        );

      formattedEntries = [...formattedEntries, ...particularsEntry];
    }

    // If the selectedType is SpliceStrength, include the particular data
    if (selectedType === "SpliceStrength") {
      const particularsEntry = data?.particular
        ?.flatMap(({ drumNo, readings, ...rest }) => [
          ...Object.entries(rest).map(([key, value]) => [
            `Drum_${drumNo}_${key}`,
            value,
          ]),
          ...readings.flatMap(({ readingNo, ...readingRest }) =>
            Object.entries(readingRest).map(([key, value]) => [
              `Reading_${drumNo}_${readingNo}_${key}`,
              value,
            ])
          ),
        ])
        .filter(
          ([key, value]) => value !== undefined && !key.includes("isOutOfNorms")
        );

      formattedEntries = [...formattedEntries, ...particularsEntry];
    }

    if (selectedType === "ASCDraft") {
      const filterNestedData = (nestedData) =>
        nestedData?.filter(
          ([key, value]) =>
            !keysToSkip.includes(key) && key !== null && value !== null
        );

      const ASCErrorEntries = data?.ASCErrorDrafts?.flatMap((error) =>
        filterNestedData(Object.entries(error)).map(([key, value]) => [
          `ASCError_${key}`,
          value,
        ])
      );

      const ASCRecordEntries = data?.ASCRecordDrafts?.flatMap((record) =>
        filterNestedData(Object.entries(record)).map(([key, value]) => [
          `ASCRecord_${key}_${record.machine_number}`,
          value,
        ])
      );

      formattedEntries = [
        ...formattedEntries,
        ...ASCErrorEntries,
        ...ASCRecordEntries,
      ];
    }

    if (selectedType === "CotCheckDraft") {
      const filterNestedData = (nestedData) =>
        nestedData?.filter(
          ([key, value]) =>
            !keysToSkip.includes(key) && key !== null && value !== null
        );

      const CotBuffEntries = data?.CotBuffDrafts?.flatMap((buff) =>
        filterNestedData(Object.entries(buff)).map(([key, value]) => [
          `CotBuff_${key}`,
          value,
        ])
      );

      const CotCheckRecordEntries = data?.CotCheckRecordDrafts?.flatMap(
        (record) =>
          filterNestedData(Object.entries(record)).map(([key, value]) => [
            `CotCheck_${key}_${record.machine_number}`,
            value,
          ])
      );

      formattedEntries = [
        ...formattedEntries,
        ...CotBuffEntries,
        ...CotCheckRecordEntries,
      ];
    }

    if (selectedType === "CountChangeDraft") {
      const filterNestedData = (nestedData) =>
        nestedData.filter(
          ([key, value]) =>
            !keysToSkip.includes(key) && key !== null && value !== null
        );

      const weightSamplesEntries = data?.weight_samples?.flatMap((sample) =>
        filterNestedData(Object.entries(sample)).map(([key, value]) => [
          `WeightSample_${key}_${sample.sample_count_number}`,
          value,
        ])
      );

      const strengthSamplesEntries = data?.strength_samples?.flatMap((sample) =>
        filterNestedData(Object.entries(sample)).map(([key, value]) => [
          `StrengthSample_${key}`,
          value,
        ])
      );

      formattedEntries = [
        ...formattedEntries,
        ...weightSamplesEntries,
        ...strengthSamplesEntries,
      ];
    }

    if (selectedType === "HairnessDraft") {
      const filterNestedData = (nestedData) =>
        nestedData?.filter(
          ([key, value]) =>
            !keysToSkip.includes(key) && key !== null && value !== null
        );

      const hairnessRecordEntries = data?.hairness_records?.flatMap((record) =>
        filterNestedData(Object.entries(record)).map(([key, value]) => [
          `HairnessRecord_${key}_${record.machine_number}`,
          value,
        ])
      );

      const hairnessErrorEntries = data?.hairness_errors?.flatMap((error) =>
        filterNestedData(Object.entries(error)).map(([key, value]) => [
          `HairnessError_${key}`,
          value,
        ])
      );

      formattedEntries = [
        ...formattedEntries,
        ...hairnessRecordEntries,
        ...hairnessErrorEntries,
      ];
    }

    return Object.fromEntries(formattedEntries);
  };

  const filteredDataToShow = selectedItemData && filterData(selectedItemData);

  const onSuccessForm = () => {
    setModelToggle(false);
    setFeedback("");
    handleToastMsg("Updated Successfully", "success");

    if (
      selectedType === "ChuteFeed1_5" ||
      selectedType === "ChuteFeed7_14" ||
      selectedType === "LAPFeed15_24" ||
      selectedType === "BRDropTest"
    ) {
      queryClient.invalidateQueries("manager-blowroom-notifications");
    } else if (selectedType === "Finisher") {
      queryClient.invalidateQueries("manager-finisher-drawing-notifications");
    } else if (
      selectedType === "RewindingStudy" ||
      selectedType === "SpliceStrength"
    ) {
      queryClient.invalidateQueries("manager-autoconer-notifications");
    } else if (
      selectedType === "ASCDraft" ||
      selectedType === "CotCheckDraft" ||
      selectedType === "CountChangeDraft" ||
      selectedType === "HairnessDraft"
    ) {
      queryClient.invalidateQueries("manager-spinning-notifications");
    }
  };

  /**handle submit the data */
  const handleSubmit = () => {
    const payload = {
      id: selectedItemData.id,
      feedback,
    };

    //blowroom condition
    if (selectedType === "ChuteFeed1_5") {
      chute15Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "ChuteFeed7_14") {
      chute7_14Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "LAPFeed15_24") {
      lapFeedMutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "BRDropTest") {
      droptest_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } //Finisher Drawing condition
    else if (selectedType === "Finisher") {
      finisher_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } //Autoconor condition
    else if (selectedType === "RewindingStudy") {
      rewinding_study_mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "SpliceStrength") {
      splice_strength_mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } //Spinning condition
    else if (selectedType === "ASCDraft") {
      asc_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "CotCheckDraft") {
      cot_checking_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "CountChangeDraft") {
      count_change_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    } else if (selectedType === "HairnessDraft") {
      hariness_Mutate(payload, {
        onSuccess: () => {
          onSuccessForm();
        },
      });
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      rowGap={3}
      alignContent={"flex-start"}
      style={{ padding: "25px", paddingBottom: "35px" }}
    >
      <Grid container item xs={12} justifyContent={"flex-end"}>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModelToggle(false);
            if (isTelevision) {
              setPauseInterval(false);
            }
          }}
        />
      </Grid>

      <Grid container item xs={12}>
        {(selectedType === "ChuteFeed7_14" ||
          selectedType === "ChuteFeed1_5" ||
          selectedType === "LAPFeed15_24") && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "1150px" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(filteredDataToShow)?.map((key) => (
                      <TableCell key={key} sx={{ minWidth: "100px" }}>
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(filteredDataToShow)?.map(
                      ([key, value], index) => {
                        const isOutOfNormsValue =
                          key === "totalWastePercentage";
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              minWidth: "100px",
                            }}
                          >
                            <p
                              style={{
                                color: isOutOfNormsValue ? "red" : "inherit",
                              }}
                            >
                              {" "}
                              {value}
                            </p>{" "}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {selectedType === "BRDropTest" && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "1150px" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(filteredDataToShow)?.map((key) => (
                      <TableCell key={key} sx={{ minWidth: "100px" }}>
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(filteredDataToShow)?.map(
                      ([key, value], index) => {
                        const isOutOfNormsValue = key.includes("difference");
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              minWidth: "100px",
                            }}
                          >
                            <p
                              style={{
                                color: isOutOfNormsValue ? "red" : "inherit",
                              }}
                            >
                              {" "}
                              {value}
                            </p>{" "}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {selectedType === "Finisher" && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "1150px" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(filteredDataToShow)?.map((key) => (
                      <TableCell key={key} sx={{ minWidth: "100px" }}>
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(filteredDataToShow)?.map(
                      ([key, value], index) => {
                        const isOutOfNormsValueHalfYard =
                          key.includes("cv") && value > 0.5;

                        const isOutOfNormsValueOneYard =
                          key.includes("cv") && value > 1;

                        return (
                          <TableCell
                            key={index}
                            sx={{
                              minWidth: "100px",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  isOutOfNormsValueHalfYard ||
                                  isOutOfNormsValueOneYard
                                    ? "red"
                                    : "inherit",
                              }}
                            >
                              {" "}
                              {value}
                            </p>{" "}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {(selectedType === "RewindingStudy" ||
          selectedType === "SpliceStrength") && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "1150px" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(filteredDataToShow)?.map((key) => (
                      <TableCell key={key} sx={{ minWidth: "100px" }}>
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(filteredDataToShow)?.map(
                      ([key, value], index) => {
                        const isOutOfNormsValueforRewinding =
                          key.includes("breakLengthMeter");

                        const isOutOfNormsValueforSplice = key.includes(
                          "averagePercentYarnReading"
                        );

                        return (
                          <TableCell
                            key={index}
                            sx={{
                              minWidth: "100px",
                            }}
                          >
                            <p
                              style={{
                                color:
                                  isOutOfNormsValueforRewinding ||
                                  isOutOfNormsValueforSplice
                                    ? "red"
                                    : "inherit",
                              }}
                            >
                              {" "}
                              {value}
                            </p>{" "}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {(selectedType === "ASCDraft" ||
          selectedType === "CotCheckDraft" ||
          selectedType === "CountChangeDraft" ||
          selectedType === "HairnessDraft") && (
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{ maxWidth: "1150px" }}
              className="custom-scrollbar"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(filteredDataToShow)?.map((key) => (
                      <TableCell key={key} sx={{ minWidth: "100px" }}>
                        {key.replace(/\b\w/g, (char) => char.toUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(filteredDataToShow)?.map(
                      ([key, value], index) => {
                        const isOutOfNormsCorrectedValues = key.includes(
                          `${
                            selectedType === "CountChangeDraft"
                              ? "count_value"
                              : selectedType === "CotCheckDraft"
                              ? "cot_buffing"
                              : "corrected"
                          }`
                        );

                        return (
                          <TableCell
                            key={index}
                            sx={{
                              minWidth: "100px",
                            }}
                          >
                            <p
                              style={{
                                color: isOutOfNormsCorrectedValues
                                  ? "red"
                                  : "inherit",
                              }}
                            >
                              {" "}
                              {value}
                            </p>{" "}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      {!isTelevision && (
        <>
          <Grid item xs={12} sm={12}>
            <Textarea
              size="lg"
              name="Size"
              placeholder="Feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value.toUpperCase())}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="center">
              <CustomButton
                style={{ width: "150px", height: "50px" }}
                type="submit"
                onClick={handleSubmit}
                disabled={!feedback || isLoadingMutate}
              >
                {isLoadingMutate ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Final Submit"
                )}
              </CustomButton>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ManagerResponse;
