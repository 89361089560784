import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../../../components/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQueryClient } from "react-query";
import { deleteNorms, getNorms, postNorms } from "./mutation";
import { handleToastMsg } from "../../../../../components/Toast";
import { useQuery } from "react-query";
import "./blowroomnormscss.css";
import Loader from "../../../../../components/Loader";
export const BlowroomNormsWrapper = styled(Grid)`
  padding: 60px;
  width: 100%;
`;

function BlowroomNorms() {
  const [rows, setRows] = useState([]);
  const [newData, setNewData] = useState([]);
  const [deleteId, setDeleteId] = useState("");

  const queryClient = useQueryClient();

  const { isLoading } = useQuery(
    "blowroom-norms",
    async () => {
      const response = await getNorms();
      return response?.data;
    },
    {
      onSuccess: (res) => {
        setRows(res.length > 0 ? res : []);
      },
    }
  );

  const { mutate, isLoading: isSubmitLoading } = useMutation(postNorms);
  const { mutate: deleteMutate, isLoading: isDeleteLoading } =
    useMutation(deleteNorms);

  const handleVarietyChange = (index, value) => {
    const newDataCopy = [...newData];
    newDataCopy[index].variety = value.toUpperCase();
    setNewData(newDataCopy);
  };

  const handleWastePercChange = (index, value) => {
    const newDataCopy = [...newData];
    newDataCopy[index].wastePerc = value.replace(/[^0-9.]/g, "");
    setNewData(newDataCopy);
  };

  const handleAddRow = () => {
    setNewData([...newData, { wastePerc: "", variety: "" }]);
  };

  const handleDeleteRow = (index) => {
    const newDataCopy = newData.filter((_, i) => i !== index);
    setNewData(newDataCopy);
  };

  //handle submit the norms data
  const handleSubmit = () => {
    mutate(newData, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        setNewData([]);
        queryClient.invalidateQueries("blowroom-norms");
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  //handle delete the norms data
  const handleDeleteData = (id) => {
    //this is form showing loading icon only on this particular id's data while delete
    setDeleteId(id);

    deleteMutate(id, {
      onSuccess: (res) => {
        handleToastMsg("Data deleted successfully", "success");
        queryClient.invalidateQueries("blowroom-norms");
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BlowroomNormsWrapper container rowGap={3} height={"100%"}>
      <Grid item>
        <h3 className="heading">Blowroom Norms</h3>
      </Grid>
      <Grid
        item
        container
        // justifyContent={"space-between"}
        xs={12}
        // width={"100%"}
        py={2}
      >
        <Grid item xs={6} className="content">
          Variety
        </Grid>
        <Grid item xs={6} className="content">
          Waste percentage
        </Grid>
      </Grid>
      <Grid item container rowGap={3}>
        {isLoading ? (
          <p>Loading...</p>
        ) : rows.length > 0 ? (
          rows?.map((item, index) => (
            <Grid item container key={index} alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={item.variety}
                  // onChange={(e) => handleVarietyChange(index, e.target.value)}
                  type="text"
                  sx={{ maxWidth: 250 }}
                  label="Variety"
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={item.wastePerc ? `${item.wastePerc}%` : ""}
                  // onChange={(e) => handleWastePercChange(index, e.target.value)}
                  type="text"
                  sx={{ maxWidth: 200 }}
                  label="Waste Percentage"
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => handleDeleteData(item.id)}
                  variant="contained"
                  style={{
                    backgroundColor: "rgba(226, 229, 232, 0)",
                  }}
                  disabled={deleteId === item.id && isDeleteLoading}
                >
                  {deleteId === item.id && isDeleteLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DeleteIcon style={{ color: "red" }} />
                  )}
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <p>No Data Available</p>
          </Grid>
        )}

        {newData?.map((item, index) => (
          <Grid item container key={index} alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <TextField
                value={item.variety}
                onChange={(e) => handleVarietyChange(index, e.target.value)}
                type="text"
                sx={{ maxWidth: 250 }}
                label="Variety"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={item.wastePerc ? `${item.wastePerc}%` : ""}
                onChange={(e) => handleWastePercChange(index, e.target.value)}
                type="text"
                sx={{ maxWidth: 200 }}
                label="Waste Percentage"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => handleDeleteRow(index)}>
                <RemoveIcon sx={{ color: "red", fontSize: "30px" }} />
              </Button>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            onClick={handleAddRow}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add Row
          </Button>
        </Grid>
      </Grid>
      <Grid item container py={2} justifyContent={"flex-end"} xs={12}>
        <CustomButton
          onClick={handleSubmit}
          style={{ width: "100px", height: "40px" }}
          type="submit"
          disabled={isSubmitLoading}
        >
          {isSubmitLoading ? <CircularProgress size={24} /> : "Submit"}
        </CustomButton>
      </Grid>
    </BlowroomNormsWrapper>
  );
}

export default BlowroomNorms;
