import apiClient from "../../../utils/clients/axios";

export const createHairnessDraft = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/spinning/hairness-drafts",
    payload
  );
  return response.data;
};

export const getHairnessDraft = async () => {
  const response = await apiClient.get(
    `/api/quality_control/spinning/hairness-drafts`
  );
  return response.data;
};

export const updateHairnessDraft = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/spinning/hairness-drafts/${id}`,
    payload
  );
  return response.data;
};
