import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { handleToastMsg } from "../../../components/Toast";

import {
  register,
  deleteUser,
  getUsers,
  update,
} from "../../Blowroom/mutation/auth/auth";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  Checkbox,
  ListItemText,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../components/Button";
import { TelevisionModulesForAddUser } from "../../../config/masterdata";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function TelevisionUserRegistration() {
  const [modelToggle, setModelToggle] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    employeeCode: "",
    module: [],
  });

  const [modules] = useState(TelevisionModulesForAddUser);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedUserId(null);
  };

  const handleConfirmDelete = () => {
    deleteUserMutation(selectedUserId);
    handleCloseDeleteDialog();
  };

  const [editUserId, setEditUserId] = useState(null);
  const handleEdit = (user) => {
    setEditMode(true);
    setModelToggle(true);
    setEditUserId(user?.id);

    setFormData((prev) => ({
      ...prev,
      name: user?.name,
      email: user?.email,
      employeeCode: user?.employeeCode,
      phoneNumber: user?.phoneNumber,
      userType: user?.role,
      module: user?.modules || [],
    }));
  };

  const handleAddUser = (user) => {
    setEditMode(false);
    setModelToggle(true);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    // Validate email
    if (name === "email") {
      if (!emailRegex.test(value)) {
        setErrors({ ...errors, email: "Invalid email address" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    }

    value =
      name === "phoneNumber" || name === "employeeCode"
        ? parseInt(value)
        : value;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
  };

  //this is for reset the form when add user success and when overlay close
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      password: "",
      userType: "",
      phoneNumber: "",
      employeeCode: "",
      module: [],
    });
  };

  const handleCloseDialogAndResetForm = () => {
    resetForm();
    setEditUserId(null);
    setModelToggle(false);
  };

  const { data: users, refetch } = useQuery("users", getUsers);
  //this is for filter the television users only
  const filteredUsers =
    users?.filter((item) => item.role === "TELEVISION") || [];

  const { mutate: registerUser } = useMutation(register, {
    onSuccess: () => {
      handleToastMsg("User added successfully", "success");
      //this is for reset the form when add user success and when overlay close
      handleCloseDialogAndResetForm();
      refetch();
    },
    onError: () => {
      handleToastMsg("An error occurred during adding a user!", "error");
    },
  });

  const { mutate: updateUser } = useMutation(update, {
    onSuccess: () => {
      handleToastMsg("User updated successfully", "success");
      //this is for reset the form when add user success and when overlay close
      handleCloseDialogAndResetForm();
      refetch();
    },
    onError: () => {
      handleToastMsg("An error occurred during adding a user!", "error");
    },
  });

  const { mutate: deleteUserMutation } = useMutation(deleteUser, {
    onSuccess: () => {
      handleToastMsg("User deleted successfully", "success");
      refetch();
    },
    onError: () => {
      handleToastMsg("An error occurred during deleting the user!", "error");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: formData.name,
      email: formData.email,
      role: "TELEVISION",
      modules: formData.module,
      employeeCode: formData.employeeCode,
      phoneNumber: formData.phoneNumber,
      ...(editMode
        ? formData.password && { password: formData.password }
        : { password: formData.password }),
    };

    //this is for edit user only
    const editPayload = { payload, editUserId };

    if (editMode) {
      updateUser(editPayload);
    } else {
      registerUser(payload);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ padding: "20px" }}
        height={"100%"}
        item
        md={12}
        sm={10.5}
      >
        <Grid item container justifyContent={"center"}>
          <h2>Television User Management</h2>
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <h3>Television Users</h3>
          </Grid>
          <Grid item container xs={6} justifyContent={"flex-end"}>
            <CustomButton onClick={() => handleAddUser()}>
              Add Users
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item container>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>User Type</TableCell>
                  <TableCell>Modules</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredUsers) &&
                  filteredUsers?.length > 0 &&
                  filteredUsers?.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.modules.join(", ")}</TableCell>
                      <TableCell>
                        <Grid style={{ display: "flex", gap: "8px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              background: "#1976d2",
                              "&:hover": { background: "#1565c0" },
                            }}
                            onClick={() => handleEdit(user)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              background: "#f44336",
                              "&:hover": { background: "#d32f2f" },
                            }}
                            onClick={() => handleClickOpen(user.id)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        open={modelToggle}
        scroll={"body"}
        onClose={handleCloseDialogAndResetForm}
        PaperProps={{ sx: { maxWidth: "80%", maxHeight: "95%" } }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogAndResetForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Grid container justifyContent={"center"} p={4}>
            <Grid container rowGap={2} xs={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Employee code"
                  name="employeeCode"
                  type="number"
                  value={formData.employeeCode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  type="number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Modules"
                  name="module"
                  value={formData.module || []}
                  onChange={(e) =>
                    setFormData({ ...formData, module: e.target.value })
                  }
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => {
                      // Create a map from value to label for easy lookup
                      const valueToLabelMap = {};
                      modules?.forEach((option) => {
                        valueToLabelMap[option.value] = option.label;
                      });

                      return selected
                        .map((value) => valueToLabelMap[value])
                        .join(", ");
                    },
                  }}
                >
                  {modules?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={formData?.module?.includes(option.value)}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {editMode ? "Update" : "Register"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} style={{ color: "green" }}>
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            style={{ color: "red" }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TelevisionUserRegistration;
