import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const DeleteConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ px: 4 }}>
        <DialogTitle style={{ marginTop: 24, marginBottom: 24 }}>
          <h3>Are you sure you want to delete this item?</h3>
          <p>This action cannot be undone!</p>
        </DialogTitle>
        <DialogActions style={{ marginBottom: 24 }}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
