import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./context/AuthProvider";
import App from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      retry: 1,
      retryDelay: (attemptIndex) => 1000 * 2 ** attemptIndex,
      cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
      staleTime: 1000 * 30, // 30 seconds of stale time
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AuthProvider>
);
// import React from "react";
// import { createRoot } from "react-dom";
// import "./index.css";
// import { QueryClient, QueryClientProvider } from "react-query";
// import { AuthProvider } from "./context/AuthProvider";
// import App from "./App";

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: true,
//       refetchOnMount: true,
//       retry: 1,
//       retryDelay: (attemptIndex) => 1000 * 2 ** attemptIndex,
//       cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
//       staleTime: 1000 * 30, // 30 seconds of stale time
//     },
//   },
// });

// const root = createRoot(document.getElementById("root"));
// root.render(
//   <AuthProvider>
//     <QueryClientProvider client={queryClient}>
//       <App />
//     </QueryClientProvider>
//   </AuthProvider>
// );

// import React from "react";
// import { createRoot } from "react-dom";
// import "./index.css";
// import { QueryClient, QueryClientProvider } from "react-query";
// import { AuthProvider } from "./context/AuthProvider";
// import App from "./App";

// // Function to render the React app using createRoot
// const renderApp = () => {
//   const queryClient = new QueryClient({
//     defaultOptions: {
//       queries: {
//         refetchOnWindowFocus: true,
//         refetchOnMount: true,
//         retry: 1,
//         retryDelay: (attemptIndex) => 1000 * 2 ** attemptIndex,
//         cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
//         staleTime: 1000 * 30, // 30 seconds of stale time
//       },
//     },
//   });

//   const rootElement = document.getElementById("root");

//   const root = createRoot(rootElement);

//   root.render(
//     <React.StrictMode>
//       <AuthProvider>
//         <QueryClientProvider client={queryClient}>
//           <App />
//         </QueryClientProvider>
//       </AuthProvider>
//     </React.StrictMode>
//   );
// };

// // Check if the DOM content is loaded before rendering the app
// if (document.readyState === "loading") {
//   document.addEventListener("DOMContentLoaded", renderApp);
// } else {
//   renderApp();
// }






