// eslint-disable
import React from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getCotCheckDraft } from "./mutation/cotCheckDraftMutation";
import "./spinningcss.css";
const CotCheckingDrafts = () => {
  const navigate = useNavigate();

  const handleOpenForm = () => {
    navigate("/record", { state: { isCotChecking: true, isDraft: false } });
  };

  const handleCorrection = (item) => {
    navigate("/record", {
      state: { data: item, isCotChecking: true, isDraft: true },
    });
  };

  const { data: cotCheckData } = useQuery(
    "cot-check",
    async () => {
      try {
        const cotCheckData = await getCotCheckDraft();
        return cotCheckData.data;
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: true,
    }
  );

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Cot Checking</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "1vw" }}>Date</TableCell>
                <TableCell style={{ width: "1vw" }}>Total LHS</TableCell>
                <TableCell style={{ width: "1vw" }}>Total RHS</TableCell>
                <TableCell style={{ width: "1vw" }}>Cots Buffing</TableCell>
                <TableCell style={{ width: "1vw" }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cotCheckData?.length > 0 &&
                cotCheckData?.map((item, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ width: "1vw" }}>
                        {dayjs(item.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.total_lhs}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.total_rhs}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        {item?.CotBuffDrafts[0]?.cot_buffing}
                      </TableCell>
                      <TableCell style={{ width: "1vw" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            width: "100px",
                            height: "40px",
                          }}
                          onClick={() => handleCorrection(item)}
                        >
                          Correction
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "200px", height: "50px" }}
            onClick={() => handleOpenForm()}
          >
            Add New Record
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CotCheckingDrafts;
