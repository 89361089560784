import dayjs from "dayjs";
import apiClient from "../../utils/clients/axios";

// simplex drafts data post
export const createSimplexCheckingDraft = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/simplex/createSimplexDraft`,
    payload
  );
  return response;
};

// simplex drafts data update
export const updateSimplexCheckingDraft = async (params) => {
  const { payload, id } = params;
  const response = await apiClient.put(
    `/api/quality_control/simplex/updateSimplexDraft/${id}`,
    payload
  );
  return response;
};

// simplex drafts data get
export const getSimplexCheckingDraft = async () => {
  const response = await apiClient.get(
    `/api/quality_control/simplex/getAllSimplexesDraft`
  );
  return response;
};

// simplex all data get
export const getSimplexCheckingData = async () => {
  const today = dayjs().format("YYYY-MM-DD"); // Format the date as needed
  const response = await apiClient.get("/api/quality_control/simplex", {
    params: {
      date: today,
    },
  });
  return response;
};

// simplex all data post
export const createSimplexCheckingData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/simplex`,
    payload
  );
  return response;
};

// simplex all data Final submit
export const createFinalSimplexCheckingData = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/simplex/createFinalSubmit`,
    payload
  );
  return response;
};
