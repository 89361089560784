import React, { useState, useEffect } from "react";
import { ContainerWrapper } from "../../../components/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Table,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  TableContainer,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TableBody,
  TableHead,
} from "@mui/material"; // Import FormControlLabel and Checkbox
import CustomButton from "../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-moment";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import apiClient from "../../../utils/clients/axios";
import { getVariety } from "../../Blowroom/mutation";
import { handleToastMsg } from "../../../components/Toast";
import dayjs from "dayjs";

function LapFeedReport() {
  const [formData, setFormData] = useState({
    reportType: "individual",
    from_date: null,
    to_date: null,
    varietyEnabled: false,
    variety: [],
    cardingMachineNoEnabled: false,
    cardingMachineNo: [],
    cardingLineNoEnabled: false,
    cardingLineNo: "",
  });

  const [cardingLineNoShow] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [cardingMachineNoOptions, setCardingMachineNoOptions] = useState([]);

  //get all variety data
  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  //show carding line number
  useEffect(() => {
    let showNumbers = [];
    switch (formData.cardingLineNo) {
      case 1:
        showNumbers = [10, 11, 12];
        break;
      case 2:
        showNumbers = [7, 8, 9];
        break;
      case 3:
        showNumbers = [13, 14];
        break;
      case 4:
        showNumbers = [1, 2, 3, 6];
        break;
      case 5:
        showNumbers = [15, 16, 17, 18, 19, 20];
        break;
      case 6:
        showNumbers = [21, 22, 23, 24, 25, 26];
        break;
      case 7:
        showNumbers = [4, 5];
        break;

      default:
        showNumbers = [];
    }
    setCardingMachineNoOptions(showNumbers);
    setFormData((prev) => ({ ...prev, cardingMachineNo: [] }));
  }, [formData.cardingLineNo]);

  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]); // State to store the fetched data

  const handlePreviewData = async (event) => {
    event.preventDefault();
    const formatDate = (date, endOfDay = false) => {
      const adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = adjustedDate.toISOString().split("T")[0];
      if (endOfDay) {
        return formattedDate + "T23:59:59";
      } else {
        return formattedDate + "T00:00:00";
      }
    };

    const formattedFromDate = formData.from_date
      ? formatDate(new Date(formData.from_date))
      : "";

    const formattedToDate = formData.to_date
      ? formatDate(new Date(formData.to_date), true)
      : "";

    //this is for send the data as a single element from the array
    const varieties = formData?.variety?.join(",") || "";
    const cardingMacinesNo = formData?.cardingMachineNo?.join(",") || "";

    try {
      setLoading(true);
      const response = await apiClient.get(
        `api/quality_control/blow_room/lap_feed_15_24_report?from_date=${formattedFromDate}&to_date=${formattedToDate}`,
        {
          params: {
            ...(formData?.reportType === "consolidated"
              ? { consolidated: true }
              : {
                  variety: varieties,
                  cardingNum: cardingMacinesNo,
                  cardingLineNum: formData.cardingLineNo,
                }),
          },
        }
      );

      if (response?.data?.length === 0) {
        handleToastMsg("No data available", "info");
        return;
      }

      setPreviewData(response.data);
      setOpenPreview(true);
    } catch (error) {
      handleClosePreview();
      handleToastMsg("Data Error Occured", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewData([]);
  };

  //handle radio select change
  const handleRadioChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      reportType: value,
    }));
  };

  //handle date change
  const handleDateChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //handle form change
  const handleFormChange = (event, field) => {
    let { value } = event.target;
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  //handle input change
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  //handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let headers = new Set();
      let dataRows = [];

      const flattenObject = (obj, parentKey = "") => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = parentKey ? `${parentKey}_${key}` : key;
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            Object.assign(acc, flattenObject(value, newKey));
          } else {
            acc[newKey] = value;
          }
          return acc;
        }, {});
      };

      previewData?.forEach((item) => {
        let row = {};
        Object.entries(item).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((obj, index) => {
              const flatObj = flattenObject(obj, `${key}_${index + 1}`);
              Object.entries(flatObj).forEach(([subKey, subValue]) => {
                headers.add(subKey);
                row[subKey] = subValue;
              });
            });
          } else if (typeof value === "object" && value !== null) {
            const flatObj = flattenObject(value, key);
            Object.entries(flatObj).forEach(([subKey, subValue]) => {
              headers.add(subKey);
              row[subKey] = subValue;
            });
          } else {
            headers.add(key);
            row[key] =
              key === "date" ? dayjs(value).format("DD-MM-YYYY") : value;
          }
        });
        dataRows.push(row);
      });

      headers = Array.from(headers);

      const excludeHeaders = ["id", "created_at", "updated_at", "employeeId"];
      headers = headers.filter((header) => !excludeHeaders.includes(header));

      const worksheetData = [headers];

      dataRows.forEach((row) => {
        const rowData = headers.map((header) => row[header] || "");
        worksheetData.push(rowData);
      });
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "LAP-FEED-REPORTS.xlsx");
      handleToastMsg("Reports downloaded successfully", "success");
    } catch (error) {
      handleToastMsg("Data Error Occured", "error");
    } finally {
      handleClosePreview();
      setLoading(false);
    }
  };

  return (
    <ContainerWrapper container rowGap={2} height={0}>
      <form onSubmit={handlePreviewData} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <h2>Lap feed Report</h2>
        </Grid>
        <Grid item container justifyContent={"space-between"} xs={12} md={8}>
          <Grid item>
            <RadioGroup
              name="reportType"
              value={formData.reportType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="individual"
                control={<Radio />}
                label="Individual Report"
              />
              <FormControlLabel
                value="consolidated"
                control={<Radio />}
                label="Consolidated Report"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item container mb={3}>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date from"
                    value={formData.from_date}
                    onChange={(value) => handleDateChange("from_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={5}
            alignItems={"center"}
            columnGap={2}
          >
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date to"
                    value={formData.to_date}
                    onChange={(value) => handleDateChange("to_date", value)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} md={12} alignItems={"center"}>
          <TableContainer component={Paper} style={{ width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell>Variety</TableCell>
                <TableCell style={{ width: 600 }}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      multiple
                      value={formData?.variety}
                      disabled={formData.reportType === "consolidated"}
                      renderValue={(selected) => selected.join(", ")}
                      onChange={(e) => handleFormChange(e, "variety")}
                    >
                      {varietyData?.map((variety) => (
                        <MenuItem key={variety} value={variety}>
                          <Checkbox
                            checked={formData?.variety?.indexOf(variety) > -1}
                          />
                          <ListItemText primary={variety} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Carding Line No</TableCell>
                <TableCell style={{ width: 600 }}>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      labelId="cardingLineNo-label"
                      id="cardingLineNo"
                      name="cardingLineNo"
                      value={formData.cardingLineNo || ""}
                      disabled={formData.reportType === "consolidated"}
                      onChange={(event) =>
                        handleInputChange("cardingLineNo", event.target.value)
                      }
                    >
                      {cardingLineNoShow.map((lineNo) => (
                        <MenuItem key={lineNo} value={lineNo}>
                          {lineNo}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Carding Machine No</TableCell>
                <TableCell style={{ width: 600 }}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        multiple
                        value={formData?.cardingMachineNo}
                        disabled={formData.reportType === "consolidated"}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(event) =>
                          handleInputChange(
                            "cardingMachineNo",
                            event.target.value
                          )
                        }
                      >
                        {cardingMachineNoOptions.map((machineNo) => (
                          <MenuItem key={machineNo} value={machineNo}>
                            <Checkbox
                              checked={
                                formData?.cardingMachineNo?.indexOf(machineNo) >
                                -1
                              }
                            />
                            <ListItemText primary={machineNo} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item container justifyContent={"flex-end"} xs={12} md={12} mt={2}>
          <CustomButton
            style={{ width: "150px", height: "50px" }}
            type="submit"
            onClick={handlePreviewData}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={32} />
            ) : (
              "Preview"
            )}
          </CustomButton>
        </Grid>
      </form>

      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          style={{
            backgroundColor: "#f2f0f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mb={2}
        >
          <b>Preview</b>
          {/* Close Icon */}
          <IconButton onClick={handleClosePreview} sx={{ color: "#000" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                    <TableCell>
                      <b>DATE</b>
                    </TableCell>
                    <TableCell>
                      <b>HALL</b>
                    </TableCell>
                    <TableCell>
                      <b>VARIETY</b>
                    </TableCell>
                    <TableCell>
                      <b>CARDING LINE NUMBER</b>
                    </TableCell>
                    <TableCell>
                      <b>TOTAL WASTE %</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>{item.hall}</TableCell>
                      <TableCell>{item.variety}</TableCell>
                      <TableCell>{item.cardingLineNum}</TableCell>
                      <TableCell>{item.totalWastePercentage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No data available to preview</p>
          )}
          <Grid item xs={12} container justifyContent={"flex-end"} mt={3}>
            <CustomButton
              style={{ width: "150px", height: "40px" }}
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={32} />
              ) : (
                "Generate"
              )}
            </CustomButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </ContainerWrapper>
  );
}

export default LapFeedReport;
