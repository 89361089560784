import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import { Typography } from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { managerSastraNotification } from "../../../mutation/manager/blowroomReportMutation";

const ManagerSastaNotification = () => {
  const {
    data: managerSastraNotifications,
    isLoading: isManagerSastraNotificationsLoading,
    isError: isManagerSastraNotificationsError,
  } = useQuery("manager-sastra-notifications", managerSastraNotification);

  const [sastraNotificationsData, setSastraNotificationsData] = useState({});

  useEffect(() => {
    if (
      managerSastraNotifications &&
      managerSastraNotifications.data &&
      managerSastraNotifications.data.data
    ) {
      setSastraNotificationsData(managerSastraNotifications.data.data);
    }
  }, [managerSastraNotifications]);

  if (isManagerSastraNotificationsLoading) {
    return <div>Loading...</div>;
  }

  if (isManagerSastraNotificationsError) {
    return <div>Error loading data</div>;
  }

  const renderTable = (category, data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return (
        <div key={category}>
          <h3>{category}</h3>
          <p>No data available</p>
        </div>
      );
    }

    const keys = Object.keys(data[0]);

    return (
      <TableContainer
        component={Paper}
        style={{ marginBottom: "20px" }}
        key={category}
        sx={{ m: 5 }}
      >
        <Grid container justifyContent={"center"}>
          <Typography variant="h6" style={{ padding: "10px" }}>
            {category}
          </Typography>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {keys.map(
                (key) =>
                  key !== "id" &&
                  key !== "employeeId" &&
                  key !== "created_at" &&
                  key !== "updated_at" &&
                  key !== "createdAt" &&
                  key !== "isOutOfNorms" &&
                  key !== "stdHank" &&
                  key !== "updatedAt" && <TableCell key={key}>{key}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                {keys.map(
                  (key) =>
                    key !== "id" &&
                    key !== "employeeId" &&
                    key !== "created_at" &&
                    key !== "updated_at" &&
                    key !== "createdAt" &&
                    key !== "isOutOfNorms" &&
                    key !== "stdHank" &&
                    key !== "updatedAt" && (
                      <TableCell key={key}>{item[key]}</TableCell>
                    )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container item md={12} alignContent={"flex-start"}>
      <Grid container justifyContent={"center"}>
        <Typography variant="h4" sx={{ pt: 4 }}>
          Sasta
        </Typography>
      </Grid>
      {Object.keys(sastraNotificationsData).map((category) =>
        renderTable(category, sastraNotificationsData[category])
      )}
    </Grid>
  );
};

export default ManagerSastaNotification;
