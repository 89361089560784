import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useQuery } from "react-query";
import { Typography, CircularProgress, Box } from "@mui/material";
import DialogModal from "../../../../components/Dialog";
import { adminNotification } from "../../mutation/admin/blowroomReportMutation";
import AdminResponse from "./AdminResponse";

const AdminNotification = () => {
  const [modelToggle, setModelToggle] = React.useState(false);
  const [selectedItemData, setSelectedItemData] = React.useState({});
  const [selectedType, setSelectedType] = React.useState("");
  const {
    data: managerNotifications,
    isLoading,
    isError,
  } = useQuery(
    "admin-notifications",
    // Define an asynchronous function that returns a Promise
    async () => {
      try {
        const response = await adminNotification(); // Assuming managerNotification is your API function
       
        return response.data; // Assuming your API response is structured as an object with a "data" property
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: false, // Disable automatic refetch
    }
  );

  if (managerNotifications && Object.keys(managerNotifications).length === 0)
    return (
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <h2>No notifications found!</h2>
      </Grid>
    );

  const renderTable = (title, data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null; // Return nothing if data is undefined, not an array, or empty
    }

    const headers = [
      "Sl no",
      "Date",
      "Carding number",
      "Value",
      "View & Respond",
    ];

    return (
      <>
        <Grid sx={{ m: 5 }}>
          <Typography>{title}</Typography>
        </Grid>
        {Object.keys(data).length > 0 ? (
          <TableContainer sx={{ m: 5 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.carding_line_num}</TableCell>
                    <TableCell>{row.waste_perc_total}%</TableCell>
                    <TableCell>
                      {/* <Link to="/manager-response"> */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setModelToggle(true);
                          setSelectedItemData(row);
                          setSelectedType(title);
                        }}
                      >
                        Respond
                      </Button>
                      {/* </Link> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Typography>No Data</Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <Grid container>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
          width="100%"
          zIndex={9999} // Ensure this is above other elements
          backdropFilter="blur(10px)" // Apply the blur effect
          background="rgba(255, 255, 255, 0.8)" // Background color with transparency
        >
          <CircularProgress /> {/* Center the loading spinner */}
        </Box>
      ) : isError ? (
        <Typography>Error loading data</Typography>
      ) : (
        <>
          {Object.keys(managerNotifications).map((key) =>
            renderTable(key, managerNotifications[key])
          )}
        </>
      )}

      <DialogModal
        DialogModal
        toggleModal={modelToggle}
        setToggleModal={setModelToggle}
      >
        <AdminResponse
          selectedItemData={selectedItemData}
          selectedType={selectedType}
          setModelToggle={setModelToggle}
        />
      </DialogModal>
    </Grid>
  );
};

export default AdminNotification;
