import { styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { SubModulesForAddUser } from "../config/masterdata";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import useAuth from "../hooks/useAuth";
// import SimplexChecking from "../container/Simplex/SimplexChecking";

const Wrapper = styled("div")`
  display: flex;
  min-height: 100vh;

  .ps-menuitem-root .ps-active {
    background-color: #1a1998;
    color: #fff;
  }
  .ps-menuitem-root .ps-active :hover {
    background-color: #1a1998;
    color: #fff;
  }
`;

function SidebarComponent() {
  const [activeMenuItem, setActiveMenuItem] = useState("lap-feed-waste");
  const location = useLocation();
  const roles = localStorage.getItem("roles") || ""; // Change this to match your storage method
  const userModules = JSON.parse(localStorage.getItem("userModules")) || [];
  // const userSubModules =
  //   JSON.parse(localStorage.getItem("userSubModules")) || [];

  const [subModuleStates, setSubModuleStates] = useState({});
  // useMemo to memoize userSubModules
  const userSubModules = useMemo(() => {
    return JSON.parse(localStorage.getItem("userSubModules")) || [];
  }, []);

  useEffect(() => {
    const subModuleState = {};
    SubModulesForAddUser?.forEach((category) => {
      category?.modules?.forEach((subModule) => {
        subModuleState[
          `show${subModule?.label?.replace(/[^a-zA-Z0-9]/g, "")}`
        ] = userSubModules?.includes(subModule?.value);
      });
    });

    setSubModuleStates(subModuleState);
  }, [userSubModules]);

  const handleMenuItemClick = (menuItemId) => {
    setActiveMenuItem(menuItemId);
    localStorage.setItem("activeMenuItem", menuItemId);
  };

  //check with ROLES for accessing the menu lists
  const showAdminAccess = roles === "ADMIN";
  const showManagerAccess = roles === "MANAGER";
  const showEmployeeAccess = roles === "EMPLOYEE";
  const showSubManagerAccess = roles === "SUB_MANAGER";
  const showMDAccess = roles === "MANAGING_DIRECTOR";

  //check with USER MODULES for accessing the menu lists
  const showBlowroom = userModules?.includes("blow_room");
  const showAutoconer = userModules?.includes("autoconer");
  const showCarding = userModules?.includes("carding");
  // const showMasterDataEdit = userModules?.includes("machine-setting"); //change later
  const showSpinning = userModules?.includes("spinning");
  const showLap = userModules?.includes("lap");
  const showFinisherDrawing = userModules?.includes("finisher_drawing");
  const showSimplexChecking = userModules?.includes("simplex_checking");
  const showGeneral = userModules?.includes("general");
  const showOthers = userModules?.includes("others");
  const showTaskSheets = userModules?.includes("task_sheets");
  const showSasta = userModules?.includes("sasta");

  const showSubMangerAndEmployeeAccess =
    showSubManagerAccess || showEmployeeAccess;

  useEffect(() => {
    //this is for remove the / from the pathname
    const pathname = location.pathname.startsWith("/")
      ? location.pathname.substring(1)
      : location.pathname;

    localStorage.setItem("activeMenuItem", pathname);
    setActiveMenuItem(pathname);
  }, [location]);

  const [collapsed, setCollapsed] = useState(true);
  // const toggleCollapse = () => {
  //   setCollapsed(!collapsed);
  // };

  // Debounce the mouse enter and leave events (pretend for glitching)
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const handleMouseEnter = debounce(() => setCollapsed(false), 100);
  const handleMouseLeave = debounce(() => setCollapsed(true), 100);

  return (
    <Wrapper>
      {roles !== "TELEVISION" && (
        <Sidebar
          // onMouseEnter={() => setCollapsed(false)}
          // onMouseLeave={() => setCollapsed(true)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          collapsed={collapsed}
          style={{
            transition: "all 0.3s ease-in-out",
            background: "#ededed",
          }}
        >
          {/**Dashboard */}
          <Menu active={activeMenuItem === "dashboard"}>
            <SubMenu label="Dashboard">
              <Link to="/dashboard">
                <MenuItem
                  active={activeMenuItem === "dashboard"}
                  onClick={() => handleMenuItemClick("dashboard")}
                >
                  Dashboard
                </MenuItem>
              </Link>
            </SubMenu>
          </Menu>

          {/* {localStorage.getItem("dashboardMenu") &&
            JSON.parse(localStorage.getItem("dashboardMenu")).map((item, index) => (
              <MenuItem key={index}>
                <Link to={item.link} style={{ textDecoration: "none" }}>
                  {item.menu}
                </Link>
              </MenuItem>
            ))} */}
          {/* </Menu> */}

          {/**Modules (readings or data entry) for both Employee and Sub Manager */}
          {showSubMangerAndEmployeeAccess && (
            <>
              {/* show only when there's access */}
              {/* <Menu>
              <SubMenu label="Machine Setting">
                <Link to="/general-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "general-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("general-machine-setting")
                    }
                  >
                    General
                  </MenuItem>
                </Link>

                <Link to="/blowroom-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "blowroom-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("blowroom-machine-setting")
                    }
                  >
                    Blowroom
                  </MenuItem>
                </Link>
                <Link to="/carding-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "carding-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("carding-machine-setting")
                    }
                  >
                    Carding
                  </MenuItem>
                </Link>
                <Link to="/drawing-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "drawing-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("drawing-machine-setting")
                    }
                  >
                    Drawing
                  </MenuItem>
                </Link>
                <Link to="/simplex-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "simplex-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("simplex-machine-setting")
                    }
                  >
                    Simplex
                  </MenuItem>
                </Link>
                <Link to="/spinning-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "spinning-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("spinning-machine-setting")
                    }
                  >
                    Spinning
                  </MenuItem>
                </Link>
                <Link to="/autoconer-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "autoconer-machine-setting"}
                    onClick={() =>
                      handleMenuItemClick("autoconer-machine-setting")
                    }
                  >
                    Autoconer
                  </MenuItem>
                </Link>
                <Link to="/other-machine-setting">
                  <MenuItem
                    active={activeMenuItem === "other-machine-setting"}
                    onClick={() => handleMenuItemClick("other-machine-setting")}
                  >
                    Others
                  </MenuItem>
                </Link>
              </SubMenu>
            </Menu> */}

              {showGeneral && subModuleStates?.showGeneralMachineSettings && (
                <Menu>
                  <SubMenu label="General">
                    <Link to="/general-machine-settings">
                      <MenuItem
                        active={activeMenuItem === "general-machine-settings"}
                        onClick={() =>
                          handleMenuItemClick("general-machine-settings")
                        }
                      >
                        Machine Setting
                      </MenuItem>
                    </Link>
                  </SubMenu>
                </Menu>
              )}

              {showOthers && subModuleStates?.showOthersMachineSettings && (
                <Menu>
                  <SubMenu label="Others">
                    <Link to="/others-machine-settings">
                      <MenuItem
                        active={activeMenuItem === "others-machine-settings"}
                        onClick={() =>
                          handleMenuItemClick("others-machine-settings")
                        }
                      >
                        Machine Setting
                      </MenuItem>
                    </Link>
                  </SubMenu>
                </Menu>
              )}

              {showBlowroom && (
                <Menu>
                  <SubMenu label="Blowroom">
                    {subModuleStates?.showBlowroomMachineSettings && (
                      <Link to="/blow-room-machine-settings">
                        <MenuItem
                          active={activeMenuItem === "blow-room-machine-settings"}
                          onClick={() =>
                            handleMenuItemClick("blow-room-machine-settings")
                          }
                        >
                          Blowroom Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showLapFeed && (
                      <Link to="/lap-feed-waste">
                        <MenuItem
                          active={activeMenuItem === "lap-feed-waste"}
                          onClick={() => handleMenuItemClick("lap-feed-waste")}
                        >
                          Lap feed waste
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showChuteFeedCotton && (
                      <Link to="/chute-feed-waste">
                        <MenuItem
                          active={activeMenuItem === "chute-feed-waste"}
                          onClick={() =>
                            handleMenuItemClick("chute-feed-waste")
                          }
                        >
                          Chute feed waste(Cotton){" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showChuteFeedOthers && (
                      <Link to="/chute-feed-waste-others">
                        <MenuItem
                          active={activeMenuItem === "chute-feed-waste-others"}
                          onClick={() =>
                            handleMenuItemClick("chute-feed-waste-others")
                          }
                        >
                          Chute feed waste(Others){" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showDropTest && (
                      <Link to="/drop-test">
                        <MenuItem
                          active={activeMenuItem === "drop-test"}
                          onClick={() => handleMenuItemClick("drop-test")}
                        >
                          Drop test
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showCarding && (
                <Menu>
                  <SubMenu label="Carding">
                    {subModuleStates?.showCardingMachineSettings && (
                      <Link to="/carding-machine-settings">
                        <MenuItem
                          active={activeMenuItem === "carding-machine-settings"}
                          onClick={() =>
                            handleMenuItemClick("carding-machine-settings")
                          }
                        >
                          Carding Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showIndividualCardingWaste && (
                      <Link to="/individual-carding-waste">
                        <MenuItem
                          active={activeMenuItem === "individual-carding-waste"}
                          onClick={() =>
                            handleMenuItemClick("individual-carding-waste")
                          }
                        >
                          Individual carding waste
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showAutoLevellerOnOff && (
                      <Link to="/auto-leveller-on/off">
                        <MenuItem
                          active={activeMenuItem === "auto-leveller-on/off"}
                          onClick={() =>
                            handleMenuItemClick("auto-leveller-on/off")
                          }
                        >
                          Auto leveller On/Off
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showAutoconer && (
                <Menu>
                  <SubMenu label="Autoconer">
                    {subModuleStates?.showAutoconerMachineSettings && (
                      <Link to="/autoconer-machine-settings">
                        <MenuItem
                          active={
                            activeMenuItem === "autoconer-machine-settings"
                          }
                          onClick={() =>
                            handleMenuItemClick("autoconer-machine-settings")
                          }
                        >
                          Autoconer Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showSpliceStrength && (
                      <Link to="/splice-strength-checking">
                        <MenuItem
                          active={activeMenuItem === "splice-strength-checking"}
                          onClick={() =>
                            handleMenuItemClick("splice-strength-checking")
                          }
                        >
                          Splice Strength
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showPaperConeIdentification && (
                      <Link to="/paper-cone-identification">
                        <MenuItem
                          active={
                            activeMenuItem === "paper-cone-identification"
                          }
                          onClick={() =>
                            handleMenuItemClick("paper-cone-identification")
                          }
                        >
                          Paper Cone Identification
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showLNChecking && (
                      <Link to="/ln-checking">
                        <MenuItem
                          active={activeMenuItem === "ln-checking"}
                          onClick={() => handleMenuItemClick("ln-checking")}
                        >
                          LN checking
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showRewindingstudy && (
                      <Link to="/rewinding-study">
                        <MenuItem
                          active={activeMenuItem === "rewinding-study"}
                          onClick={() => handleMenuItemClick("rewinding-study")}
                        >
                          Rewinding Study
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showTrail && (
                      <Link to="/trail">
                        <MenuItem
                          active={activeMenuItem === "trail"}
                          onClick={() => handleMenuItemClick("trail")}
                        >
                          Trail
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showU && (
                      <Link to="/u-percent-trail">
                        <MenuItem
                          active={activeMenuItem === "u-percent-trail"}
                          onClick={() => handleMenuItemClick("u-percent-trail")}
                        >
                          U%
                        </MenuItem>
                      </Link>
                    )}

                    <Link to="/alarm-cops">
                      <MenuItem
                        active={activeMenuItem === "alarm-cops"}
                        onClick={() => handleMenuItemClick("alarm-cops")}
                      >
                        Alarm cops
                      </MenuItem>
                    </Link>
                    <Link to="/cuts">
                      <MenuItem
                        active={activeMenuItem === "cuts"}
                        onClick={() => handleMenuItemClick("cuts")}
                      >
                        Cuts
                      </MenuItem>
                    </Link>
                  </SubMenu>
                </Menu>
              )}

              {showSpinning && (
                <Menu>
                  <SubMenu label="Spinning">
                    {subModuleStates?.showSpinningMachineSettings && (
                      <Link to="/spinning-machine-settings">
                        <MenuItem
                          active={activeMenuItem === "spinning-machine-settings"}
                          onClick={() =>
                            handleMenuItemClick("spinning-machine-settings")
                          }
                        >
                          Spinning Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showCountChange && (
                      <Link to="/count-change-record">
                        <MenuItem
                          active={activeMenuItem === "count-change-record"}
                          onClick={() =>
                            handleMenuItemClick("count-change-record")
                          }
                        >
                          Count Change
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showCotChecking && (
                      <Link to="/cot-checking">
                        <MenuItem
                          active={activeMenuItem === "cot-checking"}
                          onClick={() => handleMenuItemClick("cot-checking")}
                        >
                          Cot Checking
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showHariness && (
                      <Link to="/hariness">
                        <MenuItem
                          active={activeMenuItem === "hariness"}
                          onClick={() => handleMenuItemClick("hariness")}
                        >
                          Hariness
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showApronSpacerCondenser && (
                      <Link to="/apron">
                        <MenuItem
                          active={activeMenuItem === "apron"}
                          onClick={() => handleMenuItemClick("apron")}
                        >
                          Apron, Spacer & Condenser
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showLap && (
                <Menu>
                  <SubMenu label="Lap">
                    {subModuleStates?.showSliverLap && (
                      <Link to="/sliver-lap">
                        <MenuItem
                          active={activeMenuItem === "sliver-lap"}
                          onClick={() => handleMenuItemClick("sliver-lap")}
                        >
                          Sliver Lap
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showRibbonLap && (
                      <Link to="/ribbon-lap">
                        <MenuItem
                          active={activeMenuItem === "ribbon-lap"}
                          onClick={() => handleMenuItemClick("ribbon-lap")}
                        >
                          Ribbon Lap
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showStructureLap && (
                      <Link to="/structure-lap">
                        <MenuItem
                          active={activeMenuItem === "structure-lap"}
                          onClick={() => handleMenuItemClick("structure-lap")}
                        >
                          Structure Lap
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showFinisherDrawing && (
                <Menu>
                  <SubMenu label="Finisher Drawing">
                    {subModuleStates?.showFinisherDrawingMachineSettings && (
                      <Link to="/finisher-drawing-machine-settings">
                        <MenuItem
                          active={activeMenuItem === "finisher-drawing-machine-settings"}
                          onClick={() =>
                            handleMenuItemClick("finisher-drawing-machine-settings")
                          }
                        >
                          Drawing Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.show112Yard && (
                      <Link to="/finisher-drawing-yards">
                        <MenuItem
                          active={activeMenuItem === "finisher-drawing-yards"}
                          onClick={() =>
                            handleMenuItemClick("finisher-drawing-yards")
                          }
                        >
                          1 & 1/2 Yard
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showSimplexChecking && (
                <Menu>
                  <SubMenu label="Simplex">
                    {subModuleStates?.showSimplexCheckingMachineSettings && (
                      <Link to="/simplex-checking-machine-settings">
                        <MenuItem
                          active={activeMenuItem === "simplex-checking-machine-settings"}
                          onClick={() =>
                            handleMenuItemClick("simplex-checking-machine-settings")
                          }
                        >
                          Simplex Machine Setting
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showSimplexChecking && (
                      <Link to="/simplex-checking-draft">
                        <MenuItem
                          active={activeMenuItem === "simplex-checking-draft"}
                          onClick={() =>
                            handleMenuItemClick("simplex-checking-draft")
                          }
                        >
                          Simplex Checking
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                </Menu>
              )}

              {showTaskSheets && (
                <Menu>
                  <SubMenu label="Task Sheets">
                    <Link to="/task-sheets-complaints">
                      <MenuItem
                        active={activeMenuItem === "task-sheets-complaints"}
                        onClick={() =>
                          handleMenuItemClick("task-sheets-complaints")
                        }
                      >
                        Task Sheets Complaints
                      </MenuItem>
                    </Link>
                  </SubMenu>
                </Menu>
              )}
            </>
          )}

          {/* <Menu>
          <SubMenu label="Carding">
            <Link to="/wrapping">
              <MenuItem
                active={activeMenuItem === "wrapping"}
                onClick={() => handleMenuItemClick("wrapping")}
              >
                Wrapping
              </MenuItem>
            </Link>

            <Link to="/neps">
              <MenuItem
                active={activeMenuItem === "neps"}
                onClick={() => handleMenuItemClick("neps")}
              >
                NEPS
              </MenuItem>
            </Link>

            <Link to="/onn-off">
              <MenuItem
                active={activeMenuItem === "onn-off"}
                onClick={() => handleMenuItemClick("onn-off")}
              >
                Onn and Off Entry
              </MenuItem>
            </Link>
          </SubMenu>
        </Menu> */}

          {/**Modules (notifications) for Manager only */}
          {showManagerAccess && (
            <>
              <Menu>
                <SubMenu label="Notifications">
                  {showBlowroom && (
                    <Link to="/manager-blowroom-notifications">
                      <MenuItem
                        active={
                          activeMenuItem === "manager-blowroom-notifications"
                        }
                        onClick={() =>
                          handleMenuItemClick("manager-blowroom-notifications")
                        }
                      >
                        Blowroom notifications
                      </MenuItem>
                    </Link>
                  )}

                  {showAutoconer && (
                    <Link to="/manager-autoconer-notifications">
                      <MenuItem
                        active={
                          activeMenuItem === "manager-autoconer-notifications"
                        }
                        onClick={() =>
                          handleMenuItemClick("manager-autoconer-notifications")
                        }
                      >
                        Autoconer notifications
                      </MenuItem>
                    </Link>
                  )}

                  {showSpinning && (
                    <Link to="/manager-spinning-notifications">
                      <MenuItem
                        active={
                          activeMenuItem === "manager-spinning-notifications"
                        }
                        onClick={() =>
                          handleMenuItemClick("manager-spinning-notifications")
                        }
                      >
                        Spinning notifications
                      </MenuItem>
                    </Link>
                  )}

                  {showFinisherDrawing && (
                    <Link to="/manager-finisher-drawing-notifications">
                      <MenuItem
                        active={
                          activeMenuItem ===
                          "manager-finisher-drawing-notifications"
                        }
                        onClick={() =>
                          handleMenuItemClick(
                            "manager-finisher-drawing-notifications"
                          )
                        }
                      >
                        Finisher Drawing notifications
                      </MenuItem>
                    </Link>
                  )}

                  {showSasta && (
                    <Link to="/manager-sasta-notifications">
                      <MenuItem
                        active={
                          activeMenuItem === "manager-sasta-notifications"
                        }
                        onClick={() =>
                          handleMenuItemClick("manager-sasta-notifications")
                        }
                      >
                        Sasta notifications
                      </MenuItem>
                    </Link>
                  )}
                </SubMenu>

                <SubMenu label="Machine Settings">
                  {showGeneral &&
                    subModuleStates?.showGeneralMachineSettings && (
                      <Link to="/general-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "general-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "general-machine-settings-correction"
                            )
                          }
                        >
                          General
                        </MenuItem>
                      </Link>
                    )}

                  {showBlowroom &&
                    subModuleStates?.showBlowroomMachineSettings && (
                      <Link to="/blow-room-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "blow-room-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "blow-room-machine-settings-correction"
                            )
                          }
                        >
                          Blowroom
                        </MenuItem>
                      </Link>
                    )}

                  {showCarding &&
                    subModuleStates?.showCardingMachineSettings && (
                      <Link to="/carding-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "carding-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "carding-machine-settings-correction"
                            )
                          }
                        >
                          Carding
                        </MenuItem>
                      </Link>
                    )}

                  {showAutoconer &&
                    subModuleStates?.showAutoconerMachineSettings && (
                      <Link to="/autoconer-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "autoconer-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "autoconer-machine-settings-correction"
                            )
                          }
                        >
                          Autoconer
                        </MenuItem>
                      </Link>
                    )}

                  {showSpinning &&
                    subModuleStates?.showSpinningMachineSettings && (
                      <Link to="/spinning-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "spinning-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "spinning-machine-settings-correction"
                            )
                          }
                        >
                          Spinning
                        </MenuItem>
                      </Link>
                    )}

                  {showFinisherDrawing &&
                    subModuleStates?.showFinisherDrawingMachineSettings && (
                      <Link to="/finisher-drawing-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "finisher-drawing-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "finisher-drawing-machine-settings-correction"
                            )
                          }
                        >
                          Finisher Drawing
                        </MenuItem>
                      </Link>
                    )}

                  {showSimplexChecking &&
                    subModuleStates?.showSimplexCheckingMachineSettings && (
                      <Link to="/simplex-checking-machine-settings-correction">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "simplex-checking-machine-settings-correction"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "simplex-checking-machine-settings-correction"
                            )
                          }
                        >
                          Simplex Checking
                        </MenuItem>
                      </Link>
                    )}

                  {showOthers && subModuleStates?.showOthersMachineSettings && (
                    <Link to="/others-machine-settings-correction">
                      <MenuItem
                        active={
                          activeMenuItem === "others-machine-settings-correction"
                        }
                        onClick={() =>
                          handleMenuItemClick(
                            "others-machine-settings-correction"
                          )
                        }
                      >
                        Others
                      </MenuItem>
                    </Link>
                  )}
                </SubMenu>
              </Menu>

              {showTaskSheets && (
                <Menu>
                  <SubMenu label="Task Sheets">
                    <Link to="/task-sheets-resolve">
                      <MenuItem
                        active={activeMenuItem === "task-sheets-resolve"}
                        onClick={() =>
                          handleMenuItemClick("task-sheets-resolve")
                        }
                      >
                        Task Sheets Respond
                      </MenuItem>
                    </Link>
                  </SubMenu>
                </Menu>
              )}
            </>
          )}

          {/**Modules (norms, sasta reports) for Sub Manager and Manager only */}
          {(showSubManagerAccess || showManagerAccess) && (
            <Menu>
              {showBlowroom && (
                <SubMenu label="Norms">
                  <Link to="/blowroom-norms">
                    <MenuItem
                      active={activeMenuItem === "blowroom-norms"}
                      onClick={() => handleMenuItemClick("blowroom-norms")}
                    >
                      Blowroom norms
                    </MenuItem>
                  </Link>
                </SubMenu>
              )}

              {showSasta && (
                <SubMenu label="Sasta Reports">
                  <Link to="/sasta-reports">
                    <MenuItem
                      active={activeMenuItem === "sasta-reports"}
                      onClick={() => handleMenuItemClick("sasta-reports")}
                    >
                      Sasta Reports
                    </MenuItem>
                  </Link>
                </SubMenu>
              )}
            </Menu>
          )}

          {/**Modules (user creates, wrapping, parameter masters) for Admin only */}
          {showAdminAccess && (
            <Menu>
              <SubMenu label="User">
                <Link to="/admin-user">
                  <MenuItem
                    active={activeMenuItem === "admin-user"}
                    onClick={() => handleMenuItemClick("admin-user")}
                  >
                    User
                  </MenuItem>
                </Link>
                <Link to="/television-user">
                  <MenuItem
                    active={activeMenuItem === "television-user"}
                    onClick={() => handleMenuItemClick("television-user")}
                  >
                    Television User
                  </MenuItem>
                </Link>
              </SubMenu>
              <SubMenu label="Wrapping">
                <Link to="/wrapping-auto">
                  <MenuItem
                    active={activeMenuItem === "wrapping-auto"}
                    onClick={() => handleMenuItemClick("wrapping-auto")}
                  >
                    Wrapping
                  </MenuItem>
                </Link>
              </SubMenu>

              <SubMenu label="Parameter Master">
                <Link to="/parameter-master">
                  <MenuItem
                    active={activeMenuItem === "parameter-master"}
                    onClick={() => handleMenuItemClick("parameter-master")}
                  >
                    Parameter Master
                  </MenuItem>
                </Link>
              </SubMenu>
            </Menu>
          )}

          {/**Modules (reports) for Sub Manager and Manager and Admin */}
          {(showSubManagerAccess || showManagerAccess || showAdminAccess) && (
            <Menu>
              <SubMenu label="Reports">
                <SubMenu label="Machine settings reports">
                  <Link to="/machine-settings-report">
                    <MenuItem
                      active={activeMenuItem === "machine-settings-report"}
                      onClick={() =>
                        handleMenuItemClick("machine-settings-report")
                      }
                    >
                      All machine settings
                    </MenuItem>
                  </Link>
                </SubMenu>

                {showTaskSheets && (
                  <SubMenu label="Task sheets reports">
                    <Link to="/task-sheets-report">
                      <MenuItem
                        active={activeMenuItem === "task-sheets-report"}
                        onClick={() =>
                          handleMenuItemClick("task-sheets-report")
                        }
                      >
                        Report issue
                      </MenuItem>
                    </Link>
                  </SubMenu>
                )}

                {showBlowroom && (
                  <SubMenu label="Blowroom reports">
                    {subModuleStates?.showLapFeed && (
                      <Link to="/lap-feed-report">
                        <MenuItem
                          active={activeMenuItem === "lap-feed-report"}
                          onClick={() => handleMenuItemClick("lap-feed-report")}
                        >
                          Lap feed waste
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showChuteFeedCotton && (
                      <Link to="/chute-feed-cotton-report">
                        <MenuItem
                          active={activeMenuItem === "chute-feed-cotton-report"}
                          onClick={() =>
                            handleMenuItemClick("chute-feed-cotton-report")
                          }
                        >
                          Chute feed waste Cotton{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showChuteFeedOthers && (
                      <Link to="/chute-feed-others-report">
                        <MenuItem
                          active={activeMenuItem === "chute-feed-others-report"}
                          onClick={() =>
                            handleMenuItemClick("chute-feed-others-report")
                          }
                        >
                          Chute feed waste Others{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showDropTest && (
                      <Link to="/drop-test-report">
                        <MenuItem
                          active={activeMenuItem === "drop-test-report"}
                          onClick={() =>
                            handleMenuItemClick("drop-test-report")
                          }
                        >
                          Drop test
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                )}

                {showCarding && (
                  <SubMenu label="Carding reports">
                    {subModuleStates?.showIndividualCardingWaste && (
                      <Link to="/individual-carding-waste-report">
                        <MenuItem
                          active={
                            activeMenuItem === "individual-carding-waste-report"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "individual-carding-waste-report"
                            )
                          }
                        >
                          Individual Carding Waste
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showAutoLevellerOnOff && (
                      <Link to="/auto-leveller-on-off-report">
                        <MenuItem
                          active={
                            activeMenuItem === "auto-leveller-on-off-report"
                          }
                          onClick={() =>
                            handleMenuItemClick("auto-leveller-on-off-report")
                          }
                        >
                          Auto leveller On/Off{" "}
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                )}

                {showAutoconer && (
                  <SubMenu label="Autoconer reports">
                    {subModuleStates?.showSpliceStrength && (
                      <Link to="/splice-strength-report">
                        <MenuItem
                          active={activeMenuItem === "splice-strength-report"}
                          onClick={() =>
                            handleMenuItemClick("splice-strength-report")
                          }
                        >
                          Splice Strength
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showPaperConeIdentification && (
                      <Link to="/paper-cone-identification-report">
                        <MenuItem
                          active={
                            activeMenuItem ===
                            "paper-cone-identification-report"
                          }
                          onClick={() =>
                            handleMenuItemClick(
                              "paper-cone-identification-report"
                            )
                          }
                        >
                          Paper Cone Identification{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showLNChecking && (
                      <Link to="/ln-checking-report">
                        <MenuItem
                          active={activeMenuItem === "ln-checking-report"}
                          onClick={() =>
                            handleMenuItemClick("ln-checking-report")
                          }
                        >
                          LN Checking{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showRewindingstudy && (
                      <Link to="/rewinding-study-report">
                        <MenuItem
                          active={activeMenuItem === "rewinding-study-report"}
                          onClick={() =>
                            handleMenuItemClick("rewinding-study-report")
                          }
                        >
                          Rewinding Study
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showTrail && (
                      <Link to="/trail-report">
                        <MenuItem
                          active={activeMenuItem === "trail-report"}
                          onClick={() => handleMenuItemClick("trail-report")}
                        >
                          Trail
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showU && (
                      <Link to="/u-percent-trail-report">
                        <MenuItem
                          active={activeMenuItem === "u-percent-trail-report"}
                          onClick={() =>
                            handleMenuItemClick("u-percent-trail-report")
                          }
                        >
                          U%
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                )}

                {showSpinning && (
                  <SubMenu label="Spinning reports">
                    {subModuleStates?.showCountChange && (
                      <Link to="/count-change-report">
                        <MenuItem
                          active={activeMenuItem === "count-change-report"}
                          onClick={() =>
                            handleMenuItemClick("count-change-report")
                          }
                        >
                          Count Change
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showCotChecking && (
                      <Link to="/cot-checking-report">
                        <MenuItem
                          active={activeMenuItem === "cot-checking-report"}
                          onClick={() =>
                            handleMenuItemClick("cot-checking-report")
                          }
                        >
                          Cot Checking{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showHariness && (
                      <Link to="/hariness-report">
                        <MenuItem
                          active={activeMenuItem === "hariness-report"}
                          onClick={() => handleMenuItemClick("hariness-report")}
                        >
                          Hariness{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showApronSpacerCondenser && (
                      <Link to="/apron-report">
                        <MenuItem
                          active={activeMenuItem === "apron-report"}
                          onClick={() => handleMenuItemClick("apron-report")}
                        >
                          Apron, Spacer & Condensor
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                )}

                {showLap && (
                  <SubMenu label="Lap reports">
                    {subModuleStates?.showSliverLap && (
                      <Link to="/sliver-lap-report">
                        <MenuItem
                          active={activeMenuItem === "sliver-lap-report"}
                          onClick={() =>
                            handleMenuItemClick("sliver-lap-report")
                          }
                        >
                          Sliver lap
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showRibbonLap && (
                      <Link to="/ribbon-lap-report">
                        <MenuItem
                          active={activeMenuItem === "ribbon-lap-report"}
                          onClick={() =>
                            handleMenuItemClick("ribbon-lap-report")
                          }
                        >
                          Ribbon Lap{" "}
                        </MenuItem>
                      </Link>
                    )}

                    {subModuleStates?.showStructureLap && (
                      <Link to="/structure-lap-report">
                        <MenuItem
                          active={activeMenuItem === "structure-lap-report"}
                          onClick={() =>
                            handleMenuItemClick("structure-lap-report")
                          }
                        >
                          Structure Lap{" "}
                        </MenuItem>
                      </Link>
                    )}
                  </SubMenu>
                )}

                {showFinisherDrawing && subModuleStates?.show112Yard && (
                  <SubMenu label="Finisher Drawing reports">
                    <Link to="/finisher-drawing-report">
                      <MenuItem
                        active={activeMenuItem === "finisher-drawing-report"}
                        onClick={() =>
                          handleMenuItemClick("finisher-drawing-report")
                        }
                      >
                        1 and 1/2 Yard
                      </MenuItem>
                    </Link>
                  </SubMenu>
                )}

                {showSimplexChecking &&
                  subModuleStates?.showSimplexChecking && (
                    <SubMenu label="Simplex reports">
                      <Link to="/simplex-checking-report">
                        <MenuItem
                          active={activeMenuItem === "simplex-checking-report"}
                          onClick={() =>
                            handleMenuItemClick("simplex-checking-report")
                          }
                        >
                          Simplex Checking
                        </MenuItem>
                      </Link>
                    </SubMenu>
                  )}

                {/* {showSasta && (
                <Link to="/manager-sasta-reports">
                  <MenuItem
                    active={activeMenuItem === "manager-sasta-reports"}
                    onClick={() => handleMenuItemClick("manager-sasta-reports")}
                  >
                    Sasta
                  </MenuItem>
                </Link>
              )} */}
              </SubMenu>
            </Menu>
          )}

          {/**Modules (notifications) for MD only */}
          {showMDAccess && (
            <Menu>
              <SubMenu label="Notifications">
                <Link to="/md-notifications">
                  <MenuItem
                    active={activeMenuItem === "md-notifications"}
                    onClick={() => handleMenuItemClick("md-notifications")}
                  >
                    All notifications
                  </MenuItem>
                </Link>
              </SubMenu>
            </Menu>
          )}
        </Sidebar>
      )}

      <Outlet />
    </Wrapper>
  );
}

export default SidebarComponent;
