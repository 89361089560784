import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const times = [{ short: 2000, long: 3500 }];
const toastType = ["success", "error", "warning", "info"];

export const handleToastMsg = (
  msg,
  type = toastType[0],
  duration = "short"
) => {
  if (["WENT_WRONG"].indexOf(msg) !== -1) {
    switch (msg) {
      case "WENT_WRONG":
        msg = "Something Went Wrong. Please Try Again Later.";
        break;
      default:
        msg = "Something Went Wrong. Please Try Again Later.";
    }
    type = "error";
  } else if (["NETWORK_CONNECTION"].indexOf(msg) !== -1) {
    switch (msg) {
      case "NETWORK_CONNECTION":
        msg = "Please Check Your Network Connection.";
        break;
      default:
        msg = "Please Check Your Network Connection.";
    }
    type = "warning";
  }
  if (msg) {
    if (type === toastType[0]) {
      handleToastSuccessMsg(msg, duration);
    } else if (type === toastType[1]) {
      handleToastErrorMsg(msg, duration);
    } else if (type === toastType[2]) {
      handleToastWarningMsg(msg, duration);
    } else if (type === toastType[3]) {
      handleToastInfoMsg(msg, duration);
    }
  }
};

export const handleToastSuccessMsg = (msg, duration) =>
  toast.success(msg, {
    autoClose: times[duration],
  });

export const handleToastErrorMsg = (msg, time) =>
  toast.error(msg, {
    autoClose: time,
  });

export const handleToastWarningMsg = (msg, duration) =>
  toast.warning(msg, {
    autoClose: times[duration],
  });
export const handleToastInfoMsg = (msg, duration) =>
  toast.info(msg, {
    autoClose: times[duration],
  });
