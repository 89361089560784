import apiClient from "../../../../../utils/clients/axios";

export const postNorms = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/blow_room/norms/`,
    payload
  );
  return response;
};

export const deleteNorms = async (id) => {
  const response = await apiClient.delete(
    `/api/quality_control/blow_room/norms/${id}`
  );
  return response;
};

export const getNorms = async () => {
  const response = await apiClient.get(`/api/quality_control/blow_room/norms/`);
  return response;
};
