import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { useMutation } from "react-query";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery, useQueryClient } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, CircularProgress } from "@mui/material";
import CustomButton from "../../../components/Button";
import {
  CHUTE_FEED_WASTE_OTHERS_CARDING_NUMBERS,
  HALL_NO,
} from "../../../config/masterdata";
import { handleToastMsg } from "../../../components/Toast";
import { chuteFeed714Create, getVariety } from "../mutation";
import "./chutefeedwasteotherscss.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const filter = createFilterOptions();

export default function ChuteFeedWasteOthers() {
  const queryClient = useQueryClient();
  const [value, setValue] = React.useState("");
  const [data, setData] = React.useState(
    CHUTE_FEED_WASTE_OTHERS_CARDING_NUMBERS
  );
  const [selectedCardingNumbers, setSelectedCardingNumbers] = React.useState(
    []
  );
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [hallNo, setHallNo] = React.useState("");
  //   const handleChange = (event) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setData(
  //       typeof value === "string"
  //         ? value.split(",").map((carding_num) => ({ carding_num }))
  //         : value.map((carding_num) => ({ carding_num }))
  //     );
  //     setSelectedCardingNumbers(value);
  //   };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newData = value?.map((carding_num) => {
      // Finding for exiting data
      const existingData = data?.find(
        (item) => item.carding_num === carding_num
      );

      return existingData
        ? {
            carding_num,
            ...existingData,
          }
        : { carding_num };
    });

    // set the new data
    setData(newData);
    setSelectedCardingNumbers(value);
  };

  const calculateTotalPercentage = () => {
    // Convert fsWaste to a number
    const fsWasteValue = parseFloat(fsWaste);

    // Check if fsWasteValue is a valid number
    if (isNaN(fsWasteValue)) {
      return ""; // Handle the case where fsWaste is not a valid number
    }

    // Calculate the sum of all production column values
    const sumOfProduction = data.reduce(
      (sum, row) => sum + parseFloat(row.production || 0),
      0
    );

    // Calculate the total percentage
    const totalPercentage =
      (fsWasteValue / (fsWasteValue + sumOfProduction)) * 100;
    return isNaN(totalPercentage) ? "" : parseFloat(totalPercentage.toFixed(2));
  };

  const handleFieldChange = (event, index, fieldName) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      const updatedData = [...data];
      updatedData[index][fieldName] = changedValue;
      setData(updatedData);
    }
  };

  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event) => {
    const { value } = event.target;
    setFeedback(value.toUpperCase());
  };

  const [fsWaste, setFsWaste] = useState("");
  // Handle fs_waste value change
  const handleFsWasteChange = (event) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseFloat(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setFsWaste(changedValue);
    }
  };

  const [cardingLineNum, setCardingLineNum] = useState("");
  const handlecardingLineNumChange = (event) => {
    const { value } = event.target;
    const changedValue = value === "" ? "" : parseInt(value);

    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    } else {
      setCardingLineNum(changedValue);
    }
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const resetForm = () => {
    setValue(null);
    setData(CHUTE_FEED_WASTE_OTHERS_CARDING_NUMBERS);
    setSelectedCardingNumbers([]);
    setCardingLineNum("");
    setFeedback("");
    setHallNo("");
    setFsWaste("")
  };

  const handleReview = (e) => {
    const payload = {
      variety: value, // Use the selected variety or an empty string if not selected
      fsWaste: fsWaste, // Set your desired value for fs_waste here
      cardingLineNum: cardingLineNum, // Set your desired value for cardingLineNum here
      feedback: feedback, // Initialize feedback as an empty string
      totalWastePercentage: calculateTotalPercentage(),
      hall: hallNo,
    };

    // Loop through the data for selected carding numbers
    selectedCardingNumbers.forEach((cardingNum, index) => {
      // Set production, cylinder_speed, liker_in_speed, flats_speed, and delivery_speed for each carding number
      payload[`production${data[index].carding_num}`] =
        data[index].production || 0;
      payload[`cylinderSpeed${data[index].carding_num}`] =
        data[index].cylinder_speed || 0;
      payload[`likerInSpeed${data[index].carding_num}`] =
        data[index].liker_in_speed || 0;
      payload[`flatsSpeed${data[index].carding_num}`] =
        data[index].flats_speed || 0;
      payload[`deliverySpeed${data[index].carding_num}`] =
        data[index].delivery_speed || 0;
      payload[`wingSetting${data[index].carding_num}`] =
        data[index].wing_setting || 0;
    });
    setModalData(payload);
    setOpenModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      variety: value, // Use the selected variety or an empty string if not selected
      fsWaste: fsWaste, // Set your desired value for fs_waste here
      cardingLineNum: cardingLineNum, // Set your desired value for cardingLineNum here
      feedback: feedback, // Initialize feedback as an empty string
      totalWastePercentage: calculateTotalPercentage(),
      cardingNo: selectedCardingNumbers?.join(", "),
      hall: hallNo,
    };

    // Loop through the data for selected carding numbers
    selectedCardingNumbers.forEach((cardingNum, index) => {
      // Set production, cylinder_speed, liker_in_speed, flats_speed, and delivery_speed for each carding number
      payload[`production${data[index].carding_num}`] =
        data[index].production || 0;
      payload[`cylinderSpeed${data[index].carding_num}`] =
        data[index].cylinder_speed || 0;
      payload[`likerInSpeed${data[index].carding_num}`] =
        data[index].liker_in_speed || 0;
      payload[`flatsSpeed${data[index].carding_num}`] =
        data[index].flats_speed || 0;
      payload[`deliverySpeed${data[index].carding_num}`] =
        data[index].delivery_speed || 0;
      payload[`wingSetting${data[index].carding_num}`] =
        data[index].wing_setting || 0;
    });

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data updated successfully", "success");
        queryClient.invalidateQueries("get-variety");
        queryClient.invalidateQueries("manager-blowroom-notifications");
        setOpenModal(false);
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occured", "error");
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { mutate, isLoading: mutateLoading } = useMutation(chuteFeed714Create);

  return (
    <form>
      <Grid m={2} container spacing={3} item md={12} sm={11.5}>
        <Grid item xs={12}>
          <h2 className="heading">Chute Feed Waste(Others)</h2>
        </Grid>
        <Grid item>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue(newValue || "");
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue(newValue || "");
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue.toUpperCase());
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
            getOptionLabel={(option) =>
              typeof option === "string" ? option : ""
            } // Ensure option is a string
            renderOption={(props, option) => <li {...props}>{option}</li>}
            sx={{ width: 250 }}
            freeSolo
            renderInput={(params) => (
              <TextField required {...params} label="Variety" />
            )}
          /> 
        </Grid>
        <Grid item>
          <TextField
            value={hallNo}
            select
            onChange={(e) => setHallNo(parseInt(e.target.value))}
            type="number"
            sx={{ width: 250 }}
            id="outlined-basic"
            label="Hall Number"
            variant="outlined"
            // onInput={handleCardingLineNumInput}
          >
            {HALL_NO?.map((hall) => (
              <MenuItem key={hall} value={hall}>
                {hall}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Carding number
            </InputLabel>
            <Select
              required
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedCardingNumbers}
              onChange={handleChange}
              input={<OutlinedInput label="Carding number" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {CHUTE_FEED_WASTE_OTHERS_CARDING_NUMBERS.map((item) => (
                <MenuItem key={item.carding_num} value={item.carding_num}>
                  <Checkbox
                    checked={
                      selectedCardingNumbers.indexOf(item.carding_num) > -1
                    }
                  />
                  <ListItemText primary={item.carding_num} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            onChange={handlecardingLineNumChange}
            value={cardingLineNum || ""}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            type="number"
            sx={{ width: 250 }}
            id="outlined-basic"
            label="Carding line number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {selectedCardingNumbers.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">FS waste</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Add your table rows here */}
                      {/* Example row with dynamic columns */}
                      <TableRow>
                        <TableCell align="center">
                          {/* Example input for each column */}
                          <TextField
                            size="small"
                            id="standard-number"
                            type="number"
                            step="0.01"
                            variant="standard"
                            style={{ width: "50px" }}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ""
                            //   );
                            // }}
                            name="fs_waste"
                            value={fsWaste} // Use the state variable for fs_waste
                            onChange={handleFsWasteChange}
                          />
                        </TableCell>
                      </TableRow>
                      {/* Add more rows as needed */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid sx={{ mt: 5 }} item md={12}>
                  <TextField
                    size="small"
                    id="standard-number"
                    type="number"
                    step="0.01"
                    variant="outlined"
                    style={{ width: "110px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={true}
                    value={calculateTotalPercentage()} // Use the calculated total percentage value
                    name="Total Waste %"
                    helperText="Total Waste %"
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid style={{ overflowX: "auto" }} item xs={9}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Carding number</TableCell>
                          {data.length > 0 &&
                            data.map((row, index) => (
                              <TableCell key={index}>
                                {row.carding_num}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Production</TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="production"
                                value={row.production}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "production")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>

                        <TableRow>
                          <TableCell>Liker in speed </TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="liker_in_speed"
                                value={row.liker_in_speed}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "liker_in_speed")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Cylinder speed</TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="cylinder_speed"
                                value={row.cylinder_speed}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "cylinder_speed")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Flats speed</TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="flats_speed"
                                value={row.flats_speed}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "flats_speed")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Delivery speed</TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="delivery_speed"
                                value={row.delivery_speed}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "delivery_speed")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Wing setting</TableCell>
                          {data.map((row, index) => (
                            <TableCell key={index}>
                              {/* Example input for each column */}
                              <TextField
                                size="small"
                                id="standard-number"
                                type="number"
                                step="0.01"
                                variant="standard"
                                style={{ width: "50px" }}
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(
                                //     /[^0-9]/g,
                                //     ""
                                //   );
                                // }}
                                name="wing_setting"
                                value={row.wing_setting}
                                onChange={(e) =>
                                  handleFieldChange(e, index, "wing_setting")
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>

                        {/* Add more rows as needed */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={9.5} sm={9.5}>
          {selectedCardingNumbers.length > 0 && (
            <TextField
              fullWidth
              onChange={handleFeedbackChange}
              value={feedback}
              size="lg"
              name="Size"
              label="Feedback"
            />
          )}
        </Grid>
        <Grid item xs={9.5} sm={9.5}>
          {selectedCardingNumbers.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleReview}
                className="submitbutton"
              >
                Final Review
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container>
            <Grid mr={2}>
              <TextField
                value={value}
                disabled={true}
                helperText="Variety"
              ></TextField>
            </Grid>
            <Grid mr={2}>
              <TextField
                value={hallNo}
                disabled={true}
                helperText="Hall Number"
              ></TextField>
            </Grid>
            <Grid mr={2}>
              <TextField
                value={cardingLineNum}
                disabled={true}
                helperText="Carding line number"
              ></TextField>
            </Grid>
            <Grid mr={2}>
              <TextField
                value={fsWaste}
                disabled={true}
                helperText="FS waste"
              ></TextField>
            </Grid>
            <Grid mr={2}>
              <TextField
                value={calculateTotalPercentage()}
                disabled={true}
                helperText="Total waste percent"
              ></TextField>
            </Grid>
          </Grid>
          <TableContainer style={{ maxHeight: "500px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Carding number</TableCell>
                  <TableCell>Production</TableCell>
                  <TableCell>Liker in speed</TableCell>
                  <TableCell>Cylinder speed</TableCell>
                  <TableCell>Flats speed</TableCell>
                  <TableCell>Delivery speed</TableCell>
                  <TableCell>Wing setting </TableCell>
                  {/* Add more table headers for other payload fields */}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(modalData).map((key) => {
                  // Check if the key represents a material index
                  const match = key.match(/^production(\d+)$/);
                  if (match) {
                    const index = parseInt(match[1]) - 1; // Material indices are 1-based
                    const production = modalData[`production${index + 1}`];
                    const cylinderSpeed =
                      modalData[`cylinderSpeed${index + 1}`];
                    const liker = modalData[`likerInSpeed${index + 1}`];
                    const flatsSpeed = modalData[`flatsSpeed${index + 1}`];
                    const deliverySpeed =
                      modalData[`deliverySpeed${index + 1}`];
                    const wing = modalData[`wingSetting${index + 1}`];
                    const cardingNum = parseInt(key.match(/\d+/)[0]);

                    return (
                      <TableRow key={key}>
                        <TableCell>{cardingNum}</TableCell>
                        <TableCell>{production}</TableCell>
                        <TableCell>{liker}</TableCell>
                        <TableCell>{cylinderSpeed}</TableCell>
                        <TableCell>{flatsSpeed}</TableCell>
                        <TableCell>{deliverySpeed}</TableCell>
                        <TableCell>{wing}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          <CustomButton
            onClick={handleSubmit}
            style={{ width: "100px", height: "40px" }}
            disabled={mutateLoading}
            type="submit"
          >
            {mutateLoading ? <CircularProgress size={24} /> : "Submit"}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
