import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { handleToastMsg } from "../../../components/Toast";

import {
  register,
  deleteUser,
  getAllManagers,
  getUsers,
  update,
} from "../../Blowroom/mutation/auth/auth";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  Checkbox,
  ListItemText,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CustomButton from "../../../components/Button";
import {
  ModulesForAddUser,
  SubModulesForAddUser,
} from "../../../config/masterdata";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Registration() {
  const [modelToggle, setModelToggle] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    userType: "",
    phoneNumber: "",
    employeeCode: "",
    superior: [],
    module: [],
    subModule: [],
  });

  const [superiors, setSuperiors] = useState([]);
  const [modules] = useState(ModulesForAddUser);
  const [filteredSubModules, setFilteredSubModules] = useState([]);

  const { data: managers } = useQuery("managers", getAllManagers);
  const userTypes = [
    { label: "Employee", value: "EMPLOYEE" },
    { label: "Sub manager", value: "SUB_MANAGER" },
    { label: "Manager", value: "MANAGER" },
    { label: "Admin", value: "ADMIN" },
    { label: "MD", value: "MANAGING_DIRECTOR" },
  ];

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickOpen = (id) => {
    setSelectedUserId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedUserId(null);
  };

  const handleConfirmDelete = () => {
    deleteUserMutation(selectedUserId);
    handleCloseDeleteDialog();
  };

  const [editUserId, setEditUserId] = useState(null);
  const [editUser, setEditUser] = useState({});
  const handleEdit = (user) => {
    setEditMode(true);
    setModelToggle(true);
    setEditUserId(user?.id);
    setEditUser(user);

    const getSuperiorIds = () => {
      const { adminId, managingDirectorId, subManagerId, managerId } =
        user?.superior;
      const combinedIds = [
        ...(adminId ? adminId : []),
        ...(managingDirectorId ? managingDirectorId : []),
        ...(subManagerId ? subManagerId : []),
        ...(managerId ? managerId : []),
      ];
      return combinedIds;
    };

    setFormData((prev) => ({
      ...prev,
      name: user?.name,
      email: user?.email,
      // password: user?.password,
      employeeCode: user?.employeeCode,
      phoneNumber: user?.phoneNumber,
      userType: user?.role,
      module: user?.modules || [],
      subModule: user?.subModules || [],
      superior: user?.superior ? getSuperiorIds() : [],
    }));
  };

  const handleAddUser = (user) => {
    setEditMode(false);
    setModelToggle(true);
    setEditUser({});
  };

  // this is for to show the submodules based on the modules select
  useEffect(() => {
    // setFormData((prev) => ({
    //   ...prev,
    //   subModule: [],
    //   superior: [],
    // }));
    // Update filtered sub-modules based on selected modules
    const selectedModules = formData?.module?.map((item) =>
      item?.toLowerCase().replace(/[_ ]/g, "")
    );
    const filtered =
      SubModulesForAddUser?.filter((subModule) =>
        selectedModules?.includes(
          subModule?.label?.toLowerCase().replace(/[_ ]/g, "")
        )
      ) || [];

    const allFilteredSubModules =
      filtered?.flatMap((item) => item?.modules?.map((item) => item)) || [];

    //this is for edit user update when select module and sub module
    if (editMode) {
      const existUserSubmodule = editUser?.subModules?.map((item) => item);
      const updateSubmodule =
        allFilteredSubModules.filter(
          (item) =>
            existUserSubmodule?.includes(item?.value) ||
            formData?.subModule?.includes(item?.value)
        ) || [];

      setFormData((prev) => ({
        ...prev,
        subModule: updateSubmodule?.map((item) => item.value) || [],
      }));
    }

    //this is for add (non edit) user update when select module and sub module
    const updateSubmodule =
      allFilteredSubModules.filter((item) =>
        formData?.subModule?.includes(item?.value)
      ) || [];

    setFormData((prev) => ({
      ...prev,
      subModule: updateSubmodule?.map((item) => item.value) || [],
    }));

    setFilteredSubModules(allFilteredSubModules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, formData?.module]);

  const handleManagerChange = (e) => {
    let { name, value } = e.target;

    //this is for getting manager id for payload
    const newManagerId = value;
    setSelectedManagerId(newManagerId);

    //this is for set the superior in formdata
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (!editMode) {
      setFormData((prev) => ({
        ...prev,
        module: [],
        subModule: [],
        superior: [],
      }));
    }
  }, [editMode, formData.userType]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;

    // Validate email
    if (name === "email") {
      if (!emailRegex.test(value)) {
        setErrors({ ...errors, email: "Invalid email address" });
      } else {
        setErrors({ ...errors, email: "" });
      }
    }

    value =
      name === "phoneNumber" || name === "employeeCode"
        ? parseInt(value)
        : value;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (formData.userType === "ADMIN") {
      setSuperiors(managers?.filter((manager) => manager.role === "ADMIN"));
    } else if (formData.userType === "EMPLOYEE") {
      setSuperiors(
        managers?.filter(
          (manager) =>
            manager.role === "MANAGER" ||
            manager.role === "SUB_MANAGER" ||
            manager.role === "MANAGING_DIRECTOR"
        )
      );
    } else if (formData.userType === "SUB_MANAGER") {
      setSuperiors(
        managers?.filter(
          (manager) =>
            manager.role === "MANAGER" || manager.role === "MANAGING_DIRECTOR"
        )
      );
    } else if (formData.userType === "MANAGER") {
      setSuperiors(
        managers?.filter(
          (manager) =>
            manager.role === "MANAGER" || manager.role === "MANAGING_DIRECTOR"
        )
      );
    } else {
      setSuperiors([]);
    }
  }, [formData.userType, managers, selectedManagerId]);

  //this is for reset the form when add user success and when overlay close
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      password: "",
      userType: "",
      phoneNumber: "",
      employeeCode: "",
      superior: [],
      module: [],
      subModule: [],
    });
  };

  const handleCloseDialogAndResetForm = () => {
    resetForm();
    setEditUserId(null);
    setModelToggle(false);
    setEditMode(false);
    setEditUser({});
  };

  const { data: users, refetch } = useQuery("users", getUsers);

  //this is for filter the other users only
  const filteredUsers =
    users?.filter((item) => item.role !== "TELEVISION") || [];

  const { mutate: registerUser, isLoading: isRegisterLoading } = useMutation(
    register,
    {
      onSuccess: () => {
        handleToastMsg("User added successfully", "success");
        //this is for reset the form when add user success and when overlay close
        handleCloseDialogAndResetForm();
        refetch();
      },
      onError: () => {
        handleToastMsg("An error occurred during adding a user!", "error");
      },
    }
  );

  const { mutate: updateUser, isLoading: isUpdateLoading } = useMutation(
    update,
    {
      onSuccess: () => {
        handleToastMsg("User updated successfully", "success");
        //this is for reset the form when add user success and when overlay close
        handleCloseDialogAndResetForm();
        refetch();
      },
      onError: () => {
        handleToastMsg("An error occurred during adding a user!", "error");
      },
    }
  );

  const { mutate: deleteUserMutation } = useMutation(deleteUser, {
    onSuccess: () => {
      handleToastMsg("User deleted successfully", "success");
      refetch();
    },
    onError: () => {
      handleToastMsg("An error occurred during deleting the user!", "error");
    },
  });

  const createSuperiorsObject = (superiorsArray, superiorIds) => {
    const superiorsObject = {
      managerId: [],
      subManagerId: [],
      managingDirectorId: [],
      adminId: [],
    };

    superiorIds?.forEach((id) => {
      const superior = superiorsArray?.find((s) => s.id === id);
      if (superior) {
        if (superior.role === "MANAGER") {
          superiorsObject.managerId.push(id);
        } else if (superior.role === "SUB_MANAGER") {
          superiorsObject.subManagerId.push(id);
        } else if (superior.role === "MANAGING_DIRECTOR") {
          superiorsObject.managingDirectorId.push(id);
        } else if (superior.role === "ADMIN") {
          superiorsObject.adminId.push(id);
        }
      }
    });

    // // Remove empty arrays from the result
    // Object.keys(superiorsObject).forEach((key) => {
    //   if (superiorsObject[key].length === 0) {
    //     // delete superiorsObject[key];
    //     superiorsObject[key] = null;
    //   }
    // });

    return superiorsObject;
  };

  const superiorsPayload = createSuperiorsObject(superiors, formData.superior);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      role: formData.userType,
      employeeCode: formData.employeeCode,
      modules: formData.module,
      subModules: formData.subModule,
      ...(formData.userType !== "MANAGING_DIRECTOR" && {
        superiors: superiorsPayload,
      }),
      ...(editMode
        ? formData.password && { password: formData.password }
        : { password: formData.password }),
    };

    //this is for edit user only
    const editPayload = { payload, editUserId };

    if (editMode) {
      updateUser(editPayload);
    } else {
      registerUser(payload);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ padding: "20px" }}
        item
        md={12}
        sm={10.5}
        height={"100%"}
      >
        <Grid item container justifyContent={"center"}>
          <h2>User Management</h2>
        </Grid>
        <Grid item container>
          <Grid item xs={6}>
            <h3>Users</h3>
          </Grid>
          <Grid item container xs={6} justifyContent={"flex-end"}>
            <CustomButton onClick={() => handleAddUser()}>
              Add Users
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item container>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>User Type</TableCell>
                  <TableCell>Modules</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredUsers) &&
                  filteredUsers.length > 0 &&
                  filteredUsers?.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell style={{ minWidth: 200 }}>
                        {user.modules.join(", ")}
                      </TableCell>
                      <TableCell>
                        <Grid style={{ display: "flex", gap: "8px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              background: "#1976d2",
                              "&:hover": { background: "#1565c0" },
                            }}
                            onClick={() => handleEdit(user)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              background: "#f44336",
                              "&:hover": { background: "#d32f2f" },
                            }}
                            onClick={() => handleClickOpen(user.id)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        open={modelToggle}
        scroll={"body"}
        onClose={handleCloseDialogAndResetForm}
        PaperProps={{ sx: { maxWidth: "80%", maxHeight: "95%" } }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogAndResetForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Grid container justifyContent={"center"} p={4}>
            <Grid container item rowGap={2} xs={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Employee code"
                  name="employeeCode"
                  type="number"
                  value={formData.employeeCode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  type="number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="User Type"
                  name="userType"
                  value={formData.userType}
                  onChange={handleChange}
                >
                  {userTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Modules"
                  name="module"
                  value={formData.module || []}
                  onChange={(e) =>
                    setFormData({ ...formData, module: e.target.value })
                  }
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => {
                      // Create a map from value to label for easy lookup
                      const valueToLabelMap = {};
                      modules?.forEach((option) => {
                        valueToLabelMap[option.value] = option.label;
                      });

                      return selected
                        .map((value) => valueToLabelMap[value])
                        .join(", ");
                    },
                  }}
                >
                  {modules?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={formData?.module?.includes(option.value)}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Sub Modules"
                  name="subModule"
                  value={formData.subModule || []}
                  onChange={(e) =>
                    setFormData({ ...formData, subModule: e.target.value })
                  }
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => {
                      // Create a map from value to label for easy lookup
                      const valueToLabelMap = {};
                      filteredSubModules?.forEach((option) => {
                        valueToLabelMap[option.value] = option.label;
                      });

                      return selected
                        .map((value) => valueToLabelMap[value])
                        .join(", ");
                    },
                  }}
                >
                  {filteredSubModules?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={formData?.subModule?.includes(option.value)}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Superior"
                  name="superior"
                  value={formData.superior || []}
                  onChange={handleManagerChange}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => {
                      // Create a map from ID to name and role for easy lookup
                      const idToNameRoleMap = {};
                      superiors?.forEach((superior) => {
                        idToNameRoleMap[
                          superior.id
                        ] = `${superior.name} - ${superior.role}`;
                      });

                      return selected
                        .map((id) => idToNameRoleMap[id])
                        .join(", ");
                    },
                  }}
                >
                  {Array.isArray(superiors) &&
                    superiors?.length > 0 &&
                    superiors.map((superior, index) => (
                      <MenuItem key={index} value={superior.id}>
                        <Checkbox
                          checked={formData?.superior?.includes(superior.id)}
                        />
                        <ListItemText
                          primary={`${superior.name} - ${superior.role}`}
                        />
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isRegisterLoading || isUpdateLoading}
                >
                  {editMode ? (
                    isUpdateLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Update"
                    )
                  ) : isRegisterLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Register"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} style={{ color: "green" }}>
            No
          </Button>
          <Button
            onClick={handleConfirmDelete}
            style={{ color: "red" }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Registration;
