import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({
  allowedRoles,
  requiredModules = [],
  requiredSubModules = [],
}) => {
  const roles = localStorage.getItem("roles") || []; // Change this to match your storage method
  const userModules = JSON.parse(localStorage.getItem("userModules")) || [];
  const userSubModules =
    JSON.parse(localStorage.getItem("userSubModules")) || [];
  const location = useLocation();

  //this is for checking to navigate based on role
  const hasRequiredRole = allowedRoles ? allowedRoles?.includes(roles) : false;

  //this is for checking to navigate based on module
  const hasRequiredModule =
    requiredModules?.length === 0 ||
    requiredModules?.some((module) => userModules?.includes(module));

  //this is for checking to navigate based on sub module
  const hasRequiredSubModule =
    requiredSubModules?.length === 0 ||
    requiredSubModules?.some((subModule) =>
      userSubModules?.includes(subModule)
    );

  return hasRequiredRole && hasRequiredModule && hasRequiredSubModule ? (
    <Outlet />
  ) : roles ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
