import apiClient from "../../../utils/clients/axios";

export const individualCardingMutate = async (payload) => {
  const response = await apiClient.post(
    "/api/quality_control/carding/individual_waste_create/",
    payload
  );

  return response;
};
