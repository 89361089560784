import apiClient from "../../../../utils/clients/axios";
import axiosWithoutToken from "../../../../utils/clients/axiosWithoutToken";

export const login = async (payload) => {
  try {
    const repsponse = await axiosWithoutToken.post(
      `/api/quality_control/login_user/`,
      payload
    );

    return repsponse;
  } catch (error) {
    throw error;
  }
};

export const register = async (payload) => {
  const repsponse = await apiClient.post(
    "/api/quality_control/register_user/",
    payload
  );

  return repsponse;
};

export const update = async (params) => {
  const { payload, editUserId } = params;
  const response = await apiClient.put(
    `/api/quality_control/edit_user/${editUserId}`,
    payload
  );

  return response;
};

export const getUserTypes = async (date) => {
  const { data } = await apiClient.get(
    `/api/quality_control/blow_room/usertypes/`
  );

  return data;
};

export const getUsers = async (date) => {
  const { data } = await apiClient.get(`/api/quality_control/users/`);
  return data;
};

export const deleteUser = async (userId) => {
  const { data } = await apiClient.delete(`/api/quality_control/${userId}`);
  return data;
};

export const getAllManagers = async () => {
  const { data } = await apiClient.get(`/api/quality_control/managers/`);
  return data;
};
