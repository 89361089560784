import { Grid, Box, TextField, Button, Tabs, Tab } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { handleToastMsg } from "../../components/Toast";
import {
  createMasterRecord,
  createSavedMasterRecord,
  updateSavedRecord,
  updateSubmittedRecord,
  getAllMasterDataFields,
} from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllSubmittedRecords } from "../ParameterMasters/mutation";
import { getAllSavedRecords } from "../SavedParameterMaster/mutation";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { ContainerWrapper } from "../../components/styles";
import { CircularProgress } from "@mui/material";
import GeneralMasterForm from "../../components/MasterSettingForms/GeneralMasterForm";
import BlowroomMasterForm from "../../components/MasterSettingForms/BlowroomMasterForm";
import CardingMasterForm from "../../components/MasterSettingForms/CardingMasterForm";
import DrawingMasterForm from "../../components/MasterSettingForms/DrawingMasterForm";
import SimplexMasterForm from "../../components/MasterSettingForms/SimplexMasterForm";
import SpinningMasterForm from "../../components/MasterSettingForms/SpinningMasterForm";
import AutoConerMasterForm from "../../components/MasterSettingForms/AutoConerMasterForm";
import OthersMasterForm from "../../components/MasterSettingForms/OthersMasterForm";

function AddParameterMaster() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { editData } = location.state || {};

  const { data: submittedMasterData } = useQuery(
    "all-parameters",
    getAllSubmittedRecords,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  const { data: savedRecords } = useQuery(
    "all-saved-parameters",
    getAllSavedRecords,
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  const submittedPartyCodes = submittedMasterData?.data?.map(
    (row) => row.partyCode
  );
  const savedPartyCodes = savedRecords?.data?.map((row) => row.partyCode);

  const [partyName, setPartyName] = useState("");
  const [variety, setVariety] = useState("");
  const [partyCode, setPartyCode] = useState("");

  const {
    data: masterDataFields,
    isLoading: isMasterDataFieldsLoading,
    isError: isMasterDataFieldsError,
  } = useQuery("all-masterDataFields", getAllMasterDataFields, {
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  // States for different categories
  const [general, setGeneral] = useState([]);
  const [blowroom, setBlowroom] = useState([]);
  const [carding, setCarding] = useState([]);
  const [drawing, setDrawing] = useState([]);
  const [simplex, setSimplex] = useState([]);
  const [spinning, setSpinning] = useState([]);
  const [autoconer, setAutoconer] = useState([]);
  const [others, setOthers] = useState([]);

  const transformData = (data) => {
    return data.map((item) => {
      const { name, inputType, ...rest } = item;

      // Create an object with the name as a key and an empty string as value
      if (name) {
        return {
          [name]: "",
          inputType: inputType,
          ...rest, // Keep any other properties if they exist
        };
      }

      return item; // Return as-is if no name
    });
  };

  console.log(transformData, "transformData");

  // Handle API Response and Set State
  const handleApiResponse = (data) => {
    if (data) {
      setGeneral(transformData(data.general));
      setBlowroom(transformData(data.blowroom));
      setCarding(transformData(data.carding));
      setDrawing(transformData(data.drawing));
      setSimplex(transformData(data.simplex));
      setSpinning(transformData(data.spinning));
      setAutoconer(transformData(data.autoconer));
      setOthers(transformData(data.others));
    }
  };

  // Call handleApiResponse after data is fetched
  useEffect(() => {
    if (
      masterDataFields &&
      !isMasterDataFieldsLoading &&
      !isMasterDataFieldsError &&
      !editData
    ) {
      handleApiResponse(masterDataFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterDataFields, isMasterDataFieldsLoading, isMasterDataFieldsError]);

  const initializeWithData = (data) => {
    setPartyName(data.partyName || "");
    setVariety(data.variety || "");
    setPartyCode(data.partyCode || "");

    setGeneral(data.general);
    // Set blowroom
    setBlowroom(data.blowroom);

    // Set carding
    setCarding(data.carding);

    // Set drawing
    setDrawing(data.drawing);

    // Set simplex
    setSimplex(data.simplex);

    // Set spinning
    setSpinning(data.spinning);

    // Set autoconer
    setAutoconer(data.autoconer);

    // Set others
    setOthers(data.others);
  };

  useEffect(() => {
    if (editData) {
      initializeWithData(editData);
    }
  }, [editData]);

  const submitMutation = useMutation(createMasterRecord, {
    onSuccess: () => {
      queryClient.invalidateQueries("all-parameters");
      handleToastMsg("Submitted", "success");
      navigate("/parameter-master");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const saveMutation = useMutation(createSavedMasterRecord, {
    onSuccess: () => {
      handleToastMsg("Saved", "success");
      queryClient.invalidateQueries("all-saved-parameters");
      navigate("/parameter-master");
    },
    onError: (error) => {
      handleToastMsg(`Error: ${error.message}`, "error");
    },
  });

  const updateSavedMutation = useMutation(
    (payload) => updateSavedRecord(editData.id, payload),
    {
      onSuccess: () => {
        handleToastMsg("Saved Successfully!", "success");
        queryClient.invalidateQueries("all-saved-parameters");
        navigate("/parameter-master", { replace: true }); // Navigate back to the desired page
        window.location.reload();
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const updateSubmittedMutation = useMutation(
    (payload) => updateSubmittedRecord(editData.id, payload),
    {
      onSuccess: () => {
        handleToastMsg("Updated Successfully!", "success");
        queryClient.invalidateQueries("all-parameters");
        navigate("/parameter-master", { replace: true }); // Navigate back to the desired page
        window.location.reload();
      },
      onError: (error) => {
        handleToastMsg(`Error: ${error.message}`, "error");
      },
    }
  );

  const validateMandatoryParameters = () => {
    if (partyName === "" || partyCode === "" || variety === "") {
      return false;
    }

    return true; // Return true if all checks pass
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (submittedPartyCodes?.includes(partyCode.trim())) {
      handleToastMsg("Error: Party Code already submitted!", "error");
      return;
    }

    if (validateMandatoryParameters()) {
      const payload = {
        partyName,
        partyCode,
        variety,
        general,
        blowroom,
        carding,
        drawing,
        simplex,
        spinning,
        autoconer,
        others,
      };

      const cleanedPayload = cleanUpPayload(payload);
      if (editData) {
        updateSavedMutation.mutate(cleanedPayload);
        return;
      } else if (savedPartyCodes.includes(partyCode)) {
        updateSavedMutation.mutate(cleanedPayload);
      } else {
        saveMutation.mutate(cleanedPayload);
      }
    } else {
      handleToastMsg(
        "Please provide party name, variety and party code",
        "error"
      );
    }
  };

  function isFieldFilled(value) {
    // Check for empty values like null, empty string, or empty array
    if (
      value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return false;
    }

    // If it's an object, we need to check its nested fields
    if (typeof value === "object" && !Array.isArray(value)) {
      return validatePayload(value); // Recursively validate object fields
    }

    return true; // All other cases are considered filled
  }

  function validatePayload(payload) {
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (key === "options") continue; // Skip "options" key

        const value = payload[key];

        if (Array.isArray(value)) {
          // For arrays, validate each object inside the array
          for (const item of value) {
            if (!isFieldFilled(item)) return false;
          }
        } else {
          if (!isFieldFilled(value)) return false;
        }
      }
    }
    return true;
  }

  function cleanUpPayload(payload) {
    // Function to clean extra spaces from text fields
    const cleanTextValue = (value) => {
      if (typeof value === "string") {
        // Trim leading/trailing spaces and replace multiple spaces between words with a single space
        return value.trim().replace(/\s+/g, " ");
      }
      return value; // If it's not a string, return the value as is
    };

    // Clean up static keys
    const staticKeys = ["partyName", "partyCode", "variety"];
    staticKeys.forEach((key) => {
      if (payload[key]) {
        payload[key] = cleanTextValue(payload[key]);
      }
    });

    // List of categories in the payload
    const categories = [
      "general",
      "blowroom",
      "carding",
      "drawing",
      "simplex",
      "spinning",
      "autoconer",
      "others",
    ];

    // Iterate over each category in the payload
    categories.forEach((category) => {
      if (Array.isArray(payload[category])) {
        payload[category] = payload[category].map((field) => {
          // Check if the field has an inputType of 'All Characters' or 'Text'
          if (
            field.inputType === "All Characters" ||
            field.inputType === "Text"
          ) {
            const fieldName = Object.keys(field).find(
              (key) =>
                key !== "inputType" &&
                key !== "category" &&
                key !== "outOfNorms" &&
                key !== "options"
            );

            if (fieldName && typeof field[fieldName] === "string") {
              // Clean up the string value to remove extra spaces
              field[fieldName] = cleanTextValue(field[fieldName]);
            }
          }
          return field; // Return the modified field
        });
      }
    });

    return payload; // Return the modified payload
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (editData) {
      const payload = {
        partyName,
        partyCode,
        variety,
        general: general,
        blowroom: blowroom,
        carding: carding,
        drawing: drawing,
        simplex: simplex,
        spinning: spinning,
        autoconer: autoconer,
        others: others,
      };
      const cleanedPayload = cleanUpPayload(payload);

      if (validatePayload(payload)) {
        updateSubmittedMutation.mutate(cleanedPayload);
        // console.log(cleanedPayload);
      } else {
        handleToastMsg("Please fill all fields before submitting", "error");
      }
      // console.log(payload);
    } else if (submittedPartyCodes.includes(partyCode.trim())) {
      handleToastMsg("Error: Party Code already exists!", "error");
      return;
    } else {
      const payload = {
        partyName,
        partyCode,
        variety,
        general: general,
        blowroom: blowroom,
        carding: carding,
        drawing: drawing,
        simplex: simplex,
        spinning: spinning,
        autoconer: autoconer,
        others: others,
      };
      const cleanedPayload = cleanUpPayload(payload);

      if (savedPartyCodes.includes(partyCode.trim())) {
        handleToastMsg(
          "This Party Code already there in Saved Data, Please check saved data",
          "error"
        );
      } else if (validatePayload(payload)) {
        submitMutation.mutate(cleanedPayload);
      } else {
        handleToastMsg("Please fill all fields before submitting", "error");
      }
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <GeneralMasterForm
            general={general}
            setGeneral={setGeneral}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 1:
        return (
          <BlowroomMasterForm
            blowroom={blowroom}
            setBlowroom={setBlowroom}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 2:
        return (
          <CardingMasterForm
            carding={carding}
            setCarding={setCarding}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 3:
        return (
          <DrawingMasterForm
            drawing={drawing}
            setDrawing={setDrawing}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 4:
        return (
          <SimplexMasterForm
            simplex={simplex}
            setSimplex={setSimplex}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 5:
        return (
          <SpinningMasterForm
            spinning={spinning}
            setSpinning={setSpinning}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 6:
        return (
          <AutoConerMasterForm
            autoconer={autoconer}
            setAutoconer={setAutoconer}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      case 7:
        return (
          <OthersMasterForm
            others={others}
            setOthers={setOthers}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
      default:
        return (
          <GeneralMasterForm
            general={general}
            setGeneral={setGeneral}
            loading={isMasterDataFieldsLoading}
            error={isMasterDataFieldsError}
          />
        );
    }
  };

  const renderButtons = () => {
    if (selectedTab === 7) {
      return (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            className="saveButton"
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "2%",
            }}
            onClick={handleSave}
            disabled={updateSavedMutation.isLoading || saveMutation.isLoading}
          >
            {updateSavedMutation.isLoading || saveMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Save and Edit Later"
            )}
          </Button>
          <Button
            variant="contained"
            // className="submitButton"
            style={{
              background: "#181E72",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleSubmit}
            disabled={
              updateSubmittedMutation.isLoading || submitMutation.isLoading
            }
          >
            {updateSubmittedMutation.isLoading || submitMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      );
    } else {
      return (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            className="saveButton"
            color="primary"
            style={{
              // background: "rgb(165, 58, 189)",
              color: "white",
              textTransform: "none",
              marginRight: "2%",
            }}
            onClick={handleSave}
            disabled={updateSavedMutation.isLoading || saveMutation.isLoading}
          >
            {updateSavedMutation.isLoading || saveMutation.isLoading ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              "Save and Edit Later"
            )}
          </Button>
          <Button
            variant="contained"
            onClick={() => setSelectedTab(selectedTab + 1)}
            className="nextButton"
            style={{
              background: "rgb(68, 31, 200)",
              color: "white",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      );
    }
  };

  return (
    <ContainerWrapper
      container
      item
      md={12}
      sm={12}
      xs={12}
      alignContent="flex-start"
      style={{ width: "50%" }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{
          marginTop: "1%",
          paddingBottom: "2%",
        }}
      >
        <h3>Add New Data</h3>
      </Grid>
      <Grid container item xs={12} className="upperGrid">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Party Name"
              value={partyName}
              onChange={(e) => setPartyName(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              label="Variety"
              value={variety}
              onChange={(e) => setVariety(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Party Code"
              value={partyCode}
              onChange={(e) => setPartyCode(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "2%", width: "100%", overflowX: "auto" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          // centered={!isSmallScreen}
          // variant={isSmallScreen ? "scrollable" : "standard"}
          variant={"scrollable"}
          scrollButtons="auto"
        >
          {[
            "General",
            "Blowroom",
            "Carding",
            "Drawing",
            "Simplex",
            "Spinning",
            "Autoconer",
            "Others",
          ].map((label, index) => (
            <Tab
              key={index}
              label={label}
              style={{
                backgroundColor:
                  selectedTab === index
                    ? "rgb(175, 186, 250)"
                    : "rgb(233, 230, 244)",
                border:
                  selectedTab === index ? "2px solid gray" : "1px solid gray",
              }}
            />
          ))}
        </Tabs>
        <Box sx={{ mt: 4 }}>{renderComponent()}</Box>
        <Box sx={{ mt: 6 }}>{renderButtons()}</Box>
      </Box>
    </ContainerWrapper>
  );
}

export default AddParameterMaster;
