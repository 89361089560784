import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
import { RewindingStudyCreate } from "./mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleToastMsg } from "../../../components/Toast";
import { HALL_NO } from "../../../config/masterdata";
import { getVariety } from "../../AutoConer/AutoConerMutationVariety";
import "./rewindingcss.css";

const filter = createFilterOptions();

const RewindingStudies = () => {
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [variety, setVariety] = useState("");
  const [formValues, setFormValues] = useState({
    hallNo: "",
    counts: "",
    denier: "",
    auto_coner_no: "",
    drumNo: "",
    drumFrom: "",
    drumTo: "",
    number_of_cones: "",
    feedback: "",
    slub_code: "",
    weight: "",
    number_of_cuts: "",
    readings: [],
    particular: [],
    breakLengthMeter: 0,
  });

  const generateDrumOptions = (drumFrom, drumTo) => {
    const options = [];
    for (let i = drumFrom; i <= drumTo; i++) {
      options.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };

  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "feedback" || field === "slub_code" || field === "denier") {
      value = value.toUpperCase();
    } else if (
      [
        "counts",
        "hallNo",
        "auto_coner_no",
        "drumNo",
        "drumFrom",
        "drumTo",
        "number_of_cones",
        "number_of_cuts",
        "weight",
      ].includes(field)
    ) {
      const changedValue = value === "" ? "" : parseFloat(value);
      if (changedValue < 0) {
        alert("Please do not enter negative value");
        return;
      } else {
        value = changedValue;
      }
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));

    // if (
    //   field === " number_of_cones" ||
    //   field === "counts" ||
    //   field === "number_of_cuts" ||
    //   field === "weight"
    // ) {
    //   calculateResult(value, field);
    // }
  };

  //   useEffect(() => {
  //     if (formValues.csp_value !== undefined && formValues.counts !== undefined) {
  //         calculateResult();
  //     }
  // }, [formValues.csp_value,formValues.counts ]);

  useEffect(() => {
    const { counts, number_of_cones, weight, number_of_cuts } = formValues;

    const isValidValue = (value) =>
      value !== undefined && value !== null && value !== "";

    if (
      isValidValue(counts) &&
      isValidValue(number_of_cones) &&
      isValidValue(weight) &&
      isValidValue(number_of_cuts)
    ) {
      calculateResult(counts, number_of_cones, weight, number_of_cuts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValues.weight,
    formValues.counts,
    formValues.number_of_cones,
    formValues.number_of_cuts,
  ]);

  const calculateResult = (counts, number_of_cones, weight, number_of_cuts) => {
    // const { counts, number_of_cones, weight, number_of_cuts } = formValues;
    const parsedCounts = parseFloat(counts);
    const parsedCones = parseFloat(number_of_cones);
    const parsedWeight = parseFloat(weight);
    const parsedCuts = parseFloat(number_of_cuts);

    if (
      !isNaN(parsedCounts) &&
      !isNaN(parsedCones) &&
      !isNaN(parsedWeight) &&
      !isNaN(parsedCuts) &&
      parsedCounts !== 0
    ) {
      const value1 = parsedCounts * parsedCones * parsedWeight * 1693;
      const calculatedValue1 = (parsedCuts / value1) * 100000;

      setFormValues((prevState) => {
        let breakLengthMeter = parseFloat(calculatedValue1.toFixed(2)) ?? "";
        return { ...prevState, breakLengthMeter };
      });
    } else {
      setFormValues((prevState) => ({ ...prevState, breakLengthMeter: "" }));
    }
  };
  const { mutate, isLoading } = useMutation(RewindingStudyCreate);

  useEffect(() => {
    const readings = [];
    for (let i = 1; i <= selectedNumber; i++) {
      readings.push({
        readingNo: i,
      });
    }
    setFormValues({ ...formValues, readings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNumber]);

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const handleTable3Change = (event, readingIndex, field) => {
    let { value } = event.target;
    const updatedFormValues = { ...formValues };

    if (field.startsWith("name") || field.startsWith("shortCut")) {
      updatedFormValues.readings[readingIndex][field] = value.toUpperCase();
    } else {
      const parsedValue = value === "" ? "" : parseFloat(value);

      if (parsedValue < 0) {
        alert("Please do not enter negative value");

        return;
      } else if (!isNaN(parsedValue)) {
        updatedFormValues.readings[readingIndex][field] = parsedValue;
      } else {
        value = parsedValue;
      }
    }

    // updatedFormValues.readings[readingIndex]["drumNo"] = formValues.drumNo;
    setFormValues(updatedFormValues);
  };

  // let shortcutSum = 0;
  // let percentSum = 0;
  // let lengthSum = 0;
  // let count = 0;

  // if (Array.isArray(formValues.readings)) {
  //   for (const row of formValues.readings) {
  //     const shortcutValue = parseFloat(row.shortCut);
  //     const percentValue = parseFloat(row.percentOfFault);
  //     const lengthValue = parseFloat(row.lengthInMM);

  //     if (
  //       !isNaN(shortcutValue) &&
  //       !isNaN(percentValue) &&
  //       !isNaN(lengthValue)
  //     ) {
  //       shortcutSum += shortcutValue;
  //       percentSum += percentValue;
  //       lengthSum += lengthValue;
  //       count++;
  //     }
  //   }
  // }

  // const shortcutAverage = count > 0 ? shortcutSum / count : 0;
  // const percentAverage = count > 0 ? percentSum / count : 0;
  // const lengthAverage = count > 0 ? lengthSum / count : 0;

  // const averageRow = {
  //   col1: "Average",
  //   col2: shortcutAverage.toFixed(2),
  //   col3: "N/A",
  //   col4: percentAverage.toFixed(2),
  //   col5: lengthAverage.toFixed(2),
  // };

  const table3Data = Array.isArray(formValues.readings)
    ? [...formValues.readings]
    : [];

  const [storedReadings, setStoredReadings] = useState([]);

  const handleStore = () => {
    const { readings, drumNo, weight, breakLengthMeter, number_of_cuts } =
      formValues;

    // Add drumNo and weight to each reading
    const updatedReadings = readings?.map((reading) => ({
      ...reading,
      drumNo,
      weight,
      breakLengthMeter,
      number_of_cuts,
    }));

    // Make a copy of the storedReadings array
    let storedData = [...storedReadings];
    const drumNoKey = `drumNumber${drumNo}`;
    const drumNoIndex = storedData.findIndex((item) => item[drumNoKey]);

    if (drumNoIndex !== -1) {
      // Update the existing entry with the new readings
      storedData[drumNoIndex] = {
        ...storedData[drumNoIndex],
        [drumNoKey]: updatedReadings,
      };
    } else {
      // Add a new entry for the drum number
      const newEntry = {
        [drumNoKey]: updatedReadings,
      };
      storedData.push(newEntry);
    }

    setStoredReadings(storedData);
    setSelectedNumber(0);
    setFormValues((prevState) => ({
      ...prevState,
      readings: [],
      number_of_cuts: "",
      drumNo: "",
      weight: "",
    }));
  };

  useEffect(() => {
    const { readings, drumNo, weight, breakLengthMeter, number_of_cuts } =
      formValues;

    if (!readings || readings.length === 0) return;

    const updatedReadings = readings?.map((reading) => ({
      ...reading,
      drumNo,
      weight,
      breakLengthMeter,
      number_of_cuts,
    }));

    // Make a copy of the storedReadings array
    let storedData = [...storedReadings];
    const drumNoKey = `drumNumber${drumNo}`;
    const drumNoIndex = storedData.findIndex((item) => item[drumNoKey]);

    if (drumNoIndex !== -1) {
      // Update the existing entry with the new readings
      storedData[drumNoIndex] = {
        ...storedData[drumNoIndex],
        [drumNoKey]: updatedReadings,
      };
    } else {
      // Add a new entry for the drum number
      const newEntry = {
        [drumNoKey]: updatedReadings,
      };
      storedData.push(newEntry);
    }

    setStoredReadings(storedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const flattenReadings = (readings) => {
    const allReadings = [];

    readings?.forEach((entry) => {
      Object.values(entry)?.forEach((readings) => {
        allReadings?.push(...readings);
      });
    });

    return allReadings;
  };

  const flattenedReadings = flattenReadings(storedReadings);

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    try {
      const payload = {
        variety: variety,
        hall: formValues.hallNo,
        counts: formValues.counts,
        denier: formValues.denier,
        autoConerNo: formValues.auto_coner_no,
        drumNo: `${formValues.drumFrom}-${formValues.drumTo}`,
        numberOfCones: formValues.number_of_cones,
        particular: flattenedReadings,
        feedback: formValues.feedback,
        slubCode: formValues.slub_code,
        machinename: `${formValues.counts}'S, ${variety}, ${formValues.denier}, ${formValues.slub_code}`,
      };

      mutate(payload, {
        onSuccess: (res) => {
          queryClient.invalidateQueries("get-variety");
          queryClient.invalidateQueries("manager-autoconer-notifications");
          handleToastMsg("Data updated successfully", "success");
          setVariety("");
          setFormValues({
            hallNo: "",
            counts: "",
            denier: "",
            auto_coner_no: "",
            feedback: "",
            drumNo: "",
            drumFrom: "",
            drumTo: "",
            slub_code: "",
            weight: "",
            number_of_cones: "",
            number_of_cuts: "",
          });

          setStoredReadings([]);
        },
        onError: (error) => {
          handleToastMsg("Data Error Occured", "error");
        },
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid
      container
      item
      md={12}
      xs={12}
      alignContent="flex-start"
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        marginTop: "1%",
        paddingBottom: "4%",
      }}
    >
      <Grid mt={2} item xs={12}>
        <h2 className="heading">Rewinding Study</h2>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableRow>
          <TableCell>Hall Number</TableCell>
          <TableCell>
            <TextField
              value={formValues.hallNo}
              select
              onChange={(e) => handleFormChange(e, "hallNo")}
              type="number"
              sx={{ width: 180 }}
              id="outlined-basic"
              label="Hall Number"
              variant="outlined"
              // onInput={handleCardingLineNumInput}
            >
              {HALL_NO?.map((hall) => (
                <MenuItem key={hall} value={hall}>
                  {hall}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        </TableRow>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Variety</TableCell>
                  <TableCell>
                    <div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={variety}
                          fullWidth
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setVariety(newValue || "");
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setVariety(newValue || "");
                            } else {
                              setVariety(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          styles={{ width: "100%" }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="Variety"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Counts</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="Counts"
                      label="Counts"
                      value={formValues.counts}
                      onChange={(e) => handleFormChange(e, "counts")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Denier</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Denier"
                      label="Denier"
                      value={formValues.denier}
                      onChange={(e) => handleFormChange(e, "denier")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Slub Code</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label="Slub Code"
                      placeholder="Slub Code"
                      value={formValues.slub_code}
                      onChange={(e) => handleFormChange(e, "slub_code")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Coner No</TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      placeholder="Auto Coner No"
                      label="Auto Coner No"
                      value={
                        isNaN(formValues.auto_coner_no)
                          ? ""
                          : formValues.auto_coner_no
                      }
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /^0+|[^0-9]/g,
                          ""
                        );
                      }}
                      onChange={(e) => handleFormChange(e, "auto_coner_no")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drum No</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum From"
                          fullWidth
                          value={formValues.drumFrom}
                          onChange={(e) => handleFormChange(e, "drumFrom")}
                          label="Drum From"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          placeholder="Drum To"
                          fullWidth
                          value={formValues.drumTo}
                          onChange={(e) => handleFormChange(e, "drumTo")}
                          label="Drum To"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of cones</TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      type="number"
                      fullWidth
                      placeholder="Number Of Cones"
                      label="Number Of Cones"
                      value={formValues.number_of_cones}
                      onChange={(e) => handleFormChange(e, "number_of_cones")}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        mt={2}
        container
        spacing={2}
        style={{
          scrollbarWidth: "unset",
          scrollbarColor: "dark dark",
          msOverflowStyle: "none",
          scrollbarTrackColor: "black",
        }}
      >
        <TableRow>
          <TableCell>Drum No</TableCell>
          <TableCell>
            <Select
              value={formValues.drumNo || ""}
              onChange={(e) => handleFormChange(e, "drumNo")}
              fullWidth
              variant="outlined"
            >
              {generateDrumOptions(formValues.drumFrom, formValues.drumTo)}
            </Select>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Number of cuts</TableCell>
          <TableCell>
            <FormControl variant="outlined">
              <TextField
                type="number"
                variant="outlined"
                label="Number Of Cuts"
                value={formValues.number_of_cuts}
                onChange={(e) => {
                  const number_of_cuts =
                    e.target.value === "" ? "" : parseInt(e.target.value);
                  setFormValues({
                    ...formValues,
                    number_of_cuts: number_of_cuts,
                  });
                  setSelectedNumber(number_of_cuts);
                }}
              />
            </FormControl>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Weight</TableCell>
          <TableCell>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              label="Weight"
              placeholder="Weight (in kg)"
              value={formValues.weight}
              onChange={(e) => handleFormChange(e, "weight")}
            />
          </TableCell>
        </TableRow>
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: "1vw",
                    }}
                  >
                    Reading Number
                  </TableCell>
                  <TableCell
                    style={{
                      width: "1vw",
                    }}
                  >
                    Short Cut
                  </TableCell>
                  <TableCell
                    style={{
                      width: "1vw",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      width: "1vw",
                    }}
                  >
                    % Of Fault
                  </TableCell>
                  <TableCell
                    style={{
                      width: "1vw",
                    }}
                  >
                    Length In MM
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table3Data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.readingNo}</TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Shortcut"
                        label="ShortCut"
                        value={row[`shortCut`]}
                        onChange={(e) =>
                          handleTable3Change(e, index, `shortCut`)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Name"
                        label="Name"
                        value={row[`name`]}
                        onChange={(e) => handleTable3Change(e, index, `name`)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="% Of Fault"
                        type="number"
                        placeholder="% Of Fault"
                        value={row[`percentOfFault`] ?? ""}
                        onChange={(e) =>
                          handleTable3Change(e, index, `percentOfFault`)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        placeholder="Length In MM"
                        label="Length In MM"
                        value={row[`lengthInMM`] ?? ""}
                        onChange={(e) =>
                          handleTable3Change(e, index, `lengthInMM`)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Grid>
      <Grid sx={{ mt: 3 }} item xs={6} sm={6} md={6} lg={6} xl={4}>
        <TextField
          size="small"
          id="standard-number"
          type="number"
          step="0.01"
          variant="outlined"
          style={{ width: "150px" }}
          disabled={true}
          name="Breaks/Length metre"
          label="Break Length Metre"
          helperText="Breaks/Length metre"
          value={formValues?.breakLengthMeter ?? ""}
        />
      </Grid>

      <Grid container item md={12} mt={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Drum Number
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Reading Number
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Short Cut
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  % Of Fault
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Length In MM
                </TableCell>
                <TableCell
                  style={{
                    width: "1vw",
                  }}
                >
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flattenedReadings.length > 0 &&
                flattenedReadings?.map((reading, index) => (
                  <TableRow key={index}>
                    <TableCell>{reading?.drumNo}</TableCell>
                    <TableCell>{reading?.readingNo}</TableCell>
                    <TableCell>{reading?.shortCut}</TableCell>
                    <TableCell>{reading?.name}</TableCell>
                    <TableCell>{reading?.percentOfFault}</TableCell>
                    <TableCell>{reading?.lengthInMM}</TableCell>
                    <TableCell>{reading?.weight}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => handleFormChange(e, "feedback")}
          name="Size"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={12} sm={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleStore}
          >
            Store
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            className="submitbutton"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Final Submit"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RewindingStudies;
