// eslint-disable
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { HALL_NO } from "../../config/masterdata";
import { getVariety } from "../AutoConer/AutoConerMutationVariety";
import "./spinningcss.css";
import { ContainerWrapper } from "../../components/styles";

const CountChange = () => {
  const filter = createFilterOptions();
  const [formValues, setFormValues] = useState({
    counts: "",
    machine_number: "",
    feedback: "",
  });

  const navigate = useNavigate();

  /**handle state */
  const { state } = useLocation();
  const ishariness = state?.isHariness;
  const isApron = state?.isApron;
  const isCotChecking = state?.isCotChecking;

  const [hallNo, setHallNo] = React.useState("");
  /**handle form change */
  const handleFormChange = (event, field) => {
    let { value } = event.target;

    if (field === "feedback") {
      value = value.toUpperCase();
    } else if (["counts"].includes(field)) {
      value = !isNaN(value) ? parseFloat(value) : value;
    }
    setFormValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const { data: varietyData } = useQuery("get-variety", () => getVariety(), {
    refetchOnMount: true,
  });

  const initialMachineNumbers = [...Array(24).keys()].map(
    (_, index) => index + 1
  );

  const initialVarietyRowsState = initialMachineNumbers?.map(() => ({
    variety: "",
  }));

  const initialTypeRowsState = initialMachineNumbers?.map(() => ({
    type: "",
  }));

  const [varietyRows, setVarietyRows] = useState(initialVarietyRowsState);
  const [typeRows, setTypeRows] = useState(initialTypeRowsState);

  const [machineNumbers, setMachineNumbers] = useState(initialMachineNumbers);
  const [lhsrhsValues, setLhsrhsValues] = useState(initialMachineNumbers);
  const [lhsLengths, setLhsLengths] = useState(Array(24).fill(0));
  const [rhsLengths, setRhsLengths] = useState(Array(24).fill(0));

  /**handle delete button status */
  //   const [deleteButtonStatus, setDeleteButtonStatus] = useState(
  //     initialMachineNumbers.reduce(
  //       (acc, machineNo) => ({ ...acc, [machineNo]: false }),
  //       {}
  //     )
  //   );

  // const [deleteButtonStatus, setDeleteButtonStatus] = useState(
  //   initialMachineNumbers.reduce((acc, machineNo) => {
  //     acc[machineNo] = false;
  //     return acc;
  //   }, {})
  // );

  /**handle RHS change values */
  const [cotCheckRecords, setCotCheckRecords] = useState([]);

  useEffect(() => {
    if (state?.data) {
      let records;
      if (ishariness) {
        records = state?.data?.hairness_records || [];
      } else if (isApron) {
        records = state?.data?.ASCRecordDrafts || [];
      } else if (isCotChecking) {
        records = state?.data?.CotCheckRecordDrafts || [];
      } else {
        records = [];
      }

      if (state?.data?.hall) {
        setHallNo(state?.data?.hall);
      }

      const processedRecords = Array.from({ length: 24 }, (_, i) => ({
        machine_number: i + 1,
        lhs: 0,
        rhs: 0,
        remarks: "",
        ...(ishariness && { variety: "" }),
        ...(isApron && { asc_type: "" }),
      }));

      // Process incoming records to merge data for the same machine_number
      records?.forEach((record) => {
        const index = record.machine_number - 1;
        if (index >= 0 && index < 24) {
          processedRecords[index] = { ...processedRecords[index], ...record };
        }
      });

      // Add additional records for machine numbers appearing multiple times
      const additionalRecords = [];
      const machineNumbersSet = new Set();

      records?.forEach((record) => {
        if (machineNumbersSet?.has(record.machine_number)) {
          const existingIndex = processedRecords.findIndex(
            (item) => item.machine_number === record.machine_number
          );
          additionalRecords.push({
            ...processedRecords[existingIndex],
            ...record,
          });
        } else {
          machineNumbersSet.add(record.machine_number);
        }
      });

      additionalRecords.forEach((record) => {
        processedRecords.splice(record.machine_number, 0, record);
      });

      setLhsLengths(processedRecords?.map((record) => record.lhs));
      setRhsLengths(processedRecords?.map((record) => record.rhs));

      /**change the values as for both */
      const updatedValues = processedRecords.map((record) => ({
        lhs: record.lhs,
        rhs: record.rhs,
        remarks: record.remarks,
        machine_number: record.machine_number,
        ...(record?.variety && { variety: record?.variety || "" }),
        ...(record?.asc_type && { type: record?.asc_type || "" }),
      }));

      setLhsrhsValues(updatedValues);
      setCotCheckRecords(records);

      // Update machine numbers based on processed records
      setMachineNumbers(
        processedRecords?.map((record) => record.machine_number)
      );

      // Update variety rows based on processed records
      setVarietyRows(() => {
        const updatedVarietyRows = processedRecords?.map((record) => ({
          variety: record?.variety || "",
        }));
        return updatedVarietyRows;
      });

      // Update variety rows based on processed records
      setTypeRows(() => {
        const updatedTypeRows = processedRecords.map((record) => ({
          type: record?.asc_type || "",
        }));
        return updatedTypeRows;
      });

      setFormValues((prev) => ({ ...prev, feedback: state?.data?.feedback }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChangeLHS = (machine_number, index, value) => {
    const updatedValue = value === "" ? "" : value;
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    const updatedLhsLengths = [...lhsLengths];
    updatedLhsLengths[index] = value.length; // store length

    setLhsLengths(updatedLhsLengths);

    setLhsrhsValues((prev) => {
      const updatedMachineNumbers = [...prev];

      // const recordIndex = updatedMachineNumbers?.findIndex(
      //   (record) => record.machine_number === machine_number
      // );

      const recordIndex = updatedMachineNumbers
        .map((record, i) => (record.machine_number === machine_number ? i : -1))
        .filter((i) => i !== -1);

      recordIndex.forEach((recordIndex) => {
        updatedMachineNumbers[recordIndex] = {
          ...updatedMachineNumbers[recordIndex],
          lhs: updatedValue,
          machine_number,
        };
      });

      // If no matching machine number is found, add a new record or handle the case accordingly
      if (recordIndex.length === 0) {
        updatedMachineNumbers[index] = {
          ...updatedMachineNumbers[index],
          lhs: updatedValue,
          machine_number,
        };
      }

      // if (recordIndex !== -1) {
      //   updatedMachineNumbers[recordIndex] = {
      //     ...updatedMachineNumbers[recordIndex],
      //     lhs: updatedValue,
      //     machine_number,
      //   };
      // } else {
      //   // If no matching machine number is found, add a new record or handle the case accordingly
      //   updatedMachineNumbers[index] = {
      //     ...updatedMachineNumbers[index],
      //     lhs: updatedValue,
      //     machine_number,
      //   };
      // }

      //previuos one
      // updatedMachineNumbers[index] = {
      //   ...updatedMachineNumbers[index],
      //   lhs: updatedValue,
      //   machine_number,
      // };
      return updatedMachineNumbers;
    });

    setCotCheckRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];

      const recordIndex = updatedRecords?.findIndex(
        (record) => record?.machine_number === machine_number
      );

      if (recordIndex !== -1) {
        updatedRecords[recordIndex] = {
          ...updatedRecords[recordIndex],
          lhs: updatedValue,
          machine_number,
          index: index,
          remarks: lhsrhsValues[index]?.remarks || null,
        };
      } else {
        // If no matching machine number is found, add a new record or handle the case accordingly
        updatedRecords[index] = {
          ...updatedRecords[index],
          lhs: updatedValue,
          machine_number,
          index: index,
          remarks: lhsrhsValues[index]?.remarks || null,
        };
      }

      // updatedRecords[index] = {
      //   ...updatedRecords[index],
      //   lhs: updatedValue,
      //   machine_number,
      //   index: index,
      //   remarks: lhsrhsValues[index]?.remarks || null,
      // };
      return updatedRecords;
    });
  };

  /**handle LHS change values */
  const handleChangeRHS = (machine_number, index, value) => {
    const updatedValue = value === "" ? "" : parseFloat(value);
    if (value < 0) {
      alert("Please do not enter negative value");
      return;
    }

    const updatedRhsLengths = [...rhsLengths];
    updatedRhsLengths[index] = value.length; // store length

    setRhsLengths(updatedRhsLengths);

    setLhsrhsValues((prev) => {
      const updatedMachineNumbers = [...prev];

      const recordIndex = updatedMachineNumbers?.findIndex(
        (record) => record?.machine_number === machine_number
      );

      if (recordIndex !== -1) {
        updatedMachineNumbers[recordIndex] = {
          ...updatedMachineNumbers[recordIndex],
          rhs: updatedValue,
          machine_number,
        };
      } else {
        // If no matching machine number is found, add a new record or handle the case accordingly
        updatedMachineNumbers[index] = {
          ...updatedMachineNumbers[index],
          rhs: updatedValue,
          machine_number,
        };
      }

      // updatedMachineNumbers[index] = {
      //   ...updatedMachineNumbers[index],
      //   rhs: updatedValue,
      //   machine_number,
      // };
      return updatedMachineNumbers;
    });

    setCotCheckRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];

      const recordIndex = updatedRecords?.findIndex(
        (record) => record?.machine_number === machine_number
      );

      if (recordIndex !== -1) {
        updatedRecords[recordIndex] = {
          ...updatedRecords[recordIndex],
          rhs: updatedValue,
          machine_number,
          index: index,
          remarks: lhsrhsValues[index]?.remarks || null,
        };
      } else {
        // If no matching machine number is found, add a new record or handle the case accordingly
        updatedRecords[index] = {
          ...updatedRecords[index],
          rhs: updatedValue,
          machine_number,
          index: index,
          remarks: lhsrhsValues[index]?.remarks || null,
        };
      }

      // updatedRecords[index] = {
      //   ...updatedRecords[index],
      //   rhs: updatedValue,
      //   machine_number,
      //   index: index,
      //   remarks: lhsrhsValues[index]?.remarks || null,
      // };
      return updatedRecords;
    });
  };

  const handleRemarks = (machine_number, index, value) => {
    setLhsrhsValues((prev) => {
      const updatedMachineNumbers = [...prev];
      updatedMachineNumbers[index] = {
        ...updatedMachineNumbers[index],
        remarks: value,
        machine_number,
      };
      return updatedMachineNumbers;
    });

    setCotCheckRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];
      updatedRecords[index] = {
        ...updatedRecords[index],
        remarks: value,
        machine_number,
        index: index,
      };
      return updatedRecords;
    });
  };

  const filteredRecords = cotCheckRecords?.filter(
    (record) => record !== undefined
  );

  /**calculate Total */
  const calculateTotal = (lengths) => {
    return lengths?.filter((length) => length > 0).length;
  };

  const lhsTotal = calculateTotal(lhsLengths);
  const rhsTotal = calculateTotal(rhsLengths);
  // const total = lhsTotal + rhsTotal;

  /**handle Variety */
  const handleVariety = (machine_number, index, value) => {
    const upperCaseValue =
      value === "" || value === null ? "" : value.toUpperCase();

    setVarietyRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].variety = upperCaseValue;
      return updatedRows;
    });

    setCotCheckRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];
      const recordIndex = updatedRecords?.findIndex(
        (record) => record?.machine_number === machine_number
      );

      if (recordIndex !== -1) {
        updatedRecords[recordIndex] = {
          ...updatedRecords[recordIndex],
          variety: upperCaseValue,
          remarks: lhsrhsValues[recordIndex]?.remarks || null,
        };
      } else {
        // If no matching machine number is found, add a new record or handle the case accordingly
        updatedRecords[index] = {
          ...updatedRecords[index],
          machine_number,
          index: index,
          remarks: lhsrhsValues[index]?.remarks || null,
          variety: upperCaseValue,
        };
      }

      // updatedRecords[index] = {
      //   ...updatedRecords[index],
      //   machine_number,
      //   index: index,
      //   remarks: lhsrhsValues[index]?.remarks || null,
      //   variety: upperCaseValue,
      // };

      return updatedRecords;
    });
  };

  /**handle Type */
  const handleType = (machine_number, index, value) => {
    setTypeRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].type = value;
      return updatedRows;
    });

    setCotCheckRecords((prevRecords) => {
      const updatedRecords = [...prevRecords];
      updatedRecords[index] = {
        ...updatedRecords[index],
        machine_number,
        index: index,
        remarks: lhsrhsValues[index]?.remarks || null,
        asc_type: value,
      };
      return updatedRecords;
    });
  };

  // const [varietyCopy, setVarietyCopy] = useState({
  //   variety: "",
  // });

  // const [newRecord] = useState({});

  // handle Add function
  // const handleAdd = (machine_number, index) => {
  //   // Clone the current machineNumbers array

  //   const newMachineNo = machineNumbers[index];

  //   if (ishariness) {
  //     setVarietyRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       const newVarietyValue = updatedRows[index]?.variety || "";
  //       updatedRows.splice(index + 1, 0, { variety: newVarietyValue });
  //       // setVarietyCopy({ variety: newVarietyValue });

  //       setCotCheckRecords((prevRecords) => [
  //         ...prevRecords,
  //         {
  //           variety: newVarietyValue,
  //           index: index + 1,
  //           machine_number,
  //         },
  //       ]);

  //       return updatedRows;
  //     });
  //   }

  //   if (isApron) {
  //     setTypeRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       const newTypeValue = updatedRows[index]?.type || "";
  //       updatedRows.splice(index + 1, 0, { type: newTypeValue });

  //       setCotCheckRecords((prevRecords) => [
  //         ...prevRecords,
  //         {
  //           asc_type: newTypeValue,
  //           index: index + 1,
  //           machine_number,
  //         },
  //       ]);

  //       return updatedRows;
  //     });
  //   }

  //   const updatedMachineNumbers = [...machineNumbers];
  //   updatedMachineNumbers.splice(index + 1, 0, newMachineNo);

  //   const updatedlhsrhsValues = [...lhsrhsValues];
  //   updatedlhsrhsValues.splice(index + 1, 0, newMachineNo);

  //   // Insert a new machine number at the specified index
  //   const updatedLhsLengths = [...lhsLengths];
  //   const updatedRhsLengths = [...rhsLengths];

  //   updatedLhsLengths.splice(index + 1, 0, 0);
  //   updatedRhsLengths.splice(index + 1, 0, 0);

  //   // Update the state with the new array
  //   setLhsrhsValues(updatedlhsrhsValues);
  //   setMachineNumbers(updatedMachineNumbers);
  //   setLhsLengths(updatedLhsLengths);
  //   setRhsLengths(updatedRhsLengths);

  //   setDeleteButtonStatus((prevStatus) => ({
  //     ...prevStatus,
  //     [machine_number]: machineNumbers.includes(machine_number),
  //   }));

  //   if (isCotChecking) {
  //     setCotCheckRecords((prevRecords) => [...prevRecords, newRecord]);
  //   } else {
  //     return;
  //   }
  // };

  // handle Delete function
  // const handleDelete = (machine_number, index) => {
  //   if (ishariness) {
  //     setVarietyRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       updatedRows.splice(index, 1);
  //       return updatedRows;
  //     });
  //   }

  //   if (isApron) {
  //     setTypeRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       updatedRows.splice(index, 1);
  //       return updatedRows;
  //     });
  //   }

  //   // Clone the current machineNumbers array
  //   const updatedMachineNumbers = [...machineNumbers];
  //   updatedMachineNumbers.splice(index, 1);

  //   const updatedlhsrhsValues = [...lhsrhsValues];
  //   updatedlhsrhsValues.splice(index, 1);

  //   const updatedLhsLengths = [...lhsLengths];
  //   updatedLhsLengths.splice(index, 1);

  //   const updatedRhsLengths = [...rhsLengths];
  //   updatedRhsLengths.splice(index, 1);

  //   // Update the state with the new array
  //   setLhsrhsValues(updatedlhsrhsValues);
  //   setMachineNumbers(updatedMachineNumbers);
  //   setLhsLengths(updatedLhsLengths);
  //   setRhsLengths(updatedRhsLengths);

  //   const remainingRows = machineNumbers.filter(
  //     (no) => no === machine_number
  //   ).length;

  //   setDeleteButtonStatus((prevStatus) => ({
  //     ...prevStatus,
  //     [machine_number]: remainingRows > 2,
  //   }));

  //   // const recordToDelete = cotCheckRecords.find(
  //   //   (record) => record.index === index
  //   // );

  //   setCotCheckRecords((prevRecords) =>
  //     prevRecords.filter(
  //       (record) => record !== undefined && record.index !== index
  //     )
  //   );
  // };

  const cotCheckingPayload = {
    total_lhs: lhsTotal,
    hall: hallNo,
    total_rhs: rhsTotal,
    cot_check_records: filteredRecords,
    feedback: formValues?.feedback,
    ...(state?.isDraft && { cotChecksDraftId: state?.data?.id }),
    cot_checking_buffs: {
      number_of_cots: lhsTotal + rhsTotal,
      cot_buffing: "",
      remarks: "",
    },
  };

  const HarinessPayload = {
    total_lhs: lhsTotal,
    hall: hallNo,

    total_rhs: rhsTotal,
    hairness_records: filteredRecords,
    feedback: formValues?.feedback,
    ...(state?.isDraft && { hairnessDraftId: state?.data?.id }),
    hairness_errors: {
      number_of_errors: lhsTotal + rhsTotal,
      corrected: "",
      remarks: "",
    },
  };

  const ASCPayload = {
    total_lhs: lhsTotal,
    hall: hallNo,
    total_rhs: rhsTotal,
    asc_records: filteredRecords,
    feedback: formValues?.feedback,
    ...(state?.isDraft && { ascDraftId: state?.data?.id }),
    asc_errors: {
      number_of_errors: lhsTotal + rhsTotal,
      corrected: "",
      remarks: "",
    },
  };
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    const draftId = state?.data?.id || "";
    const cotCheckDrafts = (state?.isCotChecking && state?.isDraft) || false;
    const harinessDrafts = (state?.isHariness && state?.isDraft) || false;
    const ascDrafts = (state?.isApron && state?.isDraft) || false;

    if (isCotChecking) {
      navigate("/cot-checking-submit", {
        state: {
          cotCheckingPayload: cotCheckingPayload,
          draftId,
          cotCheckDrafts,
        },
      });
    }

    if (ishariness) {
      queryClient.invalidateQueries("get-variety");
      navigate("/hariness-submit", {
        state: { HarinessPayload: HarinessPayload, draftId, harinessDrafts },
      });
    }

    if (isApron) {
      navigate("/apron-submit", {
        state: { ASCPayload: ASCPayload, draftId, ascDrafts },
      });
    }
  };

  return (
    <ContainerWrapper container item xs={12}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <TextField
          value={hallNo}
          select
          onChange={(e) => setHallNo(parseInt(e.target.value))}
          type="number"
          sx={{ width: 180 }}
          id="outlined-basic"
          label="Hall Number"
          variant="outlined"
          // onInput={handleCardingLineNumInput}
        >
          {HALL_NO?.map((hall) => (
            <MenuItem key={hall} value={hall}>
              {hall}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Machine No</TableCell>
                {ishariness && <TableCell>Variety</TableCell>}
                {isApron && <TableCell>Type</TableCell>}
                <TableCell>LHS</TableCell>
                <TableCell>RHS</TableCell>
                <TableCell>Remarks</TableCell>
                {/* <TableCell style={{ width: "1vw" }}>Add</TableCell>
                <TableCell style={{ width: "1vw" }}>Delete</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {machineNumbers?.map((machine_number, index) => (
                <TableRow key={index}>
                  <TableCell>{machine_number}</TableCell>
                  {ishariness && (
                    <TableCell>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "10px",
                        }}
                      >
                        <Autocomplete
                          value={varietyRows[index]?.variety ?? ""}
                          sx={{ width: "200px" }}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              handleVariety(machine_number, index, newValue);
                            } else if (typeof newValue === "string") {
                              handleVariety(machine_number, index, newValue);
                            } else if (newValue && newValue.inputValue) {
                              handleVariety(machine_number, index, newValue);
                            } else {
                              handleVariety(machine_number, index, newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue.toUpperCase());
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={varietyData || []} // Use varietyData as options, or an empty array if it's not available yet
                          getOptionLabel={(option) =>
                            typeof option === "string" ? option : ""
                          } // Ensure option is a string
                          renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Variety"
                              fullWidth
                              type="text"
                              // value={varietyRows[index]?.variety || ""}
                              onChange={(e) =>
                                handleVariety(
                                  machine_number,
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                  {isApron && (
                    <TableCell>
                      <TextField
                        variant="outlined"
                        select
                        sx={{ width: "200px" }}
                        fullWidth
                        value={typeRows[index]?.type}
                        onChange={(e) =>
                          handleType(machine_number, index, e.target.value)
                        }
                      >
                        <MenuItem value="APRON">APRON</MenuItem>
                        <MenuItem value="SPACER">SPACER</MenuItem>
                        <MenuItem value="CONDENSER">CONDENSER</MenuItem>
                      </TextField>
                    </TableCell>
                  )}
                  <TableCell>
                    <TextField
                      type="number"
                      value={lhsrhsValues[index]?.lhs || ""}
                      onChange={(e) =>
                        handleChangeLHS(machine_number, index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={lhsrhsValues[index]?.rhs || ""}
                      onChange={(e) =>
                        handleChangeRHS(machine_number, index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      value={lhsrhsValues[index]?.remarks || ""}
                      onChange={(e) =>
                        handleRemarks(machine_number, index, e.target.value)
                      }
                    />
                  </TableCell>
                  {/* <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAdd(machine_number, index)}
                    >
                      +
                    </Button>
                  </TableCell> */}
                  {/* <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDelete(machine_number, index)}
                      disabled={!deleteButtonStatus[machine_number]}
                    >
                      -
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}

              <TableRow>
                <TableCell>Total</TableCell>
                {ishariness && <TableCell style={{ width: "1vw" }}></TableCell>}
                {isApron && <TableCell style={{ width: "1vw" }}></TableCell>}
                <TableCell>
                  {lhsLengths.filter((length) => length > 0).length}
                </TableCell>
                <TableCell>
                  {rhsLengths.filter((length) => length > 0).length}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid mt={3} item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => handleFormChange(e, "feedback")}
          name="Size"
          variant="outlined"
          label="Feedback (or) Any Queries"
          value={formValues.feedback}
        />
      </Grid>
      <Grid mt={3} item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", height: "50px" }}
            className="submitbutton"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Grid>
    </ContainerWrapper>
  );
};

export default CountChange;
