import apiClient from "../../../utils/clients/axios";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

// Get all records from the general master
export const getAllSubmittedData = async (masterKey) => {
  const flow = masterKey + "_master";

  const response = await apiClient.get(`/api/quality_control/${flow}`);
  return response;
};

// post general drafts records
export const saveDraftsData = async (payload, masterKey) => {
  const flow = masterKey + "_master";
  const endPoint = "create" + capitalizeFirstLetter(masterKey) + "MasterDraft";
  const response = await apiClient.post(
    `/api/quality_control/${flow}/${endPoint}`,
    payload
  );
  return response;
};

// post general submit all records
export const submitData = async (payload, masterKey) => {
  const flow = masterKey + "_master";
  const response = await apiClient.post(
    `/api/quality_control/${flow}`,
    payload
  );
  return response;
};

// put general drafts records
export const updateDraftsData = async (params) => {
  const { payload, id, masterKey } = params;
  const flow = masterKey + "_master";
  const endPoint = "update" + capitalizeFirstLetter(masterKey) + "MasterDraft";

  const response = await apiClient.put(
    `/api/quality_control/${flow}/${endPoint}/${id}`,
    payload
  );
  return response;
};

// update manager(Respond)
export const managerUpdateData = async (params) => {
  const { payload, id, masterKey } = params;
  const flow = masterKey + "_master";
  const response = await apiClient.put(
    `/api/quality_control/${flow}/${id}`,
    payload
  );
  return response;
};

// Get standards data from  parameter master data
export const getStandardsMasterData = async (params) => {
  const { partyCode, key } = params;
  const response = await apiClient.get(`/api/quality_control/master_data/`, {
    params: { partyCode: partyCode, key: key },
  });
  return response;
};
