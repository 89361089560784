import apiClient from "../../../utils/clients/axios";

export const SpliceStrengthCreate = async (payload) => {
  const response = await apiClient.post(
    `/api/quality_control/autoconer/splice-strength-create/`,
    payload
  );
  return response;
};

export const varietyMasterData = async () => {
  const response = await apiClient.get(
    "/api/quality_control/autoconer/autoconer-variety-master-data/"
  );

  return response;
};
