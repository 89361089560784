const CalculateCV = (data) => {
  if (!data || data?.length === 0) {
    return; // or throw an error as per your application's error handling strategy
  }

  let noOfReadings = data?.length;

  // Calculate the mean (average) value
  const calculateMean = (data) => {
    const sum = data?.reduce((acc, value) => acc + value, 0);
    return sum / noOfReadings;
  };

  

  // Calculate the variance value
  const calculateVariance = (data, mean) => {
    if (noOfReadings <= 1) {
      return 0; // If there's only one reading, variance is zero
    }

    const squaredDiffs = data?.map((value) => (value - mean) ** 2);
    const sumOfSquaredDiffs = squaredDiffs?.reduce(
      (acc, value) => acc + value,
      0
    );

    const variance = sumOfSquaredDiffs / (noOfReadings - 1);

    return variance;
  };

  const mean = calculateMean(data);
  const variance = calculateVariance(data, mean);

  // Calculate standard deviation and CV
  const stdDev = Math.sqrt(variance);
  const CV = (stdDev / mean) * 100;

  return CV.toFixed(2);
};

export default CalculateCV;
