import apiClient from "../../../../utils/clients/axios";

export const chuteFeed_1_5_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.put(
    `/api/quality_control/blow_room/chute_feed_1_5_update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const chuteFeed_7_14_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.put(
    `/api/quality_control/blow_room/chute_feed_7_14_update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const lapFeed_15_24_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.put(
    `/api/quality_control/blow_room/lap_feed_15_24_update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const droptest_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.put(
    `/api/quality_control/blow_room/drop_test_update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const finisher_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/finisher/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const count_change_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/spinning/count-change-drafts/${id}`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const cot_checking_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/spinning/cot_checks_draft/${id}`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const hariness_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/spinning/hairness-drafts/${id}`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const asc_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/spinning/asces_draft/${id}`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const splice_strength_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/autoconer/splice-strength-update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};

export const rewinding_study_update = async (payload) => {
  const { feedback, id } = payload;
  const response = await apiClient.patch(
    `/api/quality_control/autoconer/rewinding-study-update/${id}/`,
    {
      feedback: feedback,
    }
  );

  return response;
};
